import React from 'react';
import { DetailFieldProps } from '../..';
import { FieldMultiSelect, FieldSelect, FieldSelectConfig } from '../../../../data/types';
import Select from '../../../Input/Select/Select';


export const SelectMultipleDetailField = ({field, internalValue, onChange}:DetailFieldProps) => {
    const {
        config={options: []}
    } = field;
    const {
        options=[]
    } = config as FieldSelectConfig;
    return (
        <Select
            multiple
            options={options}
            onChange={onChange}
            value={internalValue as string[]}
        />
    )
}

export default SelectMultipleDetailField;