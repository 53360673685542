import { useSelector } from 'react-redux';
import { getContentTypeFields } from '../../../../../../../data/content-type/content-types/selectors';

import { cloneDeep as _cloneDeep } from 'lodash';
import { useCallback, useEffect } from 'react';
import {
  DeletedRecordWithFilterAutomation,
  FilterConditionGroup,
  NewRecordWithFilterAutomation,
  RecordAutomation,
  ScheduledWithFilterAutomation,
  UpdatedRecordWithFilterAutomation,
} from '@taida-corp/taidacorp-sdk';
import { FilterBuilderForm } from '../../../../../components/FilterBuilder/FilterBuilderForm';

interface AutomationWithFilterFormProps {
  updateAutomation: (updatedRecordAutomation:RecordAutomation) => void;
  formData:
    | NewRecordWithFilterAutomation
    | UpdatedRecordWithFilterAutomation
    | DeletedRecordWithFilterAutomation
    | ScheduledWithFilterAutomation;
}

export const AutomationWithFilterForm = ({ formData, updateAutomation }: AutomationWithFilterFormProps) => {
  const allFields = useSelector(getContentTypeFields);
  const setFilters = useCallback(
    (newFilters: FilterConditionGroup) => {
      const updated = _cloneDeep(formData) as NewRecordWithFilterAutomation;
      updated.triggerConfig.filters = newFilters;
      updateAutomation(updated);
    },
    [formData, updateAutomation],
  );

  useEffect(() => {
    if (formData.triggerConfig.filters === undefined) {
      const updated = _cloneDeep(formData) as NewRecordWithFilterAutomation;
      updated.triggerConfig.filters = { operator: 'and', conditions: [] };
      updateAutomation(updated);
    }
  }, [formData, updateAutomation]);

  if (formData.triggerConfig.filters === undefined) {
    return null;
  }

  return (
    <FilterBuilderForm
      filterActionText="Automation Will Only Run For Records Matching These Filters"
      filters={formData.triggerConfig.filters}
      setFilters={setFilters}
      fields={allFields}
    />
  );
};
