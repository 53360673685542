import React, { useCallback, useEffect, useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';

export const useSelectOption = ({ value, index, onMoveOption }: any) => {
  const ref = useRef<HTMLDivElement | null>(null);

  // this fixes the styling since we are in an absolute positioned popover
  const [{ isDragging }, drag, dragPreview] = useDrag(
    {
      type: 'select-option',
      item: () => {
        return { value, index, dragItem: true };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [value, index],
  );
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'select-option',
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      hover: (draggedItem: any, monitor: DropTargetMonitor) => {
        if (!ref.current) {
          return;
        }
        if (draggedItem.index === index) {
          return;
        }
        onMoveOption(draggedItem.index, index);
        draggedItem.index = index;
      },
    }),
    [index, value, onMoveOption],
  );
  const combinedRef = useCallback(
    (el: any) => {
      dragPreview(el);
      drop(el);
      ref.current = el;
    },
    [dragPreview, drop],
  );
  return {
    ref: combinedRef,
    drag,
    isDragging,
    isOver,
  };
};

export default useSelectOption;
