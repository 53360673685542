import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { User } from '../types';



export const fetchUsers = createAsyncThunk<User[], undefined, { rejectValue: string[] }>(
    'users/fetchUsers',
    async () => {
      try {
        const usersData = await API.get(
          'Users', // function defined in our serverless.yml
          '',            // the function's path
          { 'responseType': 'json' }
        );
        return usersData;
    } catch (error) {
        console.log(error);
      }
    },
  );
  