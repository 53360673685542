import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export const FullPageError = ({ children }: { children?: React.ReactNode }) => {
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center bg-danger">
      <FontAwesomeIcon
        size={'10x'}
        color={'white'}
        icon={faExclamationTriangle}
      />
      <h1 className="mt-5 display-3 text-white text-uppercase">Error</h1>
      {children ?? <h3 className="text-white">Something went wrong</h3>}
    </div>
  );
};

export default FullPageError;
