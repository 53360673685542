import {
  values as _values,
  orderBy as _orderBy,
  filter as _filter,
  map as _map,
  forEach as _forEach,
  get as _get,
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const getCampaignsState = (state: RootState) => state.campaigns;

export const getCampaigns = createSelector(getCampaignsState, (state) => state.campaigns);
export const getCampaignsIsInitialized = createSelector(
  getCampaignsState,
  (state) => state.isInitialized,
);
export const getCampaignsIsLoading = createSelector(getCampaignsState, (state) => state.isLoading);
export const getCampaignsIsSaving = createSelector(getCampaignsState, (state) => state.isSaving);

export const getCampaignsList = createSelector(getCampaigns, (campaigns) => _values(campaigns));

export const getSelectedCampaignId = createSelector(
  getCampaignsState,
  (state) => state.selectedCampaignId,
);

export const getSelectedCampaign = createSelector(
  getCampaigns,
  getSelectedCampaignId,
  (campaigns, campaignId) =>
    campaignId === undefined ? undefined : _get(campaigns, campaignId, undefined),
);
