import { findIndex as _findIndex } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllFieldsList, getCompany } from '../../../../data/company/selectors';
import { getFirstDealInSelectedView } from '../../../../data/deals/selectors';
import { getSlackChannels, getSlackUsers } from '../../../../data/slack/selectors';
import { Automation, FormulaDependency, Template, TemplateEditorOption, User } from '../../../../data/types';
import { getUsers } from '../../../../data/users/selectors';
import AutomationTemplateEditor from './AutomationTemplateEditor';

interface AutomationEditorProps {
  internalAutomation: Automation;
  onChange: (e: Automation) => void;
  testAutomation: () => void;
  saveAutomation: () => void;
  deleteAutomation: (automation:Automation) => void;
}

export const AutomationEditor = ({
  internalAutomation,
  onChange,
  saveAutomation,
  testAutomation,
  deleteAutomation,
}: AutomationEditorProps) => {
  const templateFields = useSelector(getAllFieldsList);
  const company = useSelector(getCompany);
  const slackChannels = useSelector(getSlackChannels);
  const slackUsers = useSelector(getSlackUsers);
  const users = useSelector(getUsers);
  const { data, isEnabled:_isEnabled, viewId, skipFirstRun, trigger, name } = internalAutomation;
  const isEnabled = _isEnabled === 'true';
  const onChangeData = useCallback(
    (key: string, value: Template) => {
      const dataIndex = _findIndex(internalAutomation.data, { key });
      let newData = [...internalAutomation.data];
      newData[dataIndex] = { key, value };
      onChange({
        ...internalAutomation,
        data: newData,
      });
    },
    [internalAutomation, onChange],
  );
  const onDeleteAutomation = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    const doDelete = confirm('Are you sure?');
    if(doDelete) {
      deleteAutomation(internalAutomation);
    }
  },[deleteAutomation, internalAutomation])
  const toggleAutomationIsEnabled = useCallback(() => {
    onChange({
      ...internalAutomation,
      isEnabled: isEnabled ? 'false' : 'true',
    });
    saveAutomation();
  }, [internalAutomation, onChange, saveAutomation, isEnabled]);
  const onChangeName = useCallback(
    (e: any) => {
      const newName = e.target.value ?? 'Automation';
      onChange({
        ...internalAutomation,
        name: newName,
      });
    },
    [internalAutomation, onChange],
  );
  const onChangeSkipFirstRun = useCallback(
    (e: any) => {
      const newSkipFirstRun = e.target.checked;
      onChange({
        ...internalAutomation,
        skipFirstRun: newSkipFirstRun,
      });
    },
    [internalAutomation, onChange],
  );
  const templateOptions:TemplateEditorOption[] = useMemo(() => {
    const fieldOptions:FormulaDependency[] = templateFields.map((field):FormulaDependency => ({
      label: field.name,
      identifier: `fields.${field.fieldId}.value`,
    }));
    const slackChannelOptions:FormulaDependency[] = slackChannels.map((channel):FormulaDependency => ({
      label: `#${channel.name}`,
      identifier: `slackChannels.${channel.id}.id`,
    }));
    const slackUserOptions:FormulaDependency[] = slackUsers.map((user):FormulaDependency => ({
      label: `@${user.name}`,
      identifier: `slackUsers.${user.id}.id`,
    }));
    const userEmailOptions:FormulaDependency[] = users.map((user:User):FormulaDependency => ({
      label: `${user.name} - ${user.email}`,
      identifier: `users.${user.userId}.email`,
    }))
    const userNameOptions:FormulaDependency[] = users.map((user:User):FormulaDependency => ({
      label: user.name,
      identifier: `users.${user.userId}.name`,
    }))
    const companyOptions:FormulaDependency[] = !!company ? Object.keys(company).map((companyKey:string) => ({
      label: companyKey,
      identifier: `company.${companyKey}`,
    })) : [];
    return [
      {
        label: 'Fields',
        options: fieldOptions
      },
      {
        label: 'Slack Channels',
        options: slackChannelOptions
      },
      {
        label: 'Slack Users',
        options: slackUserOptions
      },
      {
        label: 'User Emails',
        options: userEmailOptions
      },
      {
        label: 'User',
        options: userNameOptions
      },
      {
        label: 'Taidacorp',
        options: companyOptions
      }
    ]

  }, [company, slackChannels, slackUsers, templateFields, users])

  return (
    <div className="px-3 d-flex flex-column">
      <div className="form-group mb-1">
        <input type="text" className="form-control" value={name} onChange={onChangeName} />
      </div>
      {data.map((datum) => (
        <AutomationTemplateEditor
        templateOptions={templateOptions}
          value={datum.value as Template}
          key={datum.key}
          name={datum.key}
          onChange={onChangeData}
        />
      ))}
      <div className="d-flex flex-row justify-content-between align-items-end mb-3">
        <div className="text-secondary">Currently {isEnabled ? 'turned on' : 'turned off'}</div>
        <div className="d-flex flex-row justify-content-end align-items-center">
          <button onClick={toggleAutomationIsEnabled} type="button" className="btn btn-outline-primary me-2">
            {isEnabled ? 'Turn It Off' : 'Turn It On'}
          </button>
          <button onClick={testAutomation} type="button" className="btn btn-outline-primary me-2">
            Test Automation
          </button>
          <button onClick={saveAutomation} type="button" className="btn btn-outline-primary">
            Save
          </button>
        </div>
        </div>
        <div className="d-flex flex-row justify-content-end align-items-end mb-3">
          <button onClick={onDeleteAutomation} type="button" className="btn btn-link px-0 text-danger">
            Delete
          </button>
      </div>
    </div>
  );
};

export default AutomationEditor;
