import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SignIn from '../../pages/anonymous/SignIn/SignIn';
import AuthPage from '../components/Page/AuthPage';

export const AnonymousRoutes = () => {
  return (
    <Routes>
      <Route
        path="/sign-in"
        element={
          <AuthPage>
            <SignIn />
          </AuthPage>
        }
      />

      <Route path="*" element={<Navigate to="/sign-in" replace />} />
    </Routes>
  );
};

export default AnonymousRoutes;
