import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterConditionGroup, NewRecordWithFilterAutomation, RecordView, TableView } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep, debounce as _debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { updateAutomation } from '../../../../data/automations/actions';
import { getContentTypeFields } from '../../../../data/content-type/content-types/selectors';
import { localUpdateView, updateRecordView } from '../../../../data/content-type/record-views/actions';
import { useAppDispatch } from '../../../../data/store';
import { FilterBuilderForm } from '../../components/FilterBuilder/FilterBuilderForm';
import ToolbarToggleButton from '../ToolbarToggleButton';

interface ViewFilteringToolProps {
  currentView: TableView;
}
export const ViewFilteringTool = ({ currentView }: ViewFilteringToolProps) => {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const allFields = useSelector(getContentTypeFields);
  const debouncedSyncUpdates = useMemo(() => {
    return _debounce(
      (updatedView: RecordView) =>
      dispatch(updateRecordView({ view: updatedView, contentTypeSlug: updatedView.contentType })
        ),
      1500,
    );
  }, [dispatch]);
  const setFilters = useCallback(
    (newFilters: FilterConditionGroup) => {
      const updated: TableView = {
        ...currentView,
        properties: {
          ...currentView.properties,
          filters: newFilters,
        },
      };
      dispatch(localUpdateView({view: updated, contentTypeSlug: updated.contentType}));
      debouncedSyncUpdates(updated);
    },
    [currentView, debouncedSyncUpdates, dispatch],
  );

  return (
    <Dropdown
      show={show}
      onToggle={(nextShow: boolean, meta) => {
        if (nextShow === true) {
          setShow(nextShow);
        }
        if (meta.source === 'rootClose') {
          setShow(false);
        }
      }}
      className="me-0"
    >
      <Dropdown.Toggle as={ToolbarToggleButton} extraClassName="btn-sm">
        <FontAwesomeIcon size="sm" className="me-1" icon={faFilter} />
        Filter
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="p-3" style={{ minWidth: 500, minHeight: 100 }}>
          <FilterBuilderForm
            filterActionText="View Will Only Show Records Matching These Filters"
            filters={(currentView as TableView).properties.filters}
            setFilters={setFilters}
            fields={allFields}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ViewFilteringTool;
