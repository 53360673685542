import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { Field } from '../../../data/types';
import { FieldKanbanComponents, FieldIcons } from '../../Fields';
import { GalleryViewCardField } from './GalleryView';

export const CardField = ({field}:{field:GalleryViewCardField}) => {
    const {
        type:fieldType,
        label,
    } = field;
    const DisplayControl = useMemo(() => {
        return FieldKanbanComponents[fieldType];
      }, [fieldType]);
    
    return (
        <div className="kanban-card__field">
            <div className="text-muted" style={{ fontSize: 12 }}>
              <FontAwesomeIcon className="me-1" icon={FieldIcons[fieldType]} />
              <small>{label}</small>
            </div>
            <div><DisplayControl field={field} /></div>
          </div>
    )
}

export default CardField;