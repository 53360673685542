import { delay as _delay } from 'lodash';
import React, { useCallback, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeNotification, hideNotification } from '../../data/notifications/actions';
import { getAlertNotificationsList, getNotificationsList } from '../../data/notifications/selectors';
import { useAppDispatch } from '../../data/store';

export const ToastWrapper = () => {
  const dispatch = useAppDispatch();
  const notifications = useSelector(getAlertNotificationsList);

  const onRemoveNotification = useCallback(
    (notificationId: string) => {
      dispatch(hideNotification(notificationId));
      // _delay(() => dispatch(removeNotification(notificationId)), 1000);
    },
    [dispatch],
  );

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: 'absolute',
        top: 80,
        right: 50,
        zIndex: 999,
      }}
    >
      {notifications.map((notification, idx) => (
        <Toast
          key={notification.notificationId}
          style={{
            position: 'relative',
            width: 400,
            maxWidth: 400,
          }}
          onClose={() => onRemoveNotification(notification.notificationId)}
          show={notification.isUnread}
          delay={notification.ttl !== -1 && notification.ttl !== undefined ? notification.ttl : undefined}
          autohide={notification.ttl !== undefined && notification.ttl !== -1}
        >
          <Toast.Header>
            <div
              style={{ display: 'block', minHeight: 20, minWidth: 20 }}
              className={`rounded me-2 bg-${notification.type}`}
            />
            <strong className="me-auto">{notification.title}</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            {notification.message}
            {notification.url !== undefined && (
              <div><br/><Link to={notification.url}>Open</Link></div>
            )}
          </Toast.Body>
        </Toast>
      ))}
    </div>
  );
};

export default ToastWrapper;
