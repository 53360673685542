import React from 'react';
import { KanbanCardField } from '../../Views/KanbanBoard/Board';

interface DatetimeKanbanFieldProps {
    field:KanbanCardField
}

export const DatetimeKanbanField = ({field}: DatetimeKanbanFieldProps) => {
    const {
        displayValue
    } = field;
    return (
        <div>{displayValue}</div>
    )
}

export default DatetimeKanbanField;