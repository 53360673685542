import { RecordAutomation } from '@taida-corp/taidacorp-sdk';
import { get as _get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedView } from '../../../../../data/content-type/record-views/selectors';
import AutomationListItem from './AutomationListIem';

interface AutomationListProps {
  automations: RecordAutomation[];
  onClickCreate: () => void;
  onClickAutomationLink: (automationId: string, link: 'edit' | 'history') => void;
}

export const AutomationList = ({
  automations: allAutomations,
  onClickAutomationLink,
  onClickCreate,
}: AutomationListProps) => {
  const [filterForView, setFilterForView] = useState(false);
  const onChange = useCallback((e) => {
    setFilterForView(e.target.checked);
  }, []);
  const selectedView = useSelector(getSelectedView);
  const automations = useMemo(() => {
    if (!filterForView || selectedView === undefined) {
      return allAutomations;
    }
    return allAutomations.filter(
      (automation) => _get(automation, ['triggerConfig', 'tableViewId']) === selectedView.viewId,
    );
  }, [selectedView, allAutomations, filterForView]);
  return (
    <>
      <div className="mb-3 d-flex flex-row justify-content-between align-items-center">
        <h3>Manage Automations</h3>
        <button onClick={onClickCreate} className="btn btn-primary">
          Create
        </button>
      </div>
      <div className="d-flex flex-row justify-content-between align-items center mb-2">
      <div >
      Showing {filterForView ? 'automations for view' : 'all automations'}
      </div>
        <div className="form-check form-switch">
          <input
            checked={filterForView}
            onChange={onChange}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="filterByView"
          />
          <label className="form-check-label" htmlFor="filterByView">
            This View
          </label>
        </div>
      </div>
      <div className="automation-list">
        {automations.map((automation) => (
          <AutomationListItem
            key={automation.automationId}
            automation={automation}
            onClickAutomationLink={onClickAutomationLink}
          />
        ))}
      </div>
    </>
  );
};

export default AutomationList;
