import { FieldAttachment, FieldAttachmentConfig, FieldAttachmentType } from '@taida-corp/taidacorp-sdk';
import { uniq } from 'lodash';
import React, { useCallback, useMemo } from 'react';

interface AttachmentConfigEditorProps {
  field: FieldAttachment;
  internalConfig: FieldAttachmentConfig;
  onChangeConfig: (e: FieldAttachmentConfig) => void;
}

export const AttachmentConfigEditor = ({
  field,
  internalConfig: config,
  onChangeConfig,
}: AttachmentConfigEditorProps) => {
  const { fileType, minNumberOfFiles, maxNumberOfFiles, minSizeKB, maxSizeKB, maxTotalFileSizeKB } = config;
  const isImageEnabled = useMemo(() => fileType !== null && fileType.indexOf('image') !== -1, [fileType]);
  const isVideoEnabled = useMemo(() => fileType !== null && fileType.indexOf('video') !== -1, [fileType]);
  const isAudioEnabled = useMemo(() => fileType !== null && fileType.indexOf('audio') !== -1, [fileType]);
  const isPDFEnabled = useMemo(() => fileType !== null && fileType.indexOf('pdf') !== -1, [fileType]);
  const onChangeTypeEnabled = useCallback(
    (isChecked: boolean, type: FieldAttachmentType) => {
      if (!isChecked) {
        const updatedFileType = fileType !== null ? fileType.filter((val) => val !== type) : null;
        onChangeConfig({
          ...config,
          fileType: updatedFileType !== null && updatedFileType.length !== 0 ? updatedFileType : null,
        });
      } else {
        const updatedFileType = fileType !== null ? fileType.concat(type) : [type];
        onChangeConfig({
          ...config,
          fileType: uniq(updatedFileType) as any,
        });
      }
    },
    [config, fileType, onChangeConfig],
  );
  const onChangeLimit = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const newValue = event.target.value;
      onChangeConfig({
        ...config,
        [key]: newValue,
      });
    },
    [config, onChangeConfig],
  );

  return (
    <div>
      <div className="mb-3">
        <label>Allowed File Types</label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="images"
            checked={isImageEnabled}
            onChange={(e) => onChangeTypeEnabled(e.target.checked, 'image')}
          />
          <label className="form-check-label" htmlFor="images">
            Images
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="videos"
            checked={isVideoEnabled}
            onChange={(e) => onChangeTypeEnabled(e.target.checked, 'video')}
          />
          <label className="form-check-label" htmlFor="videos">
            Videos
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="audio"
            checked={isAudioEnabled}
            onChange={(e) => onChangeTypeEnabled(e.target.checked, 'audio')}
          />
          <label className="form-check-label" htmlFor="audio">
            Audio
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="pdf"
            checked={isPDFEnabled}
            onChange={(e) => onChangeTypeEnabled(e.target.checked, 'pdf')}
          />
          <label className="form-check-label" htmlFor="pdf">
            PDF
          </label>
        </div>
        <small>None selected will allow any type of file</small>
      </div>
      <div>
        <div className="form-floating mb-3">
          <input
            type="number"
            className="form-control form-control-sm"
            id="minFiles"
            placeholder="1"
            value={minNumberOfFiles ?? ''}
            onChange={(e) => onChangeLimit(e, 'minNumberOfFiles')}
          />
          <label htmlFor="minFiles">Min Number of Files</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="number"
            className="form-control form-control-sm"
            id="maxFiles"
            placeholder="1"
            value={maxNumberOfFiles ?? ''}
            onChange={(e) => onChangeLimit(e, 'maxNumberOfFiles')}
          />
          <label htmlFor="maxFiles">Max Number of Files</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="number"
            className="form-control form-control-sm"
            id="maxTotalSize"
            placeholder="1"
            value={maxTotalFileSizeKB ?? ''}
            onChange={(e) => onChangeLimit(e, 'maxTotalFileSizeKB')}
          />
          <label htmlFor="maxTotalSize">Max Total Size (kb)</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="number"
            className="form-control form-control-sm"
            id="minFileSize"
            placeholder="1"
            value={minSizeKB ?? ''}
            onChange={(e) => onChangeLimit(e, 'minSizeKB')}
          />
          <label htmlFor="minFileSize">Min File Size (kb)</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="number"
            className="form-control form-control-sm"
            id="maxFileSize"
            placeholder="1"
            value={maxSizeKB ?? ''}
            onChange={(e) => onChangeLimit(e, 'maxSizeKB')}
          />
          <label htmlFor="maxFileSize">Max File Size (kb)</label>
        </div>
      </div>
    </div>
  );
};

export default AttachmentConfigEditor;
