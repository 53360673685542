
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { getCampaignsIsSaving } from '../../../../data/campaigns/selectors';
import { fetchMarketingTemplate, fetchMarketingTemplates } from '../../../../data/marketing-services/marketing-templates/actions';
import { useAppDispatch } from '../../../../data/store';
import { Campaign, MarketingTemplate } from '../../../../data/types';
import './CampaignDetailModal.scss';
import CampaignForm from './CampaignForm';

export const CampaignDetailModal = ({
  campaign,
  templates,
  isVisible,
  back,
}: {
  campaign: Campaign;
  templates: MarketingTemplate[];
  isVisible: boolean;
  back: any;
}) => {
  const dispatch = useAppDispatch();
  const isSaving = useSelector(getCampaignsIsSaving);

  const { campaignId } = useParams<{ campaignId: string; }>();
  useEffect(() => {
    // dispatch(fetchCampaignActivities({ campaignId }));
  }, [dispatch, campaignId]);
  return (
    <Modal size="xl" fullscreen="lg-down" show={isVisible} onHide={back} keyboard={false}>
      <Modal.Header className="d-lg-none" closeButton>
        <Modal.Title>Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row m-0 border-bottom">
          <div className="py-3 col-12">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>{campaign.name}</div>
              <div>
                {isSaving && (
                  <div>
                    Saving... <FontAwesomeIcon icon={faCog} title={'Saving Campaign'} className={'fa-spin'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 border-bottom">
          <nav className="nav">
            <Link
              className="nav-link"
              to={`/marketing/campaigns/${campaignId}`}
            >
              Campaign
            </Link>
            <Link
              className="nav-link"
              to={
                `/marketing/campaigns/${campaignId}/logs`
              }
            >
              Run History
            </Link>
            <Link
              className="nav-link"
              to={
                `/marketing/campaigns/${campaignId}/activity`
              }
            >
              Activity
            </Link>
          </nav>
        </div>
        <div className="row px-0 m-0" style={{ minHeight: 'calc(100vh - 200px)' }}>
          <div className="col-12">
            <Routes>
              <Route
                index
                element={<CampaignForm campaign={campaign} templates={templates} />}
              />
              <Route path="activity" element={<div>Activity</div>} />
              <Route path="logs" element={<div>Run History</div>} />
            </Routes>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CampaignDetailModal;
