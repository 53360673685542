import { find as _find } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import {
  getAllDealsWithValuesCastForSortingAndFiltering,
  getSelectedDeal,
  getSelectedDealId,
} from '../../../../../data/deals/selectors';
import { getCompany } from '../../../../../data/company/selectors';
import { getUsers } from '../../../../../data/users/selectors';
import { FieldSelect, FieldSelectOptions, FieldSelectUser, FieldValue, isFieldAttachmentValue, isFieldDateValue, isFieldFormulaValue, isFieldSelect, User } from '../../../../../data/types';
import { DropDownOption } from './InputWithDropdownStrings';
import { format, addDays, addBusinessDays } from 'date-fns';
import { isBankHoliday, getObservedHolidays, getBankHolidays, isHoliday } from 'date-fns-holiday-us';
import * as holiday from 'date-fns-holiday-us';
import * as fns from 'date-fns';
import { toSelectValue } from '../../../../../utils/fieldValueCasting';

(window as any).holiday = holiday;
(window as any).fns = fns;

export const getTokensDropdownOptions = createSelector(
  getSelectedDeal,
  getCompany,
  getUsers,
  (deal, company, users) => {
    if (!company) {
      return [];
    }
    if (!deal) {
      return [];
    }
    const fieldOptions: DropDownOption[] = company.fields.map((field): DropDownOption => {
      let value = deal.fields[`${field.fieldId}`];
      if (isFieldFormulaValue(value)) {
        value = value.value;
      } else if (isFieldDateValue(value)) {
        value = format(new Date(value.dtValue), 'MM/dd/yyyy');
      } else if (field.type === 'attachment' || isFieldAttachmentValue(value)) {
        value = 'Attachment'; //format(new Date(value.dtValue), 'MM/DD/YYYY');
      } else if(field.type === 'select') {
        // TODO: add checks for when field missing config or options
        value = deal.fields[`${field.fieldId}-og`] as string;
        // debugger;
        const optionsArray = (field as FieldSelect).config.options as FieldSelectOptions;
        const labelValue = toSelectValue(value!, optionsArray);
        value = labelValue!;
      } else if(field.type === 'select-user') {
        // TODO: add checks for when field missing config or options
        // value = deal.fields[`${field.fieldId}-og`] as string;
        // debugger;
        // const labelValue = toSelectValue(value!, optionsArray);
        // value = labelValue!;
      }
      
      return {
        label: field.name,
        value: `${value}`,
      };
    });
    const userEmailOptions: DropDownOption[] = users.map(
      (user: User): DropDownOption => ({
        label: `${user.name} - ${user.email}`,
        value: user.email,
      }),
    );
    const userNameOptions: DropDownOption[] = users.map(
      (user: User): DropDownOption => ({
        label: user.name,
        value: user.name,
      }),
    );
    const companyOptions: DropDownOption[] = !!company
      ? ['name'].map((companyKey: string) => ({
          label: companyKey,
          value: `${(company as any)[companyKey]}`,
        }))
      : [];

      const todayAt5 = new Date();
      todayAt5.setHours(0, 0, 0, 0);
      const tomorrowAt5 = addDays(todayAt5, 1);
      const nextBizDayAt5 = addBusinessDays(new Date(), 1);
      nextBizDayAt5.setHours(0, 0, 0, 0);
      let twentyNineDays = new Date();
      twentyNineDays.setHours(0, 0, 0, 0);
      twentyNineDays = addDays(twentyNineDays, 29);
      let thirtyBizDaysAt5 = addBusinessDays(twentyNineDays, 1);
      let isThirtyDaysAHoliday = isBankHoliday(thirtyBizDaysAt5);
      while(isThirtyDaysAHoliday) {
        thirtyBizDaysAt5 = addBusinessDays(thirtyBizDaysAt5, 1);
        isThirtyDaysAHoliday = isBankHoliday(thirtyBizDaysAt5);
      }
      todayAt5.setHours(17, 0, 0, 0);
      tomorrowAt5.setHours(17, 0, 0, 0);
      nextBizDayAt5.setHours(17, 0, 0, 0);
      twentyNineDays.setHours(17, 0, 0, 0);
      thirtyBizDaysAt5.setHours(17, 0, 0 ,0);
      const formatDate = (dateIn:Date) => format(dateIn, 'MM/dd/yyyy');
      const formatDateTime = (dateIn:Date) => format(dateIn, 'MM/dd/yyyy p');
    return [
      {
        label: 'Helpers',
        options: [
          {label: 'Checkmark', value: '✓'},
          {label: 'Today at 5PM MM/DD/YYYY', value: formatDateTime(todayAt5) },
          {label: 'Tomorrow at 5PM MM/DD/YYYY', value: formatDateTime(tomorrowAt5) },
          {label: 'Next biz day at 5PM MM/DD/YYYY', value: formatDateTime(nextBizDayAt5) },
          {label: '29 + 1 biz day at 5PM MM/DD/YYYY', value: formatDateTime(thirtyBizDaysAt5) },

          {label: 'Today MM/DD/YYYY', value: formatDate(todayAt5) },
          {label: 'Tomorrow MM/DD/YYYY', value: formatDate(tomorrowAt5) },
          {label: 'Next biz day MM/DD/YYYY', value: formatDate(nextBizDayAt5) },
          {label: '29 + 1 biz day MM/DD/YYYY', value: formatDate(thirtyBizDaysAt5) },
        ] as DropDownOption[],
      },
      {
        label: 'Fields',
        options: fieldOptions,
      },
      {
        label: 'User Emails',
        options: userEmailOptions,
      },
      {
        label: 'User',
        options: userNameOptions,
      },
      {
        label: 'Taidacorp',
        options: companyOptions,
      },
    ];
  },
);

export const getRecipientsDropdownOptions = getTokensDropdownOptions;