import React from 'react';
import { DetailFieldProps } from '..';
import { DealRecord, Field, FieldValue } from '../../../data/types';


export const DatetimeDetailField = ({ displayValue}: DetailFieldProps) => {
    
    return (
        <div>{displayValue}</div>
    )
}

export default DatetimeDetailField;