import { values as _values, orderBy as _orderBy, filter as _filter } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const getTextMessagesState = (state: RootState) => state.textMessages;

export const getTextMessages = createSelector(getTextMessagesState, (state) => state.textMessages);
export const getTextMessagesIsInitialized = createSelector(getTextMessagesState, (state) => state.isInitialized);
export const getTextMessagesIsLoading = createSelector(getTextMessagesState, (state) => state.isLoading);
export const getTextMessagesIsSending = createSelector(getTextMessagesState, (state) => state.isSending);
export const getSelectedPhoneNumber = createSelector(getTextMessagesState, (state) => state.selectedPhoneNumber);

export const getTextMessagesList = createSelector(getTextMessages, (textMessages) => {
  return _orderBy(_values(textMessages), ['dateCreated'], ['desc']);
})

export const getTextMessagesListForSelectedNumber = createSelector(getSelectedPhoneNumber, getTextMessagesList, (externalNumber, textMessages) => {
  return _filter(textMessages, (textMessage) => textMessage.externalNumber === externalNumber);
});
