import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setDealsSearchTerm } from '../../../data/deals/actions';
import { getDealsSearchTerm } from '../../../data/deals/selectors';
import { useAppDispatch } from '../../../data/store';
import useAuth from '../../../hooks/auth/useAuth';

export const TopBar = () => {
    const {signout} = useAuth();
    const dispatch = useAppDispatch();
    const dealsSearchTerm = useSelector(getDealsSearchTerm);
    const updateDealsSearchTerm = useCallback((e) => {
      dispatch(setDealsSearchTerm(e.target.value ?? ''));
    }, [dispatch])
    return (
        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
  <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3" to="/">Taidacorp</Link>
  <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <input value={dealsSearchTerm} onChange={updateDealsSearchTerm} className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" />
  <div className="navbar-nav">
    <div className="nav-item text-nowrap">
      <button className="btn btn-link nav-link px-3" onClick={signout}>Sign out</button>
    </div>
  </div>
</header>
    )
}

export default TopBar;