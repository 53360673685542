import { faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter as _filter, find as _find, debounce as _debounce, } from 'lodash';
import DatePicker from 'react-datepicker';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FILTER_EXPRESSION, FilterCondition, OPERATOR_OPTIONS, FieldSelect, FieldMultiSelect, FieldSelectUser, FieldMultiSelectUser } from '../../../../data/types';
import { FieldFilterExpressionOptions } from '../../../../components/Fields';
import ChangeFilterOperator from './ChangeFilterOperator';
import { getFieldFilterConditionDefault } from '../../../../utils/fieldFilters';
import SelectFilterDropdown from '../../../../components/Fields/Select/SelectFilterDropdown/SelectFilterDropdown';
import UserSelectFilterDropdown from '../../../../components/Fields/UserSelect/UserSelectFilterDropdown/UserSelectFilterDropdown';

interface FilterFieldConditionRowProps {
  removeFilter: () => void;
  updateFilteringField: (
    newField: string,
    newExpression: FILTER_EXPRESSION,
    newOption: string | undefined,
    newCompareTo: any,
    index: number,
  ) => void;
  operator: OPERATOR_OPTIONS;
  fields: Field[];
  index: number;
  condition: FilterCondition;
  onChildPopover: Function;
}

export const FilterFieldConditionRow = ({
  fields,
  index,
  condition,
  removeFilter,
  updateFilteringField,
  onChildPopover,
}: FilterFieldConditionRowProps) => {
  // const [internalCompareTo, setInternalCompareTo] = useState(condition.compareTo);
  const expression = condition.expression;
  const option = condition.option;
  const fieldId = condition.fieldId;

  const fieldObj = useMemo(() => _find(fields, { fieldId: condition.fieldId }), [condition.fieldId, fields]);
  const filterExpressionOptions = useMemo(() => {
    if(fieldObj === undefined) {
      return [];
    }
    return FieldFilterExpressionOptions[fieldObj.type];
  }, [fieldObj]);
  const selectedExpressionOption = useMemo(() => {
    return _find(filterExpressionOptions, { expression });
  }, [expression, filterExpressionOptions]);
  const compareToControlType = useMemo(() => {
    if (selectedExpressionOption === undefined) {
      return undefined;
    }
    if (typeof selectedExpressionOption.compareToControl === 'function') {
      return selectedExpressionOption.compareToControl(option);
    }
    return selectedExpressionOption.compareToControl;
  }, [selectedExpressionOption, option]);
  useEffect(() => {
    // setInternalCompareTo(condition.compareTo);
  }, [condition.compareTo]);

  const changeFilteringField = useCallback(
    (e: any) => {
      const newFieldId = e.target.value;
      const newField = _find(fields, { fieldId: newFieldId });
      const newExp = getFieldFilterConditionDefault(newField as Field);
      updateFilteringField(newFieldId, newExp.expression, newExp.option, newExp.compareTo, index);
    },
    [fields, index, updateFilteringField],
  );
  const changeExpression = useCallback(
    (e: any) => {
      const newExpression: FILTER_EXPRESSION = e.target.value;
      const newSelectedExpression = _find(filterExpressionOptions, { expression: newExpression });
      const newOption = newSelectedExpression!.options !== undefined ? newSelectedExpression?.options[0] : undefined;
      const newCompareTo = undefined;
      updateFilteringField(fieldId, newExpression, newOption, newCompareTo, index);
    },
    [fieldId, filterExpressionOptions, index, updateFilteringField],
  );
  const changeOption = useCallback(
    (e: any) => {
      const newOption = e.target.value;
      const newCompareTo = undefined;
      updateFilteringField(fieldId, expression, newOption, newCompareTo, index);
    },
    [expression, fieldId, index, updateFilteringField],
  );
  const updateCompareTo = useCallback(
    (newCompareTo:any) => {
      updateFilteringField(fieldId, expression, option, newCompareTo, index);
    },
    [expression, fieldId, index, option, updateFilteringField],
  );
  const debouncedUpdateCompareTo = useMemo(() => {
    return (newValue:any) => updateCompareTo(newValue);
    // return _debounce((newValue:any) => updateCompareTo(newValue), 700);
  }, [updateCompareTo]);
  const handleChangeEventCompareTo = useCallback(
    (e:any) => {
      const newCompareTo = e.target.value ?? '';
      // setInternalCompareTo(newCompareTo);
      debouncedUpdateCompareTo(newCompareTo);
    },
    [debouncedUpdateCompareTo],
  );
  const handleChangeCheckboxEventCompareTo = useCallback(
    (e:any) => {
      const newCompareTo = e.target.checked ? 'true' : 'false';
      // setInternalCompareTo(newCompareTo);
      debouncedUpdateCompareTo(newCompareTo);
    },
    [debouncedUpdateCompareTo],
  );
  const changeCompareTo = useCallback(
    (newCompareTo:any) => {
      // setInternalCompareTo(newCompareTo);
      debouncedUpdateCompareTo(newCompareTo);
    },
    [debouncedUpdateCompareTo],
  );
  useEffect(() => {
    if(condition.fieldId === undefined && fields.length > 0) {
      changeFilteringField({target: {value: fields[0].fieldId}});
    }
  }, [changeFilteringField, condition.fieldId, fields]);
  
  return (
    <div className="view-filter__current-fields-list__item">
      
      <div className="change-filter-field me-1">
        <select className="form-select form-select-sm" value={fieldId} onChange={changeFilteringField}>
          {fields.map((fieldOption) => (
            <option key={fieldOption.fieldId} value={fieldOption.fieldId}>{fieldOption.name}</option>
          ))}
        </select>
      </div>
      <div className="change-filter-field me-1">
        <select className="form-select form-select-sm" value={expression} onChange={changeExpression}>
          {filterExpressionOptions.map((expressionOption) => (
            <option key={expressionOption.expression} value={expressionOption.expression}>{expressionOption.expression}</option>
          ))}
        </select>
      </div>
      {selectedExpressionOption !== undefined && selectedExpressionOption.options !== undefined && (
        <div className="change-filter-field me-1">
          <select className="form-select form-select-sm" value={option} onChange={changeOption}>
            {selectedExpressionOption.options.map((secondaryOption) => (
              <option key={secondaryOption} value={secondaryOption}>{secondaryOption}</option>
            ))}
          </select>
        </div>
      )}
      {compareToControlType === 'text' && (
        <div className="change-filter-field me-1">
          <input value={condition.compareTo as string} onChange={handleChangeEventCompareTo} />
        </div>
      )}
      {compareToControlType === 'formula' && (
        <div className="change-filter-field me-1">
          <input value={condition.compareTo as string} onChange={handleChangeEventCompareTo} />
        </div>
      )}
      {compareToControlType === 'checkbox' && (
        <div className="change-filter-field me-1">
          <input type='checkbox' checked={condition.compareTo as string === 'true'} onChange={handleChangeCheckboxEventCompareTo} />
        </div>
      )}
      {compareToControlType === 'number' && (
        <div className="change-filter-field me-1">
          <input placeholder='Number' type='number' value={condition.compareTo as string} onChange={handleChangeEventCompareTo} />
        </div>
      )}
      {compareToControlType === 'datepicker' && (
        <div className="change-filter-field me-1">
          <DatePicker
      withPortal
      placeholderText='Choose a date'
      // dateFormat={dateFormat}
      // onClickOutside={stopEditing as any}
      // onBlur={stopEditing as any}
      // startOpen={true}
      // showTimeSelect={isDatetime}
      selected={!!condition.compareTo ? new Date(condition.compareTo as string) : null}
      onChange={(newDate) => changeCompareTo(!!newDate ? (newDate as Date).toISOString() : null)}
    />
        </div>
      )}
      {compareToControlType === 'select' && (
        <div className="change-filter-field me-1">
          <SelectFilterDropdown
            field={fieldObj as FieldSelect}
            value={condition.compareTo as string | number| string[]}
            onChange={changeCompareTo} />
        </div>
      )}
      {compareToControlType === 'multi-select' && (
        <div className="change-filter-field me-1">
          <SelectFilterDropdown
            multiple field={fieldObj as FieldMultiSelect}
            value={condition.compareTo as string | number| string[]}
            onChange={changeCompareTo} />
        </div>
      )}
      {compareToControlType === 'select-user' && (
        <div className="change-filter-field me-1">
          <UserSelectFilterDropdown
            field={fieldObj as FieldSelectUser}
            value={condition.compareTo as string | number| string[]}
            onChange={changeCompareTo} />
        </div>
      )}
      {compareToControlType === 'multi-select-user' && (
        <div className="change-filter-field me-1">
          <UserSelectFilterDropdown
            multiple field={fieldObj as FieldMultiSelectUser}
            value={condition.compareTo as string | number| string[]}
            onChange={changeCompareTo} />
        </div>
      )}
      {/* {(
        <>
          {compareToControlType !== undefined && (
            <div className="change-filter-field me-1">---{compareToControlType} control---</div>
          )}
        </>
      )} */}
      <div className='btn' onClick={removeFilter}>
        <FontAwesomeIcon icon={faTrash} size='1x' />
      </div>
    </div>
  );
};

export default FilterFieldConditionRow;
