import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import ContentDetailPage from '../../pages/ContentPage/content-detail-page/ContentDetailPage';
import ContentPage from '../../pages/ContentPage/ContentPage';
import NewContentPage from '../../pages/ContentPage/new-content-page/NewContentPage';


export const WrappedContentRoutes = (props:any) => {
  const { contentTypeSlug } = useParams<{ contentTypeSlug: string }>();
  return <ContentRoutes contentTypeSlug={contentTypeSlug!} />;
};


export const ContentRoutes = ({contentTypeSlug}:{contentTypeSlug:string}) => {

  useEffect(() => {
    // load content type
    // load record views
    // load records
    // load automations
    // wire up sockets
  }, [contentTypeSlug])

  return (
     <>
    <Routes>
      <Route index element={<ContentPage contentTypeSlug={contentTypeSlug} />} />
      <Route path=":viewId" element={<ContentPage contentTypeSlug={contentTypeSlug} />}/>
      <Route path=":viewId/edit/:recordId/*" element={<ContentPage contentTypeSlug={contentTypeSlug} />}/>
    </Routes>
    <Routes>
       <Route path=":viewId/edit/new" element={<NewContentPage contentTypeSlug={contentTypeSlug} />} />
       <Route path=":viewId/edit/:recordId/*" element={<ContentDetailPage contentTypeSlug={contentTypeSlug} />} />
    </Routes>
    </>
  );
};

export default ContentRoutes;
