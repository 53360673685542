import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchCompany } from '../../data/company/companyActions';
import { fetchDealRecords } from '../../data/deals/actions';
import { fetchNotifications, postMarkAsRead, postSoftDelete } from '../../data/notifications/actions';
import { getNotificationsList } from '../../data/notifications/selectors';
import { useAppDispatch } from '../../data/store';
import { ToastNotification } from '../../data/types';
import { fetchViews } from '../../data/views/actions';
import NotificationListItem from './NotificationListItem';

export const NotificationsList = () => {
  const dispatch = useAppDispatch();
  const notifications = useSelector(getNotificationsList);
  const markAsRead = useCallback(
    (notificationId: string) => {
      dispatch(postMarkAsRead(notificationId));
    },
    [dispatch],
  );
  const softDelete = useCallback(
    (notificationId: string) => {
      dispatch(postSoftDelete(notificationId));
    },
    [dispatch],
  );

  return (
    <div className="h-100">
      <div className="p-3">
        <h4>Notifications</h4>
      </div>
      <div className="px-3">
        {notifications.map((notification, idx) => {
          return (
            <NotificationListItem
              key={notification.notificationId}
              notification={notification}
              markAsRead={markAsRead}
              softDelete={softDelete}
            />
          );
        })}
        {notifications.length === 0 && <div className="text-secondary">No notifications</div>}
      </div>
    </div>
  );
};

export default NotificationsList;
