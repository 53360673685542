import { keyBy as _keyBy } from 'lodash';
import { DealRecord } from './deals';
import { FieldMultiSelect, FieldSelect, FieldText } from './fields';
import { FilterConditionGroup } from './filters';
import { SORT_OPTIONS } from './sorting';


export interface Contact {
  companyId: string;
  contactId: string;
  contactUser?: string;
  email: string;
  firstName: string;
  lastName: string;
  role: 'realtor' | 'seller' | 'buyer' | 'wholesaler' | 'property_management' | 'business';
  tags: string[]; // wholesaler, fund, flipper, str, ltr, dispo rep
  state?: string;
  city?: string;
  source?: string;
  businessName?: string;
  propertyAddressLine1?: string;
  unsubscribedFromAllDT?: string;
  createdDT: string;
  updatedDT: string;
}
export const isContact = (maybeContact: any): maybeContact is Contact => {
  if (maybeContact === undefined) {
    return false;
  }
  return maybeContact.contactId !== undefined && maybeContact.email !== undefined && maybeContact.role !== undefined;
};

export interface ContactNotificationPreferences {
  frequency: 'instant' | 'daily' | 'weekly' | 'never';
  emailOverride?: string;
}

export interface ContactSavedSearch {
  companyId: string;
  contactId: string;
  savedSearchId: string;
  notificationPreferences: ContactNotificationPreferences;
  name: string;
  orderBy?: [string[], SORT_OPTIONS[]];
  filters: FilterConditionGroup;
  createdDT: string;
  updatedDT: string;
}

export interface ContactActivityLog {
  companyId: string;
  eventId: string;
  campaignId: string;
  logId?: string;
  savedSearchId?: string;
  contactId: string;
  'event#eventDT': string;
  'contactId#event#eventDT': string;
  event:
    | 'email-sent'
    | 'email-delivered'
    | 'email-opened'
    | 'email-clicked'
    | 'email-unsubscribed'
    | 'email-complained'
    | 'email-permanent_fail'
    | 'email-temporary_fail'
    | 'campaign-no-matches'
    | 'emailed-listing-clicked'
    | 'web-listing-clicked';
  eventDT: string;
  extra: any;
}

export const ContactFieldDefinitions = [
  {
    name: 'Email',
    fieldId: 'email',
    type: 'text',
    config: {
      defaultValue: null,
    },
  } as FieldText,
  {
    name: 'Role',
    fieldId: 'role',
    type: 'select',
    config: {
      defaultValue: null,
      options: [
        { label: 'Realtor', value: 'realtor' },
        { label: 'Seller', value: 'seller' },
        { label: 'Buyer', value: 'buyer' },
        { label: 'Wholesaler/Dispo Rep', value: 'wholesaler' },
        { label: 'Property Management Co', value: 'property_management' },
        { label: 'Business', value: 'business' },
      ],
    },
  } as FieldSelect,
  {
    name: 'Tags',
    fieldId: 'tags',
    type: 'multi-select',
    config: {
      defaultValue: null,
      options: [
        { label: 'Fund', value: 'fund' },
        { label: 'Flipper', value: 'flipper' },
        { label: 'STR', value: 'str' },
        { label: 'LTR', value: 'ltr' },
        { label: 'Homeowner', value: 'homeowner' },
        { label: 'Landlord', value: 'landlord' },
      ],
    },
  } as FieldMultiSelect,
  {
    name: 'Business Name',
    fieldId: 'businessName',
    type: 'text',
    config: {
      defaultValue: null,
    },
  } as FieldText,
  {
    name: 'Property Address Line 1',
    fieldId: 'propertyAddressLine1',
    type: 'text',
    config: {
      defaultValue: null,
    },
  } as FieldText,
  {
    name: 'First Name',
    fieldId: 'firstName',
    type: 'text',
    config: {
      defaultValue: null,
    },
  } as FieldText,
  {
    name: 'Last Name',
    fieldId: 'lastName',
    type: 'text',
    config: {
      defaultValue: null,
    },
  } as FieldText,
  {
    config: {
      defaultValue: null,
      options: [
        {
          label: 'Alabama',
          value: 'AL',
        },
        {
          label: 'Alaska',
          value: 'AK',
        },
        {
          label: 'American Samoa',
          value: 'AS',
        },
        {
          label: 'Arizona',
          value: 'AZ',
        },
        {
          label: 'Arkansas',
          value: 'AR',
        },
        {
          label: 'California',
          value: 'CA',
        },
        {
          label: 'Colorado',
          value: 'CO',
        },
        {
          label: 'Connecticut',
          value: 'CT',
        },
        {
          label: 'Delaware',
          value: 'DE',
        },
        {
          label: 'District Of Columbia',
          value: 'DC',
        },
        {
          label: 'Federated States Of Micronesia',
          value: 'FM',
        },
        {
          label: 'Florida',
          value: 'FL',
        },
        {
          label: 'Georgia',
          value: 'GA',
        },
        {
          label: 'Guam',
          value: 'GU',
        },
        {
          label: 'Hawaii',
          value: 'HI',
        },
        {
          label: 'Idaho',
          value: 'ID',
        },
        {
          label: 'Illinois',
          value: 'IL',
        },
        {
          label: 'Indiana',
          value: 'IN',
        },
        {
          label: 'Iowa',
          value: 'IA',
        },
        {
          label: 'Kansas',
          value: 'KS',
        },
        {
          label: 'Kentucky',
          value: 'KY',
        },
        {
          label: 'Louisiana',
          value: 'LA',
        },
        {
          label: 'Maine',
          value: 'ME',
        },
        {
          label: 'Marshall Islands',
          value: 'MH',
        },
        {
          label: 'Maryland',
          value: 'MD',
        },
        {
          label: 'Massachusetts',
          value: 'MA',
        },
        {
          label: 'Michigan',
          value: 'MI',
        },
        {
          label: 'Minnesota',
          value: 'MN',
        },
        {
          label: 'Mississippi',
          value: 'MS',
        },
        {
          label: 'Missouri',
          value: 'MO',
        },
        {
          label: 'Montana',
          value: 'MT',
        },
        {
          label: 'Nebraska',
          value: 'NE',
        },
        {
          label: 'Nevada',
          value: 'NV',
        },
        {
          label: 'New Hampshire',
          value: 'NH',
        },
        {
          label: 'New Jersey',
          value: 'NJ',
        },
        {
          label: 'New Mexico',
          value: 'NM',
        },
        {
          label: 'New York',
          value: 'NY',
        },
        {
          label: 'North Carolina',
          value: 'NC',
        },
        {
          label: 'North Dakota',
          value: 'ND',
        },
        {
          label: 'Northern Mariana Islands',
          value: 'MP',
        },
        {
          label: 'Ohio',
          value: 'OH',
        },
        {
          label: 'Oklahoma',
          value: 'OK',
        },
        {
          label: 'Oregon',
          value: 'OR',
        },
        {
          label: 'Palau',
          value: 'PW',
        },
        {
          label: 'Pennsylvania',
          value: 'PA',
        },
        {
          label: 'Puerto Rico',
          value: 'PR',
        },
        {
          label: 'Rhode Island',
          value: 'RI',
        },
        {
          label: 'South Carolina',
          value: 'SC',
        },
        {
          label: 'South Dakota',
          value: 'SD',
        },
        {
          label: 'Tennessee',
          value: 'TN',
        },
        {
          label: 'Texas',
          value: 'TX',
        },
        {
          label: 'Utah',
          value: 'UT',
        },
        {
          label: 'Vermont',
          value: 'VT',
        },
        {
          label: 'Virgin Islands',
          value: 'VI',
        },
        {
          label: 'Virginia',
          value: 'VA',
        },
        {
          label: 'Washington',
          value: 'WA',
        },
        {
          label: 'West Virginia',
          value: 'WV',
        },
        {
          label: 'Wisconsin',
          value: 'WI',
        },
        {
          label: 'Wyoming',
          value: 'WY',
        },
      ],
    },
    fieldId: 'state',
    name: 'State',
    type: 'select',
  } as FieldSelect,
  {
    name: 'City',
    fieldId: 'city',
    type: 'text',
    config: {
      defaultValue: null,
    },
  } as FieldText,
  {
    name: 'Source',
    fieldId: 'source',
    type: 'select',
    config: {
      defaultValue: null,
      options: [
        { label: 'facebook', value: 'facebook' },
        { label: 'craigslist', value: 'craigslist' },
        { label: 'mls', value: 'mls' },
        { label: 'zillow', value: 'zillow' },
        { label: 'other', value: 'other' },
      ],
    },
  } as FieldSelect,
];

export const ContactFieldDefinitionsDict = _keyBy(ContactFieldDefinitions, 'fieldId');

export const mapContactToFilterableRecord = (contact: Contact): DealRecord => {
  return {
    companyId: contact.companyId,
    dealId: contact.contactId,
    createdDT: contact.createdDT,
    updatedDT: contact.updatedDT,
    fields: {
      role: contact.role,
      'role-og': contact.role,
      tags: {
        value: contact.tags,
      },
      'tags-og': {
        value: contact.tags,
      },
      email: contact.email,
      'email-og': contact.email,      
      firstName: contact.firstName,
      'firstName-og': contact.firstName,
      lastName: contact.lastName,
      'lastName-og': contact.lastName,
      city: contact.city!,
      'city-og': contact.city!,
      state: contact.state!,
      'state-og': contact.state!,
      source: contact.source!,
      'source-og': contact.source!,
      businessName: contact.businessName!,
      'businessName-og': contact.businessName!,
      propertyAddressLine1: contact.propertyAddressLine1!,
      'propertyAddressLine1-og': contact.propertyAddressLine1!,
    },
  };
};

// export const mapContactToDataRecord = (contact: Contact): DataRecord => {
//   return {
//     companyId: contact.companyId,
//     "companyId#contentType": '',
//     id: contact.contactId,
//     contentType: 'contacts',
//     createdDT: contact.createdDT,
//     updatedDT: contact.updatedDT,
//     fields: {
//       role: contact.role,
//       'role-og': contact.role,
//       tags: {
//         value: contact.tags,
//       },
//       'tags-og': {
//         value: contact.tags,
//       },
//       email: contact.email,
//       'email-og': contact.email,      
//       firstName: contact.firstName,
//       'firstName-og': contact.firstName,
//       lastName: contact.lastName,
//       'lastName-og': contact.lastName,
//       city: contact.city!,
//       'city-og': contact.city!,
//       state: contact.state!,
//       'state-og': contact.state!,
//       source: contact.source!,
//       'source-og': contact.source!,
//       businessName: contact.businessName!,
//       'businessName-og': contact.businessName!,
//       propertyAddressLine1: contact.propertyAddressLine1!,
//       'propertyAddressLine1-og': contact.propertyAddressLine1!,
//     },
//   };
// };
