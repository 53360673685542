import React from 'react';
import { FieldNumber } from '../../../data/types';
import { KanbanColumn } from './Board';
import Column from './Column';

interface ColumnListProps {
  columns: KanbanColumn[];
  moveCard: (cardId: string, fromColumnId: string, toColumnId: string) => void;
  addCard: (columnId: string) => void;
  addColumn: (newColumnTitle: string) => void;
  moveColumn: (fromIndex: number, toIndex: number) => void;
  onDropColumn: (columnId: string, toIndex: number) => void;
  onDropCard: (cardId: string, columnId: string) => void;

}

export const ColumnList = ({ columns, moveCard, addCard, moveColumn, addColumn, onDropCard, onDropColumn }: ColumnListProps) => {
  
  return (
    <div className="kanban-board__columns-list">
      {columns.map((column, index) => {
        return (
        <Column
            canDrag={column.id !== 'Uncategorized'}
            key={column.id}
            column={column}
            index={index}
            moveCard={moveCard}
            addCard={addCard}
            moveColumn={moveColumn}
            addColumn={addColumn}
            onDropCard={onDropCard}
            onDropColumn={onDropColumn}
        />
        )
      })}
    </div>
  );
};

export default ColumnList;
