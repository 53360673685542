import { Field, TableViewField } from '@taida-corp/taidacorp-sdk';
import classNames from 'classnames';
import { useMemo, useRef } from 'react';
import { useDataCell } from './useDataCell';

import './TableDataCell.scss';
import { getTableCellDisplayComponent } from '../../../components/Fields/TableCellDisplayComponents';

interface TableDataCellProps {
  style: any;
  id: string;
  field: Field;
  tableViewField: TableViewField;
  rowIndex: number;
  columnIndex: number;
  isLastColumn: boolean;
}
export const TableDataCell = ({
  style,
  id,
  field,
  tableViewField,
  rowIndex,
  columnIndex,
  isLastColumn,
}: TableDataCellProps) => {
  const cellRef = useRef<HTMLDivElement>(null);

  const {
    cellProps,
    isInSelectedRange,
    isRowHovered,
    isColumnHovered,
    isFocused,
    isOnlySelectedCell,
    fieldValue,
    displayValue,
    width,
  } = useDataCell(cellRef, id, tableViewField, field, rowIndex, columnIndex);

  const DisplayComponent = useMemo(() => {
    return getTableCellDisplayComponent(field.type);
  }, [field.type]);

  const classname = classNames({
    'table-data-cell': true,
    'table-data-cell--is-text': field.type !== 'number',
    'table-data-cell--is-number': field.type === 'number',
    'table-data-cell--is-selected': isInSelectedRange,
    'table-data-cell--is-row-hovered': isRowHovered,
    'table-data-cell--is-col-hovered': isColumnHovered,
    'table-data-cell--is-hovered': isColumnHovered && isRowHovered,
    'table-data-cell--is-focused': isFocused,
    'table-data-cell--is-only-selected': isOnlySelectedCell,
    'table-data-cell--is-last-column': isLastColumn,
  });

  return (
    <div
      {...cellProps}
      ref={cellRef}
      style={{ ...style, zIndex: columnIndex + 1, width: width, maxWidth: width, minWidth: width }}
      className={classname}
    >
      {displayValue}
    </div>
  );
};

export default TableDataCell;
