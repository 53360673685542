import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setSelectedContactId } from '../../../data/contacts/actions';
import { getSelectedContact } from '../../../data/contacts/selectors';
import { getCompany } from '../../../data/company/selectors';
import { useAppDispatch } from '../../../data/store';
import ContentDetailModal from './ContentDetailModal';
import { getEnrichedSelectedRecord, getRawSelectedRecord } from '../../../data/content-type/records/selectors';
import { setRecordFormData, setSelectedRecordId } from '../../../data/content-type/records/actions';
import { getSelectedContentTypeAdminUpdateRecordForm } from '../../../data/content-type/record-views/selectors';

export const ContentDetailPage = (props: any) => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const { recordId } = useParams<{ recordId: string; }>();
  const selectedRecord = useSelector(getEnrichedSelectedRecord);
  const adminUpdateRecordForm = useSelector(getSelectedContentTypeAdminUpdateRecordForm);
  
  const location = useLocation();
  const navigate = useNavigate();
  const back = useCallback(() => {
    const parts = location.pathname.split('/');
    const indexOfEdit = parts.indexOf('edit');
    const root = parts[1];
    setIsVisible(false);
    setTimeout(() => {
      navigate(`/${root}`);
      dispatch(setSelectedRecordId(undefined));
    }, 100);
  }, [location.pathname, navigate, dispatch]);
  useEffect(() => {
    if (selectedRecord === undefined || selectedRecord.id !== recordId) {
      dispatch(setSelectedRecordId(recordId));
    } else {
      dispatch(setRecordFormData(selectedRecord.fields));
    }
  }, [dispatch, recordId, selectedRecord]);

  if(selectedRecord === undefined  || selectedRecord.id !== recordId || adminUpdateRecordForm === undefined) {
    return (
        <Modal size="xl" fullscreen="sm-down" show={isVisible} onHide={back} keyboard={false}>
          <Modal.Header className="d-sm-none" closeButton>
            <Modal.Title>Loading Content</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            Loading...
          </Modal.Body>
        </Modal>
      );
  }

  return (
    <ContentDetailModal isVisible={isVisible} back={back} />
  );
};

export default ContentDetailPage;
