import { faCheck, faCog, faDownload, faFilter, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContentType, RecordView } from '@taida-corp/taidacorp-sdk';
import { Dashboard } from '@uppy/react';
import { cloneDeep as _cloneDeep, get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { updateRecordView } from '../../../../data/content-type/record-views/actions';
import { useAppDispatch } from '../../../../data/store';
import useCSVImporter from '../../../../hooks/useCSVImporter/useCSVImporter';
import { parseCSVFile } from '../../../../utils/csvImporter';
import ToolbarToggleButton from '../ToolbarToggleButton';
import ColumnMapper from './ColumnMapper';
import ImportTable from './ImportTable';

interface CSVImporterToolProps {
  currentView: RecordView;
  contentType: ContentType;
}
export const CSVImporterTool = ({ currentView, contentType }: CSVImporterToolProps) => {
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUppyVisible, setIsUppyVisible] = useState(true);

  const toggleModal = () => setIsModalVisible((val) => !val);
  const toggleUppy = () => setIsUppyVisible((val) => !val);

  const {
    reset,
    startRunning,
    uppy,
    file,
    isFileAdded,
    isParsing,
    isDoneParsing,
    rowState,
    rowErrors,
    isUploading,
    headers,
    dataRows,
    fieldMappings,
    fields,
    columns,

    updateFieldMapping,
  } = useCSVImporter(contentType);

  useEffect(() => {
    setIsUppyVisible(!isFileAdded);
  }, [isFileAdded]);

  return (
    <>
      <ToolbarToggleButton onClick={toggleModal}>
        <FontAwesomeIcon size="sm" className="me-1" icon={faUpload} />
        Import
      </ToolbarToggleButton>
      <Modal size="xl" fullscreen="lg-down" show={isModalVisible} onHide={toggleModal} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>CSV Import</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="row m-0 border-bottom">
            <div className="py-3 col-12">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div>CSV Importer</div>
                <div className="d-flex flex-row align-items-center">
                <button onClick={startRunning} type="button" className="btn btn-success me-2">Start Import</button>
                <button onClick={reset} type="button" className="btn btn-warning">
                    Reset
                  </button>
                  {isParsing && (
                    <div>
                      Parsing... <FontAwesomeIcon icon={faCog} title={'Parsing CSV'} className={'fa-spin'} />
                    </div>
                  )}
                  {isUploading && (
                    <div>
                      Saving... <FontAwesomeIcon icon={faCog} title={'Saving Content'} className={'fa-spin'} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="row px-0 m-0"
            style={{ minHeight: 'calc(100vh - 200px)', maxHeight: 'calc(100vh - 200px)', overflowY: 'scroll' }}
          >
            {isDoneParsing && (
              <ColumnMapper
                contentType={contentType}
                fieldMappings={fieldMappings}
                fields={fields}
                columns={columns}
                updateFieldMapping={updateFieldMapping}
              />
            )}
            <div className="col-12 p-3">
              <>
                {uppy !== null && isUppyVisible && <Dashboard uppy={uppy} closeModalOnClickOutside />}
                {isDoneParsing && (
                  <ImportTable
                    headers={headers}
                    dataRows={dataRows}
                    rowErrors={rowErrors}
                    rowState={rowState}
                    currentView={currentView}
                  />
                )}
              </>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CSVImporterTool;
