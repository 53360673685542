import React, { useMemo } from 'react';
import { DetailFieldProps } from '..';
import { isFieldFormulaValue } from '../../../data/types';


export const ReadOnlyDetailField = ({field, displayValue}: DetailFieldProps) => {

    return (
        <input
        type="text"
        readOnly
        className="form-control-plaintext"
        id={`field-${field.fieldId}`}
        value={displayValue}
      />
    )
}

export default ReadOnlyDetailField;