import { values as _values, orderBy as _orderBy, filter as _filter } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const getNotificationsState = (state: RootState) => state.notifications;

export const getNotifications = createSelector(getNotificationsState, (state) => state.notifications);

export const getNotificationsList = createSelector(getNotifications, (notifications) => {
  const notificationsList = _values(notifications);
  const notDeletedNotifications = _filter(notificationsList, (not) => !not.isDeleted);
  return _orderBy(notDeletedNotifications, ['createdDT'], ['desc']);
});


export const getAlertNotificationsList = createSelector(getNotificationsList, (notifications) => {
  return _filter(notifications, (val) => !!val.isAlertVisible);
});

  export const getUnreadNotificationsCount = createSelector(getNotificationsList, (notifications) => {
    if(!Array.isArray(notifications)) {
      return 0;
    }
    return _filter(notifications, (val) => !!val.isUnread).length;
  });