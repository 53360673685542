import { cloneDeep as _cloneDeep } from 'lodash';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Field,
  FilterCondition,
  FilterConditionGroup,
  FILTER_EXPRESSION,
  getFieldFilterConditionDefault,
  OPERATOR_OPTIONS,
} from '@taida-corp/taidacorp-sdk';
import { useCallback } from 'react';
import FilterFieldCondition from './FilterFieldCondition';
import { remove, replace } from '../../../../utils/array';

interface FilterBuilderFormProps {
  emptyText?: string;
  filterActionText?: string;
  fields: Field[];
  filters: FilterConditionGroup;
  setFilters: (updatedFilterConditionGroup:FilterConditionGroup) => void;
}

export const FilterBuilderForm = ({
  filters,
  setFilters,
  fields,
  emptyText = 'No Filters Applied',
  filterActionText = 'Which Records Should Be Acted Upon',
}: FilterBuilderFormProps) => {
  const addFilteringFieldGroup = useCallback(() => {
    const { operator, conditions } = filters || { operator: 'and', conditions: [] };
    const newCondition = getFieldFilterConditionDefault(fields[0]);
    const newConditionGroup: FilterConditionGroup = { operator: 'and', conditions: [newCondition] };
    setFilters({ operator, conditions: [...conditions, newConditionGroup] });
  }, [fields, filters, setFilters]);

  const addFilteringField = useCallback(() => {
    const { operator, conditions } = filters || { operator: 'and', conditions: [] };
    const newCondition = getFieldFilterConditionDefault(fields[0]);
    setFilters({ operator, conditions: [...conditions, newCondition] });
  }, [fields, filters, setFilters]);
  const removeFilteringField = useCallback(
    (index: number) => {
      const { operator, conditions } = filters || { operator: 'and', conditions: [] };
      const newConditions = remove(conditions, index);
      setFilters({ operator, conditions: newConditions });
    },
    [filters, setFilters],
  );
  const updateFilteringField = useCallback(
    (
      newField: string,
      newExpression: FILTER_EXPRESSION,
      newOption: string | undefined,
      newCompareTo: any,
      index: number,
    ) => {
      const { operator, conditions } = filters || { operator: 'and', conditions: [] };
      const newConditions: FilterCondition[] = replace(
        conditions,
        {
          fieldId: newField,
          expression: newExpression,
          option: newOption,
          compareTo: newCompareTo,
        } as FilterCondition,
        index,
      );
      setFilters({ operator, conditions: newConditions });
    },
    [filters, setFilters],
  );
  const updateOperator = useCallback(
    (newOperator: OPERATOR_OPTIONS) => {
      const { operator, conditions } = filters || { operator: 'and', conditions: [] };

      setFilters({ operator: newOperator, conditions });
    },
    [filters, setFilters],
  );
  const updateRootFilterConditionAtIndex = useCallback(
    (newFilters: FilterConditionGroup, index:number) => {
      const updatedRootFilterConditions = replace(filters.conditions, newFilters, index);
      setFilters({conditions: updatedRootFilterConditions, operator: filters.operator});
    },
    [filters.conditions, filters.operator, setFilters],
  );

  return (
    <>
      <div className="mb-3">
        <label className="form-label">Filters</label>
        {filters.conditions.length === 0 && (
          <div>
            <small className="text-muted">{emptyText}</small>
          </div>
        )}
        {filters.conditions.length > 0 && (
          <div className="view-filter__current-fields-list">
            <div className="text-secondary mb-1">{filterActionText}</div>

            {filters.conditions.map((condition, conditionIndex) => {
              return (
                <FilterFieldCondition
                  key={`filter-${conditionIndex}`}
                  operator={filters.operator}
                  condition={condition}
                  fields={fields}
                  index={conditionIndex}
                  updateFilteringField={updateFilteringField}
                  removeFilteringField={removeFilteringField}
                  updateOperator={updateOperator}
                  onChildPopover={() => {}}
                  updateFilterConditionAtIndex={updateRootFilterConditionAtIndex}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="mb-3">
        <button type='button' onClick={addFilteringField} className="btn btn-link text-black ps-0">
          <small className="text-muted">
            <FontAwesomeIcon className="ms-2" icon={faPlus} /> Add Condition
          </small>
        </button>
        <button type='button' onClick={addFilteringFieldGroup} className="btn btn-link text-black ps-0">
          <small className="text-muted">
            <FontAwesomeIcon className="ms-2" icon={faPlus} /> Add Condition Group
          </small>
        </button>
      </div>
    </>
  );
};
