import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoAttachment } from '@taida-corp/taidacorp-sdk';
import { useMemo } from 'react';
import getFontAwesomeIconFromMIME from '../../../../../../../utils/getFontAwesomeIconFromMime';


export const VideoAttachmentView = ({
  attachment,
  onDelete,
}: {
  attachment: VideoAttachment;
  onDelete: () => void;
}) => {
    const FaIcon = useMemo(() => {
        return getFontAwesomeIconFromMIME(attachment.original.mime);
      }, [attachment.original.mime]);
  return (
    <div className="w-100 bg-light p-4 position-relative ">
      <div>{attachment.original.name}</div>
      <div className="row mt-3">
        {attachment.thumbnail_150x150.map((thumbnail, index) => (
          <div className="col" key={thumbnail.id}>
            <a href={thumbnail.ssl_url} target="_blank" rel="noreferrer">
              <figure className="figure">
                <img className="figure-img img-fluid" src={thumbnail.ssl_url} alt={thumbnail.name} />
                <figcaption className="figure-caption text-end">Thumbnail {index + 1}</figcaption>
              </figure>
            </a>
          </div>
        ))}
      </div>
      <div className="row mt-3">
        {attachment.video_cover.map((video_cover, index) => (
          <div className="col" key={video_cover.id}>
            <a href={video_cover.ssl_url} target="_blank" rel="noreferrer">
              <figure className="figure">
                <img className="figure-img img-fluid" src={video_cover.ssl_url} alt={video_cover.name} />
                <figcaption className="figure-caption text-end">Video Cover {index + 1}</figcaption>
              </figure>
            </a>
          </div>
        ))}
      </div>
      <div className="row mt-3">
        <div className="col">
          <div className="ratio ratio-16x9">
            <video controls>
              <source src={attachment.video_mp4_1080p.ssl_url} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <a href={attachment.original.ssl_url} target="_blank" rel="noreferrer">
            <div className="bg-white rounded p-3 d-inline-flex flex-column ">
              <FontAwesomeIcon size="10x" color={'black'} icon={FaIcon} />
              <div>{attachment.original.name}</div>
            </div>
          </a>
        </div>
      </div>
      <button type="button" onClick={onDelete} className="btn btn-sm btn-danger">
        Delete Asset
      </button>
    </div>
  );
};

export default VideoAttachmentView;
