import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { Campaign } from '../types';
import {
  fetchCampaigns,
  createCampaign,
  updateCampaign,
  realTimeCampaignCreated,
  realTimeCampaignDeleted,
  realTimeCampaignUpdated,
  setSelectedCampaignId,
  fetchCampaign,
} from './actions';

interface CampaignsState {
  campaigns: Record<string, Campaign>;
  isLoading: boolean;
  isInitialized: boolean;
  isSaving: boolean;
  selectedCampaignId: string | undefined;
}

const initialState: CampaignsState = {
  campaigns: {},
  isLoading: false,
  isInitialized: false,
  isSaving: false,
  selectedCampaignId: undefined,
};

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedCampaignId, (state, { payload }) => {
      state.selectedCampaignId = payload;
    });
    builder.addCase(fetchCampaigns.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCampaigns.fulfilled, (state, { payload }) => {
      state.campaigns = keyBy(payload, 'campaignId');
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchCampaigns.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });

    builder.addCase(fetchCampaign.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCampaign.fulfilled, (state, { payload }) => {
      state.campaigns = { ...state.campaigns, [payload.campaignId]: payload };
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchCampaign.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(createCampaign.pending, (state, { payload }) => {
      state.isSaving = true;
    });
    builder.addCase(createCampaign.fulfilled, (state, { payload }) => {
      state.campaigns = { ...state.campaigns, [payload.campaignId]: payload };
      state.selectedCampaignId = payload.campaignId;
      state.isSaving = false;
    });
    builder.addCase(createCampaign.rejected, (state, { payload }) => {
      state.isSaving = false;
    });
    builder.addCase(updateCampaign.pending, (state, { payload }) => {
      state.isSaving = true;
    });
    builder.addCase(updateCampaign.fulfilled, (state, { payload }) => {
      state.campaigns = { ...state.campaigns, [payload.campaignId]: payload };
      state.isSaving = false;
    });
    builder.addCase(updateCampaign.rejected, (state, { payload }) => {
      state.isSaving = false;
    });

    builder.addCase(realTimeCampaignUpdated, (state, { payload }) => {
      const { campaign: message } = payload;
      state.campaigns = { ...state.campaigns, [message.campaignId]: message };
    });
    builder.addCase(realTimeCampaignCreated, (state, { payload }) => {
      const { campaign: message } = payload;
      state.campaigns = { ...state.campaigns, [message.campaignId]: message };
    });
    builder.addCase(realTimeCampaignDeleted, (state, { payload }) => {
      const { campaign: message } = payload;
      delete state.campaigns[message.campaignId];
    });
  },
});

export default campaignsSlice;
