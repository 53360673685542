import { get as _get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { CellMeasurer } from 'react-virtualized';
import { DealRecord, Field, TableView } from '../../../../data/types';
import TableDataCell from '../TableDataCell/TableDataCell';
import TableHeaderCell from '../TableHeaderCell/TableHeaderCell';

export const CellWrapper = ({
  data,
  view,
  fields,
  parent,
  columnIndex,
  key,
  rowIndex,
  style,
  hoveredRowIndex,
  setHoveredRowIndex,
  grid,
}: {
  data: DealRecord[];
  view: TableView;
  fields: Record<string, Field>;
  parent: any;
  columnIndex: number;
  key: any;
  rowIndex: number;
  style: any;
  hoveredRowIndex:null|number;
  setHoveredRowIndex:Function;
  grid:any;
}) => {

  const tableField = view.properties.fields.filter((tableViewField) => tableViewField.isVisible && fields[tableViewField.fieldId] !== undefined)[columnIndex];
  const fieldField =   _get(fields, [tableField.fieldId]);
  const onMouseOver = useCallback(() => {
    setHoveredRowIndex(rowIndex);
    if(grid !== null && grid !== undefined) {
      grid.current.forceUpdateGrids();
    }
  }, [grid, rowIndex, setHoveredRowIndex]);

  const dealRecord = useMemo(() => {
    return  _get(data, [rowIndex - 1]);
    
  }, [data, rowIndex]);
  if (rowIndex === 0) {
    return (
        <div key={key} style={style} className='table-header__th ' >
          <TableHeaderCell
              field={fieldField}
              tableField={tableField}
              canHide={columnIndex !== 0}
            />
        </div>
    );
  }
  return (
      <div key={key} style={style}
      onMouseOver={onMouseOver}
      >
        <TableDataCell row={rowIndex} index={columnIndex} key={`dataCell-${tableField.fieldId}-${dealRecord.dealId}`} dealRecord={dealRecord} field={tableField} error={undefined} isRowHovered={hoveredRowIndex === rowIndex} />
      </div>
  );
};

export default CellWrapper;
