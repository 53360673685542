import { faFileAudio, faFile, faCross, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { DetailFieldProps } from '..';
import {
  Attachment,
  FieldAttachment,
  isAudioAttachment,
  isFieldAttachmentValue,
  isFieldFormulaValue,
  isImageAttachment,
  isVideoAttachment,
} from '../../../data/types';
import AudioAttachmentView from './AttachmentAssetViews/AudioAttachmentView';
import FileAttachmentView from './AttachmentAssetViews/FileAttachmentView';
import ImageAttachmentView from './AttachmentAssetViews/ImageAttachmentView';
import VideoAttachmentView from './AttachmentAssetViews/VideoAttachmentView';
import AttachmentInlineUploader from './AttachmentInlineUploader';

interface AttachmentDetailFieldProps extends DetailFieldProps {
  field: FieldAttachment;
}

export const AttachmentDetailField = ({ field, internalValue, ogValue, onChange }: AttachmentDetailFieldProps) => {
  const onChangeHandle = useCallback(
    (e) => {
      onChange(e.target.value ?? '');
    },
    [onChange],
  );
  const attachments = useMemo(() => {
    if (!isFieldAttachmentValue(ogValue) || ogValue.value === null) {
      return [];
    }
    let _attachments = ogValue.value as Attachment[];
    if (!Array.isArray(_attachments)) {
      _attachments = [_attachments];
    }
    return _attachments;
  }, [ogValue]);
  const canUploadMore = useMemo(() => {
    if (!!field.config.maxNumberOfFiles && attachments.length >= field.config.maxNumberOfFiles) {
      return false;
    }
    return true;
  }, [field, attachments]);

  const removeAttachmentAtIndex = useCallback(
    (index: number) => {
      if (attachments.length === 0) {
        return;
      }
      const _attachments = Array.from(attachments);
      _attachments.splice(index, 1);
      onChange({ value: _attachments });
    },
    [onChange, attachments],
  );

  const addAttachments = useCallback(
    (newAttachments: Attachment[]) => {
      if (newAttachments.length === 0) {
        return;
      }
      const _attachments = Array.from([...attachments, ...newAttachments]);
      onChange({ value: _attachments });
    },
    [onChange, attachments],
  );

  return (
    <div>
      {attachments.map((attachment, index) => {
        if (isImageAttachment(attachment)) {
          return (
            <div key={attachment.original.original_id} className="mb-3">
              <ImageAttachmentView attachment={attachment} onDelete={() => removeAttachmentAtIndex(index)} />
            </div>
          );
        }
        if (isVideoAttachment(attachment)) {
          return (
            <div key={attachment.original.original_id} className="mb-3">
              <VideoAttachmentView attachment={attachment} onDelete={() => removeAttachmentAtIndex(index)} />
            </div>
          );
        }
        if (isAudioAttachment(attachment)) {
          return (
            <div key={attachment.original.original_id} className="mb-3">
              <AudioAttachmentView attachment={attachment} onDelete={() => removeAttachmentAtIndex(index)} />
            </div>
          );
        }
        return (
          <div key={attachment.original.original_id} className="mb-3">
              <FileAttachmentView attachment={attachment} onDelete={() => removeAttachmentAtIndex(index)} />
          </div>
        );
      })}
      {canUploadMore && (
        <div>
          <AttachmentInlineUploader onComplete={addAttachments} field={field} />
        </div>
      )}
    </div>
  );
};

export default AttachmentDetailField;
