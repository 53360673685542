import React from 'react';
import { Link } from 'react-router-dom';

interface TableFooterProps {
    total:number
    addRowLink: string;
}

export const TableFooter = ({total, addRowLink}:TableFooterProps) => {

    return (
        <div className='flex-shrink-1 p-1 border-top bg-light'>
            {total} rows <Link to={addRowLink}>Add Row</Link>
        </div>
    )
}

export default TableFooter;