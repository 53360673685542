import React, { useCallback } from 'react';
import { FieldLongText, FieldTextConfig } from '../../../data/types';

interface TextConfigEditorProps {
  field: FieldLongText;
  internalConfig:FieldTextConfig;
  onChangeConfig: (e: any) => void;
}

export const LongTextConfigEditor = ({field, internalConfig:config, onChangeConfig}: TextConfigEditorProps) => {
  const {
    defaultValue='',
  } = config;
  const onChange = useCallback((e:any) => {
    const newVal = e.target.value;
    onChangeConfig({
      defaultValue: newVal
    });
  }, [onChangeConfig]);
  return (
    <textarea className="form-control" placeholder="Default Value" value={defaultValue || ''} onChange={onChange} />
  );
};

export default LongTextConfigEditor;
