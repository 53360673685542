import {filter as _filter, sortBy as _sortBy, find as _find, findIndex as _findIndex} from 'lodash'
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { getAllFieldsList } from '../../../../data/company/selectors';
import { DealRecord, Field, FormView } from '../../../../data/types';
import { getViews } from '../../../../data/views/selectors';
import DealFormInput from './DealFormInput';



export const DealForm = ({ dealRecord, formViewId }: { dealRecord: DealRecord; formViewId:string }) => {
  const fields = useSelector(getAllFieldsList);
  const views = useSelector(getViews);
  const form = useMemo(() => {
    return views[formViewId] as FormView;
  }, [formViewId, views])
  const formFields = useMemo(() => {
    const _ogFormFields = !!form ? form.properties.fields : [];
    const fieldsInForm = _filter(fields, (field) => {
      return !!_find(_ogFormFields, {fieldId: field.fieldId});
    })
    const orderedFields = _sortBy(fieldsInForm, (field) => {
      return _findIndex(_ogFormFields, {fieldId: field.fieldId})
    })
    return orderedFields
  }, [fields, form])
  console.log('formFields', formFields);

  if(!form) {
    return <div>Loading form...</div>
  }
  return (
      <AutoSizer>
          {({height, width}) => (
              
    <form className='pb-5 px-3' style={{height, width, overflowY: 'scroll'}}>
      {formFields.map((field) => (
        <DealFormInput key={`field-${formViewId}-${field.fieldId}`} field={field} deal={dealRecord} />
      ))}
      
    </form>

)}
    </AutoSizer>
  );
};

export default DealForm;
