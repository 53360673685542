import { Integration, IntegrationAction, RecordAutomation, RecordAutomationAction } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep, get as _get, findIndex as _findIndex } from 'lodash';
import { useCallback, useMemo } from 'react';
import { AutomationActionInputsForm } from './AutomationActionInputs/AutomationActionInputsForm';

interface ActionEditorProps {
  automation: RecordAutomation;
  action: RecordAutomationAction;
  onChangeAction: (updatedAction: RecordAutomationAction, index: number) => void;
  index: number;
}

export const ActionEditor = ({ automation, action, onChangeAction, index }: ActionEditorProps) => {
  const hasIntegration = useMemo(() => {
    return action.integrationId !== undefined && action.integrationId !== '' && action.integrationId !== '--';
  }, [action]);
  const hasIntegrationAction = useMemo(() => {
    return (
      action.integrationActionId !== undefined &&
      action.integrationActionId !== '' &&
      action.integrationActionId !== '--'
    );
  }, [action]);
  const integrationOptions = useMemo(
    () => [
      {
        value: 'taidacorp-email',
        label: 'Taida Corp Email',
      },
    ],
    [],
  );
  const integrationActionOptions: Record<string, { value: string; label: string }[]> = useMemo(
    () => ({
      'taidacorp-email': [{ value: 'taidacorp-email-send-email', label: 'Send Email' }],
    }),
    [],
  );
  const selectedIntegrationOptions = useMemo(() => {
    if (action.integrationId === undefined) {
      return [];
    }
    return _get(integrationActionOptions, [action.integrationId!], []);
  }, [action.integrationId, integrationActionOptions]);

  const onChangeIntegration = useCallback(
    (e) => {
      const updatedAction = {
        ...action,
        integrationId: e.target.value === undefined || e.target.value === '--' ? undefined : e.target.value,
      };
      onChangeAction(updatedAction, index);
    },
    [action, index, onChangeAction],
  );
  const onChangeIntegrationAction = useCallback(
    (e) => {
      const updatedAction = {
        ...action,
        integrationActionId: e.target.value === undefined || e.target.value === '--' ? undefined : e.target.value,
      };
      onChangeAction(updatedAction, index);
    },
    [index, onChangeAction, action],
  );

  return (
    <div className="card card-body mb-2">
      <div className='d-flex flex-row justify-content-between align-items-center mb-2'>
        <div>Action</div>
        <div><button className='btn btn-sm btn-danger' type='button'>Delete</button></div>
      </div>
      <div className="mb-2">
        <select
          value={action.integrationId ?? ''}
          onChange={onChangeIntegration}
          placeholder="Select Integration"
          className="form-select"
        >
          <option>--</option>
          {integrationOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-2">
        <select
          key={action.integrationId}
          disabled={!hasIntegration}
          value={action.integrationActionId ?? ''}
          onChange={onChangeIntegrationAction}
          placeholder="Select Integration Action"
          className="form-select"
        >
          <option>--</option>
          {selectedIntegrationOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {hasIntegration && hasIntegrationAction && automation !== undefined ? (
        <AutomationActionInputsForm
          action={action}
          onChangeAction={onChangeAction}
          index={index}
          automation={automation}
        />
      ) : (
        <div>Finish</div>
      )}
    </div>
  );
};

export default ActionEditor;
