import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

interface FullPageLoaderProps {
  loadingText?:string;
}

export const FullPageLoader = ({loadingText='Loading'}:FullPageLoaderProps) => {
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center bg-secondary">
      {/* <FontAwesomeIcon spin size={'10x'} color={'white'} icon={faSpinnerThird} /> */}
      <h1 className="mt-5 display-3 text-white text-uppercase">{loadingText}</h1>
    </div>
  );
};

export default FullPageLoader;
