import React from 'react';
import { useSelector } from 'react-redux';
import { DetailFieldProps } from '../..';
import { FieldMultiSelect, FieldMultiSelectUser, FieldSelect, FieldSelectUser } from '../../../../data/types';
import { getSelectUsersOptions } from '../../../../data/users/selectors';
import Select from '../../../Input/Select/Select';


export const UserSelectDetailField = ({field, internalValue, onChange}:DetailFieldProps) => {
    const options = useSelector(getSelectUsersOptions);
    return (
        <Select
            options={options}
            onChange={onChange}
            value={internalValue as string}
        />
    )
}

export default UserSelectDetailField;