import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { getSelectedView } from '../../../../data/content-type/record-views/selectors';
import { useAppDispatch } from '../../../../data/store';
import { toggleIsAutomationsPanelVisible } from '../../../../data/table-ui/actions';


export const TableAutomationsToggle = () => {
  const dispatch = useAppDispatch();
  const onClick = useCallback(() => {
    dispatch(toggleIsAutomationsPanelVisible());
  }, [dispatch])
  const selectedView = useSelector(getSelectedView);
  if(!selectedView || selectedView.type !== 'table') {
    return null;
  }
  return (
    <div tabIndex={0} className="deals-toolbar__item" onClick={onClick}>
      <FontAwesomeIcon className='me-1' icon={faBolt} /> Automations
    </div>
  );
};

export default TableAutomationsToggle;
