import { PayloadAction } from '@reduxjs/toolkit';
import { Action, Dispatch, MiddlewareAPI } from 'redux';
import {
  connect,
  fetchToken,
  onBeginReconnect,
  onBrokenConnection,
  onCloseConnection,
  onError,
  onMessageReceived,
  onOpenConnection,
  onReconnectAttempt,
  onReconnected,
  send,
  WEBSOCKET_ACTION_PREFIX as prefix,
} from './actions';

const PING_INTERVAL_MS = 30000;
const HEALTH_CHECK_INTERVAL_MS = 1000;

interface ReduxWebSocketOptions {
  reconnectInterval: number;
  reconnectOnClose: boolean;
  reconnectOnError: boolean;
  onOpen?: (s: WebSocket) => void;
}

export default class ReduxWebSocket {
  private options: ReduxWebSocketOptions;

  private websocket: WebSocket | null = null;

  private reconnectCount: number = 0;

  private reconnectionInterval: any | null = null;
  private pingInterval: any | null = null;
  private healthCheckInterval: any | null = null;
  private lastEvent?: Date | null;

  // Keep track of the last connect payload we used to connect, so that when we automatically
  // try to reconnect, we can reuse the previous connect payload.
  private lastConnectPayload: {
    url: string;
  } | null = null;

  // Keep track of if the WebSocket connection has ever successfully opened.
  private isConnected = false;
  private isConnecting = false;

  /**
   * Constructor
   * @constructor
   *
   * @param {ReduxWebSocketOptions} options
   */
  constructor(options: ReduxWebSocketOptions) {
    this.options = options;
  }

  /**
   * WebSocket connect event handler.
   *
   * @param {MiddlewareAPI} store
   * @param {Action} action
   */
  connect = ({ dispatch }: MiddlewareAPI, { payload }: { payload: { url: string } }) => {
    if (this.isConnecting) {
      return;
    }
    this.lastEvent = new Date();
    this.isConnecting = true;
    this.close();
    this.lastConnectPayload = payload;
    this.websocket = new WebSocket(payload.url);

    this.websocket.addEventListener('close', (event) => this.handleClose(dispatch, event));
    this.websocket.addEventListener('error', (event) => this.handleError(dispatch, event));
    this.websocket.addEventListener('open', (event) => {
      this.isConnecting = false;
      this.handleOpen(dispatch, this.options.onOpen, event);
    });
    this.websocket.addEventListener('message', (event) => this.handleMessage(dispatch, event));
    this.startPing(dispatch);
    this.startHealthCheck(dispatch);
  };

  /**
   * WebSocket disconnect event handler.
   *
   * @throws {Error} Socket connection must exist.
   */
  disconnect = () => {
    if (this.websocket !== null) {
      this.close();
    } else {
      throw new Error('Socket connection not initialized. Dispatch WEBSOCKET_CONNECT first');
    }
  };

  /**
   * WebSocket send event handler.
   *
   * @param {MiddlewareAPI} _store
   * @param {Action} action
   *
   * @throws {Error} Socket connection must exist.
   */
  send = (_store: MiddlewareAPI, { payload }: PayloadAction) => {
    if (this.websocket) {
      this.websocket.send(JSON.stringify(payload));
    } else {
      throw new Error('Socket connection not initialized. Dispatch WEBSOCKET_CONNECT first');
    }
  };

  /**
   * Handle a close event.
   *
   * @param {Dispatch} dispatch
   * @param {Event} event
   */
  private handleClose = (dispatch: Dispatch, event: Event) => {
    this.isConnecting = false;

    dispatch(onCloseConnection({ event }));

    // Conditionally attempt reconnection if enabled and applicable
    const { reconnectOnClose } = this.options;
    if (reconnectOnClose && this.canAttemptReconnect()) {
      this.handleBrokenConnection(dispatch);
    }
  };

  /**
   * Handle an error event.
   *
   * @param {Dispatch} dispatch
   * @param {Event} event
   */
  private handleError = (dispatch: Dispatch, event: Event) => {
    this.isConnecting = false;

    dispatch(onError({ action: null, error: new Error('`redux-websocket` error') }));

    // Conditionally attempt reconnection if enabled and applicable
    const { reconnectOnError } = this.options;
    if (reconnectOnError && this.canAttemptReconnect()) {
      this.handleBrokenConnection(dispatch);
    }
  };

  /**
   * Handle an open event.
   *
   * @param {Dispatch} dispatch
   * @param {(s: WebSocket) => void | undefined} onOpen
   * @param {Event} event
   */
  private handleOpen = (dispatch: Dispatch, onOpen: ((s: WebSocket) => void) | undefined, event: Event) => {
    this.isConnecting = false;
    this.lastEvent = new Date();
    // Clean up any outstanding reconnection attempts.
    if (this.reconnectionInterval) {
      clearInterval(this.reconnectionInterval);

      this.reconnectionInterval = null;
      this.reconnectCount = 0;

      dispatch(onReconnected());
    }

    // Hook to allow consumers to get access to the raw socket.
    if (onOpen && this.websocket != null) {
      onOpen(this.websocket);
    }

    // Now we're fully open and ready to send messages.
    dispatch(onOpenConnection({ event }));

    // Track that we've been able to open the connection. We can use this flag
    // for error handling later, ensuring we don't try to reconnect when a
    // connection was never able to open in the first place.
    this.isConnected = true;
  };

  private handleMessage = (dispatch: Dispatch, event: MessageEvent | any) => {
    this.lastEvent = new Date();
    const { data } = event;
    if (data !== undefined) {
      const serverEvent = JSON.parse(data);
      const { event: actionType, ...payload } = serverEvent;
      const actionFromServer = {
        type: actionType,
        payload,
      };
      console.log(actionFromServer);
      dispatch(actionFromServer);
    } else {
      dispatch(onMessageReceived({ event }));
    }
  };

  /**
   * Close the WebSocket connection.
   * @private
   *
   * @param {number} [code]
   * @param {string} [reason]
   */
  private close = (code?: number, reason?: string) => {
    if (this.websocket !== null) {
      this.websocket.close(code || 1000, reason || 'WebSocket connection closed by redux-websocket.');

      this.websocket = null;
      this.isConnected = false;
    }
  };

  /**
   * Handle a broken socket connection.
   * @private
   *
   * @param {Dispatch} dispatch
   */
  private handleBrokenConnection = (dispatch: Dispatch) => {
    const { reconnectInterval } = this.options;

    this.websocket = null;

    // maybe we need a new token
    dispatch(fetchToken() as any);

    // First, dispatch actions to notify Redux that our connection broke.
    dispatch(onBrokenConnection());
    dispatch(onBeginReconnect());

    this.reconnectCount = 1;

    dispatch(onReconnectAttempt({ reconnectCount: this.reconnectCount }));

    // Attempt to reconnect immediately by calling connect with assertions
    // that the arguments conform to the types we expect.
    this.connect(
      { dispatch } as MiddlewareAPI,
      { payload: this.lastConnectPayload } as PayloadAction<{ url: string; protocols: string[] }>,
    );

    // Attempt reconnecting on an interval.
    this.reconnectionInterval = setInterval(() => {
      this.reconnectCount += 1;
      if (this.reconnectCount > 30) {
        alert('disconnected from realtime updates - try refreshing your page');
        clearInterval(this.reconnectionInterval);
        clearInterval(this.pingInterval);
        clearInterval(this.healthCheckInterval);
        return;
      }
      dispatch(onReconnectAttempt({ reconnectCount: this.reconnectCount }));

      // Call connect again, same way.
      this.connect({ dispatch } as MiddlewareAPI, { payload: this.lastConnectPayload! });
    }, reconnectInterval);
  };

  // Only attempt to reconnect if the connection has ever successfully opened,
  // and we're not currently trying to reconnect.
  //
  // This prevents ongoing reconnect loops to connections that have not
  // successfully opened before, such as net::ERR_CONNECTION_REFUSED errors.
  //
  // This also prevents starting multiple reconnection attempt loops.
  private canAttemptReconnect(): boolean {
    return this.isConnected && this.reconnectionInterval == null;
  }

  startPing(dispatch: Dispatch) {
    const self = this;
    this.pingInterval = setInterval(() => {
      const data = {
        action: 'ping',
      };
      try {
        dispatch(send(data));
      } catch (e) {
        console.error(e);
      }
    }, PING_INTERVAL_MS);
  }
  startHealthCheck(dispatch: Dispatch) {
    const self = this;
    this.healthCheckInterval = setInterval(() => {
      const timeDelta = Date.now() - (self.lastEvent as Date).valueOf();
      if (timeDelta > PING_INTERVAL_MS + 10 * 1000) {
        // do something here
        clearInterval(self.healthCheckInterval);
        clearInterval(self.pingInterval);

        const { reconnectOnError } = this.options;
        if (reconnectOnError && this.canAttemptReconnect()) {
          this.handleBrokenConnection(dispatch);
        }
      }
    }, HEALTH_CHECK_INTERVAL_MS);
  }
}
