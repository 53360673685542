import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIndex as _findIndex, find as _find } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyFieldList } from '../../../../data/company/selectors';
import {
  DealView,
  Field,
  FormView,
  FormViewField,
  KanbanView,
  TableView,
  TableViewField,
  ViewField,
} from '../../../../data/types';
import { updateDealView } from '../../../../data/views/actions';
import useFieldsToggle from '../../../../hooks/useFieldsToggle/useFieldsToggle';

import './FieldsToggle.scss';

interface FieldsToggleProps {
  field: ViewField;
  index:number;
  view:TableView|KanbanView;
  onDropField: () => void
  onMoveField: (startIndex: number, endIndex: number) => void;
  setFieldIsVisible: (fieldId: string, isVisible: boolean, startIndex:number) => void
}

export const FieldsToggle = ({
  field,
  index,
  onDropField,
  onMoveField,
  setFieldIsVisible,
}: FieldsToggleProps) => {
  const fullFields = useSelector(getCompanyFieldList);
  const fullField = useMemo(() => _find(fullFields, {fieldId: field.fieldId}), [field.fieldId, fullFields]);
  const {ref, drag, isDragging, isOver, canToggle, canDND, onToggleVisibility} = useFieldsToggle({field, index, setFieldIsVisible, onMoveField, onDropField});
  
  return (
    <div ref={ref} className="d-flex flex-row align-items-center justify-content-between">
      <div className="fields-toggle form-check form-switch flex-grow-1">
        <input
          id={`field-toggle-${field.fieldId}`}
          name={`field-toggle-${field.fieldId}`}
          className="form-check-input"
          type="checkbox"
          checked={field.isVisible}
          disabled={!canToggle}
          onChange={onToggleVisibility}
        />
        <label className="form-check-label" htmlFor={`field-toggle-${field.fieldId}`}>
          {fullField === undefined ? <span className='text-danger'><i>{field.fieldId}</i></span> : fullField!.name}
        </label>
      </div>
      {canDND && (
      <div ref={drag} className='grab btn ps-0'>
      <FontAwesomeIcon icon={faGripVertical} />
      </div>
      )}
    </div>
  );
};
