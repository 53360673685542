import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { TextMessage } from '../types';
import { fetchTextMessages, fetchTextMessagesForPhoneNumber, realTimeTextMessageCreated, realTimeTextMessageDeleted, realTimeTextMessageUpdated, sendTextMessage, setSelectedPhoneNumber } from './actions';


interface TextMessagesState {
  textMessages: Record<string, TextMessage>;
  isLoading: boolean;
  isInitialized: boolean;
  isSending: boolean;
  selectedPhoneNumber?:string;
}

const initialState: TextMessagesState = {
  textMessages: {},
  isLoading: false,
  isInitialized: false,
  isSending:false,
  selectedPhoneNumber:undefined
};

export const textMessagesSlice = createSlice({
  name: 'textMessages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedPhoneNumber, (state, { payload }) => {
      state.selectedPhoneNumber = payload;
    });
    builder.addCase(fetchTextMessages.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTextMessages.fulfilled, (state, { payload }) => {
      state.textMessages = keyBy(payload, 'messageId');
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchTextMessages.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });

    builder.addCase(fetchTextMessagesForPhoneNumber.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTextMessagesForPhoneNumber.fulfilled, (state, { payload }) => {
      const updates = keyBy(payload, 'messageId');

      state.textMessages =  {...state.textMessages, ...updates};
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchTextMessagesForPhoneNumber.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });

    builder.addCase(sendTextMessage.pending, (state, { payload }) => {
      state.isSending = true;
    });
    builder.addCase(sendTextMessage.fulfilled, (state, { payload }) => {

      state.textMessages =  {...state.textMessages, [payload.messageId]: payload};
      state.isSending = false;
    });
    builder.addCase(sendTextMessage.rejected, (state, { payload }) => {
      state.isSending = false;
    });
    builder.addCase(realTimeTextMessageUpdated, (state, { payload }) => {
      const { textMessage: message } = payload;
      state.textMessages = {...state.textMessages, [message.messageId]: message};
    });
    builder.addCase(realTimeTextMessageCreated, (state, { payload }) => {
      const { textMessage: message } = payload;
      state.textMessages = {...state.textMessages, [message.messageId]: message};
    });
    builder.addCase(realTimeTextMessageDeleted, (state, { payload }) => {
      const { textMessage: message } = payload;
      delete state.textMessages[message.messageId];
    });
  },
});

export default textMessagesSlice;
