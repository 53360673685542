import { MonthlyScheduledFilterAutomation, MonthlyScheduledViewAutomation, RecordAutomation } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep } from 'lodash';
import { useCallback } from 'react';

interface DayOfMonthInputProps {
  form: MonthlyScheduledFilterAutomation|MonthlyScheduledViewAutomation;
  updateAutomation: (updatedRecordAutomation:RecordAutomation) => void;
}

export const DayOfMonthInput = ({ form, updateAutomation }: DayOfMonthInputProps) => {
  const onChange = useCallback(
    (e) => {
      const updated = _cloneDeep(form) as MonthlyScheduledFilterAutomation|MonthlyScheduledViewAutomation;
      updated.triggerConfig.scheduleOptions.dayOfMonth = parseInt(e.target.value);
      updateAutomation(updated);
    },
    [updateAutomation, form],
  );

  return (
    <div className="mb-3">
      <label htmlFor="dayOfMonth" className="form-label">
        Day of the Month
      </label>
      <select
        onChange={onChange}
        value={form.triggerConfig.scheduleOptions.dayOfMonth}
        className="form-control"
        id="dayOfMonth"
        aria-describedby="dayOfMonthHelp"
      >
        {[...Array(31)].map((x, idx) => (
          <option key={idx} value={idx + 1}>
            {idx + 1}
          </option>
        ))}
      </select>
      <div id="dayOfMonthHelp" className="form-text"></div>
    </div>
  );
};

export default DayOfMonthInput;
