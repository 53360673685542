import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createField } from '../../../../data/company/fieldActions';
import { DealView, TableView } from '../../../../data/types';

interface AddFieldButtonProps {
  readonly view: DealView;
  readonly style:any;
}
export const AddFieldButton = ({
  view,
  style
}: AddFieldButtonProps) => {
  const tableView = view as TableView;
  const dispatch = useDispatch();
  const createColumn = useCallback(() => {
    dispatch(createField({type: 'text', viewId: tableView.viewId}));
  }, [dispatch, tableView.viewId]);

  return (
            <div style={style}>
            <button
              onClick={createColumn}
              className="btn btn-light2 d-flex flex-shrink-1 flex-row align-items-center justify-content-center rounded-0"
              style={{ height: 32, width: 140 }}
              type="button"
            >
              +
            </button>
            </div>
  );
};

export default AddFieldButton;
