import { useState, useRef, useMemo, useCallback } from "react";

interface useListItemProps {
    onEnter:Function;
    onEscape:Function;
}

export const useListItem = ({onEnter, onEscape}:useListItemProps) => {
    const listItemRef = useRef<any>(null);
    const [isSelected, setIsSelected] = useState(false);

    const onKeyDown = useCallback(
        (event: any) => {
            const listItem = listItemRef.current!;
            const prevElement = listItem.previousElementSibling as HTMLElement;
            const nextElement = listItem.nextElementSibling as HTMLElement;
            // const upElement = listItem.parentElement?.previousElementSibling?.children[
            //     listItem.cellIndex
            // ] as HTMLElement;
            // const downElement = listItem.parentElement?.nextElementSibling?.children[
            //     listItem.cellIndex
            // ] as HTMLElement;


            switch (event.key) {
                case "ArrowLeft":
                    if (prevElement) {
                        prevElement.focus();
                    } else {
                        listItem.blur();
                    }
                    setIsSelected(false);
                    return;
                case "ArrowRight":
                    if (nextElement) {
                        nextElement.focus();
                    } else {
                        listItem.blur();
                    }
                    setIsSelected(false);
                    return;
                case "ArrowUp":
                    if (prevElement) {
                        prevElement.focus();
                    } else {
                        listItem.blur();
                    }
                    setIsSelected(false);
                    return;
                case "ArrowDown":
                    if (nextElement) {
                        nextElement.focus();
                    } else {
                        listItem.blur();
                    }
                    setIsSelected(false);
                    return;
                case "Enter":
                    listItem.blur();
                    setIsSelected(false);
                    onEnter();
                    return;
                case "Escape":
                    listItem.blur();
                    setIsSelected(false);
                    onEscape();
                    return;
                default:
                    break;
            }

            if (event.code === "Tab") {
                listItemRef.current!.blur();
                setIsSelected(false);
                return;
            }

        },
        [onEnter, onEscape]
    );
    const onFocus = useCallback((e) => {
        if (e.currentTarget === e.target) {
            setIsSelected(true);
        }
    }, []);
    const deselect = useCallback(() => {
        setIsSelected(false);
    }, []);

    const onBlur = useCallback((e) => {
        setIsSelected(false);
    }, []);
    const onClick = useCallback((e) => {
        onEnter();
    }, [onEnter]);
    const onMouseEnter = useCallback((e) => {
        setIsSelected(true);
    }, []);
    const onMouseLeave = useCallback((e) => {
        setIsSelected(false);
    }, []);

    return {
        isSelected,
        deselect,
        listItemProps: {
            tabIndex: 0,
            ref: listItemRef,
            onFocus,
            onBlur,
            onKeyDown,
            onMouseEnter,
            onMouseLeave,
            onClick,
        }
    }
}