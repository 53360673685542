import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../data/user/selectors';
import ProfileImagePicker from './components/ProfileImagePicker';
import SignatureResult from './components/SignatureResult';

export const SignatureWizard = (props: any) => {
    const user = useSelector(getUser);
    const [email, setEmail] = useState(user!.email);
    const [name, setName] = useState(user!.name);
    const [role, setRole] = useState('Sales Lead');
    const [phone, setPhone] = useState('');
    const [profileImageURL, setProfileImageURL] = useState('https://tc-uploads.s3.amazonaws.com/e2/d3d08442c94450b607b320ef483920/800x800-YT-23.jpg');
    const onChangeEmail = useCallback((e) => {
        setEmail(e.target.value ?? '')
    }, [])
    const onChangeName= useCallback((e) => {
        setName(e.target.value ?? '')
    }, [])
    const onChangeRole= useCallback((e) => {
        setRole(e.target.value ?? '')
    }, [])
    const onChangePhone= useCallback((e) => {
        setPhone(e.target.value ?? '')
    }, [])

  const onSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);
  
  return (
    <div className="row mt-3">
      <div className="col-2 h-100">
          <h6>Signature Fields</h6>
        <form onSubmit={onSubmit}>
        <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input disabled type="text" className="form-control" id="name" value={name} onChange={onChangeName}  />
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input disabled type="email" className="form-control" id="email" value={email} onChange={onChangeEmail} />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <input type="text" className="form-control" id="phone" value={phone} onChange={onChangePhone}  />
          </div>

          <div className="mb-3">
            <label htmlFor="role" className="form-label">
              Role
            </label>
            <input type="text" className="form-control" id="role" value={role} onChange={onChangeRole} placeholder='Sales Lead' />
          </div>
          <div className="mb-3">
            <ProfileImagePicker setProfileImageURL={setProfileImageURL} profileImageURL={profileImageURL} />
          </div>
        </form>
      </div>
      <div className="offset-2 col-8">
          <SignatureResult name={name} email={email} phone={phone} role={role} profileImageURL={profileImageURL} />
      </div>
    </div>
  );
};

export default SignatureWizard;
