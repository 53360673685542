import { createSlice } from '@reduxjs/toolkit';
import { User } from '../types';
import { fetchUsers } from './actions';


interface UsersState {
  users: User[];
  isLoading: boolean;
  isInitialized: boolean;
}

const initialState: UsersState = {
  users: [],
  isLoading: false,
  isInitialized: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.users = payload;
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchUsers.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });
  },
});

export default usersSlice;
