import { get as _get, find as _find } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  createMasonryCellPositioner,
  Masonry,
  MasonryCellProps,
  Positioner,
} from 'react-virtualized';

import './GalleryView.scss';
import {
  Attachment,
  DealRecord,
  FieldAttachment,
  FieldAttachmentValue,
  FieldNumber,
  FieldType,
  FileAttachment,
  GalleryView,
  ImageAttachment,
  VideoAttachment,
} from '../../../data/types';
import getHeaderImageFromAttachmentsArray from '../../../utils/getHeaderImage';
import { useNavigate } from 'react-router-dom';
import CardField from './CardField';

const list: any[] = [];
const cache: CellMeasurerCache = new CellMeasurerCache({
  defaultHeight: 250,
  defaultWidth: 260,
  fixedWidth: true,
});
const cellPositioner: Positioner = createMasonryCellPositioner({
  cellMeasurerCache: cache,
  columnCount: 8,
  columnWidth: 260,
  spacer: 10,
});

export interface GalleryViewCardField {
  ogValue: any;
  displayValue: string;
  label: string;
  type: FieldType;
}
export interface GalleryViewCard {
  id: string;
  coverImageURL?: string | null;
  primaryFieldValue?: string;
  fields: GalleryViewCardField[];
}

interface GalleryViewProps {
  deals: GalleryViewCard[];
  view: GalleryView;
}

export const GalleryViewComponent = ({ deals, view }: GalleryViewProps) => {
  const navigate = useNavigate();

  const onContextMenu = useCallback(
    (e, dealId: string) => {
      e.preventDefault();
      navigate(`/deals/${view.viewId}/edit/${dealId}`);
      /*
      e.preventDefault();
      // TODO: implement an actual right click menu
      // eslint-disable-next-line no-restricted-globals
      const shouldDelete = confirm('You sure you want to delete this row?');
      if (shouldDelete) {
        dispatch(deleteDealRecord(dealRecord));
      }
      */
    },
    [navigate, view],
  );
  const cellRenderer = useCallback(
    ({ index, key, parent, style }: MasonryCellProps) => {
      const datum = deals[index];
      const cardImageUrl = datum.coverImageURL;

      if (datum === undefined) {
        return <div />;
      }
      return (
        <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
          <div style={style}>
            <div className="card" style={{ width: 260 }} onContextMenu={(e) => onContextMenu(e, datum.id)}>
              {cardImageUrl && <img className="card-img-top" alt={`${datum.primaryFieldValue}`} src={cardImageUrl} />}
              <div className="card-body">
                <h5 className="card-title">{datum.primaryFieldValue ?? 'Oops'}</h5>
                <div>
                  {datum.fields.map((field, fieldIndex) => {
                    return <CardField key={`card-${datum.id}-${fieldIndex}`} field={field} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </CellMeasurer>
      ) as JSX.Element;
    },
    [deals, onContextMenu],
  );
  return (
    <div className="gallery-wrapper h-100 w-100">
      <AutoSizer>
        {({ height, width }) => (
          <div className="kanban-board" style={{ height, width, overflow: 'auto' }}>
            <div style={{padding: 20}}>
              <Masonry
                autoHeight={false}
                cellCount={deals.length}
                cellMeasurerCache={cache}
                cellPositioner={cellPositioner}
                cellRenderer={cellRenderer}
                height={height - 40}
                width={width - 40}
                keyMapper={(index:number) => deals[index].id}
              />
            </div>
          </div>
        )}
      </AutoSizer>
    </div>
  );
};

export default GalleryViewComponent;
