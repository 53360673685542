import { values as _values, orderBy as _orderBy } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const getRealTimeState = (state: RootState) => state.realTime;

export const getBrowserId = createSelector(getRealTimeState, (state) => state.browserId);
export const getToken = createSelector(getRealTimeState, (state) => state.token);
export const getIsInitialized = createSelector(getRealTimeState, (state) => state.isInitialized);
export const getIsLoading = createSelector(getRealTimeState, (state) => state.isLoading);
