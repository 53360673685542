import { RecordAutomation } from '@taida-corp/taidacorp-sdk';

import { useCallback } from "react";

interface AutomationListItemProps {
    onClickAutomationLink: (automationId: string, link: 'edit' | 'history') => void;
automation: RecordAutomation;
}
export const AutomationListItem = ({onClickAutomationLink, automation}:AutomationListItemProps) => {
    const onClickEdit = useCallback(() => {
        onClickAutomationLink(automation.automationId, 'edit')
    }, [automation.automationId, onClickAutomationLink])
    const onClickHistory = useCallback(() => {
        onClickAutomationLink(automation.automationId, 'history')
    }, [automation.automationId, onClickAutomationLink])
    return (
        <div className='card card-body mb-2 d-flex flex-row justify-content-between align-items-center'>
            <div>{automation.name}</div>
            <div>
            <button className='btn btn-primary me-2' onClick={onClickEdit}>Edit</button>
            <button className='btn btn-primary' onClick={onClickHistory}>History</button>
            </div>
        </div>
    )
}

export default AutomationListItem;