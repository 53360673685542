import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { DealActivity } from '../types';


export const toggleIsAutomationsPanelVisible = createAction('table-ui.toggleIsAutomationsPanelVisible');
export const setHoverRowIndex = createAction<{ hoverRowIndex: number|null }>('table-ui.setHoverRowIndex');
export const setHoverColumnIndex = createAction<{ hoverColumnIndex: number|null }>('table-ui.setHoverColumnIndex');
export const setHoverCoords = createAction<{ hoverRowIndex: number, hoverColumnIndex: number }|null>('table-ui.setHoverCoords');

export const dragSelectionCoords = createAction<{ dragRowIndex: number, dragColumnIndex: number }>('table-ui.dragSelectionCoords');
export const startSelectionCoords = createAction<{ startRowIndex: number, startColumnIndex: number }>('table-ui.startSelectionCoords');
export const setSelectedRangeCoords = createAction<{ startRowIndex: number, startColumnIndex: number; endRowIndex: number, endColumnIndex: number }|null>('table-ui.setSelectedRangeCoords');
export const deselectRange = createAction('deselectRange');
