import { faCog, faCheck, faCross, faTimes, faMinus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecordView } from '@taida-corp/taidacorp-sdk';
import { get } from 'lodash';
import { useMemo } from 'react';

interface ImportTableRowProps {
  rowState: 'IDLE' | 'PENDING' | 'POSTING' | 'DONE' | 'ERROR';
  headers: string[];
  rowIndex: number;
  row: any;
}

export const ImportTableRow = ({ rowState, headers, rowIndex, row }: ImportTableRowProps) => {
  return (
    <tr>
      <td>
        {rowState === 'PENDING' && (
          <FontAwesomeIcon icon={faMinus} title={'Idle'} color="gray" />
        )}
        {rowState === 'POSTING' && <FontAwesomeIcon icon={faMinus} color="gray" title={'Uploading'} className={'fa-pulse'} />}
        {rowState === 'DONE' && <FontAwesomeIcon icon={faCheck} title={'Done'} color="green" />}
        {rowState === 'ERROR' && <FontAwesomeIcon icon={faTimes} title={'Error'} color="red" />}
      </td>
      {headers.map((val) => (
        <td key={`row-${rowIndex}-${val}`}>{get(row, val, 'empty')}</td>
      ))}
    </tr>
  );
};

export default ImportTableRow;
