import React, { useMemo, useRef, useState } from 'react';
import Card from './Card';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { KanbanColumn } from './Board';

interface ColumnProps {
  index: number;
  column: KanbanColumn;
  canDrag?: boolean;
  moveCard: (cardId: string, fromColumnId: string, toColumnId: string) => void;
  addCard: (columnId: string) => void;
  addColumn: (newColumnTitle: string) => void;
  moveColumn: (fromIndex: number, toIndex: number) => void;
  onDropColumn: (columnId: string, toIndex: number) => void;
  onDropCard: (cardId: string, columnId: string) => void;
}

export const Column = ({
  column,
  index,
  moveColumn,
  moveCard,
  onDropCard,
  onDropColumn,
  canDrag = true,
}: ColumnProps) => {
  const [showMore, setShowMore] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [collectedProps, drop] = useDrop(
    {
      accept: ['kanban-column', 'kanban-card'],
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: any, monitor: DropTargetMonitor) {
        if (!ref.current) {
          return;
        }
        const dragType = monitor.getItemType() as 'kanban-column' | 'kanban-card';
        const dragId = item.id;
        const dragIndex = item.index;
        const hoverIndex = index;
        const hoverId = column.id;
        if (dragType === 'kanban-column') {
          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
            return;
          }

          // Determine rectangle on screen
          const hoverBoundingRect = ref.current?.getBoundingClientRect();

          // Get vertical middle
          const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

          // Determine mouse position
          const clientOffset = monitor.getClientOffset();

          // Get pixels to the top
          const hoverClientX = (clientOffset as { x: number }).x - hoverBoundingRect.left;

          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%

          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
            return;
          }

          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
            return;
          }

          // Time to actually perform the action
          moveColumn(dragIndex, hoverIndex);

          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          item.index = hoverIndex;
        } else {
          const currentColumnId = item.columnId;
          const currentColumnIndex = item.columnIndex;
          const destColumnId = column.id;
          const destColumnIndex = index;
          if (currentColumnId === destColumnId) {
            return;
          }
          moveCard(dragId, currentColumnId, destColumnId);
          item.index = column.cards.length;
          item.columnId = destColumnId;
          item.columnIndex = destColumnIndex;
        }
      },
      drop: (item, monitor) => {
        const result = monitor.getDropResult();
        const type = monitor.getItemType();
        if (type === 'kanban-column') {
          onDropColumn(item.id, item.index);
        }
        if (type === 'kanban-card') {
          onDropCard(item.id, column.id);
        }
      },
    },
    [column.id, index, moveColumn, onDropCard, onDropColumn],
  );

  const [{ isDragging }, drag] = useDrag(
    {
      canDrag,
      type: 'kanban-column',
      item: () => {
        return { id: column.id, index };
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [column.id, index, canDrag],
  );

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  const columnClassName = classNames('kanban-board__column', {
    'kanban-board__column--isdragging': isDragging,
  });
  const columnTitleClassName = classNames('kanban-board__column__header__title', {
    'kanban-board__column__header__title--uncategorized': !canDrag,
  });
  return (
    <div ref={ref} key={column.id} className={columnClassName} style={{ opacity }}>
      <div className="kanban-board__column__header">
      <div className={columnTitleClassName}>{column.title}</div>
      <div className={columnTitleClassName}>{column.summation}</div>
        <div className="kanban-board__column__header__collapse-toggle">
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </div>
      <div className="kanban-board__column__card-list">
        {column.cards.slice(0, 19).map((card, cardIndex) => {
          return <Card key={card.id} card={card} index={cardIndex} columnId={column.id} columnIndex={index} />;
        })}
        {column.cards.length > 20 && showMore && (
          <>
          {column.cards.slice(20).map((card, cardIndex) => {
            return <Card key={card.id} card={card} index={cardIndex} columnId={column.id} columnIndex={index} />;
          })}
          </>
        )}
        
        {column.cards.length > 20 && !showMore && (
          <div className='d-grid'>
          <div className='mt-3 btn btn-secondary btn-full' onClick={() => setShowMore(true)}>Show All</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Column;
