import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import './AutomationPanel.scss';
import { Automation, TableView } from '../../../data/types';
import SendSlackChannelNotification from './AutomationComponents/SendSlackChannelNotification';
import { useSelector } from 'react-redux';
import { getSlackChannels } from '../../../data/slack/selectors';
import { useAppDispatch } from '../../../data/store';
import { fetchSlackChannels } from '../../../data/slack/actions';
import { createAutomation, deleteAutomation, fetchAutomations, testAutomation, updateAutomation } from '../../../data/automations/actions';
import {
  getAutomations,
  getAutomationsForSelectedView,
  getIsInitialized,
  getIsLoading,
} from '../../../data/automations/selectors';
import { getFirstDealInSelectedView } from '../../../data/deals/selectors';
import SendWebhookNotification from './AutomationComponents/SendWebhookNotification';

interface AutomationPanelProps {
  view: TableView;
  isVisible: boolean;
}

export const AutomationPanel = ({ isVisible, view }: AutomationPanelProps) => {
  const dispatch = useAppDispatch();
  const automations = useSelector(getAutomationsForSelectedView);
  const isLoading = useSelector(getIsLoading);
  const isInitialized = useSelector(getIsInitialized);
  const channels = useSelector(getSlackChannels);
  const testDeal = useSelector(getFirstDealInSelectedView);

  useEffect(() => {
    dispatch(fetchSlackChannels());
    dispatch(fetchAutomations());
  }, [dispatch]);
  const createSlackNotification = useCallback(() => {
    const automation: Partial<Automation> = {
      runForEachUser: false,
      type: 'slack',
      name: `[${view.name}] Send Slack Message`,
      viewId: view.viewId,
      skipFirstRun: false,
      isEnabled: 'false',
      trigger: 'on new deal in view',
      data: [
        { key: 'channel', value: { templateDependencies: [], templateSource: '' } },
        { key: 'text', value: { templateDependencies: [], templateSource: '' } },
      ],
    };
    dispatch(createAutomation(automation));
  }, [dispatch, view.name, view.viewId]);
  const createWebhookNotification = useCallback(() => {
    const automation: Partial<Automation> = {
      runForEachUser: false,
      type: 'webhook',
      name: `[${view.name}] Send Webhook`,
      viewId: view.viewId,
      skipFirstRun: false,
      isEnabled: 'false',
      trigger: 'on new deal in view',
      data: [
        { key: 'url', value: { templateDependencies: [], templateSource: '' } },
      ],
    };
    dispatch(createAutomation(automation));
  }, [dispatch, view.name, view.viewId]);
  const saveUpdatedAutomation = useCallback((updatedAutomation:Automation) => {
    dispatch(updateAutomation(updatedAutomation));
  }, [dispatch])
  const onDeleteAutomation = useCallback((deletedAutomation:Automation) => {
    dispatch(deleteAutomation(deletedAutomation));
  }, [dispatch])
  const testUpdatedAutomation = useCallback((updatedAutomation:Automation) => {
    if(testDeal !== undefined) {
      dispatch(testAutomation({automation: updatedAutomation, deal: testDeal}));
    } else {
      alert('Must have at least 1 deal in view to test');
    }
  }, [dispatch, testDeal])
  const panelWrapperClassName = classNames('automation-panel-wrap', {
    'automation-panel-wrap--visible': isVisible,
  });
  return (
    <div className={panelWrapperClassName}>
      <div className="automation-panel">
        <h3>Manage Automations</h3>
        <div className="mb-5">
          <div className="d-grid gap-2">
            {automations.length === 0 && isInitialized && <div>No Automations</div>}
            {automations.map((automation) => {
              if (automation.type === 'slack') {
                return (
                  <SendSlackChannelNotification
                    key={automation.automationId}
                    view={view}
                    automation={automation}
                    saveUpdatedAutomation={saveUpdatedAutomation}
                    testUpdatedAutomation={testUpdatedAutomation}
                    deleteAutomation={onDeleteAutomation}
                  />
                );
              }
              if (automation.type === 'webhook') {
                return (
                  <SendWebhookNotification
                    key={automation.automationId}
                    view={view}
                    automation={automation}
                    saveUpdatedAutomation={saveUpdatedAutomation}
                    testUpdatedAutomation={testUpdatedAutomation}
                    deleteAutomation={onDeleteAutomation}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
        <h3>Create Automations</h3>
        <div>
          <div className="d-grid gap-2">
            <button onClick={createSlackNotification} type="button" className="btn btn-primary btn-lg">
              Create a Slack Notification
            </button>
            <button type="button" className="btn btn-secondary btn-lg">
              Create an Email Notification
            </button>
            <button type="button" className="btn btn-success btn-lg">
              Create a SMS Notification
            </button>
            <button type="button" onClick={createWebhookNotification} className="btn btn-warning btn-lg">
              Create a Webhook Notification
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomationPanel;
