import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchContentType, setSelectedContentType } from '../../data/content-type/content-types/actions';
import {
  getContentTypeFields,
  getContentTypeFieldsObject,
  getSelectedContentType,
} from '../../data/content-type/content-types/selectors';
import { fetchRecordViews, setSelectedViewId } from '../../data/content-type/record-views/actions';
import {
  getSelectedView,
  getSelectedViewId,
  getToggleableFields,
  getViewsListForContentType,
  getVisibleFields,
} from '../../data/content-type/record-views/selectors';
import { fetchDataRecords } from '../../data/content-type/records/actions';
import {
  getEnrichedRecordsForSelectedContentType,
  getIsSelectedContentTypeInitialized,
  getIsSelectedContentTypeLoading,
  getSelectedViewRecordIds,
} from '../../data/content-type/records/selectors';
import { useAppDispatch } from '../../data/store';
import { AutomationPanel } from './automation-panel/AutomationPanel';
import FormBuilderView from './record-views/form-builder-view/FormBuilderView';
import TableViewComponent from './record-views/table-view/TableViewComponent';
import ViewToolbar from './view-toolbar/ViewToolbar';

interface ContentPageProps {
  contentTypeSlug: string;
}
export const ContentPage = ({ contentTypeSlug, ...props }: any) => {
  console.log('props', props);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {viewId} = useParams<{viewId:string}>()
  const views = useSelector(getViewsListForContentType);
  const contentType = useSelector(getSelectedContentType);
  const contentTypeFieldsList = useSelector(getContentTypeFields);
  const contentTypeFieldsObject = useSelector(getContentTypeFieldsObject);
  const selectedView = useSelector(getSelectedView);
  const selectedViewId = useSelector(getSelectedViewId);
  const visibleFields = useSelector(getVisibleFields);
  const toggleableFields = useSelector(getToggleableFields);

  const isInitialized = useSelector(getIsSelectedContentTypeInitialized);
  const isLoading = useSelector(getIsSelectedContentTypeLoading);
  const ids = useSelector(getSelectedViewRecordIds);

  useEffect(() => {
    console.log('contentTypeSlug', contentTypeSlug);
    dispatch(setSelectedContentType(contentTypeSlug));
    dispatch(fetchContentType({ contentTypeSlug }));
    dispatch(fetchRecordViews({ contentTypeSlug }));
    dispatch(fetchDataRecords({ contentTypeSlug }));
    return () => {
      // setSelectedContentType(undefined);
    };
  }, [contentTypeSlug, dispatch]);
  useEffect(() => {
    if(selectedViewId === undefined && viewId === undefined && views.length > 0) {
      navigate(`./${views[0].viewId}`)
    } else if(selectedViewId === undefined && viewId !== undefined) {
      dispatch(setSelectedViewId({viewId, contentTypeSlug}));
    } else if(selectedViewId !== viewId) {
      dispatch(setSelectedViewId({viewId, contentTypeSlug}));
    }
  }, [viewId, views, dispatch, navigate, selectedViewId, contentTypeSlug])

  if (selectedView === undefined || contentType === undefined) {
    return null;
  }

  return (
    <div  className="d-flex flex-column h-100 w-100">
      <ViewToolbar currentView={selectedView} views={views} contentType={contentType} />
      {selectedView.type === 'table' && (
        <>
        <TableViewComponent ids={ids} view={selectedView} contentType={contentType} />
        <AutomationPanel view={selectedView} />
        </>
      )}
      {selectedView.type === 'form' && <FormBuilderView view={selectedView} contentType={contentType} />}
    </div>
  );
};

export default ContentPage;
