import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface TextSortOptionProps {
    isSelected: boolean,
    setSelected: () => void;
}

export const LongTextSortAscendingComponent = ({isSelected, setSelected}: TextSortOptionProps) => {
    const btnClass = classNames('field-sort-button', {
        'field-sort-button--is-selected': isSelected,
    })
    return (
        <div className={btnClass} onClick={setSelected}>A <FontAwesomeIcon icon={faArrowRight} /> Z</div>
    )
}

export const LongTextSortDescendingComponent = ({isSelected, setSelected}: TextSortOptionProps) => {
    const btnClass = classNames('field-sort-button', {
        'field-sort-button--is-selected': isSelected,
    })
    return (
        <div className={btnClass} onClick={setSelected}>Z <FontAwesomeIcon icon={faArrowRight} /> A</div>
    )
}
