import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface UserSelectSortOptionProps {
    isSelected: boolean,
    setSelected: () => void;
}

export const UserSelectSortAscendingComponent = ({isSelected, setSelected}: UserSelectSortOptionProps) => {
    const btnClass = classNames('field-sort-button', {
        'field-sort-button--is-selected': isSelected,
    })
    return (
        <div className={btnClass} onClick={setSelected}>A-Z</div>
    )
}

export const UserSelectSortDescendingComponent = ({isSelected, setSelected}: UserSelectSortOptionProps) => {
    const btnClass = classNames('field-sort-button', {
        'field-sort-button--is-selected': isSelected,
    })
    return (
        <div className={btnClass} onClick={setSelected}>Z-A</div>
    )
}
