import { NewRecordInViewAutomation, RecordAutomation, ScheduledWithViewAutomation, UpdatedRecordInViewAutomation } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getViewsListForContentType } from '../../../../../../../data/content-type/record-views/selectors';

interface AutomationViewPickerPrios {
  formData: NewRecordInViewAutomation | UpdatedRecordInViewAutomation | ScheduledWithViewAutomation;
  updateAutomation: (updatedRecordAutomation:RecordAutomation) => void;
}

export const AutomationViewPicker = ({ formData, updateAutomation }: AutomationViewPickerPrios) => {
  const views = useSelector(getViewsListForContentType);
  const tableViews = useMemo(() => views.filter((view) => view.type === 'table'), [views]);
  const onChange = useCallback((e) => {
    const newViewId = e.target.value;
    const updated = _cloneDeep(formData) as NewRecordInViewAutomation;
    updated.triggerConfig.tableViewId = newViewId;
    updateAutomation(updated)
  }, [formData, updateAutomation]);
  return (
    <div className="mb-3">
      <label htmlFor="automation-view" className="form-label">
        Table View
      </label>

      <select
        name="automation-view"
        onChange={onChange}
        value={formData.triggerConfig.tableViewId}
        className="form-select"
      >
        {tableViews.map((view) => (
          <option key={view.viewId} value={view.viewId}>
            {view.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AutomationViewPicker;
