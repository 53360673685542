import { find as _find } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, FieldType } from '../../../../data/types';
import { getFieldConfigDefault } from '../../../../utils/fieldConfig';
import { EnabledFieldTypeSelectOptions, FieldConfigEditors } from '../../../Fields';



interface TableHeaderCellPopoverProps {
  readonly name: string;
  readonly field:Field;
  readonly fieldType: FieldType;
  readonly config: any;
  readonly canHide: boolean;
  readonly onSave: (name: string, fieldType: FieldType, config: any) => void;
  readonly onDelete: () => void;
  readonly onHide: () => void;
}

export const TableHeaderCellPopover = ({
  name,
  field,
  fieldType,
  config,
  onSave,
  onHide,
  onDelete,
  canHide,
}: TableHeaderCellPopoverProps) => {
  const [internalName, setInternalName] = useState(name);
  const [internalFieldType, setInternalFieldType] = useState(fieldType);
  const [internalConfig, setInternalConfig] = useState<any|undefined>(config);
  const onChangeName = (e: any) => setInternalName(e.target.value ?? '');
  const onChangeFieldType = (e: any) => {
    const newFieldType = e.target.value;
    const newConfigForField = getFieldConfigDefault(newFieldType);
    setInternalFieldType(e.target.value);
    setInternalConfig(newConfigForField);
  }
  const onChangeConfig = (e: any) => setInternalConfig(e);
  const onSubmit = useCallback((e: any) => {
    e.preventDefault();
    onSave(internalName, internalFieldType, internalConfig);
  }, [internalConfig, internalFieldType, internalName, onSave]);
  const helpText = useMemo(() => {
    return _find(EnabledFieldTypeSelectOptions, { value: internalFieldType })!.helpText;
  }, [internalFieldType]);
  const onDeleteConfirm = useCallback(() => {
    // TODO: implement an actual right click menu
    // eslint-disable-next-line no-restricted-globals
    const shouldDelete = confirm('You sure you want to delete this column?');
    if (shouldDelete) {
      onDelete();
    }
  }, [onDelete]);
  const ConfigControl = useMemo(() => {
    return FieldConfigEditors[internalFieldType]
  }, [internalFieldType]);
  useEffect(() => {
    setInternalConfig(config);
  }, [config])
  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            value={internalName}
            onChange={onChangeName}
            placeholder="Field Name"
          />
        </div>
        <div className="mb-2">
          <select className="form-select" value={internalFieldType} onChange={onChangeFieldType}>
            {EnabledFieldTypeSelectOptions.map((fieldTypeOption) => (
              <option key={fieldTypeOption.value} value={fieldTypeOption.value}>
                {fieldTypeOption.label}
              </option>
            ))}
          </select>
          <small className="text-muted">{helpText}</small>
        </div>
        <div className="mb-3">
          <ConfigControl onChangeConfig={onChangeConfig} field={field} internalConfig={internalConfig} />
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div>
            {canHide && (
              <button onClick={onHide} type="button" className="btn btn-secondary me-2">
                Hide
              </button>
            )}
            <button onClick={onDeleteConfirm} type="button" className="btn btn-danger">
              Delete
            </button>
          </div>

          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default TableHeaderCellPopover;
