import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab } from '@headlessui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { getCompany } from '../../../data/company/selectors';
import { fetchDealActivities } from '../../../data/deal-activity/actions';
import { setSelectedDealId } from '../../../data/deals/actions';
import { getDealIsSaving, getSelectedDeal } from '../../../data/deals/selectors';
import { getIsDocumentSending, getIsDocumentDownloading } from '../../../data/documents/selectors';
import { useAppDispatch } from '../../../data/store';
import { DealRecord, Field } from '../../../data/types';

import CommentsSection from './Comments/CommentsSection';
import './DealDetailModal.scss';
import DealListingForm from './DealListingForm/DealListingForm';
import DocumentsList from './Documents/DocumentsList';
import NewDocument from './Documents/new-document/NewDocument';
import DealForm from './Form/DealForm';
import DealMapView from './Map/DealMapView';
import ConversationDetail from './SMSMessages/ConversationDetail';
import ConversationsByNumbers from './SMSMessages/ConversationsByNumber';
import TitleCompanyInfoSection from './TitleCompanyInfo/TitleCompanyInfoSection';

export const DealDetailModal = ({
  deal,
  fields,
  isVisible,
  back,
}: {
  deal: DealRecord;
  fields: Field[];
  isVisible: boolean;
  back: any;
}) => {
  const dispatch = useAppDispatch();
  const isSending = useSelector(getIsDocumentSending);
  const isDownloading = useSelector(getIsDocumentDownloading);
  const isSaving = useSelector(getDealIsSaving);

  const { dealId, viewId } = useParams<{ dealId: string; viewId: string }>();
  useEffect(() => {
    dispatch(fetchDealActivities({ dealId: dealId! }));
  }, [dispatch, dealId]);
  return (
    <Modal size="xl" fullscreen="lg-down" show={isVisible} onHide={back} keyboard={false}>
      <Modal.Header className="d-lg-none" closeButton>
        <Modal.Title>Deal</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row m-0 border-bottom">
          <div className="py-3 col-12">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>{deal.primaryFieldValue}</div>
              <div>
                {isSaving && (
                  <div>
                    Saving... <FontAwesomeIcon icon={faCog} title={'Saving Deal'} className={'fa-spin'} />
                  </div>
                )}
                {isSending && (
                  <div>
                    Sending... <FontAwesomeIcon icon={faCog} title={'Sending document'} className={'fa-spin'} />
                  </div>
                )}
                {isDownloading && (
                  <div>
                    Preparing Download...{' '}
                    <FontAwesomeIcon icon={faCog} title={'Downloading document'} className={'fa-spin'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 border-bottom">
          <nav className="nav">
            <Link
              className="nav-link"
              to={viewId !== undefined ? `/deals/${viewId}/edit/${dealId}` : `/notifications/modal/deals/${dealId}`}
            >
              Deal
            </Link>
            <Link
              className="nav-link"
              to={
                viewId !== undefined
                  ? `/deals/${viewId}/edit/${dealId}/text-messages`
                  : `/notifications/modal/deals/${dealId}/text-messages`
              }
            >
              SMS Messages
            </Link>
            <Link
              className="nav-link"
              to={
                viewId !== undefined
                  ? `/deals/${viewId}/edit/${dealId}/activity`
                  : `/notifications/modal/deals/${dealId}/activity`
              }
            >
              Activity
            </Link>
            <Link
              className="nav-link"
              to={
                viewId !== undefined
                  ? `/deals/${viewId}/edit/${dealId}/contact`
                  : `/notifications/modal/deals/${dealId}/contact`
              }
            >
              Contact
            </Link>
            <Link
              className="nav-link"
              to={
                viewId !== undefined
                  ? `/deals/${viewId}/edit/${dealId}/map`
                  : `/notifications/modal/deals/${dealId}/map`
              }
            >
              Map
            </Link>
            <Link
              className="nav-link"
              to={
                viewId !== undefined
                  ? `/deals/${viewId}/edit/${dealId}/listing`
                  : `/notifications/modal/deals/${dealId}/listing`
              }
            >
              Listing
            </Link>
            <Link
              className="nav-link"
              to={
                viewId !== undefined
                  ? `/deals/${viewId}/edit/${dealId}/title`
                  : `/notifications/modal/deals/${dealId}/title`
              }
            >
              Title
            </Link>
            <Link
              className="nav-link"
              to={
                viewId !== undefined
                  ? `/deals/${viewId}/edit/${dealId}/documents`
                  : `/notifications/modal/deals/${dealId}/documents`
              }
            >
              Documents
            </Link>
          </nav>
        </div>
        <div className="row px-0 m-0" style={{ minHeight: 'calc(100vh - 200px)' }}>
          <div className="col-12 px-0">
            <Routes>
              <Route
                index
                element={<DealForm dealRecord={deal} formViewId={'57a98a08-8453-48f5-afb2-3038407d3af6'} />}
              />
              <Route path="activity" element={<CommentsSection />} />
              <Route path="text-messages/:phoneNumber" element={<ConversationDetail />} />
              <Route path="text-messages" element={<ConversationsByNumbers />} />
              <Route path="contact" element={<div>Contact</div>} />
              <Route path="map" element={<DealMapView deal={deal} />} />
              <Route path="listing" element={<DealListingForm deal={deal} />} />
              <Route path="documents" element={<DocumentsList deal={deal} />} />
              <Route path="title" element={<TitleCompanyInfoSection deal={deal} />} />
              <Route path="documents/new" element={<NewDocument deal={deal} fields={fields} />} />
            </Routes>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DealDetailModal;
