import { createSlice } from '@reduxjs/toolkit';
import { fetchSlackChannels } from './actions';

export interface SlackChannel {
  id?: string;
    name?: string;
    is_channel?: boolean;
    is_group?: boolean;
    is_im?: boolean;
    created?: number;
    is_archived?: boolean;
    is_general?: boolean;
    unlinked?: number;
    name_normalized?: string;
    is_shared?: boolean;
    creator?: string;
    is_moved?: number;
    is_ext_shared?: boolean;
    is_global_shared?: boolean;
    is_org_default?: boolean;
    is_org_mandatory?: boolean;
    is_org_shared?: boolean;
    pending_shared?: string[];
    pending_connected_team_ids?: string[];
    is_pending_ext_shared?: boolean;
    conversation_host_id?: string;
    is_member?: boolean;
    is_private?: boolean;
    is_mpim?: boolean;
    topic?: any;
    purpose?: any;
    num_members?: number;
    shared_team_ids?: string[];
    internal_team_ids?: string[];
    previous_names?: string[];
}
export interface SlackUser {
  id?: string;
    team_id?: string;
    name?: string;
    deleted?: boolean;
    color?: string;
    real_name?: string;
    tz?: string;
    tz_label?: string;
    tz_offset?: number;
    profile?: any;
    is_admin?: boolean;
    is_owner?: boolean;
    is_primary_owner?: boolean;
    is_restricted?: boolean;
    is_ultra_restricted?: boolean;
    is_bot?: boolean;
    is_app_user?: boolean;
    updated?: number;
    has_2fa?: boolean;
    is_workflow_bot?: boolean;
    enterprise_user?: any;
    is_invited_user?: boolean;
    locale?: string;
}
type MessageAttachment = any;
type KnownBlock = any;
type Block = any;
export interface SlackMessage {
  channel: string;
  text?: string;
  as_user?: boolean;
  attachments?: MessageAttachment[];
  blocks?: (KnownBlock | Block)[];
  icon_emoji?: string;
  icon_url?: string;
  link_names?: boolean;
  mrkdwn?: boolean;
  parse?: 'full' | 'none';
  reply_broadcast?: boolean;
  thread_ts?: string;
  unfurl_links?: boolean;
  unfurl_media?: boolean;
  username?: string;
}

interface SlackState {
  channels: any[];
  users: any[];
  isLoading: boolean;
  isInitialized: boolean;
}

const initialState: SlackState = {
  channels: [],
  users: [],
  isLoading: false,
  isInitialized: false,
};

export const slackSlice = createSlice({
  name: 'slack',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSlackChannels.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSlackChannels.fulfilled, (state, { payload }) => {
      state.channels = payload.channels;
      state.users = payload.users;
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchSlackChannels.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });
  },
});

export default slackSlice;
