import { ContentType, Field } from '@taida-corp/taidacorp-sdk';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSelectedContentType } from '../../../../../../data/content-type/content-types/selectors';
import { updateField } from '../../../../../../data/content-type/fields/actions';
import { useAppDispatch } from '../../../../../../data/store';

interface RenameFieldModalProps {
  hide: () => void;
  field: Field;
}

export const RenameFieldModal = ({ hide, field }: RenameFieldModalProps) => {
  const dispatch = useAppDispatch();
  const contentType = useSelector(getSelectedContentType);
  const [fieldName, setFieldName] = useState(field.name);

  const onChangeFieldName = useCallback((e: any) => {
    const newValue = e.target.value ?? '';
    setFieldName(newValue);
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        updateField({
          field: {
            ...field,
            name: fieldName,
          },
          contentTypeSlug: contentType!.slug,
        }),
      );
    },
    [contentType, dispatch, field, fieldName],
  );
  return (
    <Modal size="lg" show={true} onHide={hide} keyboard={false}>
      <Modal.Header className="d-lg-none" closeButton>
        <Modal.Title>Rename Field</Modal.Title>
      </Modal.Header>
      <Modal.Body className="h-100" style={{zIndex: 1000}}>
        Rename field
        <form onSubmit={onSubmit}>
          <div className="mb-3">
            <label htmlFor="field-name" />
            <input
              className="form-control"
              type="text"
              id="field-name"
              value={fieldName}
              onChange={onChangeFieldName}
            />
          </div>
          <div className="mb-3">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
