import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { DocumentTemplate } from '../types';

export const setSelectedDocumentTemplateId = createAction<string | undefined>('templates/setSelectedDocumentTemplateId');


export const fetchDocumentTemplates = createAsyncThunk<{ templates: DocumentTemplate[] }, undefined, { rejectValue: string[] }>(
  'templates/fetchDocumentTemplates',
  async () => {
    try {
      const { templates } = await API.get(
        'DocumentTemplates', // function defined in our serverless.yml
        '', // the function's path
        { responseType: 'json' },
      );
      return { templates };
    } catch (error) {
      console.log(error);
      return { templates: [] };
    }
  },
);

export const fetchDocumentTemplateDetails = createAsyncThunk<{ template: DocumentTemplate }, {templateId:string}, { rejectValue: string[] }>(
  'templates/fetchDocumentTemplateDetails',
  async (data) => {
    try {
      const { template } = await API.get(
        'DocumentTemplates', // function defined in our serverless.yml
        data.templateId, // the function's path
        { responseType: 'json' },
      );
      return { template };
    } catch (error) {
      console.log(error);
      return { template: [] };
    }
  },
);
