import React, { useCallback } from 'react';

interface FormulaTableDataCellProps {
  onChange: (newValue: string | undefined | any) => void;
  internalValue: string | undefined | any;
}

export const FormulaTableEditCell = ({ internalValue }: FormulaTableDataCellProps) => {
  return <div className="form-control border-0 px-0">{internalValue}</div>;
};

export default FormulaTableEditCell;
