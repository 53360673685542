import Handlebars from "handlebars";
import { useCallback, useMemo, useState } from "react";

import signatureTemplateString from "../template-string";
import FakeEmail from "./FakeEmail";

const template = Handlebars.compile(signatureTemplateString);

export const SignatureResult = ({name, email, phone, role, profileImageURL}:any) => {
    const [copyButtonText, setCopyButtonText] = useState('Copy Signature');
    const signatureHTML = useMemo(() => {
        const htmlString = template({
            name, email, phone, role, profileImageURL
        });
        return htmlString;
    }, [email, name, phone, profileImageURL, role])

    const toggleButtonText = useCallback(() => {
        setCopyButtonText('Copied!');
        setTimeout(() => {
            setCopyButtonText('Copy Signature');
        }, 3000)
    }, []);

    const copyTextToClipboard = useCallback(async () => {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(signatureHTML);
        } else {
          return document.execCommand('copy', true, signatureHTML);
        }
      }, [signatureHTML]);

      const copySignatureToClipboard = useCallback(async () => {
        const sign = document.getElementById('signature-block');
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(sign!);
        selection?.removeAllRanges();
        selection?.addRange(range);
        document.execCommand('copy');
        toggleButtonText();
        selection?.removeAllRanges();

      }, [toggleButtonText]);

    return (
        <div>
            <FakeEmail>
                <div id='signature-block' dangerouslySetInnerHTML={{__html:signatureHTML}} />
            </FakeEmail>
            <div className='d-flex flex-column'>
            <div className='mb-3'><button type='button' onClick={copySignatureToClipboard} className='btn btn-primary'>{copyButtonText}</button></div>
            <div className='mb-3'><button type='button' onClick={copyTextToClipboard} className='btn btn-primary'>Copy Signature Source HTML</button></div>
                <textarea rows={10} style={{maxWidth: 600}} defaultValue={signatureHTML} />
            </div>
        </div>
    )
}

export default SignatureResult;