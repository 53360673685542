import { Field, TableViewField } from '@taida-corp/taidacorp-sdk';
import classNames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useHeaderCell } from './useHeaderCell';

import './TableHeaderCell.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faChevronDown,
  faCopy,
  faCross,
  faPencilAlt,
  faShapes,
  faShoppingCart,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import ToolbarToggleButton from '../../../view-toolbar/ToolbarToggleButton';
import { RenameFieldModal } from '../field-config-modals/rename-field/RenameFieldModal';
import { FieldConfigModal } from '../field-config-modals/field-config/FieldConfigModal';

interface TableHeaderCellProps {
  style: any;
  canHide: boolean;
  field: Field;
  tableViewField: TableViewField;
  isLastColumn: boolean;
  columnIndex: number;
}
export const TableHeaderCell = ({
  style,
  canHide,
  field,
  tableViewField,
  isLastColumn,
  columnIndex,
}: TableHeaderCellProps) => {
  const [show, setShow] = useState(false);
  const [showRenameFieldModal, setShowRenameFieldModal] = useState(false);
  const toggleRenameFieldModal = useCallback(() => setShowRenameFieldModal((val) => !val), []);
  const [showFieldConfigModal, setShowFieldConfigModal] = useState(false);
  const toggleShowFieldConfigModal = useCallback(() => setShowFieldConfigModal((val) => !val), []);
  const cellRef = useRef<HTMLDivElement>(null);
  const { cellProps, width } = useHeaderCell(cellRef, tableViewField, field, columnIndex);
  const classname = classNames({
    'd-flex flex-direction-row align-items-center justify-content-between': true,
    'table-header-cell': true,
    'table-header-cell--is-text': field.type !== 'number',
    'table-header-cell--is-number': field.type === 'number',
    // 'table-header-cell--is-selected': isInSelectedRange,
    // 'table-header-cell--is-row-hovered': isRowHovered,
    // 'table-header-cell--is-col-hovered': isColumnHovered,
    // 'table-header-cell--is-hovered': isColumnHovered && isRowHovered,
    // 'table-header-cell--is-focused': isFocused,
    // 'table-header-cell--is-only-selected': isOnlySelectedCell,
    // 'table-header-cell--is-last-column': isLastColumn,
  });

  return (
    <div
      {...cellProps}
      ref={cellRef}
      style={{ ...style, zIndex: columnIndex + 1, width: width, maxWidth: width, minWidth: width }}
      className={classname}
    >
      <div className='header-field-name' style={{width: 'calc(100% - 27px)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
      >
      {field.name}
      </div>
      <OverlayTrigger
        trigger="click"
        delay={{show: 0, hide: 400}}
        placement={'bottom'}
        rootClose
        overlay={
          <Popover id={`header-popover-${field.fieldId}`} style={{zIndex: 999}}>
            <Popover.Body className="p-0">
              <div className="bg-light" style={{ width: 250 }}>
                <div className="d-grid gap-2 align-left">
                  <button onClick={toggleShowFieldConfigModal} className="btn btn-light text-dark text-start">
                    <FontAwesomeIcon className="me-2" icon={faShapes} fixedWidth />
                    Customize Field Type
                  </button>
                  <button onClick={toggleRenameFieldModal} className="btn btn-light text-dark text-start">
                    <FontAwesomeIcon className="me-2" icon={faPencilAlt} fixedWidth />
                    Rename Field
                  </button>
                  {/* <button className="btn btn-light text-dark text-start">
                    <FontAwesomeIcon className="me-2" icon={faCopy} fixedWidth />
                    Duplicate Field
                  </button> */}
                  <button className="btn btn-light text-dark text-start">
                    <FontAwesomeIcon className="me-2" icon={faTimes} fixedWidth />
                    Hide Field
                  </button>
                  <button className="btn btn-light text-dark text-start">
                    <FontAwesomeIcon className="me-2" icon={faTrash} fixedWidth />
                    Delete Field
                  </button>
                </div>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <ToolbarToggleButton extraClassName='px-0 btn-no-focus' style={{outline: 'none'}}>
          <FontAwesomeIcon size="sm" className="me-1" icon={faChevronDown} />
        </ToolbarToggleButton>
      </OverlayTrigger>
      {showRenameFieldModal && <RenameFieldModal hide={toggleRenameFieldModal} field={field} />}
      {showFieldConfigModal && <FieldConfigModal hide={toggleShowFieldConfigModal} field={field} />}
    </div>
  );
};

export default TableHeaderCell;
