import React, { useCallback, useMemo } from 'react';
import { Field, FieldDateValue, isFieldDatetime, isFieldDateValue } from '../../../data/types';
import DatePicker from 'react-datepicker';
import { TableCellFieldProps } from '..';

export const DatetimeTableEditCell = ({
  field,
  internalValue,
  onChange,
  stopEditing,
  ...inputProps
}: TableCellFieldProps) => {
  const dateCastInternalValue = useMemo(() => {
    if(isFieldDateValue(internalValue)) {
      return new Date(internalValue.dtValue);
    } else {
      return !!internalValue ? new Date(internalValue) : undefined;
    }
  }, [internalValue]);
  const isDatetime = useMemo(() => isFieldDatetime(field), [field]);
  const dateFormat = useMemo(() => isDatetime ? 'Pp' : "P", [isDatetime])
  const onChangeEvent = useCallback(
    (newDate: Date | null) => {
      const dtValue = !!newDate ? newDate.toISOString() : null;
      onChange({
        dtValue,
        googleCalendarEventId: !!internalValue ? internalValue.googleCalendarEventId : undefined
      } as FieldDateValue);
    },
    [internalValue, onChange],
  );
  return (
    <>
    <input type='text' style={{height: 10}} value={!!internalValue ? internalValue.googleCalendarEventId : ''} />
    <DatePicker
      withPortal
      portalId='portal'
      dateFormat={dateFormat}
      onClickOutside={stopEditing as any}
      onBlur={stopEditing as any}
      startOpen={true}
      showTimeSelect={isDatetime}
      selected={dateCastInternalValue}
      onChange={onChangeEvent}
    />
    </>
  );
};

export default DatetimeTableEditCell;
