import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCampaigns } from "../../../data/campaigns/actions";
import { getCampaignsIsInitialized, getCampaignsIsLoading, getCampaignsList } from "../../../data/campaigns/selectors";
import { useAppDispatch } from "../../../data/store";

export const CampaignsList = (props: any) => {
    const dispatch = useAppDispatch();
  const isLoading = useSelector(getCampaignsIsLoading);
  const isInitialized = useSelector(getCampaignsIsInitialized);
  const _campaigns = useSelector(getCampaignsList);
  const campaigns = useMemo(() => {
    // filter and search
    return _campaigns;
  }, [_campaigns]);

  useEffect(() => {
    dispatch(fetchCampaigns());
    // dispatch(fetchCampaignLogs());
  }, [dispatch]);

  console.log('_campaigns', _campaigns);

  return (
    <div className="container-fluid my-3">
      <h4>Campaigns <Link className='btn btn-primary' to={`/marketing/campaigns/new`}>New</Link></h4>
      <div className="row">
        <div className="col-12">
          <div className="d-none d-flex flex-row justify-content-between">
            <div className="d-flex flex-row">
                <div>
                    <input className='form-control' type='text' placeholder='Search'/>
                </div>
            </div>
            <div className="">
                <Link className='btn btn-primary' to={`/marketing/campaigns/new`}>News</Link>
            </div>
          </div>
        </div>
      </div>
      {campaigns.map((campaign) => (
        <div key={campaign.campaignId} className="row">
          <Link to={`/marketing/campaigns/${campaign.campaignId}`}>
            <div>{campaign.name}</div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CampaignsList;
