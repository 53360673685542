import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastNotification } from '../../data/types';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { getLastSelectedViewId } from '../../data/views/selectors';

interface NotificationListItemProps {
  notification: ToastNotification;
  markAsRead: (notificationId: string) => void;
  softDelete: (notificationId: string) => void;
}

export const NotificationListItem = ({ notification, markAsRead, softDelete }: NotificationListItemProps) => {
  const navigate = useNavigate();
  const lastSelectedViewId = useSelector(getLastSelectedViewId);
  const onClickMarkAsRead = useCallback(() => {
    markAsRead(notification.notificationId);
  }, [notification, markAsRead]);
  const onClickDelete = useCallback(() => {
    softDelete(notification.notificationId);
  }, [notification, softDelete]);
  const markAsUnreadAndOpen = useCallback(() => {
    if (!!notification.url) {
      markAsRead(notification.notificationId);
      navigate(notification.url);
    }
  }, [markAsRead, navigate, notification.notificationId, notification.url]);
  const formattedDate = useMemo(() => {
    return format(new Date(notification.createdDT), 'MM/dd/yyyy p');
  }, [notification.createdDT]);

  const objectUrl = useMemo(() => {
    if (notification.attachment !== undefined) {
      if (notification.attachment.objectType === 'deal') {
        return `/notifications/modal/deals/${notification.attachment.objectId}`;
      }
    }
    return notification.url;
  }, [notification.attachment, notification.url]);

  return (
    <div className="card mb-3">
      <div className="card-header">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            {!!notification.isUnread && (
              <span className="me-3">
                <FontAwesomeIcon color="#e74c3c" icon={faCircle} />{' '}
              </span>
            )}
            {notification.title}
          </div>
          <div className="text-capitalize">{formattedDate}</div>
        </div>
      </div>
      <div className="card-body">
        <div>{notification.message}</div>
        {notification.url && (
          <div className="col-12">
            <Link onClick={markAsUnreadAndOpen} to={notification.url}>
              {notification.urlTitle ?? notification.url ?? 'Open'}
            </Link>
          </div>
        )}
        {objectUrl && (
          <div className="col-12">
            <Link to={objectUrl!}>{'Open Detail Modal'}</Link>
          </div>
        )}
      </div>
      <div className="card-footer">
        <div onClick={onClickMarkAsRead} className="btn btn-sm btn-link">
          Mark as Read
        </div>
        <div onClick={onClickDelete} className="btn btn-sm btn-link">
          Delete
        </div>
      </div>
    </div>
  );
};

export default NotificationListItem;
