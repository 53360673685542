import { values as _values, orderBy as _orderBy, filter as _filter, map as _map, keyBy as _keyBy } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { FormView } from '../types';

export const getViewsState = (state: RootState) => state.views;
export const getViews = (state: RootState) => state.views.views;
export const getIsInitialized = (state: RootState) => state.views.isInitialized;
export const getIsCreating = (state: RootState) => state.views.isCreating;
export const getSelectedViewId = (state: RootState) => state.views.selectedView !== undefined ? state.views.selectedView.viewId : undefined;

export const getSelectedView = createSelector(getViews, getSelectedViewId, (views, id) => {
  if (id === undefined) {
    return undefined;
  }
  const { [id]: view } = views;
  return view;
});
export const getLastSelectedViewId = (state: RootState) => state.views.lastSelectedViewId;

export const getLastSelectedView = createSelector(getViews, getLastSelectedViewId, (views, id) => {
  if (id === undefined) {
    return undefined;
  }
  const { [id]: view } = views;
  return view;
});


export const getDefaultFormView = createSelector(getViews, (views) => {
  const { '8be27736-b347-49cb-916d-4264f1c4c3d2': view } = views;
  return view as undefined | FormView;
});

export const getViewsList = createSelector(getViews, (views) => _orderBy(_values(views), ['createdDT'], ['asc']));
