import * as FullStory from '@fullstory/browser';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './data/store';
import ToastWrapper from './components/ToastWrapper/ToastWrapper';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  FullStory.init({ orgId: 'o-1A1KQ1-na1' });
  Sentry.init({
    dsn: "https://94fa90e9250d4d3b9a2fca0fe92db48b@o1222910.ingest.sentry.io/6366979",
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <Router>
          <ToastWrapper />
          <App />
        </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
