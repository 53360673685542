import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { Contact } from '../types';
import {
  fetchContacts,
  createContact,
  updateContact,
  realTimeContactCreated,
  realTimeContactDeleted,
  realTimeContactUpdated,
  setSelectedContactId,
  fetchContact,
} from './actions';

interface ContactsState {
  contacts: Record<string, Contact>;
  isLoading: boolean;
  isInitialized: boolean;
  isSaving: boolean;
  selectedContactId: string | undefined;
  errors: string[] | undefined;
}

const initialState: ContactsState = {
  contacts: {},
  isLoading: false,
  isInitialized: false,
  isSaving: false,
  selectedContactId: undefined,
  errors: [],
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedContactId, (state, { payload }) => {
      state.errors = [];
      state.selectedContactId = payload;
    });
    builder.addCase(fetchContacts.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchContacts.fulfilled, (state, { payload }) => {
      state.contacts = keyBy(payload, 'contactId');
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchContacts.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });

    builder.addCase(fetchContact.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchContact.fulfilled, (state, { payload }) => {
      state.contacts = { ...state.contacts, [payload.contactId]: payload };
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchContact.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(createContact.pending, (state, { payload }) => {
      state.errors = [];
      state.isSaving = true;
    });
    builder.addCase(createContact.fulfilled, (state, { payload }) => {
      state.contacts = { ...state.contacts, [payload.contactId]: payload };
      state.selectedContactId = payload.contactId;
      state.isSaving = false;
    });
    builder.addCase(createContact.rejected, (state, { payload: errors }) => {
      state.errors = errors;
      state.isSaving = false;
    });
    builder.addCase(updateContact.pending, (state, { payload }) => {
      state.errors = undefined;

      state.isSaving = true;
    });
    builder.addCase(updateContact.fulfilled, (state, { payload }) => {
      state.contacts = { ...state.contacts, [payload.contactId]: payload };
      state.isSaving = false;
    });
    builder.addCase(updateContact.rejected, (state, { payload: errors }) => {
      state.errors = errors;
      state.isSaving = false;
    });

    builder.addCase(realTimeContactUpdated, (state, { payload }) => {
      const { contact: message } = payload;
      state.contacts = { ...state.contacts, [message.contactId]: message };
    });
    builder.addCase(realTimeContactCreated, (state, { payload }) => {
      const { contact: message } = payload;
      state.contacts = { ...state.contacts, [message.contactId]: message };
    });
    builder.addCase(realTimeContactDeleted, (state, { payload }) => {
      const { contact: message } = payload;
      delete state.contacts[message.contactId];
    });
  },
});

export default contactsSlice;
