import React, { useCallback } from 'react';
import { FieldSelectUser, FieldSelectUserConfig } from '@taida-corp/taidacorp-sdk';

interface UserSelectConfigEditorProps {
  field: FieldSelectUser;
  internalConfig: FieldSelectUserConfig;
  onChangeConfig: (e: any) => void;
}

export const UserSelectConfigEditor = ({ field, internalConfig:config, onChangeConfig }: UserSelectConfigEditorProps) => {
  const { defaultValue = '' } = config;
  const onChangeDefaultValue = useCallback(
    (e: any) => {
      const newVal = e.target.value;
      onChangeConfig({
        defaultValue: newVal,
      });
    },
    [onChangeConfig],
  );

  return (
      <input
        type="text"
        className="form-control"
        placeholder="Default Select Value"
        value={defaultValue || ''}
        onChange={onChangeDefaultValue}
      />
  );
};

export default UserSelectConfigEditor;
