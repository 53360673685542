import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrello } from '@fortawesome/free-brands-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { faStream, faTable } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { ViewIconColors, ViewIconDefinitions } from '../../../../components/Views/ViewIcons';
import { DealView } from '../../../../data/types';
import { createDealView } from '../../../../data/views/actions';
import { getViewsList, getSelectedView } from '../../../../data/views/selectors';

export const ViewPicker = (props: any) => {
  const views = useSelector(getViewsList);
  const selectedView = useSelector(getSelectedView);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createTableView = useCallback(() => {
    dispatch(createDealView('table'));
    navigate('/deals/new');
  }, [dispatch, navigate]);
  const createKanbanView = useCallback(() => {
    dispatch(createDealView('kanban'));
    navigate('/deals/new');
  }, [dispatch, navigate]);
  const createFormView = useCallback(() => {
    dispatch(createDealView('form'));
    navigate('/deals/new');
  }, [dispatch, navigate]);
  const createAPIView = useCallback(() => {
    dispatch(createDealView('api'));
    navigate('/deals/new');
  }, [dispatch, navigate]);
  const createGalleryView = useCallback(() => {
    dispatch(createDealView('gallery'));
    navigate('/deals/new');
  }, [dispatch, navigate]);
  const createCalendarView = useCallback(() => {
    dispatch(createDealView('calendar'));
    navigate('/deals/new');
  }, [dispatch, navigate]);

  return (
    <Dropdown className="me-0">
      <Dropdown.Toggle variant="rounded border-0 d-flex flex-row align-items-center deals-toolbar__item">
        <FontAwesomeIcon icon={faStream} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {views.map((view: DealView) => {
          return (
            <Dropdown.Item
              as={Link}
              key={view.viewId}
              to={{ pathname: `/deals/${view.viewId}` }}
              className="d-flex flex-row align-items-center"
              // onClick={() => dispatchSetSelectedView(view.viewId)}
            >
              <FontAwesomeIcon
                className="me-2"
                size="1x"
                color={ViewIconColors[view.type]}
                icon={ViewIconDefinitions[view.type]}
              />
              {view.name}
            </Dropdown.Item>
          );
        })}
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createTableView}>
          Create Table View
        </Dropdown.Item>
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createKanbanView}>
          Create Kanban View
        </Dropdown.Item>
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createAPIView}>
          Create API View
        </Dropdown.Item>
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createFormView}>
          Create Form View
        </Dropdown.Item>
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createGalleryView}>
          Create Gallery View
        </Dropdown.Item>
        <Dropdown.Item disabled className="d-flex flex-row align-items-center" onClick={createCalendarView}>
          Create Calendar View
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ViewPicker;
