import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { createFormattedNotification } from '../../../utils/notifications';
import { addNotification } from '../../notifications/actions';
import { MarketingTemplate } from '../../types';


// sendMarketingTemplatePreview, fetchMarketingTemplates, createMarketingTemplate, realTimeMarketingTemplateCreated, realTimeMarketingTemplateDeleted, realTimeMarketingTemplateUpdated
export const setSelectedMarketingTemplateId = createAction<string|undefined>('marketingTemplate.setSelectedMarketingTemplateId');


export const fetchMarketingTemplates = createAsyncThunk<MarketingTemplate[], undefined, { rejectValue: string[] }>(
    'marketingTemplates/fetchMarketingTemplates',
    async () => {
      try {
        const data = await API.get(
          'MarketingTemplates', // function defined in our serverless.yml
          '',            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const fetchMarketingTemplate = createAsyncThunk<MarketingTemplate, {marketingTemplateId:string}, { rejectValue: string[] }>(
    'marketingTemplates/fetchMarketingTemplate',
    async ({marketingTemplateId}) => {
      try {
        const data = await API.get(
          'MarketingTemplates', // function defined in our serverless.yml
          marketingTemplateId,            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  
  export const createMarketingTemplate = createAsyncThunk<MarketingTemplate, Partial<MarketingTemplate>, { rejectValue: string[] }>(
    'marketingTemplates/createMarketingTemplate',
    async (marketingTemplate) => {
      try {
        const resp = await API.post(
          'MarketingTemplates', // function defined in our serverless.yml
          ``,            // the function's path
          { 'responseType': 'json', body: marketingTemplate }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const updateMarketingTemplate = createAsyncThunk<MarketingTemplate, Partial<MarketingTemplate>, { rejectValue: string[] }>(
    'marketingTemplates/updateMarketingTemplate',
    async (marketingTemplate) => {
      try {
        const resp = await API.post(
          'MarketingTemplates', // function defined in our serverless.yml
          marketingTemplate.marketingTemplateId,            // the function's path
          { 'responseType': 'json', body: marketingTemplate }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  

  export const sendMarketingTemplatePreview = createAsyncThunk<MarketingTemplate, {marketingTemplate: MarketingTemplate, numberOfListings: number}, { rejectValue: string[] }>(
    'marketingTemplates/sendMarketingTemplatePreview',
    async ({marketingTemplate, numberOfListings}, thunkApi) => {
      try {
        const {sentTo, marketingTemplate:resp} = await API.post(
          'MarketingTemplates', // function defined in our serverless.yml
          `${marketingTemplate.marketingTemplateId!}/preview`,            // the function's path
          { 'responseType': 'json', body: {marketingTemplate, numberOfListings} }
        );
        if(!!sentTo) {
          thunkApi.dispatch(addNotification(createFormattedNotification(`Sent Preview`, sentTo, 'primary')))
        } else {

        }
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  


export const realTimeMarketingTemplateUpdated = createAction<{ marketingTemplate: MarketingTemplate }>('marketingTemplate.updated');
export const realTimeMarketingTemplateDeleted = createAction<{ marketingTemplate: MarketingTemplate }>('marketingTemplate.deleted');
export const realTimeMarketingTemplateCreated = createAction<{ marketingTemplate: MarketingTemplate }>('marketingTemplate.created');
