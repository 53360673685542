

export const FakeEmail = ({children}:any) => {


    return (
        <div className='h-100 w-100 d-flex flex-column border border-1 p-3 mb-3' style={{maxWidth: 700}}>
            <div>
            <div>To: <b>Your Recipient</b></div>
            <div>Subject: <b>Checkout My New Email Signature</b></div>
            </div>
            <div className='my-3'>
            Lorem ipsum dolor sit amet, vix sententiae cotidieque appellantur et. Et denique volutpat maluisset qui, utamur omnesque eum ad. Vel unum graeco id, quo modo evertitur forensibus te. An postea omnium argumentum vis, eum tempor quidam ne. Persius urbanitas ullamcorper ad mea, sed ad dicit appareat. Diam veritus percipitur ex eos.
<br/><br/>
Mel ut illud oporteat incorrupte, summo utinam ex nam, idque alienum ne est. Quaeque euripidis reprehendunt qui in, aliquid salutatus te has. Et dolore partem debitis vis, putent appetere eu usu. Quod quando oportere ea duo. An minimum epicuri complectitur eos, no eum cibo decore, labitur mnesarchum ne qui. Te intellegam neglegentur has.

            </div>
            {children}
        </div>
    )
}

export default FakeEmail;
