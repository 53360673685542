import { RecordView } from '@taida-corp/taidacorp-sdk';
import ImportTableRow from './ImportTableRow';

interface ImportTableProps {
  headers: string[];
  dataRows: Record<string, string>[];
  currentView: RecordView;
  rowState: ('IDLE' | 'PENDING' | 'POSTING' | 'DONE' | 'ERROR')[];
  rowErrors: (string | null)[];
}

export const ImportTable = ({ headers, dataRows, currentView, rowState, rowErrors }: ImportTableProps) => {
  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th>Is Uploading</th>
          {headers.map((val, colIdx) => (
            <th key={val}>
              <div className="d-flex flex-column">
                <div>Column {colIdx}</div>
                <div>{val}</div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataRows.map((row, idx) => (
          <ImportTableRow
            key={`${currentView.contentType}-import-${rowState[idx]}}-${idx}`}
            row={row}
            rowIndex={idx}
            rowState={rowState[idx]}
            headers={headers}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ImportTable;
