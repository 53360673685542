import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { createDealActivity } from '../../../../data/deal-activity/actions';
import { getSelectedDealId } from '../../../../data/deals/selectors';
import { useAppDispatch } from '../../../../data/store';

export const CommentsFooter = () => {
  const [messageText, setMessageText] = useState('');
  const dispatch = useAppDispatch();
  const dealId = useSelector(getSelectedDealId);
  const updateMessageText = useCallback((e) => setMessageText(e.target.value ?? ''), []);
  const createMessage = useCallback(
    (e) => {
      e.preventDefault();
      if (messageText.length > 0) {
        dispatch(
          createDealActivity({
            dealId,
            message: messageText,
          }),
        );
        setTimeout(() => {
          setMessageText('12345');
        }, 0)
      }
    },
    [dealId, dispatch, messageText],
  );

  return (
    <div
      className="bg-white border-top w-100 position-absolute bottom-0 d-flex flex-col justify-content-end"
      style={{ minHeight: 120 }}
    >
      <textarea
        className="form-control border-0"
        placeholder="Message"
        aria-label="Message"
        aria-describedby="Message"
        rows={3}
        onChange={updateMessageText}
      />
      <button onClick={createMessage} className="btn btn-outline-secondary" type="button" id="Message-Send">
        Send
      </button>
    </div>
  );
};

export default CommentsFooter;
