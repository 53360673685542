import React, { useCallback, useMemo } from 'react';
import { filter as _filter } from 'lodash';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  TableView,
  KanbanView,
  Field,
  FILTER_EXPRESSION,
  OPERATOR_OPTIONS,
  isFilterCondition,
  isFilterConditionGroup,
  FilterConditionGroup,
  FilterCondition,
} from '../../../../data/types';

import FilterFieldConditionRow from './FilterFieldConditionRow';
import { ALLOWED_FILTER_TYPES } from '../../../../components/Fields';
import FilterFieldCondition from './FilterFieldCondition';
import FilterFieldConditionGroupRow from './FilterFieldConditionGroupRow';

interface ViewFilterPopoverProps {
  view: TableView | KanbanView;
  allFields: Field[];
  addFilteringField: () => void;
  addFilteringFieldGroup: () => void;
  updateFilteringField: (
    newField: string,
    newExpression: FILTER_EXPRESSION,
    newOption: string | undefined,
    newCompareTo: any,
    index: number,
  ) => void;
  removeFilteringField: (index: number) => void;
  updateOperator: (newOperator: OPERATOR_OPTIONS) => void;
  updateFilterCondition: (newFilters: FilterConditionGroup) => void;
  onChildPopover: any;
}
export const ViewFilterPopover = ({
  view,
  allFields,
  addFilteringField,
  addFilteringFieldGroup,
  updateFilteringField,
  removeFilteringField,
  updateOperator,
  updateFilterCondition,
  onChildPopover,
}: ViewFilterPopoverProps) => {
  const { operator, conditions } = view.properties.filters || { operator: 'and', conditions: [] };
  const availableFields = useMemo(() => {
    return _filter(allFields, (field) => ALLOWED_FILTER_TYPES.indexOf(field.type) !== -1);
  }, [allFields]);

  const updateFilterConditionAtIndex = useCallback((updatedFilterConditionGroup:FilterConditionGroup, idx:number) => {
    const newConditions:(FilterConditionGroup | FilterCondition)[] = [...conditions];
    newConditions.splice(idx, 1, updatedFilterConditionGroup);
    updateFilterCondition({conditions: newConditions, operator})
  }, [conditions, operator, updateFilterCondition])

  return (
    <div className="deals-toolbar__popover">
      {conditions.length === 0 && (
        <div>
          <small className="text-muted">No filters applied to this view</small>
        </div>
      )}
      {conditions.length > 0 && (
        <div className="view-filter__current-fields-list">
          <div className="text-secondary mb-1">In this view, show records</div>
          
          {conditions.map((condition, conditionIndex) => {
            
              return (
                <FilterFieldCondition
                  key={conditionIndex}
                  operator={operator}
                  condition={condition}
                  fields={availableFields}
                  index={conditionIndex}
                  updateFilteringField={updateFilteringField}
                  removeFilteringField={removeFilteringField}
                  updateOperator={updateOperator}
                  onChildPopover={onChildPopover}
                  updateFilterCondition={(newFilters:FilterConditionGroup) => updateFilterConditionAtIndex(newFilters, conditionIndex)}
                />
              );
          })}
          </div>
      )}
      <div>
        <button onClick={addFilteringField} className="btn btn-link text-black ps-0">
          <small className="text-muted">
            <FontAwesomeIcon className="ms-2" icon={faPlus} /> Add Condition
          </small>
        </button>
        <button onClick={addFilteringFieldGroup} className="btn btn-link text-black ps-0">
          <small className="text-muted">
            <FontAwesomeIcon className="ms-2" icon={faPlus} /> Add Condition Group
          </small>
        </button>
      </div>
    </div>
  );
};

export default ViewFilterPopover;
