import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { ContactSavedSearch } from '../types';
import {
  fetchContactSavedSearches,
  createContactSavedSearch,
  updateContactSavedSearch,
  realTimeContactSavedSearchCreated,
  realTimeContactSavedSearchDeleted,
  realTimeContactSavedSearchUpdated,
  setSelectedContactSavedSearchId,
  fetchContactSavedSearch,
  deleteContactSavedSearch,
} from './actions';

interface ContactSavedSearchSavedSearchesState {
  contactSavedSearches: Record<string, ContactSavedSearch>;
  isLoading: boolean;
  isInitialized: boolean;
  isSaving: boolean;
  selectedContactSavedSearchId: string | undefined;
}

const initialState: ContactSavedSearchSavedSearchesState = {
  contactSavedSearches: {},
  isLoading: false,
  isInitialized: false,
  isSaving: false,
  selectedContactSavedSearchId: undefined,
};

export const contactSavedSearchesSlice = createSlice({
  name: 'contactSavedSearches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedContactSavedSearchId, (state, { payload }) => {
      state.selectedContactSavedSearchId = payload;
    });
    builder.addCase(fetchContactSavedSearches.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchContactSavedSearches.fulfilled, (state, { payload }) => {
      state.contactSavedSearches = keyBy(payload, 'savedSearchId');
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchContactSavedSearches.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });

    builder.addCase(fetchContactSavedSearch.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchContactSavedSearch.fulfilled, (state, { payload }) => {
      state.contactSavedSearches = { ...state.contactSavedSearches, [payload.savedSearchId]: payload };
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchContactSavedSearch.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(createContactSavedSearch.pending, (state, { payload }) => {
      state.isSaving = true;
    });
    builder.addCase(createContactSavedSearch.fulfilled, (state, { payload }) => {
      state.contactSavedSearches = { ...state.contactSavedSearches, [payload.savedSearchId]: payload };
      state.selectedContactSavedSearchId = payload.savedSearchId;
      state.isSaving = false;
    });
    builder.addCase(createContactSavedSearch.rejected, (state, { payload }) => {
      state.isSaving = false;
    });
    builder.addCase(updateContactSavedSearch.pending, (state, { payload }) => {
      state.isSaving = true;
    });
    builder.addCase(updateContactSavedSearch.fulfilled, (state, { payload }) => {
      state.contactSavedSearches = { ...state.contactSavedSearches, [payload.savedSearchId]: payload };
      state.isSaving = false;
    });
    builder.addCase(updateContactSavedSearch.rejected, (state, { payload }) => {
      state.isSaving = false;
    });

    builder.addCase(deleteContactSavedSearch.pending, (state, { payload }) => {
      state.isSaving = true;
    });
    builder.addCase(deleteContactSavedSearch.fulfilled, (state, { payload }) => {
      delete state.contactSavedSearches[payload.savedSearchId];
      state.isSaving = false;
    });
    builder.addCase(deleteContactSavedSearch.rejected, (state, { payload }) => {
      state.isSaving = false;
    });

    builder.addCase(realTimeContactSavedSearchUpdated, (state, { payload }) => {
      const { contactSavedSearch: message } = payload;
      state.contactSavedSearches = { ...state.contactSavedSearches, [message.savedSearchId]: message };
    });
    builder.addCase(realTimeContactSavedSearchCreated, (state, { payload }) => {
      const { contactSavedSearch: message } = payload;
      state.contactSavedSearches = { ...state.contactSavedSearches, [message.savedSearchId]: message };
    });
    builder.addCase(realTimeContactSavedSearchDeleted, (state, { payload }) => {
      const { contactSavedSearch: message } = payload;
      delete state.contactSavedSearches[message.savedSearchId];
    });
  },
});

export default contactSavedSearchesSlice;
