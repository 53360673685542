import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCompany } from '../../../../data/company/selectors';
import {
  fetchMarketingTemplate,
  setSelectedMarketingTemplateId,
} from '../../../../data/marketing-services/marketing-templates/actions';
import { getSelectedMarketingTemplate } from '../../../../data/marketing-services/marketing-templates/selectors';
import { useAppDispatch } from '../../../../data/store';
import MarketingTemplateEditorModal from './MarketingTemplateEditorModal';

export const MarketingTemplateEditorPage = (props: any) => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const { marketingTemplateId } = useParams<{ marketingTemplateId: string }>();
  const selectedMarketingTemplate = useSelector(getSelectedMarketingTemplate);

  const navigate = useNavigate();
  const back = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      navigate(`/marketing/templates/`);
      dispatch(setSelectedMarketingTemplateId(undefined));
    }, 100);
  }, [navigate, dispatch]);
  useEffect(() => {
    if (
      selectedMarketingTemplate === undefined ||
      selectedMarketingTemplate.marketingTemplateId !== marketingTemplateId
    ) {
      dispatch(setSelectedMarketingTemplateId(marketingTemplateId));
      dispatch(fetchMarketingTemplate({ marketingTemplateId: marketingTemplateId! }));
    }
  }, [selectedMarketingTemplate, marketingTemplateId, dispatch]);

  if (
    selectedMarketingTemplate ===
    undefined  || selectedMarketingTemplate.marketingTemplateId !== marketingTemplateId
  ) {
    return (
      <Modal size="xl" fullscreen="sm-down" show={isVisible} onHide={back} keyboard={false}>
        <Modal.Header className="d-sm-none" closeButton>
          <Modal.Title>Loading Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">Loading...</Modal.Body>
      </Modal>
    );
  }

  return (
    <MarketingTemplateEditorModal marketingTemplate={selectedMarketingTemplate} isVisible={isVisible} back={back} />
  );
};

export default MarketingTemplateEditorPage;
