import { findIndex as _findIndex } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../data/store';
import { useSelector } from 'react-redux';
import DealsToolbar from './deals-toolbar/DealsToolbar';
import {
  getCompany,
  getCompanyFieldList,
  getCompanyIsInitialized,
  getFields,
} from '../../data/company/selectors';
import { fetchCompany } from '../../data/company/companyActions';
import { createDealRecord, fetchDealRecords } from '../../data/deals/actions';
import FullPageError from '../../components/Error/FullPageError';
import FullPageLoader from '../../components/Loader/FullPageLoader';
import { useNavigate, useParams } from 'react-router-dom';
import DealsKanban from './components/DealsKanban';
import DealsTable from './components/DealsTable';
import { fetchViews, setSelectedViewId } from '../../data/views/actions';
import { getViewsList, getSelectedView, getIsCreating } from '../../data/views/selectors';
import useWebSocket from '../../hooks/useWebSocket/useWebSocket';
import AutomationPanel from './AutomationPanel/AutomationPanel';
import { createFormattedNotification } from '../../utils/notifications';
import { addNotification } from '../../data/notifications/actions';
import DealsAPIView from './components/DealsAPIView';
import DealsFormEditor from './components/DealsFormEditor';
import DealsGallery from './components/DealsGallery';

export const Deals = () => {
  const dispatch = useAppDispatch();
  const company = useSelector(getCompany);
  const companyIsInitialized = useSelector(getCompanyIsInitialized);
  const fields = useSelector(getFields);
  const orderedFields = useSelector(getCompanyFieldList);
  const views = useSelector(getViewsList);
  const selectedView = useSelector(getSelectedView);
  const isCreatingView = useSelector(getIsCreating);
  const { viewId } = useParams<{ viewId: string | undefined }>();
  const navigate = useNavigate();
  const [isAutomationPanelVisible, setIsAutomationPanelVisible] = useState(false);
  const [isEditingGroupingField, setIsEditingGroupingField] = useState(false);
  const toggleAutomationPanel = useCallback(() => setIsAutomationPanelVisible(val => !val), []);
  const onCreateDealRecord = useCallback(() => {
    dispatch(createDealRecord(''));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCompany());
    dispatch(fetchViews());
    dispatch(fetchDealRecords());
  }, [dispatch]);
  useEffect(() => {
    if(viewId === 'new' && selectedView !== undefined) {
      navigate(`/deals/${selectedView.viewId}`)
      return;
    }
    if(viewId === 'new' && isCreatingView === false && views.length > 0) {
      dispatch(setSelectedViewId(views[0].viewId));
      return;
    }
    if (viewId !== undefined && viewId !== 'new' && selectedView === undefined && views.length > 0 && _findIndex(views, {viewId}) === -1) {
      //navigate('/deals');
      dispatch(addNotification(createFormattedNotification('404 View Not Found', 'Could not find view so opening default')))
    } else if (
      viewId !== undefined ||
      (viewId !== undefined && selectedView !== undefined && selectedView.viewId !== viewId)
    ) {
      dispatch(setSelectedViewId(viewId));
    } else if (viewId === undefined && selectedView === undefined && views.length > 0) {
      dispatch(setSelectedViewId(views[0].viewId));
    }
  }, [dispatch, selectedView, viewId, views, navigate, isCreatingView]);


  if (company === undefined && companyIsInitialized) {
    return (
      <FullPageError>
        <div className="h3 mb-5 text-white">We couldn't load the deals...</div>
        <a href="mailto:tyler@taidacorp.com" target="_blank" rel="noreferrer" className="btn btn-primary btn-lg">
          Send Tyler an Email
        </a>
      </FullPageError>
    );
  }

  if (viewId === 'new') {
    return <FullPageLoader loadingText='Creating View...' />;
  }

  if (company === undefined || selectedView === undefined) {
    return <FullPageLoader />;
  }

  return (
    <div className="d-flex flex-column h-100 w-100">
      <DealsToolbar toggleAutomationPanel={toggleAutomationPanel} setIsEditingGroupingField={setIsEditingGroupingField}  />
      {selectedView.type === 'table' && (
        <>
        <DealsTable view={selectedView} fields={fields} onCreateRecord={onCreateDealRecord} />
        <AutomationPanel view={selectedView} isVisible={isAutomationPanelVisible} />
        </>
      )}
      {selectedView.type === 'kanban' && (
        // <DealsKanban view={selectedView} fields={fields} onCreateRecord={onCreateDealRecord} />
        <DealsKanban isEditingGroupingField={isEditingGroupingField} setIsEditingGroupingField={setIsEditingGroupingField} view={selectedView} fields={fields} />
      )}
      {selectedView.type === 'gallery' && (
        <DealsGallery view={selectedView} fields={fields} />
        )}
      {selectedView.type === 'api' && (
        <DealsAPIView view={selectedView} fields={orderedFields} />
        )}
      {selectedView.type === 'form' && (
        <DealsFormEditor view={selectedView} fields={orderedFields} />
        )}
    </div>
  );
};

export default Deals;
