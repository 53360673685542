import { forEach as _forEach } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldFormula, FieldFormulaConfig } from '@taida-corp/taidacorp-sdk';
import { getContentTypeFields } from '../../../../../data/content-type/content-types/selectors';
import { TemplateEditorOption } from '../../../../../data/types';
import TemplateEditor from '../../../../../utils/templateEditor';

interface FormulaConfigEditorProps {
  field: FieldFormula;
  internalConfig: FieldFormulaConfig;
  onChangeConfig: (newConfig: FieldFormulaConfig) => void;
}

export const FormulaConfigEditor = ({ field, internalConfig: config, onChangeConfig }: FormulaConfigEditorProps) => {
  const allFields = useSelector(getContentTypeFields);
  // make sure we don't recursively use this field in the formula
  const templateOptions: TemplateEditorOption[] = useMemo(
    () => [
      {
        label: 'Item',
        options: allFields
          .filter((val) => val.fieldId !== field.fieldId)
          .map(
            (field) => ({
              identifier: `fields.${field.fieldId}.value`,
              label: field.name,
            }),
          ),
      },
      {
        label: 'Item Meta',
        options: [
          {
            identifier: `item.id`,
            label: 'Item Id',
          },
          {
              identifier: `item.createdDT`,
              label: 'Created DT',
            },
            {
              identifier: `item.updatedDT`,
              label: 'Updated DT',
            }
          ]
      },
    ],
    [allFields, field.fieldId],
  );
  const onChange = useCallback((newTemplateString: string) => {
    onChangeConfig({
      templateString: newTemplateString
    })
  }, [onChangeConfig])
  return (
    <div>
      <div className="row">
        <div className="col-8">
          <div className="">Enter template</div>
          {/* <TemplateEditor
            value={config.template ?? { templateDependencies: [], templateSource: '' }}
            onChange={onChange}
            templateOptions={templateOptions}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default FormulaConfigEditor;
