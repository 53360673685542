import { map as _map, result, values as _values } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DashboardModal } from '@uppy/react';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import Uppy from '@uppy/core';
import { Portal } from '@headlessui/react';
import useSimpleImageUploader from '../../../../hooks/useSimpleImageUploader/useSimpleImageUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';

export const ProfileImagePicker = ({ setProfileImageURL, profileImageURL }: any) => {
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const stopEditing = () => setIsPickerVisible(false);
  const clearProfileImage = useCallback(() => setProfileImageURL(''), [setProfileImageURL]);
  const onComplete = useCallback((original: string, optimized: string, thumbnail: string) => {
    setProfileImageURL(optimized);
  }, [setProfileImageURL]);

  const uppy = useSimpleImageUploader({ onComplete });

  return uppy !== null ? (
    <>
      {!profileImageURL && (<div className='btn' onClick={() => setIsPickerVisible(true)}>
          <div><FontAwesomeIcon icon={faImage} size={'7x'} /></div>
          <div>Choose Image</div>
      </div>)}
      {profileImageURL && <img className='image-fluid' width={150} src={profileImageURL} alt='signature profile' />}
      {profileImageURL && <button className='btn btn-danger btn-sm' onClick={clearProfileImage}>Clear Selected Image</button>}
      {isPickerVisible && (
        <Portal>
          <DashboardModal
            uppy={uppy}
            closeModalOnClickOutside
            open={true}
            onRequestClose={stopEditing}
            plugins={['Webcam', 'Unsplash', 'Instagram', 'Url']}
          />
        </Portal>
      )}
    </>
  ) : (
    <div>Initing uploader...</div>
  );
};

export default ProfileImagePicker;
