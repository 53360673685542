import { useEffect } from 'react';

export const useOnClickOutside = (
  ref: React.RefObject<any>,
  handler: (event: MouseEvent | TouchEvent) => void,
  rootOnly:boolean = false,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    if (rootOnly) {
      document.getElementById('root')!.addEventListener('mousedown', listener);
      document.getElementById('root')!.addEventListener('touchstart', listener);
    } else {
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
    }
    return () => {
      if (rootOnly) {
        document.getElementById('root')!.removeEventListener('mousedown', listener);
        document.getElementById('root')!.removeEventListener('touchstart', listener);
      } else {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      }
    };
  }, [ref, handler, rootOnly]);
};

export default useOnClickOutside;
