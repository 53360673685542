import { useCallback, useMemo } from 'react';
import { DocumentTemplate } from '../../../../../data/types';

export const Step1 = ({ form, onChange, next, documentTemplates, selectedDocumentTemplate }: any) => {
  const isNextEnabled = useMemo(() => {
    return selectedDocumentTemplate !== undefined;
  }, [selectedDocumentTemplate]);
  const onChangeTemplate = useCallback((e) => {
    const newValue = e.target.value;
    onChange({
        template_uuid: newValue
    })
}, [onChange])
  return (
    <div className="h-100 d-flex flex-column p-3">
      <div>
        <h3>Choose Template</h3>
      </div>
      <div className="flex-grow-1">
        <select onChange={onChangeTemplate} value={form.template_uuid} className="form-select" aria-label="Default select example">
          <option key='choose template' value=''>-- Choose template --</option>
          {documentTemplates.map((docTemp: DocumentTemplate) => (
            <option key={docTemp.id} value={docTemp.id}>
              {docTemp.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex-shrink-1 d-flex flex-row justify-content-end">
        <button className="btn btn-primary" disabled={!isNextEnabled} onClick={next}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Step1;