import {
  isToday,
  isTomorrow,
  isEqual,
  isSameDay,
  isAfter,
  isBefore,
  isWithinInterval,
  subDays,
  subWeeks,
  addWeeks,
  subMonths,
  addMonths,
  addDays,
  isValid,
  endOfDay,
  startOfDay,
  addYears,
  subYears,
} from 'date-fns';
import { find as _find, get as _get, every as _every, some as _some, map as _map, filter as _filter, isEqual as _isEqual } from 'lodash';
import {
  dateExpressionWithIsOptions,
  dateExpressionWithWithinOptions,
  dateIsOptionsWithDateCompareTo,
  dateIsOptionsWithNumericCompareTo,
  dateIsOptionsWithoutCompareTo,
  ExpressionIsOptionWithDateCompareTo,
  ExpressionIsOptionWithNumericCompareTo,
  ExpressionIsOptionWithoutCompareTo,
  ExpressionIsWithinOptionWithNumericCompareTo,
  ExpressionIsWithinOptionWithoutCompareTo,
  ExpressionWithIsOption,
  ExpressionWithIsWithinOption,
} from '../components/Fields';
import { DealRecord, DealView, Field, FieldMultiSelectValue, FieldType, FilterCondition, FilterConditionGroup, isFieldAttachmentValue, isFilterCondition, isFilterConditionGroup, NUMBER_FILTER_EXPRESSION_OPTIONS, User } from '../data/types';
import { toNumber } from './fieldValueCasting';

export const getFieldFilterConditionDefault = (field: Field): FilterCondition => {
  const { fieldId, type: fieldType } = field;
  if (fieldType === 'text' || fieldType === 'formula' || fieldType === 'phone') {
    return {
      fieldId: fieldId,
      expression: 'contains',
      compareTo: undefined,
      option: undefined,
    };
  }
  if (fieldType === 'number') {
    return {
      fieldId: fieldId,
      expression: '=',
      compareTo: undefined,
      option: undefined,
    };
  }
  if (fieldType === 'select' || fieldType === 'select-user') {
    return {
      fieldId: fieldId,
      expression: 'is',
      compareTo: undefined,
      option: undefined,
    };
  }
  if (fieldType === 'multi-select' || fieldType === 'multi-select-user') {
    return {
      fieldId: fieldId,
      expression: 'is exactly',
      compareTo: [],
      option: undefined,
    };
  }
  if (fieldType === 'checkbox') {
    return {
      fieldId: fieldId,
      expression: 'is',
      compareTo: false,
    };
  }
  if (fieldType === 'date' || fieldType === 'datetime') {
    return {
      fieldId: fieldId,
      expression: 'is',
      compareTo: undefined,
      option: 'exact date...',
    };
  }
  return {
    fieldId: fieldId,
    expression: 'is not empty',
    compareTo: undefined,
    option: undefined,
  };
};
type fieldFilterFunction = (deal: DealRecord) => boolean;
const getDateExpressionWithIsWithinOptionsFilterFunction = (
  condition: FilterCondition,
  fields: Record<string, Field>,
): fieldFilterFunction => {
  const withIsWithinFunctions: Record<
    ExpressionWithIsWithinOption,
    Record<
      ExpressionIsWithinOptionWithNumericCompareTo | ExpressionIsWithinOptionWithoutCompareTo,
      (dateIn: Date, compareTo: string | number | undefined) => boolean
    >
  > = {
    'is within': {
      'the next month': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, { start: startOfDay(new Date()), end: endOfDay(addMonths(new Date(), 1)) }),
      'the next number of days': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined) return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isWithinInterval(dateIn, {
          start: startOfDay(new Date()),
          end: endOfDay(addDays(new Date(), numberIn)),
        });
      },
      'the next week': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, { start: startOfDay(new Date()), end: endOfDay(addWeeks(new Date(), 1)) }),
      'the next year': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, { start: startOfDay(new Date()), end: endOfDay(addYears(new Date(), 1)) }),
      'the past month': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, { start: startOfDay(subMonths(new Date(), 1)), end: startOfDay(new Date()) }),
      'the past number of days': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined) return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isWithinInterval(dateIn, {
          start: startOfDay(subDays(new Date(), numberIn)),
          end: startOfDay(new Date()),
        });
      },
      'the past week': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, { start: startOfDay(subWeeks(new Date(), 1)), end: startOfDay(new Date()) }),
      'the past year': (dateIn: Date, compareTo: string | number | undefined) =>
        isWithinInterval(dateIn, { start: startOfDay(subYears(new Date(), 1)), end: startOfDay(new Date()) }),
    },
  };
  return (deal: DealRecord) => {
    const value = deal.fields[condition.fieldId];
    if (typeof value !== 'string' || !condition.option) {
      return true;
    }
    const valueDate = new Date(value as string);
    const comparisonFunc =
      withIsWithinFunctions[condition.expression as ExpressionWithIsWithinOption][
        condition.option as ExpressionIsWithinOptionWithoutCompareTo | ExpressionIsWithinOptionWithNumericCompareTo
      ];
    return comparisonFunc(valueDate, condition.compareTo as string | undefined);
  };
};
const getDateExpressionWithIsOptionsFilterFunction = (
  condition: FilterCondition,
  fields: Record<string, Field>,
): fieldFilterFunction => {
  const withIsFunctions: Record<
    ExpressionWithIsOption,
    Record<
      ExpressionIsOptionWithoutCompareTo | ExpressionIsOptionWithNumericCompareTo | ExpressionIsOptionWithDateCompareTo,
      (dateIn: Date, compareTo: string | number | undefined) => boolean
    >
  > = {
    is: {
      today: (dateIn: Date, compareTo: string | number | undefined) => isToday(dateIn),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) => isTomorrow(dateIn),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isSameDay(dateIn, subWeeks(new Date(), 1)),
      'one week from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isSameDay(dateIn, addWeeks(new Date(), 1)),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isSameDay(dateIn, subMonths(new Date(), 1)),
      'one month from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isSameDay(dateIn, addMonths(new Date(), 1)),
      'number of days ago': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isSameDay(dateIn, subDays(new Date(), numberIn));
      },
      'number of days from now': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isSameDay(dateIn, addDays(new Date(), numberIn));
      },
      'exact date...': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const compareDateIn = typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isSameDay(dateIn, compareDateIn);
      },
    },
    'is after': {
      today: (dateIn: Date, compareTo: string | number | undefined) => isAfter(dateIn, endOfDay(new Date())),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, endOfDay(addDays(new Date(), 1))),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, endOfDay(subWeeks(new Date(), 1))),
      'one week from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, endOfDay(addWeeks(new Date(), 1))),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, endOfDay(subMonths(new Date(), 1))),
      'one month from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, endOfDay(addMonths(new Date(), 1))),
      'number of days ago': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isAfter(dateIn, endOfDay(subDays(new Date(), numberIn)));
      },
      'number of days from now': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isAfter(dateIn, endOfDay(addDays(new Date(), numberIn)));
      },
      'exact date...': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const compareDateIn = typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isAfter(dateIn, endOfDay(compareDateIn));
      },
    },
    'is before': {
      today: (dateIn: Date, compareTo: string | number | undefined) => isBefore(dateIn, startOfDay(new Date())),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, startOfDay(addDays(new Date(), 1))),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, startOfDay(subWeeks(new Date(), 1))),
      'one week from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, startOfDay(addWeeks(new Date(), 1))),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, startOfDay(subMonths(new Date(), 1))),
      'one month from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, startOfDay(addMonths(new Date(), 1))),
      'number of days ago': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isBefore(dateIn, startOfDay(subDays(new Date(), numberIn)));
      },
      'number of days from now': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isBefore(dateIn, startOfDay(addDays(new Date(), numberIn)));
      },
      'exact date...': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const compareDateIn = typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isBefore(dateIn, startOfDay(compareDateIn));
      },
    },
    'is not': {
      today: (dateIn: Date, compareTo: string | number | undefined) => !isToday(dateIn),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) => !isTomorrow(dateIn),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        !isSameDay(dateIn, subWeeks(new Date(), 1)),
      'one week from now': (dateIn: Date, compareTo: string | number | undefined) =>
        !isSameDay(dateIn, addWeeks(new Date(), 1)),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        !isSameDay(dateIn, subMonths(new Date(), 1)),
      'one month from now': (dateIn: Date, compareTo: string | number | undefined) =>
        !isSameDay(dateIn, addMonths(new Date(), 1)),
      'number of days ago': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return !isSameDay(dateIn, subDays(new Date(), numberIn));
      },
      'number of days from now': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return !isSameDay(dateIn, addDays(new Date(), numberIn));
      },
      'exact date...': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const compareDateIn = typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return !isSameDay(dateIn, compareDateIn);
      },
    },
    'is on or after': {
      today: (dateIn: Date, compareTo: string | number | undefined) => isAfter(dateIn, startOfDay(new Date())),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, startOfDay(addDays(new Date(), 1))),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, startOfDay(subWeeks(new Date(), 1))),
      'one week from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, startOfDay(addWeeks(new Date(), 1))),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, startOfDay(subMonths(new Date(), 1))),
      'one month from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isAfter(dateIn, startOfDay(addMonths(new Date(), 1))),
      'number of days ago': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isAfter(dateIn, startOfDay(subDays(new Date(), numberIn)));
      },
      'number of days from now': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isAfter(dateIn, startOfDay(addDays(new Date(), numberIn)));
      },
      'exact date...': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const compareDateIn = typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isAfter(dateIn, startOfDay(compareDateIn));
      },
    },
    'is on or before': {
      today: (dateIn: Date, compareTo: string | number | undefined) => isBefore(dateIn, endOfDay(new Date())),
      tomorrow: (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, endOfDay(addDays(new Date(), 1))),
      'one week ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, endOfDay(subWeeks(new Date(), 1))),
      'one week from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, endOfDay(addWeeks(new Date(), 1))),
      'one month ago': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, endOfDay(subMonths(new Date(), 1))),
      'one month from now': (dateIn: Date, compareTo: string | number | undefined) =>
        isBefore(dateIn, endOfDay(addMonths(new Date(), 1))),
      'number of days ago': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isBefore(dateIn, endOfDay(subDays(new Date(), numberIn)));
      },
      'number of days from now': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const numberIn = typeof compareTo === 'string' ? parseInt(compareTo) : compareTo;
        if (typeof numberIn !== 'number') return true;
        return isBefore(dateIn, endOfDay(addDays(new Date(), numberIn)));
      },
      'exact date...': (dateIn: Date, compareTo: string | number | undefined) => {
        if (compareTo === undefined || compareTo === null || compareTo === '') return true;
        const compareDateIn = typeof compareTo === 'string' ? new Date(compareTo) : compareTo;
        if (!isValid(compareDateIn)) return true;
        return isBefore(dateIn, endOfDay(compareDateIn));
      },
    },
  };
  return (deal: DealRecord) => {
    const value = deal.fields[condition.fieldId];
    if (typeof value !== 'string' || !condition.option) {
      return true;
    }
    const valueDate = new Date(value as string);
    const comparisonFunc =
      withIsFunctions[condition.expression as ExpressionWithIsOption][
        condition.option as
          | ExpressionIsOptionWithoutCompareTo
          | ExpressionIsOptionWithDateCompareTo
          | ExpressionIsOptionWithNumericCompareTo
      ];
    return comparisonFunc(valueDate, condition.compareTo as string | undefined);
  };
};
const getNumberFilterFunction = (condition: FilterCondition, fields: Record<string, Field>) => {
  const numberExpressionOptions = {
    '=': (numberIn: number, compareTo: number) => {
      return numberIn === compareTo;
    },
    '!=': (numberIn: number, compareTo: number) => numberIn !== compareTo,
    '<': (numberIn: number, compareTo: number) => numberIn < compareTo,
    '<=': (numberIn: number, compareTo: number) => numberIn <= compareTo,
    '>': (numberIn: number, compareTo: number) => numberIn > compareTo,
    '>=': (numberIn: number, compareTo: number) => numberIn >= compareTo,
  };
  return (deal: DealRecord) => {
    const value = deal.fields[condition.fieldId];
    const valueNum = toNumber(value as any);
    const compareToNum = toNumber(condition.compareTo as any);
    if (valueNum === undefined || compareToNum === undefined) {
      return true;
    }
    const comparisonFunc = numberExpressionOptions[condition.expression as NUMBER_FILTER_EXPRESSION_OPTIONS] as (
      numberIn: number,
      compareTo: any,
    ) => boolean;
    return comparisonFunc(valueNum as any, compareToNum);
  };
};

export const getFilterFunction = (
  condition: FilterConditionGroup | FilterCondition,
  fields: Record<string, Field>,
  currentUser: User
): fieldFilterFunction => {
  if (isFilterCondition(condition)) {
    return getFilterConditionFunction(condition, fields, currentUser);
  }

  return (deal: DealRecord) => {
    if (!isFilterConditionGroup(condition)) {
      return true;
    }
    const { conditions, operator } = condition;

    if (conditions.length === 0) {
      return true;
    }

    const conditionFuncs = _map(conditions, (conditionIn) =>
      getFilterFunction(conditionIn, fields, currentUser)
    );
    if (operator === "and") {
      return _every(conditionFuncs.map((func) => func(deal)));
    }

    if (operator === "or") {
      return _some(conditionFuncs.map((func) => func(deal)));
    }

    return true;
  };
};

export const getFilterConditionFunction = (
  condition: FilterCondition,
  fields: Record<string, Field>,
  currentUser: User
): fieldFilterFunction => {
  const field = _get(fields, condition.fieldId);
  if (field === undefined) {
    return (deal: DealRecord) => true;
  }
  const isATextTypeField =
    field.type === "formula" || field.type === "text" || field.type === "phone";
  const isADateTypeField = field.type === "date" || field.type === "datetime";
  const isASelectTypeField =
    field.type === "select" || field.type === "select-user";
  const isAMultiSelectTypeField =
    field.type === "multi-select" || field.type === "multi-select-user";

  // is empty - return true for value is undefined or null or ''
  if (condition.expression === "is empty") {
    return (deal: DealRecord) => {
      const value = deal.fields[`${condition.fieldId}-og`];
      if (isFieldAttachmentValue(value)) {
        return (
          value.value === null ||
          value.value === [] ||
          value.value === undefined
        );
      }
      return (
        value === undefined ||
        value === null ||
        value === "" ||
        (isAMultiSelectTypeField &&
          Array.isArray((value as FieldMultiSelectValue).value) &&
          (value as FieldMultiSelectValue).value === [])
      );
    };
  }
  // is not empty - return true for value is not undefined null or ''
  if (condition.expression === "is not empty") {
    return (deal: DealRecord) => {
      const value = deal.fields[condition.fieldId];
      const ogValue = deal.fields[`${condition.fieldId}-og`];
      if (isFieldAttachmentValue(ogValue)) {
        return (
          ogValue.value !== null &&
          ogValue.value !== [] &&
          ogValue.value !== undefined
        );
      }
      if (isAMultiSelectTypeField) {
        return (
          Array.isArray((value as FieldMultiSelectValue).value) &&
          (value as FieldMultiSelectValue).value !== []
        );
      }
      return value !== undefined && value !== null && value !== "";
    };
  }
  if (
    (isATextTypeField || field.type === "checkbox") &&
    condition.expression === "is"
  ) {
    return (deal: DealRecord) => {
      // compareTo that is empty is not a valid filter here, so return true for those cases
      if (
        condition.compareTo === null ||
        condition.compareTo === undefined ||
        condition.compareTo === ""
      ) {
        return true;
      }
      const value = deal.fields[`${condition.fieldId}-og`];
      return value === (condition.compareTo ?? "");
    };
  }
  if (isATextTypeField && condition.expression === "is not") {
    return (deal: DealRecord) => {
      if (
        condition.compareTo === null ||
        condition.compareTo === undefined ||
        condition.compareTo === ""
      ) {
        return true;
      }
      const value = deal.fields[condition.fieldId];
      return value !== (condition.compareTo ?? "");
    };
  }
  if (isATextTypeField && condition.expression === "contains") {
    return (deal: DealRecord) => {
      if (
        condition.compareTo === null ||
        condition.compareTo === undefined ||
        condition.compareTo === ""
      ) {
        return true;
      }
      const value = deal.fields[condition.fieldId];
      return (value as string).includes(condition.compareTo);
    };
  }
  if (isATextTypeField && condition.expression === "does not contain") {
    return (deal: DealRecord) => {
      if (
        condition.compareTo === null ||
        condition.compareTo === undefined ||
        condition.compareTo === ""
      ) {
        return true;
      }
      const value = deal.fields[condition.fieldId];
      if (value === undefined) {
        return true;
      }
      return !(value as string).includes(condition.compareTo);
    };
  }
  if (field.type === "select-user" && condition.expression === "is me") {
    return (deal: DealRecord) => {
      const value = deal.fields[`${condition.fieldId}-og`];
      return value === currentUser.userId;
    };
  }
  if (isASelectTypeField && condition.expression === "is") {
    return (deal: DealRecord) => {
      if (condition.compareTo === undefined || condition.compareTo === "") {
        return true;
      }
      const value = deal.fields[`${condition.fieldId}-og`];
      return value === (condition.compareTo ?? "");
    };
  }
  if (isASelectTypeField && condition.expression === "is not") {
    return (deal: DealRecord) => {
      if (condition.compareTo === undefined || condition.compareTo === "") {
        return true;
      }
      const value = deal.fields[`${condition.fieldId}-og`];
      return value !== (condition.compareTo ?? "");
    };
  }
  if (isASelectTypeField && condition.expression === "is any of") {
    return (deal: DealRecord) => {
      if (condition.compareTo === undefined || condition.compareTo === []) {
        return true;
      }
      const value = deal.fields[`${condition.fieldId}-og`];
      return condition.compareTo.indexOf(value as string) !== -1;
    };
  }
  if (isASelectTypeField && condition.expression === "is none of") {
    return (deal: DealRecord) => {
      if (condition.compareTo === undefined || condition.compareTo === []) {
        return true;
      }
      const value = deal.fields[`${condition.fieldId}-og`];
      return condition.compareTo.indexOf(value as string) === -1;
    };
  }
  if (isAMultiSelectTypeField && condition.expression === "has all of") {
    return (deal: DealRecord) => {
      if (condition.compareTo === undefined || condition.compareTo === []) {
        return true;
      }
      const value = deal.fields[`${condition.fieldId}-og`];
      if(value === null) {
        return false;
      }
      if(typeof(value) === 'string') {
        return condition.compareTo === [value]
      }
      if(typeof(value as FieldMultiSelectValue).value  === 'string') {
        return condition.compareTo === [(value as any).value]
      }
      return condition.compareTo.every(cp => ((value as any).value as string[]).includes(cp));
    };
  }
  if (isAMultiSelectTypeField && condition.expression === "has any of") {
    return (deal: DealRecord) => {
      if (condition.compareTo === undefined || condition.compareTo === []) {
        return true;
      }
      const value = deal.fields[`${condition.fieldId}-og`];
      if(value === null) {
        return false;
      }
      if(typeof(value) === 'string') {
        return condition.compareTo === [value]
      }
      if(typeof(value as FieldMultiSelectValue).value  === 'string') {
        return condition.compareTo === [(value as any).value]
      }
      return condition.compareTo.some(cp => ((value as any).value as string[]).includes(cp));
    };
  }
  if (isAMultiSelectTypeField && condition.expression === "has none of") {
    return (deal: DealRecord) => {
      if (condition.compareTo === undefined || condition.compareTo === []) {
        return true;
      }
      const value = deal.fields[`${condition.fieldId}-og`];
      if(value === null) {
        return false;
      }
      if(typeof(value) === 'string') {
        return condition.compareTo === [value]
      }
      if(typeof(value as FieldMultiSelectValue).value  === 'string') {
        return condition.compareTo === [(value as any).value]
      }
      return !condition.compareTo.some(cp => ((value as any).value as string[]).includes(cp));
    };
  }
  if (isAMultiSelectTypeField && condition.expression === "is exactly") {
    return (deal: DealRecord) => {
      if (condition.compareTo === undefined || condition.compareTo === []) {
        return true;
      }
      const value = deal.fields[`${condition.fieldId}-og`];
      if(value === null) {
        return false;
      }
      if(typeof(value) === 'string') {
        return condition.compareTo === [value]
      }
      if(typeof(value as FieldMultiSelectValue).value  === 'string') {
        return condition.compareTo === [(value as any).value]
      }
      return _isEqual(condition.compareTo, ((value as any).value as string[]));
    };
  }
  if (field.type === "date" || field.type === "datetime") {
    if (
      dateExpressionWithIsOptions.indexOf(
        condition.expression as ExpressionWithIsOption
      ) !== -1
    ) {
      return getDateExpressionWithIsOptionsFilterFunction(condition, fields);
    }
    if (
      dateExpressionWithWithinOptions.indexOf(
        condition.expression as ExpressionWithIsWithinOption
      ) !== -1
    ) {
      return getDateExpressionWithIsWithinOptionsFilterFunction(
        condition,
        fields
      );
    }
  }
  if (field.type === "number") {
    return getNumberFilterFunction(condition, fields);
  }
  return (deal: DealRecord) => true;
};

export const filterDeals = (
  deals: DealRecord[],
  condition: FilterConditionGroup | FilterCondition,
  fields: Record<string, Field>,
  currentUser: User
) => {
  const filterFunc = getFilterFunction(condition, fields, currentUser!);
  return deals.filter(filterFunc);
};

export const isDealFilteredIn = (
  fields: Record<string, Field>,
  deal: DealRecord,
  view: DealView,
  user?: User
): boolean => {
  if (view === undefined || view.type === "form" || view.type === "api") {
    return false;
  }

  const filterFunc = getFilterFunction(view.properties.filters, fields, user!);
  return filterFunc(deal);
};
