import CommentsFooter from "./CommentsFooter";
import MessagesList from "./MessagesList";
import './Comments.scss';

export const CommentsSection = () => {

    return (
        <div className='comments-section d-flex flex-column flex-1 h-100 px-0'>
            <MessagesList />
            <CommentsFooter />
        </div>
    )
    
}

export default CommentsSection;