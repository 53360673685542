import { Field, FieldDateValue, isFieldDatetime, isFieldDate } from '@taida-corp/taidacorp-sdk';
import DatePicker from 'react-datepicker';

import { isString, isStringArray } from '@taida-corp/taidacorp-sdk';
import React, { useCallback, useMemo } from 'react';
import { RecordInputComponentProps } from '../base';
import { format } from 'date-fns';

export const DateInputComponent = ({
  field,
  value: persistedValue,
  onChange,
  extraProps = {},
}: RecordInputComponentProps) => {
  const errors = useMemo(() => {
    if (persistedValue === undefined || !isStringArray(persistedValue.errors)) {
      return [] as string[];
    }
    return persistedValue.errors;
  }, [persistedValue]);
  const value = useMemo(() => {
    if (persistedValue === undefined || !isString(persistedValue.internalValue)) {
      return null;
    }
    try {
      return new Date(persistedValue.internalValue);
    } catch (error) {
      return null;
    }
  }, [persistedValue]);

  const isDatetime = useMemo(() => isFieldDatetime(field), [field]);
  const dateFormat = useMemo(() => (isDatetime ? 'Pp' : 'P'), [isDatetime]);
  const onChangeEvent = useCallback(
    (newDate: Date | null) => {
      const dtValue = !!newDate ? newDate.toISOString() : null;
      onChange({ internalValue: dtValue });
    },
    [onChange],
  );
  return (
    <>
      <DatePicker
        withPortal
        portalId="portal"
        dateFormat={dateFormat}
        className="form-control"
        //   onClickOutside={stopEditing as any}
        //   onBlur={stopEditing as any}
        startOpen={false}
        showTimeSelect={isDatetime}
        selected={value}
        onChange={onChangeEvent}
      />
      {errors.map((err) => (
        <div key={err} className="mt-1">
          <small className="text-danger">{err}</small>
        </div>
      ))}
    </>
  );
};

export default DateInputComponent;
