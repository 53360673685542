import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { move, remove } from '../../../utils/array';
import { findIndex as _findIndex, cloneDeep as _cloneDeep, find as _find } from 'lodash';

import { useSelector } from 'react-redux';
import { getGalleryFormattedDeals, getKanbanFormattedDeals, getSelectedViewDealIds, getSelectedViewDeals } from '../../../data/deals/selectors';
import { Field, FieldNumber, FieldSelect, GalleryView, GalleryViewProperties, KanbanView, KanbanViewProperties } from '../../../data/types';
import { useAppDispatch } from '../../../data/store';
import { createField, updateField } from '../../../data/company/fieldActions';
import { updateDealRecordByField } from '../../../data/deals/actions';
import Board, { KanbanColumn } from '../../../components/Views/KanbanBoard/Board';
import GroupingFieldSelect from '../../../components/Views/KanbanBoard/GroupingFieldSelect';
import { updateDealView } from '../../../data/views/actions';
import { getKanbanGroupingFields, getKanbanSummationFields } from '../../../data/company/selectors';
import { GalleryViewComponent } from '../../../components/Views/GalleryView/GalleryView';


export const DealsGallery = ({ view, fields,  }: { view: GalleryView; fields: Record<string, Field> }) => {
  const dispatch = useAppDispatch();
  const deals = useSelector(getGalleryFormattedDeals);
  const galleryProps = view.properties as GalleryViewProperties;
  
  return (
    <GalleryViewComponent
        deals={deals}
        view={view}
    />
  );
};

export default DealsGallery;
