import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileAttachment } from '@taida-corp/taidacorp-sdk';
import { useMemo } from 'react';
import getFontAwesomeIconFromMIME from '../../../../../../../utils/getFontAwesomeIconFromMime';

export const FileAttachmentView = ({ attachment, onDelete }: { attachment: FileAttachment; onDelete: () => void }) => {
  const FaIcon = useMemo(() => {
    return getFontAwesomeIconFromMIME(attachment.original.mime);
  }, [attachment.original.mime]);
  return (
    <div className="w-100 bg-light p-4 position-relative ">
      <div>{attachment.original.name}</div>
      <div className="row mt-3">
        {attachment.thumbnail_150x150 !== undefined && (
          <div className="col">
            <a href={attachment.thumbnail_150x150.ssl_url} target="_blank" rel="noreferrer">
              <figure className="figure">
                <img
                  className="figure-img img-fluid"
                  src={attachment.thumbnail_150x150.ssl_url}
                  alt={attachment.thumbnail_150x150.name}
                />
                <figcaption className="figure-caption text-end">Thumbnail</figcaption>
              </figure>
            </a>
          </div>
        )}
        {attachment.image_landscape && (
          <div className="col">
            <a href={attachment.image_landscape.ssl_url} target="_blank" rel="noreferrer">
              <figure className="figure">
                <img
                  className="figure-img img-fluid"
                  src={attachment.image_landscape.ssl_url}
                  alt={attachment.image_landscape.name}
                />
                <figcaption className="figure-caption text-end">Landscape</figcaption>
              </figure>
            </a>
          </div>
        )}
        {attachment.image_1920 && (
          <div className="col">
            <a href={attachment.image_1920.ssl_url} target="_blank" rel="noreferrer">
              {' '}
              <figure className="figure">
                <img
                  className="figure-img img-fluid"
                  src={attachment.image_1920.ssl_url}
                  alt={attachment.image_1920.name}
                />
                <figcaption className="figure-caption text-end">1920</figcaption>
              </figure>
            </a>
          </div>
        )}
      </div>
      <div className="row mb-3">
        <div className="col">
          <a href={attachment.original.ssl_url} target="_blank" rel="noreferrer">
            <div className="bg-white rounded p-3 d-inline-flex flex-column ">
              <FontAwesomeIcon size="10x" color={'black'} icon={FaIcon} />
              <div>{attachment.original.name}</div>
            </div>
          </a>
        </div>
      </div>
      <button type="button" onClick={onDelete} className="btn btn-sm btn-danger">
        Delete Asset
      </button>
    </div>
  );
};

export default FileAttachmentView;
