export const signatureTemplateString = `
<table style="vertical-align: middle; font-size: medium; font-family: Arial">
  <tbody>
    <tr>
      <td>
        <table
          style="vertical-align: middle; font-size: medium; font-family: Arial"
        >
          <tbody>
            <tr>
              {{#if profileImageURL}}
              <td width="150" style="vertical-align: middle">
                <span style="margin-right: 20px; display: block"
                  ><img
                    src="{{ profileImageURL }}"
                    width="130"
                    style="max-width: 130px"
                /></span>
              </td>
              {{/if}}
              <td style="vertical-align: middle">
                <h3 color="##34495" style="margin: 0px; font-size: 18px">
                  <span>{{ name }}</span>
                </h3>
                <p
                  color="##34495"
                  font-size="medium"
                  style="margin: 0px; font-size: 14px; line-height: 22px"
                >
                  <span>{{ role }}</span>
                </p>
                <p
                  color="##34495"
                  font-size="medium"
                  style="
                    margin: 0px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                  "
                >
                  <span>Taidacorp</span>
                </p>
              </td>
              <td width="30"><div style="width: 30px"></div></td>
              <td
                color="#34495e"
                direction="vertical"
                width="1"
                style="
                  width: 1px;
                  border-bottom: none;
                  border-left: 1px solid rgb(52, 73, 94);
                "
              ></td>
              <td width="30"><div style="width: 30px"></div></td>
              <td style="vertical-align: middle">
                <table
                  style="vertical-align: middle;"
                >
                  <tbody>
                    <tr height="25" style="vertical-align: middle">
                      <td width="30" style="vertical-align: middle">
                        <table style="vertical-align: middle;">
                          <tbody>
                            <tr>
                              <td style="vertical-align: bottom">
                                <span
                                  color="#34495e"
                                  width="11"
                                  style="
                                    display: block;
                                    background-color: rgb(52, 73, 94);
                                  "
                                  ><img
                                    src="https://tc-uploads.s3.amazonaws.com/f4/cd5e9b589841939016b76181d8e2bf/mobile-duotone.jpg"
                                    color="#34495e"
                                    width="13"
                                    style="
                                      display: block;
                                      background-color: rgb(52, 73, 94);
                                    "
                                /></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="padding: 0px">
                        <a
                          href="tel:{{ phone }}"
                          color="##34495"
                          style="text-decoration: none; font-size: 12px"
                          ><span>{{ phone }}</span></a
                        >
                      </td>
                    </tr>
                    <tr height="25" style="vertical-align: middle">
                      <td width="30" style="vertical-align: middle">
                        <table style="vertical-align: middle;" >
                          <tbody>
                            <tr>
                              <td style="vertical-align: bottom">
                                <span
                                  color="#34495e"
                                  width="11"
                                  style="
                                    display: block;
                                    background-color: rgb(52, 73, 94);
                                  "
                                  ><img
                                    src="https://tc-uploads.s3.amazonaws.com/f1/f127faf79a4cdf8520d71c929a1b5d/envelope-duotone.jpg"
                                    color="#34495e"
                                    width="13"
                                    style="
                                      display: block;
                                      background-color: rgb(52, 73, 94);
                                    "
                                /></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="padding: 0px">
                        <a
                          href="mailto:{{ email }}"
                          color="##34495"
                          style="text-decoration: none; font-size: 12px"
                          ><span>{{ email }}</span></a
                        >
                      </td>
                    </tr>
                    <tr height="25" style="vertical-align: middle">
                      <td width="30" style="vertical-align: middle">
                        <table
                          style="
                            vertical-align: middle;
                            font-size: medium;
                            font-family: Arial;
                          "
                        >
                          <tbody>
                            <tr>
                              <td style="vertical-align: bottom">
                                <span
                                  color="#34495e"
                                  width="11"
                                  style="
                                    display: block;
                                    background-color: rgb(52, 73, 94);
                                  "
                                  ><img
                                    src="https://tc-uploads.s3.amazonaws.com/88/05cefd1a8543109b05e9f9cc027087/link-duotone.jpg"
                                    color="#34495e"
                                    width="13"
                                    style="
                                      display: block;
                                      background-color: rgb(52, 73, 94);
                                    "
                                /></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="padding: 0px">
                        <a
                          href="//www.taidacorp.com"
                          color="##34495"
                          style="text-decoration: none; font-size: 12px"
                          ><span>www.taidacorp.com</span></a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
`

export default signatureTemplateString;
