import { Action } from 'redux';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Amplify, { API, Auth, Hub } from 'aws-amplify';

export const WEBSOCKET_ACTION_PREFIX = 'REALTIME_WEBSOCKET::'

export const setToken = createAction<string|undefined>('realTime/set-token');
  export const fetchToken = createAsyncThunk<string, undefined, { rejectValue: string[] }>(
    'realTime/fetchToken',
    async () => {
      const tokenData = await API.post(
        'Tokens', // function defined in our serverless.yml
        '', // the function's path
        { responseType: 'json', body: {} },
      );
      return tokenData.token;
    },
  );
  

  // These actions are called by dispatching in components
  export const connect = createAction<{url: string}>(`${WEBSOCKET_ACTION_PREFIX}/connect`);
  export const disconnect = createAction<string>(`${WEBSOCKET_ACTION_PREFIX}/disconnect`);
  export const send = createAction<{action:any, data?:any}>(`${WEBSOCKET_ACTION_PREFIX}/send`);

  // These actions are dispatched from inside the websocket middleware
  export const onOpenConnection = createAction<{event: Event}>(`${WEBSOCKET_ACTION_PREFIX}/onOpenConnection`);
  export const onCloseConnection = createAction<{event: Event}>(`${WEBSOCKET_ACTION_PREFIX}/onCloseConnection`);
  export const onBrokenConnection = createAction<undefined>(`${WEBSOCKET_ACTION_PREFIX}/onBrokenConnection`);
  export const onBeginReconnect = createAction<undefined>(`${WEBSOCKET_ACTION_PREFIX}/onBeginReconnect`);
  export const onReconnectAttempt = createAction<{reconnectCount: number}>(`${WEBSOCKET_ACTION_PREFIX}/onReconnectAttempt`);
  export const onReconnected = createAction<undefined>(`${WEBSOCKET_ACTION_PREFIX}/onReconnected`);
  export const onError = createAction<{action: Action|null, error: any}>(`${WEBSOCKET_ACTION_PREFIX}/onError`);
  export const onMessageReceived = createAction<{event: MessageEvent}>(`${WEBSOCKET_ACTION_PREFIX}/onMessageReceived`);
