import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { createFormattedNotification } from '../../utils/notifications';
import { addNotification } from '../notifications/actions';
import { SlackChannel, SlackMessage, SlackUser } from './slice';



export const fetchSlackChannels = createAsyncThunk<{channels:SlackChannel[];users:SlackUser[]}, undefined, { rejectValue: string[] }>(
    'slack/fetchSlackChannels',
    async () => {
      try {
        const {channels, users} = await API.get(
          'Slack', // function defined in our serverless.yml
          'channels',            // the function's path
          { 'responseType': 'json' }
        );
        return {channels, users};
    } catch (error) {
        console.log(error);
        return {channels: [], users: []};
      }
    },
  );
  
  export const sendSlackMessage = createAsyncThunk<SlackMessage, SlackMessage, { rejectValue: string[] }>(
    'slack/sendSlackMessage',
    async (messageOptions, thunkApi) => {
      try {
        const channelsData = await API.post(
          'Slack', // function defined in our serverless.yml
          'messages',            // the function's path
          { responseType: 'json', body: { messageOptions } },
          );
          thunkApi.dispatch(addNotification(createFormattedNotification('Sent Slack Message', '')))
        return channelsData;
    } catch (error) {
        console.log(error);
      }
    },
  );
  