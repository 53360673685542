import { isString, isStringArray } from '@taida-corp/taidacorp-sdk';
import React, { useCallback, useMemo } from 'react';
import { RecordInputComponentProps } from '../base';

export const LongTextInputComponent = ({
  field,
  value: persistedValue,
  onChange,
  extraProps = {},
}: RecordInputComponentProps) => {
  const errors = useMemo(() => {
    if (persistedValue === undefined || !isStringArray(persistedValue.errors)) {
      return [] as string[];
    }
    return persistedValue.errors;
  }, [persistedValue])
  const value = useMemo(() => {
    if (persistedValue === undefined || !isString(persistedValue.internalValue)) {
      return '';
    }
    return persistedValue.internalValue;
  }, [persistedValue]);
  const onChangeHandle = useCallback(
    (e) => {
      onChange({internalValue: e.target.value ?? ''});
    },
    [onChange],
  );
  return (
    <>
    <textarea
      className="form-control"
      {...extraProps}
      id={`field-${field.fieldId}`}
      value={value}
      onChange={onChangeHandle}
    />
    {errors.map((err) => <div key={err} className='mt-1'><small className='text-danger'>{err}</small></div>)}
    </>
  );
};

export default LongTextInputComponent;
