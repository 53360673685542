import {
  EnrichedDataRecord,
  Field,
  PersistedFieldValue,
} from '@taida-corp/taidacorp-sdk';
import { debounce as _debounce } from 'lodash';
import { useCallback, useMemo } from 'react';
import { getRecordFormInputComponent } from '../../components/Fields/RecordFormInputComponents';

interface RecordFormInputProps {
  field: Field;
  formData: Record<string, PersistedFieldValue | undefined>;
  updateField: (fieldId: string, internalValue: PersistedFieldValue) => void;
}

export const RecordFormInput = ({ field, updateField, formData }: RecordFormInputProps) => {
  const value = useMemo(() => formData[field.fieldId], [formData, field.fieldId])
  const onChange =  useCallback((newValue) => updateField(field.fieldId, newValue), [field.fieldId, updateField])
  const DetailField = useMemo(() => {
    return getRecordFormInputComponent(field.type);
  }, [field]);

  if (field.type === 'email') {
    console.log(formData);
  }

  return (
    <div className="mb-3 row">
      <label className="col-sm-2 col-form-label" htmlFor={`field-${field.fieldId}`}>
        {field.name} {/* {field.fieldId} */}
      </label>
      <div className="col-sm-10">
        <DetailField
          field={field}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default RecordFormInput;
