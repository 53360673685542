import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface SelectSortOptionProps {
    isSelected: boolean,
    setSelected: () => void;
}

export const SelectSortAscendingComponent = ({isSelected, setSelected}: SelectSortOptionProps) => {
    const btnClass = classNames('field-sort-button', {
        'field-sort-button--is-selected': isSelected,
    })
    return (
        <div className={btnClass} onClick={setSelected}>First-Last</div>
    )
}

export const SelectSortDescendingComponent = ({isSelected, setSelected}: SelectSortOptionProps) => {
    const btnClass = classNames('field-sort-button', {
        'field-sort-button--is-selected': isSelected,
    })
    return (
        <div className={btnClass} onClick={setSelected}>Last-First</div>
    )
}
