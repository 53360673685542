import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { TableView } from '@taida-corp/taidacorp-sdk';
// import { Automation, TableView } from '../../../data/types';
// import SendSlackChannelNotification from './AutomationComponents/SendSlackChannelNotification';
// import { useSelector } from 'react-redux';
// import { getSlackChannels } from '../../../data/slack/selectors';
// import { useAppDispatch } from '../../../data/store';
// import { fetchSlackChannels } from '../../../data/slack/actions';
// import { createAutomation, deleteAutomation, fetchAutomations, testAutomation, updateAutomation } from '../../../data/automations/actions';
// import {
//   getAutomations,
//   getAutomationsForSelectedView,
//   getIsInitialized,
//   getIsLoading,
// } from '../../../data/automations/selectors';
// import { getFirstDealInSelectedView } from '../../../data/deals/selectors';
// import SendWebhookNotification from './AutomationComponents/SendWebhookNotification';

import './AutomationPanel.scss';
import { useSelector } from 'react-redux';
import { getIsAutomationsPanelVisible } from '../../../data/table-ui/selectors';
import AutomationList from './components/automation-list/AutomationList';
import {
  getAutomationsListForContentType,
  getSelectedAutomation,
} from '../../../data/content-type/record-automations/selectors';
import { useAppDispatch } from '../../../data/store';
import {
  createRecordAutomation,
  fetchRecordAutomation,
  fetchRecordAutomations,
  setSelectedAutomationId,
} from '../../../data/content-type/record-automations/actions';
import AutomationEditor from './components/automation-editor/AutomationEditor';
import AutomationHistory from './components/automation-history/AutomationHistory';
import { AutoSizer } from 'react-virtualized';
import { toggleIsAutomationsPanelVisible } from '../../../data/table-ui/actions';

interface AutomationPanelProps {
  view: TableView;
}

export const AutomationPanel = ({ view }: AutomationPanelProps) => {
  const isVisible = useSelector(getIsAutomationsPanelVisible);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if(ref.current === null || ref.current.parentElement === null) {
      return;
    }
    setHeight(ref.current.parentElement.offsetHeight);
    setWidth(ref.current.parentElement.offsetWidth);

  }, []);
  const hidePanel = useCallback(() => {
    dispatch(toggleIsAutomationsPanelVisible());
  }, [dispatch])
  /*
  const automations = useSelector(getAutomationsForSelectedView);
  const isLoading = useSelector(getIsLoading);
  const isInitialized = useSelector(getIsInitialized);
  const channels = useSelector(getSlackChannels);
  const testDeal = useSelector(getFirstDealInSelectedView);

  useEffect(() => {
    dispatch(fetchSlackChannels());
    dispatch(fetchAutomations());
  }, [dispatch]);
  const createSlackNotification = useCallback(() => {
    const automation: Partial<Automation> = {
      runForEachUser: false,
      type: 'slack',
      name: `[${view.name}] Send Slack Message`,
      viewId: view.viewId,
      skipFirstRun: false,
      isEnabled: 'false',
      trigger: 'on new deal in view',
      data: [
        { key: 'channel', value: { templateDependencies: [], templateSource: '' } },
        { key: 'text', value: { templateDependencies: [], templateSource: '' } },
      ],
    };
    dispatch(createAutomation(automation));
  }, [dispatch, view.name, view.viewId]);
  const createWebhookNotification = useCallback(() => {
    const automation: Partial<Automation> = {
      runForEachUser: false,
      type: 'webhook',
      name: `[${view.name}] Send Webhook`,
      viewId: view.viewId,
      skipFirstRun: false,
      isEnabled: 'false',
      trigger: 'on new deal in view',
      data: [
        { key: 'url', value: { templateDependencies: [], templateSource: '' } },
      ],
    };
    dispatch(createAutomation(automation));
  }, [dispatch, view.name, view.viewId]);
  const saveUpdatedAutomation = useCallback((updatedAutomation:Automation) => {
    dispatch(updateAutomation(updatedAutomation));
  }, [dispatch])
  const onDeleteAutomation = useCallback((deletedAutomation:Automation) => {
    dispatch(deleteAutomation(deletedAutomation));
  }, [dispatch])
  const testUpdatedAutomation = useCallback((updatedAutomation:Automation) => {
    if(testDeal !== undefined) {
      dispatch(testAutomation({automation: updatedAutomation, deal: testDeal}));
    } else {
      alert('Must have at least 1 deal in view to test');
    }
  }, [dispatch, testDeal])
  */
  const selectedAutomation = useSelector(getSelectedAutomation);
  const automationsList = useSelector(getAutomationsListForContentType);
  const [detailViewForSelectedAutomation, setDetailViewForSelectedAutomation] = useState<'edit' | 'history'>('edit');
  const onClickAutomationLink = useCallback(
    (automationId: string, link: 'edit' | 'history') => {
      setDetailViewForSelectedAutomation(link);
      dispatch(setSelectedAutomationId({ automationId }));
    },
    [dispatch],
  );
  const onClickCreate = useCallback(() => {
    dispatch(setSelectedAutomationId({ automationId: undefined }));
    setDetailViewForSelectedAutomation('edit');
    dispatch(
      createRecordAutomation({
        automation: {},
        contentTypeSlug: view.contentType,
      }),
    );
  }, [dispatch, view.contentType]);

  useEffect(() => {
    dispatch(fetchRecordAutomations({ contentTypeSlug: view.contentType }));
  }, [dispatch, view.contentType]);

  const panelWrapperClassName = classNames('ct-automation-panel-wrap', {
    'ct-automation-panel-wrap--visible': isVisible,
  });
  return (
    <div ref={ref} className={panelWrapperClassName}>
      <div className="ct-automation-panel" style={{height, width, overflowX: 'hidden', overflowY: 'scroll'}}>
        <div className='d-flex flex-row justify-content-end align-items-center border-bottom border-black p-3'>
          <button className='btn btn-close' onClick={hidePanel} />
        </div>
        <div className='p-3 h-100 w-100'>
        {selectedAutomation !== undefined && detailViewForSelectedAutomation === 'edit' && (
          <AutomationEditor persistedRecordAutomation={selectedAutomation} />
        )}
        {selectedAutomation !== undefined && detailViewForSelectedAutomation === 'history' && (
          <AutomationHistory selectedAutomation={selectedAutomation} />
        )}
        {selectedAutomation === undefined && (
          <AutomationList
            automations={automationsList}
            onClickAutomationLink={onClickAutomationLink}
            onClickCreate={onClickCreate}
          />
        )}
      </div>
      </div>
    </div>
  );
};

export default AutomationPanel;
