import React, { useCallback, useMemo } from 'react';
import { DetailFieldProps } from '..';



export const CheckboxDetailField = ({internalValue, onChange}: DetailFieldProps) => {
    const isChecked = useMemo(() => (internalValue === 'true' || internalValue === true), [internalValue]);
    const onChangeHandler = useCallback((e) => {
        const newValue = e.target.checked ? 'true' : 'false';
        onChange(newValue);
    }, [onChange])
    return (
        <div>
          <input type='checkbox' checked={isChecked} onChange={onChangeHandler} />
        </div>
    )
}

export default CheckboxDetailField;