import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { createFormattedNotification } from '../../utils/notifications';
import { addNotification } from '../notifications/actions';
import { Campaign } from '../types';


// sendCampaignPreview, fetchCampaigns, createCampaign, realTimeCampaignCreated, realTimeCampaignDeleted, realTimeCampaignUpdated
export const setSelectedCampaignId = createAction<string|undefined>('campaign.setSelectedCampaignId');


export const fetchCampaigns = createAsyncThunk<Campaign[], undefined, { rejectValue: string[] }>(
    'campaigns/fetchCampaigns',
    async () => {
      try {
        const data = await API.get(
          'Campaigns', // function defined in our serverless.yml
          '',            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const fetchCampaign = createAsyncThunk<Campaign, {campaignId:string}, { rejectValue: string[] }>(
    'campaigns/fetchCampaign',
    async ({campaignId}) => {
      try {
        const data = await API.get(
          'Campaigns', // function defined in our serverless.yml
          campaignId,            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  
  export const createCampaign = createAsyncThunk<Campaign, Partial<Campaign>, { rejectValue: string[] }>(
    'campaigns/createCampaign',
    async (campaign) => {
      try {
        const resp = await API.post(
          'Campaigns', // function defined in our serverless.yml
          ``,            // the function's path
          { 'responseType': 'json', body: campaign }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const updateCampaign = createAsyncThunk<Campaign, Partial<Campaign>, { rejectValue: string[] }>(
    'campaigns/updateCampaign',
    async (campaign) => {
      try {
        const resp = await API.post(
          'Campaigns', // function defined in our serverless.yml
          campaign.campaignId,            // the function's path
          { 'responseType': 'json', body: campaign }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  



export const realTimeCampaignUpdated = createAction<{ campaign: Campaign }>('campaign.updated');
export const realTimeCampaignDeleted = createAction<{ campaign: Campaign }>('campaign.deleted');
export const realTimeCampaignCreated = createAction<{ campaign: Campaign }>('campaign.created');
