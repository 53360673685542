import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldIcons, FieldKanbanComponents } from '../../Fields';
import { KanbanCard } from './Board';
import CardField from './CardField';

interface CardProps {
  card: KanbanCard;
  index: number;
  columnId: string;
  columnIndex: number;
}

export const Card = ({ card, index, columnId, columnIndex }: CardProps) => {
  const { viewId } = useParams<{ viewId: string }>();
  const navigate = useNavigate();
  const onContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      navigate(`/deals/${viewId}/edit/${card.id}`);
      /*
      e.preventDefault();
      // TODO: implement an actual right click menu
      // eslint-disable-next-line no-restricted-globals
      const shouldDelete = confirm('You sure you want to delete this row?');
      if (shouldDelete) {
        dispatch(deleteDealRecord(dealRecord));
      }
      */
    },
    [card.id, navigate, viewId],
  );
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: 'kanban-card',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { id: card.id, index, columnIndex, columnId },
  }));
  if (collected.isDragging) {
    return <div ref={dragPreview} />;
  }

  return (
    <div ref={drag} key={card.id} className="card"  onContextMenu={onContextMenu}>
      {card.coverImageURL && <img className="card-img-top" alt={`${card.id}`} src={card.coverImageURL} />}
      <div className="card-body">
        {card.fields.map((field, fieldIndex) => {
          return <CardField key={`card-${card.id}-${fieldIndex}`} field={field} />;
        })}
      </div>
    </div>
  );
};

export default Card;
