import {find as _find} from 'lodash';
import { ContentType, EnrichedDataRecord, TableView } from "@taida-corp/taidacorp-sdk";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { AutoSizer, MultiGrid } from "react-virtualized";
import { getColumnWidths } from "../../../../data/content-type/record-views/selectors";
import CellWrapper from "./table-cell/CellWrapper";
import TableFooter from "./table-footer/TableFooter";
import AddFieldButton from "./table-header/add-field-button/AddFieldButton";
import { getHoveredColumnIndex, getHoveredRowIndex, getSelectedRange } from '../../../../data/table-ui/selectors';
import { getEnrichedRecordsForSelectedContentType, getEnrichedSelectedRecord, getSelectedViewRecords } from '../../../../data/content-type/records/selectors';
import Queue from 'queue-promise';
import { useAppDispatch } from '../../../../data/store';
import { updateDataRecord } from '../../../../data/content-type/records/actions';


const STYLE = {

};
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: '2px solid #aaa',
  backgroundColor: '#f7f7f7',
};
const STYLE_TOP_LEFT_GRID = {
  // borderBottom: '2px solid #aaa',
  borderRight: '2px solid #aaa',
  borderTop: '1px solid #dee2e6',
  borderBottom: '1px solid #dee2e6',
  backgroundColor: '#f5f7f7',
  borderSpacing: '2px'

};
const STYLE_TOP_RIGHT_GRID = {
  borderTop: '1px solid #dee2e6',
  borderBottom: '1px solid #dee2e6',
  backgroundColor: '#f5f7f7',
  borderSpacing: '2px'
};

interface TableViewComponentProps {
    view:TableView;
    ids:string[];
    contentType:ContentType
}

export const TableViewComponent = ({ids, view, contentType}:TableViewComponentProps) => {
    const grid = useRef<MultiGrid|null>();
    const location = useLocation();
    const addRowLink = useMemo(() => {
      const root = location.pathname.split('/')[1];
      return `/${root}/${view.viewId}/edit/new`
    }, [view.viewId, location]);
    const widths = useSelector(getColumnWidths);
    const fields = useMemo(() => view.properties.fields, [view.properties.fields]);
    const fieldsCount = useMemo(() => fields.length, [fields.length]);
    const hoveredRowIndex = useSelector(getHoveredRowIndex);
    const hoveredColumnIndex = useSelector(getHoveredColumnIndex);
    const selectedRange = useSelector(getSelectedRange);

    const records = useSelector(getSelectedViewRecords);
    const dispatch = useAppDispatch();
    const run = useCallback(() => {
        console.log('fully loaded!!');
        const tasks = records.map((record, rowIdx) => {

            return async () => {
              console.log('running queued task', record);
              await dispatch(updateDataRecord({record, contentTypeSlug: record.contentType}))
            };
        });
        const queue = new Queue({concurrent: 5, interval: 500, start: true});
        queue.enqueue(tasks);
    }, [records, dispatch])

    const _cellRender = useCallback(
        ({ columnIndex, key, rowIndex, style, parent }: any) => {
          if(rowIndex === ids.length + 1 ) {
            if(columnIndex === 0) {
              return (
                <div style={style}>
                  <div className='p-1'>
                  <Link to={addRowLink}>Add Row</Link>
                  </div>
                </div>
              )
            }
            return null;
            
          }
          if(columnIndex === fieldsCount) {
            if(rowIndex > 0) {
              return null;
            }
            return <AddFieldButton key={key} style={style} view={view}/>
          }
          const tableViewField = fields[columnIndex];
          const field = _find(contentType.fields, {fieldId: tableViewField.fieldId})!;
          // const key = rowIndex === 0 ? `header-${field.fieldId}` : `data-${ids[rowIndex - 1]}-${field.fieldId}`;
          const cellId = ids[rowIndex - 1]
          return (
            <CellWrapper key={key} {...{grid, columnIndex, rowIndex, style, id: cellId, tableViewField,field,
              hoveredRowIndex, hoveredColumnIndex, selectedRange,
              isLastColumn: columnIndex === (fieldsCount - 1)
                //hoveredRowIndex, setHoveredRowIndex,  data: dealRecords, fields, view: view as TableView, parent, columnIndex, key, 
            }}
            />
          );
        },
        [ids, fieldsCount, fields, contentType.fields, hoveredRowIndex, hoveredColumnIndex, selectedRange, addRowLink, view],
      );
      const _columnWidth = useCallback(
        ({ index }: any) => {
          if(index === fieldsCount) {
            return 100;
          }
          const fieldId = view.properties.fields.filter((tableViewField) => tableViewField.isVisible)[index].fieldId;
          return widths[fieldId] ?? 140;
        },
        [fieldsCount, view.properties.fields, widths],
      );
      useEffect(() => {
        if(grid.current) {
          grid.current.recomputeGridSize();
        }
      }, [widths]);

      console.log('inside table view components')

    return (
        <div className="h-100 w-100" style={{ overflow: 'hidden' }}>
          <button onClick={run} >CLICK HERE TO RUN THE SCRIPT</button>
      <AutoSizer >
        {({ width, height }) => (
          <MultiGrid
          ref={(ref) => { grid.current = ref }}
          onSectionRendered={(...wut) => {
              console.log(wut);
            }}
            fixedColumnCount={1}
            fixedRowCount={1}
            scrollToColumn={0}
            scrollToRow={0}
            cellRenderer={_cellRender}
            columnWidth={_columnWidth}
            columnCount={fieldsCount + 1}
            height={height}
            rowHeight={({index}) => index === 0 ? 34 : 45}
            rowCount={ids.length + 2}
            style={STYLE}
            styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
            styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
            styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
            width={width}
            enableFixedColumnScroll
            enableFixedRowScroll
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
          />
        )}
      </AutoSizer>
      <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <TableFooter total={ids.length} addRowLink={addRowLink} />
        </div>
    </div>
    )
}

export default TableViewComponent;