import {
    values as _values,
    orderBy as _orderBy,
    keyBy as _keyBy,
    groupBy as _groupBy,
    reduce as _reduce,
    find as _find,
    map as _map,
    get as _get,
    forEach as _forEach,
    findIndex as _findIndex,
    toNumber as _toNumber,
  } from 'lodash';

import { FieldSelectOptions } from "../data/types";

export const toCheckbox = (value:string|undefined):boolean => {
    if(value === undefined) {
        return false;
    }
    if(value === '1' || value === 'true') {
        return true;
    }
    return false;
}

export const toNumber = (value:string|undefined):number|undefined => {
    if(value === undefined) {
        return value;
    }
    return _toNumber(value);
}

export const toSelectIndex = (value:string|undefined, options:FieldSelectOptions):number|undefined => {
    if(value === undefined) {
        return undefined;
    }
    const val = _findIndex(options, {value});
    if(val === -1) {
        return undefined;
    }
    return val;
}

export const toSelectValue = (value:string|undefined, options:FieldSelectOptions):string|undefined => {
    const val = toSelectIndex(value, options);
    if(val === undefined) {
        return undefined;
    }
    return options[val].label;
}