import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
// import logger from 'redux-logger'

// import authSlice from './auth/slice';
import notificationsSlice from './notifications/slice';
import propertiesSlice from './properties/slice';
import dealflowSlice from './deals/slice';
import userSlice from './user/slice';
import usersSlice from './users/slice';
import companySlice from './company/slice';
import viewsSlice from './views/slice';
import realTimeSlice from './realTime/slice';
import createRealtimeWebSocketMiddleware from './realTime/createRealtimeWebSocketMiddleware';
import slackSlice from './slack/slice';
import automationsSlice from './automations/slice';
import documentsSlice from './documents/slice';
import documentTemplatesSlice from './documentTemplates/slice';
import textMessagesSlice from './text-messages/slice';
import dealActivitySlice from './deal-activity/slice';
import marketingTempaltesSlice from './marketing-services/marketing-templates/slice';
import contactsSlice from './contacts/slice';
import contactSavedSearchesSlice from './contact-saved-searches/slice';
import CampaignsSlice from './campaigns/slice';
import ContentTypesSlice from './content-type/content-types/slice';
import RecordsSlice from './content-type/records/slice';
import RecordViewsSlice from './content-type/record-views/slice';
import RecordAutomationsSlice from './content-type/record-automations/slice';
import TableUISlice from './table-ui/slice';

const websocketMiddleware = createRealtimeWebSocketMiddleware({
  onOpen: (socket: WebSocket) => {
    // @ts-ignore
    window.__socket = socket; // eslint-disable-line no-underscore-dangle
  },
  // dateSerializer: (date) => date.toISOString(),
  deserializer: JSON.parse,
});

export const store = configureStore({
  reducer: {
    // auth: authSlice.reducer,
    user: userSlice.reducer,
    users: usersSlice.reducer,
    notifications: notificationsSlice.reducer,
    properties: propertiesSlice.reducer,
    deals: dealflowSlice.reducer,
    company: companySlice.reducer,
    views: viewsSlice.reducer,
    realTime: realTimeSlice.reducer,
    slack: slackSlice.reducer,
    automations: automationsSlice.reducer,
    documents: documentsSlice.reducer,
    documentTemplates: documentTemplatesSlice.reducer,
    textMessages: textMessagesSlice.reducer,
    dealActivities: dealActivitySlice.reducer,
    marketingTemplates: marketingTempaltesSlice.reducer,
    contacts: contactsSlice.reducer,
    contactSavedSearches: contactSavedSearchesSlice.reducer,
    campaigns: CampaignsSlice.reducer,

    contentTypes: ContentTypesSlice.reducer,
    records: RecordsSlice.reducer,
    recordViews: RecordViewsSlice.reducer,
    recordAutomations: RecordAutomationsSlice.reducer,

    tableUI: TableUISlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.event', 'payload.error'],
      },
    }).concat(websocketMiddleware),
  devTools: false,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
