import {
  values as _values,
  orderBy as _orderBy,
  filter as _filter,
  map as _map,
  keyBy as _keyBy,
  get as _get,
  find as _find,
  merge as _merge,
  flatten as _flatten,
  mapValues as _mapValues
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FieldAttachment, FieldNumber, FieldSelect, FieldSelectUser, FormView, isFieldAttachment, RecordView, TableViewField } from '@taida-corp/taidacorp-sdk';
import { getContentTypeFields, getSelectedContentType, getSelectedContentTypeString } from '../content-types/selectors';

export const getViewsState = (state: RootState) => state.recordViews;
export const getViews = (state: RootState) => state.recordViews.views;
export const getAllViewsList = createSelector(getViews, (viewsByContentTypeAndViewId) => {
  const viewsByIdArrayGroupedByContentType = _values(viewsByContentTypeAndViewId);
  const viewsByIdGroupedById = _map(viewsByIdArrayGroupedByContentType, (viewsById) => _values(viewsById));
  const views = _flatten(viewsByIdGroupedById);
  return views;
});
export const getIsInitialized = (state: RootState) => state.recordViews.isInitialized;
export const getIsUpdating = (state: RootState) => state.recordViews.isUpdating;
export const getIsCreating = (state: RootState) => state.recordViews.isCreating;
export const getSelectedViewId = (state: RootState) => state.recordViews.selectedViewId;
export const getLastSelectedViewIdForContentType = (state: RootState) => state.recordViews.lastSelectedViewIdForContentType;

export const getSelectedView = createSelector(
  getViews,
  getSelectedContentTypeString,
  getSelectedViewId,
  (views, contentType, id) => {
    if (id === undefined || contentType === undefined) {
      return undefined;
    }
    return _get(views, [contentType, id]);
  },
);

export const getLastSelectedViewIdForSelectedContentType = createSelector(
  getViewsState,
  getSelectedContentTypeString,
  (state, contentType): string | undefined => {
    if (contentType === undefined) {
      return undefined;
    }
    return _get(state, ['lastSelectedViewIdForContentType', contentType]);
  },
);

export const getLastSelectedView = createSelector(
  getViews,
  getLastSelectedViewIdForSelectedContentType,
  getSelectedContentTypeString,
  (views, id, contentType) => {
    if (id === undefined || contentType === undefined) {
      return undefined;
    }
    return _get(views, [contentType, id]);
  },
);

export const getDefaultDealsFormView = createSelector(getViews, (views) => {
  return _get(views, ['deals', '8be27736-b347-49cb-916d-4264f1c4c3d2']) as undefined | FormView;
});

export const getViewsListForContentType = createSelector(getViews, getSelectedContentTypeString, (views, contentType):RecordView[] => {
    if(contentType === undefined) {
        return [] as RecordView[];
    }
    const contentTypeViews = _get(views, [contentType], {}) as Record<string, RecordView>;
    return _orderBy(_values(contentTypeViews), ['createdDT'], ['asc']);
}
);


const reduceFieldWidths = (curr: Record<string, number>, field: TableViewField) => {
  const width = Math.max(80, field.width ?? 140);
  return { ...curr, [field.fieldId]: width};
};
export const getColumnWidths = createSelector(getSelectedView, (view) => {
  if (view === undefined || view.type !== 'table') {
    return {};
  }

  const values = view.properties.fields.reduce(reduceFieldWidths, {});
  return values;
});



// Return just the fields that are visible in the current view
export const getVisibleFields = createSelector(getContentTypeFields, getSelectedView, (fields, view) => {
  if (view === undefined || fields.length === 0) {
    return [];
  }
  let allFields = view.properties.fields;

  const visibleFields = _filter(allFields, { isVisible: true }) as { fieldId: string; isVisible: true }[];
  const visibleFieldIds = _map(visibleFields, (field) => field.fieldId);
  return _filter(fields, (field) => visibleFieldIds.indexOf(field.fieldId) !== -1);
});

export const getToggleableFields = createSelector(getContentTypeFields, getSelectedView, (fields, view) => {
  if (view === undefined || fields.length === 0) {
    return [];
  }
  const viewFields: TableViewField[] = view.properties.fields;
  const contentTypePrimaryKeyField = fields[0];
  console.log('HERE', {
    viewFields, fields
  })
  const primaryKeyField:TableViewField = _find(viewFields, {fieldId: contentTypePrimaryKeyField.fieldId}) ?? {fieldId: contentTypePrimaryKeyField.fieldId, isVisible: true, width: 100};

  const allFields = fields;
  const viewFieldsWithoutPrimary = _orderBy(viewFields.filter((field) => {
    const hasntBeenDeleted = _find(allFields, {fieldId: field.fieldId}) !== undefined;
    return field.fieldId !== contentTypePrimaryKeyField.fieldId && hasntBeenDeleted;
  }), ['isVisible'], ['desc'])
  const allMissingFields:TableViewField[] = allFields.filter((field) => {
    const isMissing = _find(viewFieldsWithoutPrimary, {fieldId: field.fieldId}) === undefined;
    return isMissing && field.fieldId !== contentTypePrimaryKeyField.fieldId;
  }).map((field):TableViewField => ({fieldId: field.fieldId, isVisible: false}));
  return [primaryKeyField, ...viewFieldsWithoutPrimary, ...allMissingFields];
});

export const getImageFields = createSelector(getContentTypeFields, getSelectedView, (fields, view) => {
  if (view === undefined || fields.length === 0) {
    return [];
  }

  const allFields = fields;
  const attachmentFields = allFields.filter((field) => isFieldAttachment(field)) as FieldAttachment[];
  return attachmentFields.filter((field) => {
    if(field.config.fileType === [] || field.config.fileType === null || field.config.fileType.indexOf('image') !== -1) {
      return true;
    }
    return false;
  })

});


export const getKanbanGroupingFields = createSelector(getContentTypeFields, (fields) => {
  return _filter(fields, (field) => field.type === 'select' || field.type === 'select-user') as (
    | FieldSelect
    | FieldSelectUser
  )[];
});

export const getKanbanSummationFields = createSelector(getContentTypeFields, (fields) => {
  return _filter(fields, (field) => field.type === 'number') as FieldNumber[];
});


export const getSelectedContentTypeAdminNewRecordForm = createSelector(getSelectedContentType, getViewsListForContentType, (contentType, views):FormView|undefined => {
  if(contentType === undefined || contentType.adminNewContentFormId === undefined) {
    return undefined;
  }
  const formId = contentType.adminNewContentFormId;
  return _find(views, {viewId: formId, type: 'form'}) as FormView|undefined;
});

export const getSelectedContentTypeAdminUpdateRecordForm = createSelector(getSelectedContentType, getViewsListForContentType, (contentType, views):FormView|undefined => {
  if(contentType === undefined || contentType.adminEditContentFormId === undefined) {
    return undefined;
  }
  const formId = contentType.adminEditContentFormId;
  return _find(views, {viewId: formId, type: 'form'}) as FormView|undefined;
});
