import {sortBy as _sortyBy} from 'lodash';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { RootState } from '../store';
import { DealView, DealViewType, ViewField } from '../types';
import { createFormattedNotification } from '../../utils/notifications';
import { addNotification } from '../notifications/actions';

export const setSelectedViewId = createAction<string | undefined>('company/setSelectedViewId');
export const setSelectedView = createAction<{viewId:string; contentType:string} | undefined>('company/setSelectedView');

export const createDealView = createAsyncThunk<DealView, DealViewType, { rejectValue: string[] }>(
  'company/create-view',
  async (type, thunkApi) => {
    const viewData = await API.post(
      'Views', // function defined in our serverless.yml
      '', // the function's path
      { responseType: 'json', body: { type } },
    );
    return viewData;
  },
);

export const updateDealView = createAsyncThunk<DealView, DealView, { rejectValue: string[] }>(
  'company/update-view',
  async (updatedView, thunkApi) => {
    
    const viewData = await API.post(
      'Views', // function defined in our serverless.yml
      updatedView.viewId, // the function's path
      { responseType: 'json', body: { view: updatedView } },
    );
    return viewData;
  },
);

export const deleteDealView = createAsyncThunk<DealView, string, { rejectValue: string[] }>(
  'company/delete-view',
  async (viewId, thunkApi) => {
    const viewData = await API.del(
      'Views', // function defined in our serverless.yml
      viewId, // the function's path
      { responseType: 'json', body: {} },
    );
    thunkApi.dispatch(addNotification(createFormattedNotification('Deleted View', '')))
    return viewData;
  },
);

export const fetchViews = createAsyncThunk<DealView[], undefined, { rejectValue: string[] }>(
  'company/fetch-views',
  async () => {
    const viewData = await API.get(
      'Views', // function defined in our serverless.yml
      '', // the function's path
      { responseType: 'json' },
    );
    return viewData;
  },
);

export const persistViewUpdates = createAsyncThunk<DealView, string, { rejectValue: string[] }>(
  'view/persistViewUpdates',
  async (viewId, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    // get current state values and write them to the API...
    const updatedView = state.views.views[viewId];

    const viewData = await API.post(
      'Views', // function defined in our serverless.yml
      viewId, // the function's path
      { responseType: 'json', body: { view: updatedView } },
    );
    return viewData;
  },
);

export const localUpdateColumnWidth =
  createAction<{ viewId: string; fieldId: string; deltaX: number }>('view.localUpdateColumnWidth');

export const localUpdateIsVisible =
  createAction<{ viewId: string; fieldId: string; isVisible: boolean }>('view.localUpdateIsVisible');

export const localUpdateViewFields =
  createAction<{ viewId: string; fields:any[] }>('view.localUpdateViewFields');

export const localUpdateView =
  createAction<{ view:DealView }>('view.localUpdateView');



export const realTimeViewUpdated = createAction<{ view: DealView }>('view.updated');
export const realTimeViewDeleted = createAction<{ view: DealView }>('view.deleted');
export const realTimeViewCreated = createAction<{ view: DealView }>('view.created');
