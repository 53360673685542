import React from 'react';
import { KanbanCardField } from '../../Views/KanbanBoard/Board';

interface TextKanbanFieldProps {
    field:KanbanCardField
}

export const TextKanbanField = ({field}: TextKanbanFieldProps) => {
    const {
        displayValue
    } = field;

    return (
        <div>{displayValue ?? ''}</div>
    )
}

export default TextKanbanField;