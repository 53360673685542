import { faArrowLeft, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getSelectedDeal, getSelectedDealAddressLine1 } from '../../../../data/deals/selectors';
import { useAppDispatch } from '../../../../data/store';
import {
  fetchTextMessagesForPhoneNumber,
  sendTextMessage,
  setSelectedPhoneNumber,
} from '../../../../data/text-messages/actions';
import { getTextMessagesListForSelectedNumber } from '../../../../data/text-messages/selectors';

export const ConversationDetail = (props: any) => {
  const dispatch = useAppDispatch();
  const { phoneNumber, viewId, dealId } = useParams<{ viewId: string; dealId: string; phoneNumber: string }>();
  const deal = useSelector(getSelectedDeal);
  const messages = useSelector(getTextMessagesListForSelectedNumber);
  const addressLine1 = useSelector(getSelectedDealAddressLine1);
  const [text, setText] = useState('');
  const onClickForSaleButton = useCallback(() => {
    const options = [
      `Hey is your home on ${addressLine1} still available? Would you be open to seller financing?`,
      `Hey is your home on ${addressLine1} still available? Would you be open to seller financing? We are able to make higher offers with seller financing.`,
      `Hey is your home on ${addressLine1} still available? Would you be open to seller financing? Seller financing gives us more options to find a win-win.`
    ]
    setText(options[Math.floor(Math.random()*options.length)])
  }, [addressLine1]);
  // set selected phone number
  const onChangeHandler = useCallback((e) => {
    setText(e.target.value ?? '');
  }, [])
  const onClickSend = useCallback(() => {
    dispatch(sendTextMessage({ body: text, to: phoneNumber, associatedDealId: dealId }));
    setText('');
  }, [dealId, dispatch, phoneNumber, text]);

  useEffect(() => {
    dispatch(setSelectedPhoneNumber(`+${phoneNumber}`));
    dispatch(fetchTextMessagesForPhoneNumber({ phoneNumber: `+${phoneNumber}` }));
  }, [dispatch, phoneNumber]);

  return (
    <div className="d-flex flex-column h-100" style={{maxHeight: 700}}>
      <div className="d-flex flex-row justify-content-between align-center p-3" style={{ backgroundColor: '#ecf0f1' }}>
        <div className="px-3 d-flex flex-column justify-content-center">
          <Link to={`/deals/${viewId}/edit/${dealId}/text-messages`}>
            <FontAwesomeIcon size="lg" icon={faArrowLeft} />
          </Link>
        </div>
        <h4 className="flex-grow-1 p-0 m-0">{phoneNumber}</h4>
      </div>
      <div className="flex-grow-1 d-flex flex-column-reverse" style={{overflowY: 'scroll'}}>
        {messages.length === 0 && <div>No messages</div>}
        {messages.map((message, idx) => {
            const classN = classNames({
                'mt-3': idx === 0,
                'mb-3 rounded p-3 mx-3 d-flex flex-column flex-shrink-0': true,
                'text-white bg-primary': message.from !== message.externalNumber,
                'text-white bg-secondary': message.from === message.externalNumber,
            })
          return (
            <div key={message.messageId} className={classN}>
              <div className='d-flex flex-row justify-content-between'><div>Sender: {message.from}</div><div>{format(new Date(message.dateCreated), 'MM/dd/yyyy p')}</div></div>
              <div>{message.body}</div>
            </div>
          );
        })}
      </div>
      <div>
        <div className="d-flex flex-row px-2 mb-1">
          <button onClick={onClickForSaleButton} type="button" className="btn btn-secondary btn-sm">
            For Sale?
          </button>
        </div>
        <div className="d-flex flex-row px-1 mb-1">
          <textarea onChange={onChangeHandler} className="form-control" value={text} />
        </div>
        <div className="d-flex flex-row justify-content-end px-2 mb-1">
          <button onClick={onClickSend} type="button" className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConversationDetail;
