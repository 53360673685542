import { DailyScheduledFilterAutomation, DailyScheduledViewAutomation, HourlyScheduledFilterAutomation, HourlyScheduledViewAutomation, RecordAutomation } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep } from 'lodash';
import { useCallback } from 'react';

interface RunOnWeekendsInputProps {
  form:
  | DailyScheduledFilterAutomation
  | DailyScheduledViewAutomation
  | HourlyScheduledFilterAutomation
  | HourlyScheduledViewAutomation;
  updateAutomation: (updatedRecordAutomation:RecordAutomation) => void;
}

export const RunOnWeekendsInput = ({ form, updateAutomation }: RunOnWeekendsInputProps) => {
  const onChange = useCallback(
    (e) => {
      const updated = _cloneDeep(form) as HourlyScheduledViewAutomation;
      updated.triggerConfig.scheduleOptions.shouldRunOnWeekends = e.target.value === 'Yes';
    updateAutomation(updated)
    },
    [form, updateAutomation],
  );

  return (
    <div className="mb-3">
      <label htmlFor="runOnWeekends" className="form-label">
        Run On Weekends
      </label>
      <select
        onChange={onChange}
        value={form.triggerConfig.scheduleOptions.shouldRunOnWeekends ? 'Yes' : 'No'}
        className="form-control"
        id="runOnWeekends"
        aria-describedby="runOnWeekendsHelp"
      >
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <div id="runOnWeekendsHelp" className="form-text"></div>
    </div>
  );
};

export default RunOnWeekendsInput;
