import { OPERATOR_OPTIONS } from '@taida-corp/taidacorp-sdk';
import React, { useCallback } from 'react';

interface ChangeFilterOperatorProps {
    operator:OPERATOR_OPTIONS;
    updateOperator:(newOperator:OPERATOR_OPTIONS) => void;
    isDisabled:boolean;
}
export const ChangeFilterOperator = ({operator, updateOperator, isDisabled}:ChangeFilterOperatorProps) => {
    const onChange = useCallback((e:any) => updateOperator(e.target.value), [updateOperator])
    if(isDisabled) {
        return (
            <div className='text-capitalize'>{operator}</div>
        )
    }
    return (
        <select className='form-select form-select-sm' value={operator} onChange={onChange}>
            <option value={'and'}>And</option>
            <option value={'or'}>Or</option>
        </select>
    )
}

export default ChangeFilterOperator;