import { keyBy as _keyBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { ToastNotification } from '../types';
import { addNotification, fetchNotifications, hideNotification, postMarkAsRead, postSoftDelete, realTimeNotificationCreated, removeNotification } from './actions';


interface NotificationsState {
  notifications: Record<string, ToastNotification>;
  isLoading: boolean;
  isInitialized: boolean;
  isUpdating: boolean;
}

const initialState: NotificationsState = {
  notifications: {},
  isLoading: false,
  isInitialized: false,
  isUpdating: false,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNotification, (state, { payload }) => {
      state.notifications = { ...state.notifications, [payload.notificationId]: payload };
    });
    builder.addCase(removeNotification, (state, { payload }) => {
      const { [payload]: _, ...remainingNotifications } = state.notifications;
      state.notifications = remainingNotifications;
    });
    builder.addCase(hideNotification, (state, { payload }) => {
      const { [payload]: updatedNotification } = state.notifications;
      if (updatedNotification !== undefined) {
        state.notifications = { ...state.notifications, [payload]: { ...updatedNotification, isAlertVisible: false } };
      }
    });
    builder.addCase(realTimeNotificationCreated, (state, { payload }) => {
      state.notifications = { ...state.notifications, [payload.notification.notificationId]: payload.notification };
    });

    builder.addCase(fetchNotifications.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      state.notifications = {...state.notifications, ..._keyBy(payload.notifications, 'notificationId')};
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchNotifications.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });

    builder.addCase(postMarkAsRead.pending, (state, { payload, meta }) => {
      state.isUpdating = true;
    });
    builder.addCase(postMarkAsRead.fulfilled, (state, { payload }) => {
      state.notifications = {...state.notifications, [payload.notificationId]: payload};
      state.isUpdating = false;
    });
    builder.addCase(postMarkAsRead.rejected, (state, { payload }) => {
      state.isUpdating = false;
    });

    builder.addCase(postSoftDelete.pending, (state, { payload, meta }) => {
      state.isUpdating = true;
    });
    builder.addCase(postSoftDelete.fulfilled, (state, { payload }) => {
      state.notifications = {...state.notifications, [payload.notificationId]: payload};
      state.isUpdating = false;
    });
    builder.addCase(postSoftDelete.rejected, (state, { payload }) => {
      state.isUpdating = false;
    });
  },
});

export default notificationsSlice;
