import { TableView } from '@taida-corp/taidacorp-sdk';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { createField } from '../../../../../../data/content-type/fields/actions';

interface AddFieldButtonProps {
  readonly view: TableView;
  readonly style:any;
}
export const AddFieldButton = ({
  view,
  style,
}: AddFieldButtonProps) => {
  const tableView = view as TableView;
  const dispatch = useDispatch();
  const createColumn = useCallback(() => {
    dispatch(createField({contentTypeSlug: tableView.contentType, type: 'text', viewId: tableView.viewId}));
  }, [dispatch, tableView.contentType, tableView.viewId]);

  return (
            <div style={style}>
            <button
              onClick={createColumn}
              className="btn btn-light2 d-flex flex-shrink-1 flex-row align-items-center justify-content-center rounded-0"
              style={{ height: 32, width: 140 }}
              type="button"
            >
              +
            </button>
            </div>
  );
};

export default AddFieldButton;
