import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldsToggle } from '../FieldToggle/FieldsToggle';
import { getImageFields, getToggleableFields } from '../../../../data/company/selectors';
import { GalleryView, KanbanView, TableView } from '../../../../data/types';
import useToolbarPopover from '../useToolbarPopover/useToolbarPopover';
import { getSelectedView } from '../../../../data/views/selectors';
import FieldsToggleList from '../FieldToggle/FieldsToggleList';
import CardHeaderSelect from '../FieldToggle/CardHeaderSelect';

const CardPopover = (props: any) => {
  const imageFields = useSelector(getImageFields);
  const view = useSelector(getSelectedView);
  if (view === undefined) {
    return null;
  }
  return (
    <div className="deals-toolbar__popover">
      <h3>{view!.name}</h3>
      <div>
        <CardHeaderSelect fields={imageFields} view={view as GalleryView|KanbanView} />
      </div>
      <FieldsToggleList view={view as KanbanView} />
    </div>
  );
};

export const ViewCardCustomizer = (props: any) => {
  const { buttonProps, ToolbarPopover, popoverProps } = useToolbarPopover({});
  return (
    <>
      <div tabIndex={0} className="deals-toolbar__item" {...buttonProps}>
        <FontAwesomeIcon className="me-2" icon={faCog} />
        Customize Cards
      </div>
      <ToolbarPopover {...popoverProps}>
        <CardPopover />
      </ToolbarPopover>
    </>
  );
};

export default ViewCardCustomizer;
