import React from 'react';
import useAuth from '../../../hooks/auth/useAuth';

export const SignIn = () => {
  const { signin } = useAuth();
  return (
    <main>
      <div className="container py-4">
        <header className="pb-3 mb-4 border-bottom">
          <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
            <span className="fs-4">Taidacorp</span>
          </a>
        </header>

        <div className="p-5 mb-4 bg-light rounded-3">
          <div className="container-fluid py-5">
            <h1 className="display-5 fw-bold">Welcome Back, Boi &lt;3</h1>
            <p className="col-md-8 fs-4">
              Sign In with your Taida Corp gmail to access the leads engine, dealflow manager, and the omnichannel marketing campaign builder.
            </p>
            <button onClick={signin} className="btn btn-primary btn-lg" type="button">
              Sign In
            </button>
          </div>
        </div>

        <div className="row align-items-md-stretch">
          <div className="col-md-6">
            <div className="h-100 p-5 text-white bg-dark rounded-3">
              <h2>Build Your Nest Egg</h2>
              <p>
                Wholesale real estate is the fastest way to break into real estate investing.
              </p>
              <a href="mailto:tyler@taidacorp.com" target="_blank" rel="noreferrer" className="btn btn-outline-light" type="button">
                Learn More
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="h-100 p-5 bg-light border rounded-3">
              <h2>High Volume</h2>
              <p>
                Our lead engine and team resources provide an no-brainer way to build wealth. It is hard work but our tools make it simple.
              </p>
              <a href="mailto:tyler@taidacorp.com" target="_blank" rel="noreferrer" className="btn btn-outline-secondary" type="button">
                Learn More
              </a>
            </div>
          </div>
        </div>

        <footer className="pt-3 mt-4 text-muted border-top">Copyright &copy; 2021 Taida Corp. All Rights Reserved.</footer>
      </div>
    </main>
  );
};

export default SignIn;
