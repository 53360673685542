import React from 'react';
import { shallowEqual, useSelector } from "react-redux";

import Table from '../../../components/Views/Table/Table';
import { getSelectedViewDealIds, getSelectedViewDealsWithFieldNameKeys } from '../../../data/deals/selectors';
import { DealView, Field } from '../../../data/types';

interface DealsTableProps {
  readonly fields: Record<string, Field>;
  readonly view: DealView;
  readonly onCreateRecord: () => void;
}


export const DealsTable = ({ fields, view, onCreateRecord }: DealsTableProps) => {
  // This keeps us from rerendering the entire table when one row is updated
  const dealIds = useSelector(getSelectedViewDealIds, shallowEqual);
  return <Table fields={fields} view={view} onCreateRecord={onCreateRecord} dealIds={dealIds} />
  
};

export default DealsTable;