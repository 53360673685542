import {keyBy as _keyBy} from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { DocumentTemplate } from '../types';
import { fetchDocumentTemplates, fetchDocumentTemplateDetails, setSelectedDocumentTemplateId } from './actions';

interface DocumentTemplatesState {
  templates: Record<string, DocumentTemplate>;
  isLoading: boolean;
  isInitialized: boolean;
  isLoadingDetails: boolean;
  selectedDocumentTemplateId?: string;
  selectedDocumentTemplate?: DocumentTemplate;
}

const initialState: DocumentTemplatesState = {
  templates: {},
  isLoading: false,
  isInitialized: false,
  isLoadingDetails: false,
  selectedDocumentTemplateId: undefined,
  selectedDocumentTemplate: undefined,
};

export const slackSlice = createSlice({
  name: 'documentTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedDocumentTemplateId, (state, { payload }) => {
      state.selectedDocumentTemplateId = payload;
      if(payload === undefined) {
        state.selectedDocumentTemplate = undefined;
      }
    });
    builder.addCase(fetchDocumentTemplates.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocumentTemplates.fulfilled, (state, { payload }) => {
      state.templates = _keyBy(payload.templates, 'id');
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchDocumentTemplates.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchDocumentTemplateDetails.pending, (state, { payload }) => {
      state.isLoadingDetails = true;
      state.selectedDocumentTemplate = undefined;
    });
    builder.addCase(fetchDocumentTemplateDetails.fulfilled, (state, { payload }) => {
      state.selectedDocumentTemplate = payload.template
      state.isLoadingDetails = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchDocumentTemplateDetails.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
      state.selectedDocumentTemplate = undefined;
    });
  },
});

export default slackSlice;
