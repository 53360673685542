import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { createContentType, setSelectedContentType } from '../../data/content-type/content-types/actions';
import { getSelectedContentType } from '../../data/content-type/content-types/selectors';
import { useAppDispatch } from '../../data/store';
import { getUser } from '../../data/user/selectors';

export const ContentTypeForm = (props: any) => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const [name, setName] = useState('');
  const onChange = useCallback((e) => {
    setName(e.target.value ?? '');
  }, []);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(createContentType({ name }));
    },
    [dispatch, name],
  );

  useEffect(() => {
    dispatch(setSelectedContentType(undefined));
  }, [dispatch]);

  if (user === undefined || user.email !== 'harrison@taidacorp.com') {
    return <Navigate to={'/'} />;
  }
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12 col-md-6">
          <form onSubmit={onSubmit}>
            <h3>Create Content Type</h3>
            <div className="mb-3">
              <label htmlFor="name">Name</label>
              <input id="name" className="form-control" type="text" value={name} onChange={onChange} />
            </div>
            <div className="mb-3">
              <button type="submit" className="btn btn-primary">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
