
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { Field } from '../../../../data/types';

interface AddSortFieldsPopoverProps {
    fields: Field[];
    addSortingField: (fieldId: string, order?: 'asc' | 'desc' | undefined) => void;
    closePopover: Function;
  }
  export const AddSortFieldsPopover = ({ fields, addSortingField, closePopover }: AddSortFieldsPopoverProps) => {
    return (
      <div>
      <AutoSizer disableWidth>
        {({height}) => (
          
      <div className="deals-toolbar__child-popover" style={{maxHeight: height - 100, overflowY: 'scroll'}}>
        {fields.map((field) => (
          <button
            className="ms-0 btn btn-link text-black text-start"
            onClick={() => {addSortingField(field.fieldId, 'asc'); closePopover()}}
            key={field.fieldId}
          >
            {field.name}
          </button>
        ))}
      </div>
      

)}
      </AutoSizer>
      </div>
    );
  };
  
  export default AddSortFieldsPopover;