import { WeeklyScheduledFilterAutomation, WeeklyScheduledViewAutomation, RecordAutomation } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep } from 'lodash';
import { useCallback } from 'react';

interface DayOfWeekInputProps {
  form: WeeklyScheduledFilterAutomation|WeeklyScheduledViewAutomation;
  updateAutomation: (updatedRecordAutomation:RecordAutomation) => void;
}

export const DayOfWeekInput = ({ form, updateAutomation }: DayOfWeekInputProps) => {
  const onChange = useCallback(
    (e) => {
      const updated = _cloneDeep(form) as WeeklyScheduledViewAutomation;
      updated.triggerConfig.scheduleOptions.dayOfTheWeek = e.target.value;
    updateAutomation(updated);
    },
    [updateAutomation, form],
  );

  return (
    <div className="mb-3">
      <label htmlFor="dayOfWeek" className="form-label">
        Day of the Week
      </label>
      <select
        onChange={onChange}
        value={form.triggerConfig.scheduleOptions.dayOfTheWeek}
        className="form-control"
        id="dayOfWeek"
        aria-describedby="dayOfWeekHelp"
      >
        <option value="monday">Monday</option>
        <option value="tuesday">Tuesday</option>
        <option value="wednesday">Wednesday</option>
        <option value="thursday">Thursday</option>
        <option value="friday">Friday</option>
        <option value="saturday">Saturday</option>
        <option value="sunday">Sunday</option>
      </select>
      <div id="dayOfWeekHelp" className="form-text"></div>
    </div>
  );
};

export default DayOfWeekInput;
