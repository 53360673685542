import { values as _values, orderBy as _orderBy, filter as _filter, map as _map, forEach as _forEach, get as _get } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getSelectedDealId } from '../deals/selectors';
import { DealActivity } from '../types';
import { getUsers, getUsersDict } from '../users/selectors';
import { format } from 'date-fns';

export const getDealActivitiesState = (state: RootState) => state.dealActivities;

export const getDealActivities = createSelector(getDealActivitiesState, (state) => state.dealActivities);
export const getDealActivitiesIsInitialized = createSelector(getDealActivitiesState, (state) => state.isInitialized);
export const getDealActivitiesIsLoading = createSelector(getDealActivitiesState, (state) => state.isLoading);
export const getDealActivitiesIsSending = createSelector(getDealActivitiesState, (state) => state.isSending);

export const getDealActivitiesList = createSelector(getDealActivities, getUsersDict, (dealActivities, users) => {
  const activities = _orderBy(_values(dealActivities), ['createdDT'], ['asc']);
  return _map(activities, (dealActivity) => {
    let userId = dealActivity.userId;
    if(userId !== undefined) {
      const user = _get(users, userId);
      if(user !== undefined) {
        userId = user.name;
      }
    }
    return {
      ...dealActivity,
      userId,
      createdDT: format(new Date(dealActivity.createdDT), 'Pp'),
    } as DealActivity;
  })
})

export const getDealActivitiesListForSelectedDeal= createSelector(getSelectedDealId, getDealActivitiesList, (dealId, dealActivities) => {
  console.log('debugging', {
    dealActivities, dealId
  })
  // console.log(dealActivities[0].dealId);
  return _filter(dealActivities, (dealActivity) => dealActivity.dealId === dealId);
});
