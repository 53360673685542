import { findIndex as _findIndex, cloneDeep as _cloneDeep, find as _find } from 'lodash';
import { ContentType, Field, FormView, FormViewField } from '@taida-corp/taidacorp-sdk';
import { useMemo, useState, useCallback } from 'react';
import { useAppDispatch } from '../../../../data/store';
import { updateRecordView } from '../../../../data/content-type/record-views/actions';

interface FormBuilderViewProps {
  view: FormView;
  contentType: ContentType;
}

export const FormBuilderView = ({ view, contentType }: FormBuilderViewProps) => {
  const dispatch = useAppDispatch();
  const [internalView, setInternalView] = useState<FormView>(view);
  const unusedFields = useMemo(() => {
    return contentType.fields.filter(
      (value) => _findIndex(internalView.properties.fields, { fieldId: value.fieldId }) === -1,
    );
  }, [internalView.properties.fields, contentType]);

  const saveView = useCallback(() => {
    dispatch(updateRecordView({ view: internalView, contentTypeSlug: contentType.slug }));
  }, [contentType.slug, dispatch, internalView]);

  const swapFields = useCallback(
    (startIdx: number, destIdx: number) => {
      const copiedView = _cloneDeep(internalView);
      const startField: FormViewField = copiedView.properties.fields[startIdx];
      const destField: FormViewField = copiedView.properties.fields[destIdx];

      const fields = copiedView.properties.fields;
      fields.splice(startIdx, 1, destField);
      fields.splice(destIdx, 1, startField);

      const updatedView = { ...copiedView, properties: { ...copiedView.properties, fields } };
      setInternalView(updatedView);
    },
    [internalView],
  );

  const addField = useCallback(
    (fieldId: string) => {
      const copiedView = _cloneDeep(internalView);
      const newField: FormViewField = {
        fieldId,
        isVisible: true,
        validationRules: [],
      };
      const fields = [...copiedView.properties.fields, newField];

      const updatedView = { ...copiedView, properties: { ...copiedView.properties, fields } };
      setInternalView(updatedView);
    },
    [internalView],
  );

  const removeField = useCallback(
    (removeIdx: number) => {
      const copiedView = _cloneDeep(internalView);
      const fields = copiedView.properties.fields;
      fields.splice(removeIdx, 1);
      const updatedView = { ...copiedView, properties: { ...copiedView.properties, fields } };
      setInternalView(updatedView);
    },
    [internalView],
  );

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <h3>Form View</h3>
          <p>
            Configure a form that is used to create a new deal. Currently just supports the default add deal form.
            <br />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 mt-2">
          <h3>Unused Fields</h3>
          {unusedFields.map((field) => (
            <div key={field.fieldId}>
              <div className="btn btn-secondary btn-sm mb-1" onClick={() => addField(field.fieldId)}>
                {field.name}
              </div>
            </div>
          ))}
        </div>
        <div className="col-12 col-md-6 mt-2">
          <h3>Form Fields</h3>

          {internalView.properties.fields.map((field, idx: number) => {
            const fullField = _find(contentType.fields, { fieldId: field.fieldId });
            if (fullField === null || fullField === undefined) {
              return null;
            }
            const canSwapUp = idx > 0;
            const canSwapDown = idx < internalView.properties.fields.length - 1;
            return (
              <div
                key={field.fieldId}
                className="d-flex flex-row justify-content-between align-items-center mb-2 bg-light p-1"
              >
                <div>{fullField.name}</div>
                <div>
                  {canSwapUp && (
                    <button className="ms-1 btn btn-sm btn-primary" onClick={() => swapFields(idx, idx - 1)}>
                      Up
                    </button>
                  )}
                  {canSwapDown && (
                    <button className="ms-1 btn btn-sm btn-primary" onClick={() => swapFields(idx, idx + 1)}>
                      Down
                    </button>
                  )}
                  <button className="ms-1 btn btn-sm btn-primary" onClick={() => removeField(idx)}>
                    x
                  </button>
                </div>
              </div>
            );
          })}

          <button onClick={saveView} type="button" className="btn btn-primary">
            Save Form View
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormBuilderView;
