import { DealDocument } from '../../../../../data/types';
import { format } from 'date-fns';
import { faExternalLinkAlt, faEnvelope, faDownload, faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import CopyButton from '../../../../CopyButton/CopyButton';

interface DocumentListItemProps {
  document: DealDocument;
  onSend: (docId: string) => void;
  onDownload: (docId: string) => void;
}

export const DocumentListItem = ({ document, onSend, onDownload }: DocumentListItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <tr>
      <td>
        <div className="d-flex flex-column">
          <div>{document.name}</div>
          {!isExpanded && <div onClick={() => setIsExpanded(true)}><FontAwesomeIcon icon={faCaretRight}/> Recipients</div>}
          {isExpanded && <div onClick={() => setIsExpanded(false)}><FontAwesomeIcon icon={faCaretDown}/> Recipients</div>}
          {isExpanded && (
            <div className="d-flex flex-column">
                <table>
                    <tr>
                    <th>Name</th>
                    <th>Has Signed</th>
                    <th>Signing Order</th>
                    <th>Share Link</th>
                    <th>Recipient Type</th>
                    </tr>
              {document.recipients!.map((rec) => (
                <tr>
                  <td className="me-1">
                    {rec.firstName} {rec.lastName} {!rec.lastName && !rec.firstName ? rec.email : undefined}
                  </td>
                  <td>
                      {rec.hasCompleted ? 'Yes' : 'No'}
                  </td>
                  <td>
                      {rec.signingOrder}
                  </td>
                    <td>
                        <div className='mb-1'>
                      {rec.sharedLink && <CopyButton text={rec.sharedLink}/>}
                      </div>
                    </td>
                    <td>
                      {rec.recipientType}
                  </td>
                </tr>
              ))}
              </table>
            </div>
          )}
        </div>
      </td>
      <td>{document.status}</td>
      <td>{format(new Date(document.dateCreated), 'MM/dd/yyyy')}</td>
      <td>{format(new Date(document.dateModified), 'MM/dd/yyyy')}</td>
      <td>
        <button onClick={() => onSend(document.id)} className="btn">
          <FontAwesomeIcon icon={faEnvelope} color={'#0A0A0A'} />
        </button>
      </td>
      <td>
        <button onClick={() => onDownload(document.id)} className="btn">
          <FontAwesomeIcon icon={faDownload} color={'#0A0A0A'} />
        </button>
      </td>
    </tr>
  );
};

export default DocumentListItem;
