import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ToastNotification } from '../types';
import { API } from 'aws-amplify';

export const addNotification = createAction<ToastNotification>('notifications/add');
export const removeNotification = createAction<string>('notifications/remove');
export const hideNotification = createAction<string>('notifications/hide');

export const fetchNotifications = createAsyncThunk<{ notifications: ToastNotification[] }, undefined, { rejectValue: string[] }>(
    'notifications/fetchNotifications',
    async () => {
      try {
        const { notifications } = await API.get(
          'Notifications', // function defined in our serverless.yml
          '', // the function's path
          { responseType: 'json' },
        );
        return { notifications };
      } catch (error) {
        console.log(error);
        return { notifications: [] };
      }
    },
  );
  

  export const postMarkAsRead = createAsyncThunk<
  ToastNotification,
  string,
  { rejectValue: string[] }
>('notifications/postMarkAsRead', async (notificationId, thunkApi) => {
  try {
    const notification = await API.post(
      'Notifications', // function defined in our serverless.yml
      `${notificationId}/read`, // the function's path
      { responseType: 'json', body: {} },
    );
    return notification;
  } catch (error) {
    console.log(error);
  }
});
export const postSoftDelete = createAsyncThunk<
  ToastNotification,
  string,
  { rejectValue: string[] }
>('notifications/postSoftDelete', async (notificationId, thunkApi) => {
  try {
    const notification = await API.post(
      'Notifications', // function defined in our serverless.yml
      `${notificationId}/soft-delete`, // the function's path
      { responseType: 'json', body: {} },
    );
    return notification;
  } catch (error) {
    console.log(error);
  }
});


export const realTimeNotificationCreated = createAction<{notification: ToastNotification}>('notification.created');
