import React, { useCallback, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrello } from '@fortawesome/free-brands-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { faStream, faTable } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { ViewIconColors, ViewIconDefinitions } from '../../../../components/Views/ViewIcons';
import { RecordView } from '@taida-corp/taidacorp-sdk';
import { createRecordView } from '../../../../data/content-type/record-views/actions';


interface ViewPickerProps {
    views: RecordView[];
    currentView?:RecordView;
    contentTypeSlug:string;
}

export const ViewPicker = ({views, currentView, contentTypeSlug}: ViewPickerProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const contentPathRoot = useMemo(() => {
    console.log(location);
    const parts = location.pathname.split('/');
    return parts[1];
  }, [location])
  const createTableView = useCallback(() => {
    dispatch(createRecordView({view: {type: 'table'}, contentTypeSlug}));
    navigate(`${contentPathRoot}/new`);
  }, [contentPathRoot, contentTypeSlug, dispatch, navigate]);
  const createKanbanView = useCallback(() => {
    dispatch(createRecordView({view: {type: 'kanban'}, contentTypeSlug}));
    navigate(`${contentPathRoot}/new`);
  }, [contentPathRoot, contentTypeSlug, dispatch, navigate]);
  const createFormView = useCallback(() => {
    dispatch(createRecordView({view: {type: 'form'}, contentTypeSlug}));
    navigate(`${contentPathRoot}/new`);
  }, [contentPathRoot, contentTypeSlug, dispatch, navigate]);
  const createAPIView = useCallback(() => {
    dispatch(createRecordView({view: {type: 'api'}, contentTypeSlug}));
    navigate(`${contentPathRoot}/new`);
  }, [contentPathRoot, contentTypeSlug, dispatch, navigate]);
  const createGalleryView = useCallback(() => {
    dispatch(createRecordView({view: {type: 'gallery'}, contentTypeSlug}));
    navigate(`${contentPathRoot}/new`);
  }, [contentPathRoot, contentTypeSlug, dispatch, navigate]);
  const createCalendarView = useCallback(() => {
    // dispatch(createRecordView({view: {type: 'calendar'}, contentTypeSlug}));
    navigate(`${contentPathRoot}/new`);
  }, [contentPathRoot, navigate]);
  console.log('contentPathRoot', contentPathRoot);
  return (
    <Dropdown className="me-0">
      <Dropdown.Toggle variant="rounded border-0 d-flex flex-row align-items-center deals-toolbar__item">
        <FontAwesomeIcon icon={faStream} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {views.map((view) => {
          return (
            <Dropdown.Item
              as={Link}
              key={view.viewId}
              to={{ pathname: `/${contentPathRoot}/${view.viewId}` }}
              className="d-flex flex-row align-items-center"
              // onClick={() => dispatchSetSelectedView(view.viewId)}
            >
              <FontAwesomeIcon
                className="me-2"
                size="1x"
                color={ViewIconColors[view.type]}
                icon={ViewIconDefinitions[view.type]}
              />
              {view.name}
            </Dropdown.Item>
          );
        })}
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createTableView}>
          Create Table View
        </Dropdown.Item>
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createKanbanView}>
          Create Kanban View
        </Dropdown.Item>
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createAPIView}>
          Create API View
        </Dropdown.Item>
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createFormView}>
          Create Form View
        </Dropdown.Item>
        <Dropdown.Item className="d-flex flex-row align-items-center" onClick={createGalleryView}>
          Create Gallery View
        </Dropdown.Item>
        <Dropdown.Item disabled className="d-flex flex-row align-items-center" onClick={createCalendarView}>
          Create Calendar View
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ViewPicker;
