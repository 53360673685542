import { FieldValue } from "./fields";

export type ValidateDataResponse = {
    data: Record<string, any>;
    cleanedData: undefined | Record<string, FieldValue>;
    errors: undefined | Record<string, string[]>;
  };
  export const isValidateDataResponse = (something: any): something is ValidateDataResponse => {
    return (something.data && something.cleanedData) || (something.errors && something.data);
  };
  