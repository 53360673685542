import { Field, FieldType, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import { TableCellDisplayComponent } from './base';
// import ColorInputComponent from './color/ColorInputComponent';
// import DateInputComponent from './date/DateInputComponent';
// import EmailInputComponent from './email/EmailInputComponent';
// import LongTextInputComponent from './long-text/LongTextInputComponent';
// import NumberInputComponent from './number/NumberInputComponent';
// import PhoneInputComponent from './phone/PhoneInputComponent';
// import ReadOnlyInputComponent from './readonly/ReadOnlyInputComponent';
// import SelectUserInputComponent from './select-user/SelectUserInputComponent';
// import SelectInputComponent from './select/SelectInputComponent';
// import URLInputComponent from './url/URLInputComponent';
// import AttachmentInputComponent from './attachment/AttachmentInputComponent';
import TextTableCellDisplayComponent from './text/TextTableCellDisplayComponent';


export const getTableCellDisplayComponent = (fieldType: FieldType): TableCellDisplayComponent => {
  // exhaustive switch here gives us a nice exception if we're missing any
  switch (fieldType) {
    case 'text':
      return TextTableCellDisplayComponent;
    case 'long-text':
      return TextTableCellDisplayComponent;
    case 'email':
      return TextTableCellDisplayComponent;
    case 'phone':
      return TextTableCellDisplayComponent;
    case 'url':
      return TextTableCellDisplayComponent;
    case 'attachment':
      return TextTableCellDisplayComponent;
    case 'number':
      return TextTableCellDisplayComponent;
    case 'slider':
      return TextTableCellDisplayComponent;
    case 'checkbox':
      return TextTableCellDisplayComponent;
    case 'color':
      return TextTableCellDisplayComponent;
    case 'date':
      return TextTableCellDisplayComponent;
    case 'datetime':
      return TextTableCellDisplayComponent;
    case 'formula':
      return TextTableCellDisplayComponent;
    case 'select':
      return TextTableCellDisplayComponent;
    case 'multi-select':
      return TextTableCellDisplayComponent;
    case 'select-user':
      return TextTableCellDisplayComponent;
    case 'multi-select-user':
      return TextTableCellDisplayComponent;

    default:
      throw new Error(`Missing detail field component for ${fieldType}`);
  }
};
