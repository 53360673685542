import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { addCompanyLink } from '../../../data/company/companyActions';
import { useAppDispatch } from '../../../data/store';

import './AddLinkButton.scss';

const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <button
    className="btn btn-sm"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

export const AddLinkButton = (props: any) => {
    const dispatch = useAppDispatch();
    const [isVisible, setIsVisible] = useState(false);
    const toggleIsVisible = useCallback(() => setIsVisible(val => !val), []);
    const [name, setName] = useState('');
    const [uri, setUri] = useState('');
    const [isExternal, setIsExternal] = useState(true);
    const onChangeName = useCallback((e:any) => setName(e.target.value ?? ''), []);
    const onChangeUri = useCallback((e:any) => setUri(e.target.value ?? ''), []);
    const onChangeIsExternal = useCallback((e:any) => setIsExternal(e.target.checked), []);
    const onSubmit = useCallback((e:any) => {
        e.preventDefault();
        dispatch(addCompanyLink({name, uri, isExternal}));
        setIsVisible(false);
    }, [dispatch, name, uri, isExternal])
    return (
    <Dropdown show={isVisible} onToggle={toggleIsVisible}>
      <Dropdown.Toggle as={CustomToggle}>
        <FontAwesomeIcon icon={faPlusCircle} />
      </Dropdown.Toggle>
      <Dropdown.Menu className='mt-2'>
        <form onSubmit={onSubmit} id='add-link-form' className='p-3' style={{width: 300}}>
          <div className="mb-3">
            <label htmlFor="new-link-name" className="form-label">
              Label
            </label>
            <input
                required
                type="text"
                className="form-control"
                id="new-link-name"
                placeholder="Link to what?"
                value={name}
                onChange={onChangeName}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="new-link-url" className="form-label">
              Link
            </label>
            <input
                required
                type="url"
                className="form-control"
                id="new-link-url"
                placeholder="http://..."
                value={uri}
                onChange={onChangeUri}
            />
          </div>
          <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                id="is-external"
                checked={isExternal}
                onChange={onChangeIsExternal}
            />
            <label className="form-check-label" htmlFor="is-external">
              Open in New Tab
            </label>
          </div>
          <div className='d-grid'>
          <button type='submit' className='btn btn-outline-primary'>Add Link</button>
          </div>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddLinkButton;
