import { debounce as _debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { updateField } from '../../../../data/company/fieldActions';
import { updateDealRecordByField } from '../../../../data/deals/actions';
import { useAppDispatch } from '../../../../data/store';
import { DealRecord, Field, FieldDateValue, FieldFormulaValue, isFieldDateValue, isFieldFormulaValue } from '../../../../data/types';
import { FieldDetailViewComponents } from '../../../Fields';

export const DealFormInput = ({ field, deal }: { field: Field; deal: DealRecord }) => {
  const { dealId } = deal;
  const { fieldId } = field;
  const ogFieldId = `${field.fieldId}-og`;
  const dispatch = useAppDispatch();
  const ogValue = useMemo(() => {
    return deal.fields![ogFieldId];
  }, [deal.fields, ogFieldId])
  const initialValue: string = useMemo(() => {
    if(isFieldFormulaValue(deal.fields![ogFieldId])) {
      return (deal.fields![ogFieldId] as FieldFormulaValue).value;
    }
    if(isFieldDateValue(deal.fields![ogFieldId])) {
      return (deal.fields![ogFieldId] as FieldDateValue).dtValue;
    }
    return (deal.fields![ogFieldId] as string);
  }, [deal.fields, ogFieldId]);
  const [internalValue, _setInternalValue] = useState(initialValue);
  const [displayValue, _setDisplayValue] = useState(initialValue);
  const DetailField = useMemo(() => {
    return FieldDetailViewComponents[field.type];
  }, [field]);

  const debouncedUpdateDealRecord = useMemo(() => {
    return _debounce(
      (updates: { dealId: string; fieldId: string; value: string }) => dispatch(updateDealRecordByField(updates)),
      1500,
    );
  }, [dispatch]);

  const onChange = useCallback(
    (newValue) => {
      _setInternalValue(newValue);
      debouncedUpdateDealRecord({ dealId: deal.dealId, fieldId: field.fieldId, value: newValue });
    },
    [_setInternalValue, debouncedUpdateDealRecord, deal.dealId, field.fieldId],
  );

  useEffect(() => {
    // _setInternalValue(initialValue);
    _setDisplayValue(initialValue);
  }, [initialValue]);

  return (
    <div className="mb-3 row">
      <label className="col-sm-2 col-form-label" htmlFor={`field-${field.fieldId}`}>
      {field.name}{' '}
      {/* {field.fieldId} */}
      </label>
      <div className="col-sm-10">
        <DetailField
          // dealId={deal.dealId}
          field={field}
          ogValue={ogValue}
          internalValue={internalValue}
          displayValue={displayValue}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default DealFormInput;
