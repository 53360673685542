import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { createFormattedNotification } from '../../utils/notifications';
import { addNotification } from '../notifications/actions';
import { Contact, ContactSavedSearch } from '../types';


// sendContactPreview, fetchContactSavedSearches, createContact, realTimeContactCreated, realTimeContactDeleted, realTimeContactUpdated
export const setSelectedContactSavedSearchId = createAction<string|undefined>('contact.setSelectedContactSavedSearchId');


export const fetchContactSavedSearches = createAsyncThunk<ContactSavedSearch[], undefined, { rejectValue: string[] }>(
    'contactSavedSearches/fetchContactSavedSearches',
    async () => {
      try {
        const data = await API.get(
          'ContactSavedSearches', // function defined in our serverless.yml
          '',            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const fetchContactSavedSearch = createAsyncThunk<ContactSavedSearch, {savedSearchId:string}, { rejectValue: string[] }>(
    'contactSavedSearches/fetchContactSavedSearch',
    async ({savedSearchId}) => {
      try {
        const data = await API.get(
          'ContactSavedSearches', // function defined in our serverless.yml
          savedSearchId,            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  
  export const createContactSavedSearch = createAsyncThunk<ContactSavedSearch, Partial<ContactSavedSearch>, { rejectValue: string[] }>(
    'contactSavedSearches/createContactSavedSearch',
    async (contactSavedSearch) => {
      try {
        const resp = await API.post(
          'ContactSavedSearches', // function defined in our serverless.yml
          ``,            // the function's path
          { 'responseType': 'json', body: contactSavedSearch }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const updateContactSavedSearch = createAsyncThunk<ContactSavedSearch, Partial<ContactSavedSearch>, { rejectValue: string[] }>(
    'contactSavedSearches/updateContactSavedSearch',
    async (contactSavedSearch) => {
      try {
        const resp = await API.post(
          'ContactSavedSearches', // function defined in our serverless.yml
          contactSavedSearch.savedSearchId,            // the function's path
          { 'responseType': 'json', body: contactSavedSearch }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  export const deleteContactSavedSearch = createAsyncThunk<ContactSavedSearch, string, { rejectValue: string[] }>(
    'contactSavedSearches/deleteContactSavedSearch',
    async (savedSearchId, thunkApi) => {
      const savedSearchData = await API.del(
        'ContactSavedSearches', // function defined in our serverless.yml
        savedSearchId, // the function's path
        { responseType: 'json', body: {} },
      );
      return savedSearchData;
    },
  );
  


export const realTimeContactSavedSearchUpdated = createAction<{ contactSavedSearch: ContactSavedSearch }>('contactSavedSearch.updated');
export const realTimeContactSavedSearchDeleted = createAction<{ contactSavedSearch: ContactSavedSearch }>('contactSavedSearch.deleted');
export const realTimeContactSavedSearchCreated = createAction<{ contactSavedSearch: ContactSavedSearch }>('contactSavedSearch.created');
