import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce as _debounce, get as _get } from 'lodash';
import { useLocation, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import {
  EnrichedDataRecord,
  TableViewField,
  Field,
  isFieldFormula,
  PersistedFieldValue,
  FieldValue,
} from '@taida-corp/taidacorp-sdk';
import { useAppDispatch } from '../../../../../data/store';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside/useOnClickOutside';
import useDoubleClick from '../../../../../hooks/useDoubleClick/useDoubleClick';
import { useSelector } from 'react-redux';
import { getHoveredColumnIndex, getHoveredRowIndex, getSelectedRange } from '../../../../../data/table-ui/selectors';
import { deselectRange, setHoverCoords, setSelectedRangeCoords } from '../../../../../data/table-ui/actions';
import { makeGetRecordById } from '../../../../../data/content-type/records/selectors';

export const useHeaderCell = (
  cellRef: RefObject<HTMLDivElement | null>,
  tableViewField: TableViewField,
  field: Field,
  columnIndex: number,
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  
  const [isContextVisible, setIsContextVisible] = useState(false);
  const width = useMemo(() => tableViewField.width ?? 140, [tableViewField]);

  const onMouseOver = useCallback(() => {
    // dispatch(
    //   setHoverCoords({
    //     hoverColumnIndex: columnIndex,
    //     hoverRowIndex: rowIndex,
    //   }),
    // );
  }, []);
  const onSingleClick = useCallback(() => {}, []);
  const onDoubleClick = useCallback(() => {
  }, []);
  const onClickOutside = useCallback(() => {
  }, []);
  useOnClickOutside(cellRef, onClickOutside, true);

  useDoubleClick({
    ref: cellRef,
    latency: 250,
    onSingleClick,
    onDoubleClick: onDoubleClick,
  });

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      
    },
    [],
  );
  const onFocus = useCallback(
    (e) => {
      
    },
    [],
  );

  const onBlur = useCallback(
    (e) => {
    },
    [],
  );

  const onContextMenu = useCallback((e) => {
    
    if (!e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey) {
      e.preventDefault();
      alert('content context menu xlicked');
      // navigate(`/datas/${viewId}/edit/${dataRecord.id}`);
    }
    /*
      e.preventDefault();
      // TODO: implement an actual right click menu
      // eslint-disable-next-line no-restricted-globals
      const shouldDelete = confirm('You sure you want to delete this row?');
      if (shouldDelete) {
        dispatch(deleteDealRecord(dataRecord));
      }
      */
  }, []);

  return {
    cellProps: {
      onKeyDown,
      onBlur,
      onFocus,
      tabIndex: 0,
      onContextMenu,
      onMouseOver,
    } as React.HTMLAttributes<HTMLDivElement>,
    isContextVisible,
    width,
  };
};
