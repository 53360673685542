import csv from 'csvtojson';
import { UppyFile } from '@uppy/core';

export const parseCSVFile = (file: UppyFile, setStarted:()=>void, setHeaders:(headers:string[])=>void, onDone:()=>void, timeoutSeconds=15): Promise<any[]> => {
  return new Promise(async (resolve, reject) => {
    const converter = csv({noheader: false});
    converter.on('header', (headers: string[]) => {
        setHeaders(headers);
    });
    converter.on('data', (data) => {

    });
    converter.on('done', (val) => {
        onDone();
    });
    file.data.text().then((value) => {
        setStarted();
        converter.fromString(value).then(resolve);
    });
    setTimeout(() => reject(`Parsing the headers timed out after ${timeoutSeconds} seconds`), timeoutSeconds * 1000);
  });
};

export const getRowsForCSV = async <T>(file: UppyFile): Promise<T[]> => {
  const text = await file.data.text();
  const values: T[] = await csv().fromString(text);
  return values;
};
