import { cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash';

import DayOfWeekInput from './views/DayOfWeekInput';
import DayOfMonthInput from './views/DayOfMonthInput';
import RunOnWeekendsInput from './views/RunOnWeekendsInput';
import TimeOfDayInput from './views/TimeOfDayInput';

import { useCallback, useEffect } from 'react';
import { RecordAutomation, ScheduledWithFilterAutomation, ScheduledWithViewAutomation, defaultScheduleOptionsForFrequency, isHourlyScheduledFilterAutomation, isHourlyScheduledViewAutomation, isDailyScheduledFilterAutomation, isDailyScheduledViewAutomation, isWeeklyScheduledFilterAutomation, isWeeklyScheduledViewAutomation, isMonthlyScheduledFilterAutomation, isMonthlyScheduledViewAutomation } from '@taida-corp/taidacorp-sdk';

interface ScheduleAutomationFormProps {
  updateAutomation: (updatedRecordAutomation:RecordAutomation) => void;
  formData: ScheduledWithFilterAutomation | ScheduledWithViewAutomation;
}

export const ScheduleAutomationForm = ({ formData, updateAutomation }: ScheduleAutomationFormProps) => {
    const   onChangeFrequency = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
      const updated = _cloneDeep(formData);
      if(updated.type === 'scheduled-with-filter-automation' || updated.type === 'scheduled-in-view-automation') {
        const newFrequency = event.target.value as "hourly" | "weekly" | "monthly" | "daily";
        const newScheduleOptions = defaultScheduleOptionsForFrequency(newFrequency);
        updated.triggerConfig!.scheduleOptions = newScheduleOptions
      }
      updateAutomation(updated);
    }, [formData, updateAutomation]);

  return (
    <>
      <div className="mb-3">
        <label htmlFor="frequency" className="form-label">
          Frequency
        </label>
        <select
          onChange={onChangeFrequency}
          value={formData.triggerConfig.scheduleOptions.frequency}
          className="form-select"
          id="frequency"
          aria-describedby="frequencyHelp"
        >
          <option value="hourly">Hourly</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      {isHourlyScheduledFilterAutomation(formData) && <RunOnWeekendsInput form={formData} updateAutomation={updateAutomation} />}
      {isHourlyScheduledViewAutomation(formData) && <RunOnWeekendsInput form={formData} updateAutomation={updateAutomation} />}
      {isDailyScheduledFilterAutomation(formData) && (
        <>
          <RunOnWeekendsInput form={formData} updateAutomation={updateAutomation} />
          <TimeOfDayInput form={formData} updateAutomation={updateAutomation} />
        </>
      )}
      {isDailyScheduledViewAutomation(formData) && (
        <>
          <RunOnWeekendsInput form={formData} updateAutomation={updateAutomation} />
          <TimeOfDayInput form={formData} updateAutomation={updateAutomation} />
        </>
      )}
      {isWeeklyScheduledFilterAutomation(formData) && (
        <>
          <DayOfWeekInput form={formData} updateAutomation={updateAutomation} />
          <TimeOfDayInput form={formData} updateAutomation={updateAutomation} />
        </>
      )}
      {isWeeklyScheduledViewAutomation(formData) && (
        <>
          <DayOfWeekInput form={formData} updateAutomation={updateAutomation} />
          <TimeOfDayInput form={formData} updateAutomation={updateAutomation} />
        </>
      )}
      {isMonthlyScheduledFilterAutomation(formData) && (
        <>
          <DayOfMonthInput form={formData} updateAutomation={updateAutomation} />
          <TimeOfDayInput form={formData} updateAutomation={updateAutomation} />
        </>
      )}
      {isMonthlyScheduledViewAutomation(formData) && (
        <>
          <DayOfMonthInput form={formData} updateAutomation={updateAutomation} />
          <TimeOfDayInput form={formData} updateAutomation={updateAutomation} />
        </>
      )}
    </>
  );
};

export default ScheduleAutomationForm;
