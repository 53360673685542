import { keyBy as _keyBy } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import {
  addCompanyLink,
  deleteCompanyLink,
  fetchCompany,
  realTimeCompanyUpdated,
  updateCompany,
} from './companyActions';
import { Company, Field } from '../types';
import { createField, updateField, deleteField } from './fieldActions';

interface CompanyState {
  company: Company | undefined;
  fields: Record<string, Field>;
  isLoading: boolean;
  isInitialized: boolean;
  isUpdating: boolean;
  errors: string[];
}

const initialState: CompanyState = {
  company: undefined,
  isLoading: false,
  isInitialized: false,
  isUpdating: false,
  fields: {},
  errors: [],
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(realTimeCompanyUpdated, (state, { payload }) => {
      const currentCompany = current(state).company;
      if (currentCompany!.updatedDT < payload.company.updatedDT) {
        state.company = payload.company;
        state.fields = _keyBy(payload.company.fields, 'fieldId');
      }
    });
    builder.addCase(fetchCompany.pending, (state) => {
      state.isLoading = true;
      state.isInitialized = false;
      state.fields = {};
      // state.company = undefined;
      state.errors = [];
    });
    builder.addCase(fetchCompany.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.fields = _keyBy(payload.fields, 'fieldId');
      state.isInitialized = true;
      state.isLoading = false;
    });
    builder.addCase(fetchCompany.rejected, (state, { payload }) => {
      state.company = undefined;
      state.isInitialized = true;
      state.isLoading = false;
      state.errors = payload ?? [];
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.fields = _keyBy(payload.fields, 'fieldId');
      state.isLoading = false;
    });
    builder.addCase(updateCompany.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });
    builder.addCase(addCompanyLink.pending, (state) => {});
    builder.addCase(addCompanyLink.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.fields = _keyBy(payload.fields, 'fieldId');
      state.isLoading = false;
    });
    builder.addCase(addCompanyLink.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });
    builder.addCase(deleteCompanyLink.pending, (state) => {});
    builder.addCase(deleteCompanyLink.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.fields = _keyBy(payload.fields, 'fieldId');
      state.isLoading = false;
    });
    builder.addCase(deleteCompanyLink.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });
    builder.addCase(createField.pending, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createField.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.fields = _keyBy(payload.fields, 'fieldId');
      state.isLoading = false;
    });
    builder.addCase(createField.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });
    builder.addCase(updateField.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateField.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.fields = _keyBy(payload.fields, 'fieldId');
      state.isLoading = false;
    });
    builder.addCase(updateField.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });
    builder.addCase(deleteField.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteField.fulfilled, (state, { payload }) => {
      state.company = payload;
      state.fields = _keyBy(payload.fields, 'fieldId');
      state.isLoading = false;
    });
    builder.addCase(deleteField.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });
  },
});

export default companySlice;
