import { DealActivity } from "../../../../data/deals/slice";
import { DealComment, DealLog } from "../../../../data/types";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export const Message = ({message}: {message:DealLog|DealComment}) => {

    if(message.activityType === 'comment') {
        return (
            <div>
            <div className='px-1 border rounded bg-primary text-white mt-2'>
            <div className='d-flex flex-row justify-content-between'><span><b>{message.userId}</b></span></div>
            <div className='message-text '>
            <ReactMarkdown remarkPlugins={[remarkGfm]} >{message.message}</ReactMarkdown>
            </div>
        </div>
        <div className='d-flex flex-row justify-content-end text-secondary'>
        <small>{message.createdDT}</small>
        </div>
        </div>
        )
    }

    if(message.activityType === 'user edit') {
        return (
            <div className='px-1'>
            <div ><b>Edit by {message.userId}</b></div>
            <div className='message-text '>
            <ReactMarkdown remarkPlugins={[remarkGfm]} >{message.message}</ReactMarkdown>
            </div>
        </div>
        )
    }

    return (
        <div className='px-1'>
            <div ><b>{message.userId}</b></div>
            <div className='message-text '>
            <ReactMarkdown remarkPlugins={[remarkGfm]} >{message.message}</ReactMarkdown>
            </div>
        </div>
    )
    
}

export default Message;