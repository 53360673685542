import { Field, TableViewField } from '@taida-corp/taidacorp-sdk';
import { MultiGrid } from 'react-virtualized';
import TableDataCell from './TableDataCell';
import TableHeaderCell from './TableHeaderCell';

interface CellWrapperProps {
  grid: React.MutableRefObject<MultiGrid | null | undefined>;
  columnIndex: number;
  rowIndex: number;
  isLastColumn: boolean;
  style: any;
  id?: string;
  field: Field;
  tableViewField: TableViewField;
  hoveredRowIndex: number | null;
  hoveredColumnIndex: number | null;
  selectedRange: {
    startRowIndex: number;
    startColumnIndex: number;
    endRowIndex: number;
    endColumnIndex: number;
  } | null;
}
export const CellWrapper = ({
  grid,
  rowIndex,
  columnIndex,
  style,
  id,
  field,
  tableViewField,
  isLastColumn,
}: CellWrapperProps) => {
  if (rowIndex === 0) {
    return (
      <TableHeaderCell
        isLastColumn={isLastColumn}
        field={field}
        tableViewField={tableViewField}
        style={style}
        columnIndex={columnIndex}
        canHide={columnIndex !== 0}
      />
    );
  }
  if (id === undefined) {
    return null;
  }

  return (
    <TableDataCell
      isLastColumn={isLastColumn}
      columnIndex={columnIndex}
      rowIndex={rowIndex}
      field={field}
      tableViewField={tableViewField}
      style={style}
      id={id}
    />
  );
};

export default CellWrapper;
