import { filter as _filter, find as _find } from 'lodash';
import { faTimesCircle, faCaretDown, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Popover, { PopoverProps } from 'react-popover';
import { TableView, KanbanView, Field } from '../../../../data/types';
import { FieldSortAscendingComponents, FieldSortDescendingComponents } from '../../../../components/Fields';
import AddSortFieldsPopover from './AddSortFieldsPopover';
import SortedFieldRow from './SortedFieldRow';
import useToolbarPopover from '../useToolbarPopover/useToolbarPopover';

interface ViewSortPopoverProps {
  view: TableView | KanbanView;
  allFields: Field[];
  addSortingField: (fieldId: string, order?: 'asc' | 'desc' | undefined) => void;
  updateSortingField: (newField: string, newOrder: 'asc' | 'desc', index: number) => void;
  removeSortingField: (index: number) => void;
  onChildPopover: any;
}
export const ViewSortPopover = ({ view, allFields, addSortingField, updateSortingField, removeSortingField, onChildPopover }: ViewSortPopoverProps) => {
  const { buttonProps, ToolbarPopover, popoverProps, closePopover } = useToolbarPopover({zIndex:999});
  const [fields, ordering] = view.properties.orderBy || [[], []];
  const availableFields = useMemo(() => {
    return _filter(allFields, (field) => fields.indexOf(field.fieldId) === -1);
  }, [fields, allFields]);
  useEffect(() => {
    onChildPopover(popoverProps.isPopoverOpen);
  }, [popoverProps.isPopoverOpen, onChildPopover]);

  return (
    <div className="deals-toolbar__popover">
      {fields.length === 0 && (
        <div>
          <small className="text-muted">No sorts applied to this view</small>
        </div>
      )}
      {fields.length > 0 && (
        <div className="view-sort__current-fields-list">
          {fields.map((field, orderIndex) => (
            <SortedFieldRow
              ordering={ordering}
              fields={allFields}
              availableFields={availableFields}
              field={field}
              index={orderIndex}
              updateSortingField={updateSortingField}
              removeSortingField={removeSortingField}
              onChildPopover={onChildPopover}
            />
          ))}
        </div>
      )}
      {fields.length === 0 ? (
        <>
          <button {...buttonProps} className="btn btn-link text-black ps-0">
            <small className="text-muted">
              Pick a field to sort by <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
            </small>
          </button>
        </>
      ) : (
        <button {...buttonProps} className="btn btn-link text-black ps-0">
          <small className="text-muted">
            Pick another field to sort by <FontAwesomeIcon className="ms-2" icon={faCaretDown} />
          </small>
        </button>
      )}
      <ToolbarPopover {...popoverProps}>
        <AddSortFieldsPopover
          closePopover={closePopover}
          fields={availableFields}
          addSortingField={addSortingField}
        />
      </ToolbarPopover>
    </div>
  );
};

export default ViewSortPopover;
