import { createSlice } from '@reduxjs/toolkit';
import { User } from '../types';
import { fetchMe, setUser } from './actions';


interface UserState {
  user: User|undefined;
  isLoading: boolean;
  isInitialized: boolean;
}

const initialState: UserState = {
  user: undefined,
  isLoading: false,
  isInitialized: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, { payload }) => {
      state.user = payload;
    });
    builder.addCase(fetchMe.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMe.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchMe.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });
  },
});

export default userSlice;
