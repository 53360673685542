import { useEffect, useMemo } from 'react';
import { DealRecord } from '../../../../data/types';
import GoogleMapReact from 'google-map-react';
import StaticMapView from '../../../GoogleMaps/StaticMapView';
import StaticStreetView from '../../../GoogleMaps/StaticStreetView';
import DealForm from '../Form/DealForm';

interface DealListingFormProps {
  deal: DealRecord;
}

export const DealListingForm = ({ deal }: DealListingFormProps) => {
  return <DealForm dealRecord={deal} formViewId={'343c1fd2-42c9-4e36-8ab9-13c98ced2721'} />;
};

export default DealListingForm;
