import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { getBrowserId } from '../realTime/selectors';
import { RootState } from '../store';
import { DealRecord, ValidateDataResponse } from '../types';

export const setSelectedDealId = createAction<string | undefined>('dealflow/setSelectedDealId');
export const setDealsSearchTerm = createAction<string | undefined>('dealflow/setDealsSearchTerm');
export const clearNewDealFormResp = createAction('dealflow/clearNewDealFormResp');

export const fetchDealRecords = createAsyncThunk<
  DealRecord[],
  { lastEvaluatedKey: any } | undefined,
  { rejectValue: string[] }
>('dealflow/fetchDeals', async (args, getThunk) => {
  const params =
    args && args.lastEvaluatedKey !== undefined ? `?afterItem=${JSON.stringify(args.lastEvaluatedKey)}` : '';
  const { deals, meta } = await API.get(
    'Deals', // function defined in our serverless.yml
    params, // the function's path
    { responseType: 'json' },
  );
  if (meta && meta.hasNext) {
    const { lastEvaluatedKey } = meta;
    getThunk.dispatch(fetchDealRecords({ lastEvaluatedKey }));
  }
  return deals;
});

export const createDealRecord = createAsyncThunk<DealRecord, string, { rejectValue: string[] }>(
  'deal-records/create',
  async (companyId, thunkApi) => {
    const browserId = getBrowserId(thunkApi.getState() as RootState);
    const dealsData = await API.post(
      'Deals', // function defined in our serverless.yml
      '', // the function's path
      { responseType: 'json', headers: { 'taidacorp-client': browserId }, body: {} },
    );
    return dealsData;
  },
);

export const validateDealWithForm = createAsyncThunk<
  ValidateDataResponse,
  { viewId: string; data: Record<string, any> },
  { rejectValue: string[] }
>('deal-records/validate-with-form', async ({ viewId, data }, thunkApi) => {
  // views{viewId}/deals

  const browserId = getBrowserId(thunkApi.getState() as RootState);
  try {
    const resp = await API.post(
      'Views', // function defined in our serverless.yml
      `${viewId}/deals?validate=true`, // the function's path
      { responseType: 'json', body: data },
    );
    return resp;
  } catch (error) {
    return (error as any).response.data;
  }
});
export const createDealWithForm = createAsyncThunk<
  ValidateDataResponse | DealRecord,
  { viewId: string; data: Record<string, any> },
  { rejectValue: string[] }
>('deal-records/create-with-form', async ({ viewId, data }, thunkApi) => {
  // views{viewId}/deals

  const browserId = getBrowserId(thunkApi.getState() as RootState);
  const resp = await API.post(
    'Views', // function defined in our serverless.yml
    `${viewId}/deals`, // the function's path
    { responseType: 'json', body: data },
  );

  return resp;
});

export const updateDealRecordByField = createAsyncThunk<
  DealRecord,
  { dealId: string; fieldId: string; value: string | any },
  { rejectValue: string[] }
>('deal-records/updateByField', async ({ dealId, fieldId, value }, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const dealRecord = state.deals.deals[dealId];
  const { fields } = dealRecord;
  const updatedFields = { ...fields, [fieldId]: value };
  const dealsData = await API.post(
    'Deals', // function defined in our serverless.yml
    dealId, // the function's path
    { responseType: 'json', body: { fields: updatedFields } },
  );
  return dealsData;
});

export const updateDealRecord = createAsyncThunk<DealRecord, DealRecord, { rejectValue: string[] }>(
  'deal-records/update',
  async (dealRecord, thunkApi) => {
    const { dealId, fields } = dealRecord;
    const dealsData = await API.post(
      'Deals', // function defined in our serverless.yml
      dealId, // the function's path
      { responseType: 'json', body: { fields } },
    );
    return dealsData;
  },
);

export const deleteDealRecord = createAsyncThunk<DealRecord, DealRecord, { rejectValue: string[] }>(
  'deal-records/delete',
  async (dealRecord, thunkApi) => {
    const dealsData = await API.del(
      'Deals', // function defined in our serverless.yml
      dealRecord.dealId, // the function's path
      { responseType: 'json', body: {} },
    );
    return dealsData;
  },
);

export const realTimeDealUpdated = createAction<{ deal: DealRecord }>('deal.updated');
export const realTimeDealDeleted = createAction<{ deal: DealRecord }>('deal.deleted');
export const realTimeDealCreated = createAction<{ deal: DealRecord }>('deal.created');
