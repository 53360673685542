import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find as _find, map as _map, filter as _filter } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { remove } from '../../../utils/array';
import './Select.scss';

interface SelectProps {
  value: string | number | string[];
  onChange: (newValue: string | number | string[]) => void;
  multiple?: boolean;
  options?: { value: string; label: string }[];
  allowEmpty?: boolean;
}

export const Select = ({ value, onChange, multiple = false, options = [], allowEmpty = true }: SelectProps) => {
  const [internalValue, setInternalValue] = useState(value);
  const onChangeInternal = useCallback(
    (e) => {
      if (!!multiple) {
        const newValues = [...e.target.options].filter(({ selected }) => selected).map(({ value }) => value);
        setInternalValue(newValues);
        onChange(newValues);
      } else {
        const newValue = e.target.value;
        if (newValue === '--') {
          setInternalValue('--');
          onChange('');
        } else {
          setInternalValue(newValue);
          onChange(newValue);
        }
      }
    },
    [onChange, multiple],
  );
  const clickedOption = useCallback(
    (clickedValue: string) => {
      const getNewVal = (val:any, optionValue:any) => {
        if (val === undefined) {
          if (multiple) {
            return [optionValue];
          } else {
            return optionValue;
          }
        }
        if (multiple) {
          const currIdx = (val as string[]).indexOf(optionValue);
          if (currIdx !== -1) {
            return remove(val as string[], currIdx);
          }
          return [...(val as string[]), optionValue];
        }
        return optionValue;
      }
      const newVal = getNewVal(internalValue, clickedValue);
      setInternalValue(newVal);
      onChange(newVal);

    },
    [internalValue, multiple, onChange],
  );
  const displayValue = useMemo(() => {
    if (internalValue === undefined) {
      return 'Select an option';
    }
    if (multiple) {
      const selected = _filter(options, (opt) => (internalValue as string[]).indexOf(opt.value) !== -1) as {
        value: string;
        label: string;
      }[];
      if (selected !== undefined && selected.length > 0) {
        return selected.map((opt) => opt.label).join(', ');
      }
    } else {
      const selected = _find(options, { value: internalValue }) as { value: string; label: string };
      if (selected !== undefined) {
        return selected.label;
      }
    }
    return 'Select an option';
  }, [internalValue, multiple, options]);
  useEffect(() => {
    if (value !== internalValue) {
      if (multiple) {
        if (typeof value === 'string') {
          setInternalValue([value]);
        } else {
          setInternalValue(value);
        }
      } else {
        setInternalValue(value);
      }
    }
  }, [value, multiple, internalValue]);
  useEffect(() => {
    if (value !== internalValue) {
      //onChange(internalValue);
    }
  }, [internalValue, value, onChange]);
  return (
    <div className="tc-select">
      <select
        multiple={multiple}
        className="selectpicker"
        value={internalValue}
        onChange={onChangeInternal}
        tabIndex={-1}
      >
        {!multiple && allowEmpty && <option value="--">--</option>}
        {options.map((selectOption) => (
          <option key={selectOption.value} value={selectOption.value}>
            {selectOption.label}
          </option>
        ))}
      </select>
      <Dropdown autoClose={multiple ? 'outside' : undefined}>
        <Dropdown.Toggle variant="btn btn-outline-secondary btn-sm" id="dropdown-basic">
          {displayValue}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: 200, overflow: 'scroll' }}>
          {options.map((selectOption) => (
            <Dropdown.Item key={selectOption.value} onClick={() => clickedOption(selectOption.value)}>
              <div className="d-flex flex-row justify-content-between">
                <div>{selectOption.label}</div>
                {internalValue !== undefined && selectOption.value === internalValue && (
                  <FontAwesomeIcon icon={faCheck} />
                )}
                {internalValue !== undefined &&
                  Array.isArray(internalValue) &&
                  (internalValue as string[]).indexOf(selectOption.value) !== -1 && <FontAwesomeIcon icon={faCheck} />}
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Select;
