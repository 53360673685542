import {
  faEnvelope,
  faEnvelopeSquare,
  faKeyboard,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMoneyBill,
  faPersonBooth,
  faRocket,
  faShoppingCart,
  faSpaceShuttle,
  faTags,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { deleteCompanyLink } from '../../../data/company/companyActions';
import { getCompany } from '../../../data/company/selectors';
import {
  getLastSelectedView,
  getLastSelectedViewIdForContentType,
} from '../../../data/content-type/record-views/selectors';
import { getUnreadNotificationsCount } from '../../../data/notifications/selectors';
import { useAppDispatch } from '../../../data/store';
import { getUser } from '../../../data/user/selectors';
import { getLastSelectedViewId } from '../../../data/views/selectors';
import AddLinkButton from './AddLinkButton';
import { CollapseNavItem } from './CollapseNavItem';

export const SideBar = () => {
  const dispatch = useAppDispatch();
  const company = useSelector(getCompany)!;
  const user = useSelector(getUser);
  const lastViewId = useSelector(getLastSelectedViewId)!;
  const { contact = '', emailConversation = '' } = useSelector(getLastSelectedViewIdForContentType);
  const notificationsCount = useSelector(getUnreadNotificationsCount);
  const { links } = company || { links: [] };
  const maybeDeleteRow = useCallback(
    (e: any, index: number) => {
      e.preventDefault();
      // eslint-disable-next-line no-restricted-globals
      const shouldDelete = confirm('Delete this saved link?');
      if (shouldDelete) {
        dispatch(deleteCompanyLink(index));
      }
    },
    [dispatch],
  );
  return (
    <nav id="sidebarMenu" className="col-md-3 col-lg-2 col-xl-2 col-xxl-2 d-md-block bg-light sidebar collapse">
      <div className="position-sticky h-100 d-flex flex-column pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink
              className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
              aria-current="page"
              to="/notifications"
            >
              <FontAwesomeIcon fixedWidth className="me-2" icon={faEnvelopeSquare} />
              Notifications
              {notificationsCount > 0 && (
                <span className="ms-1 flex-shrink-0 badge rounded-pill bg-danger">
                  {notificationsCount}
                  <span className="visually-hidden">unread messages</span>
                </span>
              )}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
              aria-current="page"
              to="/"
            >
              <FontAwesomeIcon fixedWidth className="me-2" icon={['fas', 'chart-line']} />
              KPIs
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
              to={`/emails/${emailConversation}`}
            >
              <FontAwesomeIcon fixedWidth className="me-2" icon={faEnvelope} />
              Email Inbox
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
              to={`/contact/${contact}`}
            >
              <FontAwesomeIcon fixedWidth className="me-2" icon={faUsers} />
              Contacts
            </NavLink>
          </li>
          {user && user.name && (
            <>
              {user.email === 'harrison@taidacorp.com' && (
                <>
                  <li className="nav-item">
                    <CollapseNavItem
                      to={`/marketing/`}
                      buttonChild={
                        <>
                          <FontAwesomeIcon fixedWidth className="me-2" icon={faRocket} />
                          Marketing
                        </>
                      }
                    >
                      <NavLink
                        className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
                        to={`/marketing/campaigns`}
                      >
                        <FontAwesomeIcon fixedWidth className="me-2" icon={faMap} />
                        Campaigns
                      </NavLink>
                      <NavLink
                        className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
                        to={`/marketing/templates`}
                      >
                        <FontAwesomeIcon fixedWidth className="me-2" icon={faKeyboard} />
                        Templates
                      </NavLink>
                    </CollapseNavItem>
                  </li>
                </>
              )}
            </>
          )}
          <li className="nav-item">
            <NavLink
              className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
              to={lastViewId ? `/deals/${lastViewId}` : '/deals'}
            >
              <FontAwesomeIcon fixedWidth className="me-2" icon={['fas', 'clipboard-check']} />
              Dealflow
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} to="/me">
              <FontAwesomeIcon fixedWidth className="me-2" icon={faUser} />
              Me
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink className="nav-link" to="marketing">
              <FontAwesomeIcon fixedWidth className="me-2" icon={['fas', 'rocket']} />
              Marketing
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="payment">
              <FontAwesomeIcon fixedWidth className="me-2" icon={['fas', 'money-check']} />
              Payments
            </NavLink>
          </li> */}
        </ul>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Assets and Resources</span>
          <AddLinkButton />
        </h6>
        <ul className="nav flex-column mb-2">
          {links.map((savedLink, savedLinkIndex) => (
            <li key={`${savedLink.uri}-${savedLink.name}`} className="nav-item">
              <a
                className="nav-link text-primary"
                target="_blank"
                rel="noreferrer"
                href={savedLink.uri}
                onContextMenu={(e) => maybeDeleteRow(e, savedLinkIndex)}
              >
                <FontAwesomeIcon fixedWidth className="me-2" icon={['fas', 'link']} />
                {savedLink.name}
              </a>
            </li>
          ))}
        </ul>
        <div className="d-flex flex-column flex-grow-1 justify-content-end  mb-2">
          <ul className="nav flex-column">
            {user && user.name && (
              <>
                {user.email === 'harrison@taidacorp.com' && (
                  <>
                    <NavLink
                      className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
                      to={'/content-types/'}
                    >
                      <FontAwesomeIcon fixedWidth className="me-2" icon={faRocket} />
                      Content Types
                    </NavLink>
                    <NavLink
                      className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')}
                      to={'/content-type-form/'}
                    >
                      <FontAwesomeIcon fixedWidth className="me-2" icon={faRocket} />
                      Content Type Form
                    </NavLink>
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SideBar;
