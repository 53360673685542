import { debounce as _debounce } from 'lodash';
import { useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";

import { getUser, getUserIsInitialized, getUserIsLoading } from '../../data/user/selectors';
import { useAppDispatch } from '../../data/store';
import { setUser, fetchMe } from '../../data/user/actions';
import { setToken, fetchToken } from '../../data/realTime/actions';
import { fetchUsers } from '../../data/users/actions';

export const useAuth = () => {
  const user = useSelector(getUser);
  const isLoading = useSelector(getUserIsLoading);
  const isInitialized = useSelector(getUserIsInitialized);
  const dispatch = useAppDispatch();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          dispatch(fetchMe());
          dispatch(fetchToken());
          dispatch(fetchUsers());
          break;
        case 'signOut':
          dispatch(setUser(undefined));
          dispatch(setToken(undefined));
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });
    dispatch(fetchToken());
    dispatch(fetchMe());
    dispatch(fetchUsers());
  }, [dispatch]);

  const signin = useCallback(
    () => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google}),
    []
  );

  const signout = useCallback(
    () => Auth.signOut(),
   []);

  useEffect(() => {
    if (!isLoading && !isInitialized && user === undefined) {
      // get user??
    }
  }, [user, isInitialized, isLoading]);
  useEffect(() => {
    if (user !== undefined) {
      // dispatch(fetchMe());
    }
  }, [user, dispatch]);

  return {
    user,
    isInitialized,
    isLoading,
    signin,
    signout,
  };
};

export default useAuth;
