import { values as _values, orderBy as _orderBy, keyBy as _keyBy } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const getUsersState = (state: RootState) => state.users;

export const getUsers = createSelector(getUsersState, (state) => state.users);
export const getUsersIsInitialized = createSelector(getUsersState, (state) => state.isInitialized);
export const getUsersIsLoading = createSelector(getUsersState, (state) => state.isLoading);

export const getUsersDict = createSelector(getUsers, (users) => {
  return _keyBy(users, 'userId');
})

export const getSelectUsersOptions = createSelector(getUsers, (users) => {
  return users.map((user) => {
    return {
      value: user.userId,
      label: user.name ?? user.email,
    };
  }) as { value: string; label: string }[];
});
