import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Property } from './slice';
import { API } from 'aws-amplify';



export const fetchProperties = createAsyncThunk<Property[], undefined, { rejectValue: string[] }>(
    'properties/fetchProperties',
    async () => {
      try {
        const propertiesData = await API.get(
          'Properties', // function defined in our serverless.yml
          '',            // the function's path
          { 'responseType': 'json' }
        );
        return propertiesData;
    } catch (error) {
        console.log(error);
      }
    },
  );
  