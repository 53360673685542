import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { updateDealRecordByField } from '../../../../data/deals/actions';
import { getSelectedViewDealIds, getSelectedViewDealsWithFieldNameKeys } from '../../../../data/deals/selectors';
import { useAppDispatch } from '../../../../data/store';
import { exportCSVFile } from '../../../../utils/csvExporter';
import { throttleActions } from '../../../../utils/throttleActions';

export const DealExport = () => {
  const selectedDeals = useSelector(getSelectedViewDealsWithFieldNameKeys);
  const dealIds = useSelector(getSelectedViewDealIds);
  const dispatch = useAppDispatch();

  const onDownload = useCallback(() => {
    exportCSVFile(selectedDeals, 'deals')
  }, [selectedDeals])
  return (
    <div tabIndex={0} className="deals-toolbar__item" onClick={onDownload}>
      <FontAwesomeIcon className="me-2" icon={faFileDownload} />
      Download CSV
    </div>
  );
};

export default DealExport;
