import {
  Attachment,
  FieldAttachment,
  isAttachmentArray,
  isAudioAttachment,
  isImageAttachment,
  isStringArray,
  isVideoAttachment,
} from '@taida-corp/taidacorp-sdk';
import React, { useCallback, useMemo } from 'react';
import { RecordInputComponentProps } from '../base';
import AudioAttachmentView from './AttachmentAssetViews/AudioAttachmentView';
import FileAttachmentView from './AttachmentAssetViews/FileAttachmentView';
import ImageAttachmentView from './AttachmentAssetViews/ImageAttachmentView';
import VideoAttachmentView from './AttachmentAssetViews/VideoAttachmentView';
import AttachmentInlineUploader from './AttachmentInlineUploader';

export const AttachmentInputComponent = ({
  field: _field,
  value: persistedValue,
  onChange,
  extraProps = {},
}: RecordInputComponentProps) => {
  const field = useMemo(() => _field as FieldAttachment, [_field]);
  const errors = useMemo(() => {
    if (persistedValue === undefined || !isStringArray(persistedValue.errors)) {
      return [] as string[];
    }
    return persistedValue.errors;
  }, [persistedValue]);
  const value = useMemo(() => {
    if (persistedValue === undefined || !isAttachmentArray(persistedValue.internalValue)) {
      return [] as Attachment[];
    }
    return persistedValue.internalValue;
  }, [persistedValue]);

  const canUploadMore = useMemo(() => {
    if (!!field.config.maxNumberOfFiles && value.length >= field.config.maxNumberOfFiles) {
      return false;
    }
    return true;
  }, [field, value]);

  const removeAttachmentAtIndex = useCallback(
    (index: number) => {
      if (value.length === 0) {
        return;
      }
      const _attachments = Array.from(value);
      _attachments.splice(index, 1);
      onChange({ internalValue: _attachments });
    },
    [onChange, value],
  );

  const addAttachments = useCallback(
    (newAttachments: Attachment[]) => {
      if (newAttachments.length === 0) {
        return;
      }
      const _attachments = Array.from([...value, ...newAttachments]);
      onChange({ internalValue: _attachments });
    },
    [onChange, value],
  );

  return (
    <>
      <div>
        {value.map((attachment, index) => {
          if (isImageAttachment(attachment)) {
            return (
              <div key={attachment.original.original_id} className="mb-3">
                <ImageAttachmentView attachment={attachment} onDelete={() => removeAttachmentAtIndex(index)} />
              </div>
            );
          }
          if (isVideoAttachment(attachment)) {
            return (
              <div key={attachment.original.original_id} className="mb-3">
                <VideoAttachmentView attachment={attachment} onDelete={() => removeAttachmentAtIndex(index)} />
              </div>
            );
          }
          if (isAudioAttachment(attachment)) {
            return (
              <div key={attachment.original.original_id} className="mb-3">
                <AudioAttachmentView attachment={attachment} onDelete={() => removeAttachmentAtIndex(index)} />
              </div>
            );
          }
          return (
            <div key={attachment.original.original_id} className="mb-3">
              <FileAttachmentView attachment={attachment} onDelete={() => removeAttachmentAtIndex(index)} />
            </div>
          );
        })}
        {canUploadMore && (
          <div>
            <AttachmentInlineUploader onComplete={addAttachments} field={field} />
          </div>
        )}
      </div>

      {errors.map((err) => (
        <div key={err} className="mt-1">
          <small className="text-danger">{err}</small>
        </div>
      ))}
    </>
  );
};

export default AttachmentInputComponent;
