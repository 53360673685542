import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { faTimesCircle, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find as _find } from 'lodash';
import { Field, SORT_OPTIONS} from '@taida-corp/taidacorp-sdk';
import { FieldSortAscendingComponents, FieldSortDescendingComponents } from '../Fields';



interface SortedFieldRowProps {
  updateSortingField: (newField: string, newOrder: 'asc' | 'desc', index: number) => void;
  removeSortingField: (index: number) => void;
  fields: Field[];
  availableFields: Field[];
  field: string;
  index: number;
  ordering: SORT_OPTIONS[];

}

export const SortedFieldRow = ({
  fields,
  availableFields,
  field,
  index,
  ordering,
  updateSortingField,
  removeSortingField,
  
}: SortedFieldRowProps) => {
  const fieldObj = useMemo(() => _find(fields, { fieldId: field }) as Field, [field, fields]);
  const order = useMemo(() => ordering[index], [index, ordering]);
  const [internalOrder, setInternalOrder] = useState(order);
  useEffect(() => {
    setInternalOrder(order);
  }, [order]);


  const changeSortingField = useCallback(
    (e: any) => {
      const newFieldId = e.target.value;
      updateSortingField(newFieldId, internalOrder, index);
    },
    [index, internalOrder, updateSortingField],
  );
  const AscComponent = FieldSortAscendingComponents[fieldObj!.type];
  const DescComponent = FieldSortDescendingComponents[fieldObj!.type];
  return (
    <div className="view-sort__current-fields-list__item">
      <div className="remove-sorted-field" onClick={() => removeSortingField(index)}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </div>
      <div className="sorted-field-row-lead">{index === 0 ? 'Sort by' : 'then by'}</div>
      <div className="change-sorted-field me-1">
      <select className='form-select form-select-sm' value={field} onChange={changeSortingField}>
            {fields.map((fieldOption) => (
              <option value={fieldOption.fieldId}>{fieldOption.name}</option>
            ))}
          </select>
      </div>
      <div className="toggle-direction-sorted-field d-flex flex-row align-items-center ms-1">
        <AscComponent
          isSelected={internalOrder === 'asc'}
          setSelected={() => {
            setInternalOrder('asc');
            updateSortingField(field, 'asc', index);
          }}
        />
        <DescComponent
          isSelected={internalOrder === 'desc'}
          setSelected={() => {
            setInternalOrder('desc');
            updateSortingField(field, 'desc', index);
          }}
        />
      </div>
      <div className="reorder-sorted-field-handle">
        <FontAwesomeIcon icon={faGripVertical} />
      </div>
    </div>
  );
};

export default SortedFieldRow;
