import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import React, { useCallback } from 'react';
import SelectConfigOptionsList from './SelectConfigOptionsList';
import { FieldSelect, FieldSelectConfig } from '@taida-corp/taidacorp-sdk';

interface SelectConfigEditorProps {
  field: FieldSelect;
  internalConfig: FieldSelectConfig;
  onChangeConfig: (e: any) => void;
}

export const SelectConfigEditor = ({ field, internalConfig:config, onChangeConfig }: SelectConfigEditorProps) => {
  const { defaultValue = '', options = [] } = config;
  const onChangeDefaultValue = useCallback(
    (e: any) => {
      const newVal = e.target.value;
      onChangeConfig({
        options: config.options,
        defaultValue: newVal,
      });
    },
    [config, onChangeConfig],
  );
  const onChangeOptions = useCallback(
    (newOptions: any) => {
      onChangeConfig({
        options: newOptions,
        defaultValue,
      });
    },
    [defaultValue, onChangeConfig],
  );

  return (
    <>
      <input
        type="text"
        className="form-control"
        placeholder="Default Select Value"
        value={defaultValue || ''}
        onChange={onChangeDefaultValue}
      />
      <hr />
      <DndProvider backend={HTML5Backend}>
        <SelectConfigOptionsList onChangeOptions={onChangeOptions} options={options} />
      </DndProvider>
    </>
  );
};

export default SelectConfigEditor;
