

export const Step5 = (props:any) => {

    return (
        <div>
            Verify extra settings like expiration and notifications <button onClick={props.next}>Send</button>
        </div>
    )
}

export default Step5;