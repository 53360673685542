import React, { useCallback } from 'react';
import ViewPicker from './ViewPicker/ViewPicker';
import ViewProperties from './ViewProperties';

import './DealsToolbar.scss';
import ViewAutomations from './ViewAutomations/ViewAutomations';

interface DealsToolbarProps {
  toggleAutomationPanel:Function;
  setIsEditingGroupingField:any;
}

export const DealsToolbar = ({toggleAutomationPanel, setIsEditingGroupingField}: DealsToolbarProps) => {
  
  return (
    <div className="deals-toolbar">
      <ViewPicker />
      <ViewProperties setIsEditingGroupingField={setIsEditingGroupingField} />
      <div className='flex-grow-1'/>
      <ViewAutomations toggleAutomationPanel={toggleAutomationPanel} />
    </div>
  );
};

export default DealsToolbar;
