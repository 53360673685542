import React, { useEffect } from 'react';
import useAuth from '../../hooks/auth/useAuth';
import Amplify, { API } from 'aws-amplify';

export const Dashboard = () => {
    const {signout} = useAuth();
    const get = async () => {
        await API.get(
          'Admin', // function defined in our serverless.yml
          'info',            // the function's path
          { 'responseType': 'text' }
        );
      };
    useEffect(() => {
        get();
    }, [])
    return (
        <div>Some stuff here</div>
    )
};

export default Dashboard;