import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { MarketingTemplate } from '../../types';
import { sendMarketingTemplatePreview, fetchMarketingTemplates, createMarketingTemplate, updateMarketingTemplate, realTimeMarketingTemplateCreated, realTimeMarketingTemplateDeleted, realTimeMarketingTemplateUpdated, setSelectedMarketingTemplateId, fetchMarketingTemplate} from './actions';


interface MarketingTemplatesState {
  marketingTemplates: Record<string, MarketingTemplate>;
  isLoading: boolean;
  isInitialized: boolean;
  isSendingPreview: boolean;
  isSaving: boolean;
  selectedMarketingTemplateId: string|undefined;
}

const initialState: MarketingTemplatesState = {
 marketingTemplates: {},
  isLoading: false,
  isInitialized: false,
  isSendingPreview:false,
  isSaving:false,
  selectedMarketingTemplateId: undefined,
};

export const marketingTemplatesSlice = createSlice({
  name: 'marketingTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedMarketingTemplateId, (state, { payload }) => {
      state.selectedMarketingTemplateId = payload;
    });
    builder.addCase(fetchMarketingTemplates.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMarketingTemplates.fulfilled, (state, { payload }) => {
      state.marketingTemplates = keyBy(payload, 'marketingTemplateId');
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchMarketingTemplates.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });

    builder.addCase(fetchMarketingTemplate.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMarketingTemplate.fulfilled, (state, { payload }) => {

      state.marketingTemplates =  {...state.marketingTemplates, [payload.marketingTemplateId]: payload};
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchMarketingTemplate.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(createMarketingTemplate.pending, (state, { payload }) => {
      state.isSaving = true;
    });
    builder.addCase(createMarketingTemplate.fulfilled, (state, { payload }) => {

      state.marketingTemplates =  {...state.marketingTemplates, [payload.marketingTemplateId]: payload};
      state.selectedMarketingTemplateId = payload.marketingTemplateId;
      state.isSaving = false;
    });
    builder.addCase(createMarketingTemplate.rejected, (state, { payload }) => {
      state.isSaving = false;
    });
    builder.addCase(updateMarketingTemplate.pending, (state, { payload }) => {
        state.isSaving = true;
      });
      builder.addCase(updateMarketingTemplate.fulfilled, (state, { payload }) => {
  
        state.marketingTemplates =  {...state.marketingTemplates, [payload.marketingTemplateId]: payload};
        state.isSaving = false;
      });
      builder.addCase(updateMarketingTemplate.rejected, (state, { payload }) => {
        state.isSaving = false;
      });
    builder.addCase(sendMarketingTemplatePreview.pending, (state, { payload }) => {
        state.isSendingPreview = true;
      });
      builder.addCase(sendMarketingTemplatePreview.fulfilled, (state, { payload }) => {
  
        state.marketingTemplates =  {...state.marketingTemplates, [payload.marketingTemplateId]: payload};
        state.isSendingPreview = false;
      });
      builder.addCase(sendMarketingTemplatePreview.rejected, (state, { payload }) => {
        state.isSendingPreview = false;
      });

    builder.addCase(realTimeMarketingTemplateUpdated, (state, { payload }) => {
      const { marketingTemplate: message } = payload;
      state.marketingTemplates = {...state.marketingTemplates, [message.marketingTemplateId]: message};
    });
    builder.addCase(realTimeMarketingTemplateCreated, (state, { payload }) => {
      const { marketingTemplate: message } = payload;
      state.marketingTemplates = {...state.marketingTemplates, [message.marketingTemplateId]: message};
    });
    builder.addCase(realTimeMarketingTemplateDeleted, (state, { payload }) => {
      const { marketingTemplate: message } = payload;
      delete state.marketingTemplates[message.marketingTemplateId];
    });
  },
});

export default marketingTemplatesSlice;
