import { faDownload, faFileDownload, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecordView } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSelectedContentType } from '../../../../data/content-type/content-types/selectors';
import { updateRecordView } from '../../../../data/content-type/record-views/actions';
import { getSelectedViewRecordsWithFieldNameKeys } from '../../../../data/content-type/records/selectors';
import { useAppDispatch } from '../../../../data/store';
import { exportCSVFile } from '../../../../utils/csvExporter';
import ToolbarToggleButton from '../ToolbarToggleButton';

interface TableExporterToolProps {
  currentView: RecordView;
}
export const TableExporterTool = ({ currentView }: TableExporterToolProps) => {
    const contentType = useSelector(getSelectedContentType);
    const selectedRecords = useSelector(getSelectedViewRecordsWithFieldNameKeys);

  const onDownload = useCallback(() => {
    if(selectedRecords.length === 0 || contentType === undefined) {
        return;
    }
    console.log('selectedRecords', selectedRecords);
    exportCSVFile(selectedRecords, contentType.name)
  }, [contentType, selectedRecords])
  return (
    <div tabIndex={0} className="deals-toolbar__item" onClick={onDownload}>
      <FontAwesomeIcon className="me-2" icon={faFileDownload} />
      Download Table
    </div>
  );
    
};

export default TableExporterTool;
