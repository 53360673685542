import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DealDetailModal from '../../components/Views/DetailModal/DealDetailModal';
import { getCompany } from '../../data/company/selectors';
import { setSelectedDealId } from '../../data/deals/actions';
import { getSelectedDeal } from '../../data/deals/selectors';
import { useAppDispatch } from '../../data/store';

export const DealDetailPage = (props: any) => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const { dealId, viewId } = useParams<{ dealId: string; viewId: string }>();
  const selectedDeal = useSelector(getSelectedDeal);
  const company = useSelector(getCompany);
  const fields = useMemo(() => {
    if(company !== undefined) {
        return company.fields;
    }
    return [];
  }, [company])
  const navigate = useNavigate();
  const back = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      if(viewId !== undefined) {
        navigate(`/deals/${viewId}`);
      } else {
        navigate(`/notifications`);
      }
      dispatch(setSelectedDealId(undefined));
    }, 100);
  }, [navigate, viewId, dispatch]);
  useEffect(() => {
    if (selectedDeal === undefined || selectedDeal.dealId !== dealId) {
      dispatch(setSelectedDealId(dealId));
    }
  }, [selectedDeal, dealId, dispatch]);

  if(selectedDeal === undefined || selectedDeal.dealId !== dealId) {
    return (
        <Modal size="xl" fullscreen="sm-down" show={isVisible} onHide={back} keyboard={false}>
          <Modal.Header className="d-sm-none" closeButton>
            <Modal.Title>Loading Deal</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            Loading...
          </Modal.Body>
        </Modal>
      );
  }

  return (
    <DealDetailModal deal={selectedDeal} fields={fields} isVisible={isVisible} back={back} />
  );
};

export default DealDetailPage;
