import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import ViewSort from './ViewSort/ViewSort';
import ViewCardCustomizer from './ViewCardCustomizer/ViewCardCustomizer';
import { useAppDispatch } from '../../../data/store';
import { DealView } from '../../../data/types';
import ViewFilter from './ViewFilter/ViewFilter';
import TableColumnCustomizer from './TableColumnCustomizer/TableColumnCustomizer';
import ViewName from './ViewName/ViewName';
import { updateDealView } from '../../../data/views/actions';
import { getSelectedView } from '../../../data/views/selectors';
import DealExport from './DealExport/DealExport';

export const ViewProperties = ({ setIsEditingGroupingField }: any) => {
  const dispatch = useAppDispatch();
  const selectedView = useSelector(getSelectedView);
  const { type } = selectedView || {};
  const updateViewProperties = useCallback(
    (updatedProperties: any) => {
      const updatedView = {
        ...selectedView,
        properties: updatedProperties,
      } as DealView;
      dispatch(updateDealView(updatedView));
    },
    [dispatch, selectedView],
  );

  return (
    <div className="d-flex flex-row align-items-center">
      <ViewName />
      {type === 'table' && (
        <>
          <TableColumnCustomizer />
        </>
      )}
      {type === 'kanban' && (
        <>
          <div tabIndex={0} onClick={() => setIsEditingGroupingField(true)} className="btn btn-sm deal-toolbar__item">
            Configure Board
          </div>
          <ViewCardCustomizer />
        </>
      )}
      {type === 'gallery' && (
        <>
          <ViewCardCustomizer />
        </>
      )}
      {(type === 'table' || type === 'kanban' || type === 'gallery') && (
        <>
          <ViewFilter />
          <ViewSort />
        </>
      )}
       {(type === 'table') && (
        <>
          <DealExport />
        </>
      )}
    </div>
  );
};

export default ViewProperties;
