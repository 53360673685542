import { filter as _filter, map as _map } from 'lodash';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createContact, setSelectedContactId } from '../../../data/contacts/actions';
import { useAppDispatch } from '../../../data/store';
import {
  getEnrichedSelectedRecord,
  getRecordFormCleanedData,
  getRecordIsSaving,
  getSelectedRecordId,
} from '../../../data/content-type/records/selectors';
import { DataRecord, FormView, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import { clearNewRecordFormResp, createDataRecord, setSelectedRecordId } from '../../../data/content-type/records/actions';
import { getSelectedContentTypeAdminNewRecordForm } from '../../../data/content-type/record-views/selectors';
import CreateRecordFormView from '../record-views/form-view/CreateRecordFormView';

interface NewContentModalProps {
  contentTypeSlug: string;
  newContentForm: FormView;
  isVisible: boolean;
  back: () => void;
}
export const NewContentModal = ({ contentTypeSlug, newContentForm, isVisible, back }: NewContentModalProps) => {
  const dispatch = useAppDispatch();
  const [shouldKeepOpen, setShouldKeepOpen] = useState(false);
  const isSaving = useSelector(getRecordIsSaving);
  const selectedRecord = useSelector(getEnrichedSelectedRecord);
  const newContactForm = useSelector(getSelectedContentTypeAdminNewRecordForm);
  const formData = useSelector(getRecordFormCleanedData);
  const { viewId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const onChangeShouldKeepOpen = useCallback((e) => {
    setShouldKeepOpen(e.target.checked);
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(createDataRecord({ contentTypeSlug }));
    },
    [dispatch, contentTypeSlug],
  );

  useEffect(() => {
    if (selectedRecord !== undefined) {
      const parts = location.pathname.split('/');
      const indexOfEdit = parts.indexOf('edit');
      const path = parts.slice(0, indexOfEdit > 0 ? indexOfEdit : undefined).join('/') + `/edit/${selectedRecord.id}`;
      if(shouldKeepOpen) {
        dispatch(clearNewRecordFormResp());
      } else {
        dispatch(clearNewRecordFormResp());
        navigate(path, { replace: true });
      }
    }
  }, [dispatch, location.pathname, navigate, selectedRecord, shouldKeepOpen]);

  return (
    <Modal size="xl" fullscreen="lg-down" show={isVisible} onHide={back} keyboard={false}>
      <Modal.Header className="d-lg-none" closeButton>
        <Modal.Title>{newContentForm.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row m-0 border-bottom">
          <div className="py-3 col-12">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>
                <div>New Content</div>
                <div className='d-flex flex-row align-items-center'>
                  <input
                    id="should-keep-open"
                    type="checkbox"
                    checked={shouldKeepOpen}
                    onChange={onChangeShouldKeepOpen}
                  />{' '}
                  <label htmlFor="should-keep-open" className="ms-1">
                    Keep new content form open after saving
                  </label>
                </div>
              </div>
              <div>
                {isSaving && (
                  <div>
                    Saving... <FontAwesomeIcon icon={faCog} title={'Saving Content'} className={'fa-spin'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row px-0 m-0" style={{ minHeight: 'calc(100vh - 200px)' }}>
          <div className="col-12 p-3">
            <CreateRecordFormView formView={newContentForm} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewContentModal;
