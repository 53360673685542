import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { getToken } from '../../data/realTime/selectors';
import { connect } from '../../data/realTime/actions';

export const useWebSocket = () => {
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token !== undefined && dispatch) {
      dispatch(connect({ url: `wss://realtime.taidacorp.com?authorization=${token}` }));
    }
  }, [dispatch, token]);
};

export default useWebSocket;
