import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { fetchCompany } from '../../data/company/companyActions';
import { fetchContentTypes } from '../../data/content-type/content-types/actions';
import { fetchDealRecords } from '../../data/deals/actions';
import { fetchNotifications } from '../../data/notifications/actions';
import { useAppDispatch } from '../../data/store';
import { fetchViews } from '../../data/views/actions';
import useWebSocket from '../../hooks/useWebSocket/useWebSocket';
import SignIn from '../../pages/anonymous/SignIn/SignIn';
import { ContentTypeForm } from '../../pages/content-type-form/ContentTypeForm';
import ContentTypeRoutes from '../../pages/content-types/ContentTypeRoutes';
import ContentTypesList from '../../pages/content-types/ContentTypesList';
import Dashboard from '../../pages/dashboard/Dashboard';
import Properties from '../../pages/properties/Properties';
import AddPropertyForm from '../../pages/property-form/AddPropertyForm';
import PropertyForm from '../../pages/property-form/UpdatePropertyForm';
import PropertyListing from '../../pages/property-listing/PropertyListing';
import SideBar from '../components/SideBar/SideBar';
import TopBar from '../components/TopBar/TopBar';
import AccountRoutes from './AccountRoutes';
import ContactRoutes from './ContactRoutes';
import ContactRoutesOld from './ContactRoutesOld';
import ContentRoutes, { WrappedContentRoutes } from './ContentRoutes';
import DealsRoutes from './DealsRoutes';
import MarketingRoutes from './MarketingRoutes';
import NotificationsRoutes from './NotificationsRoutes';

export const MainRoutes = () => {
  useWebSocket();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchNotifications());
    // dispatch(fetchDealRecords());
    dispatch(fetchContentTypes());
    dispatch(fetchViews());
    dispatch(fetchCompany());
  }, [dispatch]);
  return (
    <>
      <TopBar />
      <div className="container-fluid h-100">
        <div className="row h-100">
          <SideBar />
          <main className="col-md-9 ms-sm-auto col-lg-10 col-xl-10 col-xxl-10 px-0 h-100">
            <Routes>
            <Route path="/content-type-form/" element={<ContentTypeForm />} />
            <Route path="/content-types/*" element={<ContentTypeRoutes />} />
            <Route path="/contact/*" element={<ContentRoutes contentTypeSlug='contact' />} />
            <Route path="/emails/*" element={<ContentRoutes contentTypeSlug='email-conversation' />} />
            <Route path="/marketing/*" element={<MarketingRoutes />} />
            <Route path="/deals/*" element={<DealsRoutes />} />
            <Route path="/notifications/*" element={<NotificationsRoutes />} />

              <Route path="/" element={<Dashboard />} />
              <Route path="/me/*" element={<AccountRoutes />} />

              <Route path="/auth" element={<Navigate to="/" />} />
              {/* <Route path="*" element={<Navigate to="/" />} /> */}

              <Route path="/:contentTypeSlug/*" element={<WrappedContentRoutes />} />
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
};

export default MainRoutes;
