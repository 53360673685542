import { forEach as _forEach } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllFieldsList } from '../../../data/company/selectors';
import { Field, FieldFormula, FieldFormulaConfig, FormulaDependency, TemplateEditorOption } from '../../../data/types';
import TemplateEditor from '../../TemplateEditor/TemplateEditor';

interface FormulaConfigEditorProps {
  field: FieldFormula;
  internalConfig: FieldFormulaConfig;
  onChangeConfig: (newConfig: FieldFormulaConfig) => void;
}

export const FormulaConfigEditor = ({ field, internalConfig: config, onChangeConfig }: FormulaConfigEditorProps) => {
  const allFields = useSelector(getAllFieldsList);
  // make sure we don't recursively use this field in the formula
  const templateOptions: TemplateEditorOption[] = useMemo(
    () => [
      {
        label: 'Deal',
        options: allFields
          .filter((val) => val.fieldId !== field.fieldId)
          .map(
            (field): FormulaDependency => ({
              identifier: `fields.${field.fieldId}.value`,
              label: field.name,
            }),
          ),
      },
      {
        label: 'Deal Meta',
        options: [
          {
            identifier: `deal.dealId`,
            label: 'Deal Id',
          },
          {
              identifier: `deal.createdDT`,
              label: 'Created DT',
            },
            {
              identifier: `deal.updatedDT`,
              label: 'Updated DT',
            }
          ]
      },
    ],
    [allFields, field.fieldId],
  );
  const onChange = useCallback((newTemplateString: string, newTemplateDependencies: FormulaDependency[]) => {
    onChangeConfig({
      template: {templateDependencies: newTemplateDependencies, templateSource: newTemplateString}
    })
  }, [onChangeConfig])
  return (
    <div>
      <div className="row">
        <div className="col-8">
          <div className="">Enter template</div>
          <TemplateEditor
            value={config.template ?? { templateDependencies: [], templateSource: '' }}
            onChange={onChange}
            templateOptions={templateOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default FormulaConfigEditor;
