import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../data/store';
import { Contact } from '../../../data/types';
import ContactActivity from './views/ContactActivity';
import ContactForm from './views/ContactForm';
import ContactSavedSearchList from './views/ContactSavedSearchList';
import ContactSavedSearchForm from './views/ContactSavedSearchForm';
import { EnrichedDataRecord, FormView } from '@taida-corp/taidacorp-sdk';
import { getEnrichedSelectedRecord, getRecordIsSaving } from '../../../data/content-type/records/selectors';

import './ContentDetailModal.scss';
import RecordFormView from '../record-views/form-view/UpdateRecordFormView';
import { getSelectedContentTypeAdminUpdateRecordForm } from '../../../data/content-type/record-views/selectors';
import { getSelectedContentType } from '../../../data/content-type/content-types/selectors';

interface ContentDetailModalProps {
  isVisible: boolean;
  back: any;
}

export const ContentDetailModal = ({ isVisible, back }: ContentDetailModalProps) => {
  const dispatch = useAppDispatch();
  const isSaving = useSelector(getRecordIsSaving);
  const record = useSelector(getEnrichedSelectedRecord)!;
  const contentType = useSelector(getSelectedContentType)

  const { contactId } = useParams<{ contactId: string }>();
  useEffect(() => {
    // dispatch(fetchContactActivities({ contactId }));
  }, [dispatch, contactId]);
  return (
    <Modal size="xl" fullscreen="lg-down" show={isVisible} onHide={back} keyboard={false}>
      <Modal.Header className="d-lg-none" closeButton>
        <Modal.Title>Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row m-0 border-bottom">
          <div className="py-3 col-12">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>{record.primaryFieldValue !== '' && record.primaryFieldValue !== undefined ? record.primaryFieldValue : record.id}</div>
              <div>
                {isSaving && (
                  <div>
                    Saving... <FontAwesomeIcon icon={faCog} title={'Saving Record'} className={'fa-spin'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0 border-bottom d-none hidden">
          <nav className="nav">
            <Link className="nav-link" to={`/contacts/${contactId}`}>
              Content
            </Link>
            {/* <Link className="nav-link" to={`/contacts/${contactId}/saved-searches`}>
              Saved Searches
            </Link>
            <Link className="nav-link" to={`/contacts/${contactId}/activity`}>
              Activity
            </Link> */}
          </nav>
        </div>
        <div className="row px-0 m-0" style={{ minHeight: 'calc(100vh - 200px)' }}>
          <div className="col-12 p-3">
            <Routes>
              <Route index element={<RecordFormView formId={contentType!.adminEditContentFormId!} record={record} />} />
              {/* <Route path="activity" element={<ContactActivity />} />
              <Route path="saved-searches" element={<ContactSavedSearchList contact={contact} />} />
              <Route path="saved-searches/new/" element={<ContactSavedSearchForm contact={contact} />} />
              <Route path="saved-searches/:savedSearchId" element={<Navigate to={`/contacts/${contact.contactId}/saved-searches`} replace />} /> */}
            </Routes>
          </div>
        </div>
      </Modal.Body>
      <Routes>
        <Route
          path="saved-searches"
          element={
            <Modal.Footer>
              <Link to="./new">New</Link>
            </Modal.Footer>
          }
        />
        {/* <Route path="saved-searches/new/" element={<div>Hooo</div>} /> */}
        <Route
          path="saved-searches/new/"
          element={
            <Modal.Footer>
              <Link to="../saved-searches/">Cancel</Link>
            </Modal.Footer>
          }
        />
      </Routes>
    </Modal>
  );
};

export default ContentDetailModal;
