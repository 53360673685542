import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DataRecord, FieldValue, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import { API } from 'aws-amplify';
import { getBrowserId } from '../../realTime/selectors';
import { RootState } from '../../store';
import { ValidateDataResponse } from '../../types';

export const setSelectedRecordId = createAction<string | undefined>('dataRecords/setSelectedRecordId');
export const setRecordsSearchTerm = createAction<string | undefined>('dataRecords/setRecordsSearchTerm');
export const clearNewRecordFormResp = createAction('dataRecords/clearNewRecordFormResp');
export const setRecordFormData = createAction<Record<string, PersistedFieldValue | undefined>>(
  'dataRecords/setRecordFormData',
);
export const setRecordFormFieldValue = createAction<{ fieldId: string; value: PersistedFieldValue }>(
  'dataRecords/setRecordFormFieldValue',
);

export const fetchDataRecords = createAsyncThunk<
  DataRecord[],
  { lastEvaluatedKey?: any; contentTypeSlug: string },
  { rejectValue: string[] }
>('dataRecords/fetchRecords', async ({ lastEvaluatedKey, contentTypeSlug }, getThunk) => {
  const params = lastEvaluatedKey !== undefined ? `?afterItem=${encodeURIComponent(JSON.stringify(lastEvaluatedKey))}` : '';
  const { records, meta } = (await API.get(
    'Content', // function defined in our serverless.yml
    `${contentTypeSlug}/records${params}`, // the function's path
    { responseType: 'json' },
  )) as { records: DataRecord[]; meta: any };

console.log('meta', meta);

  if (meta && meta.hasNext) {
    const { lastEvaluatedKey } = meta;
    getThunk.dispatch(fetchDataRecords({ lastEvaluatedKey, contentTypeSlug }));
  }
  return records;
});

export const createDataRecord = createAsyncThunk<DataRecord, { contentTypeSlug: string }, { rejectValue: string[] }>(
  'dataRecords/create',
  async ({ contentTypeSlug }, thunkApi) => {
    const browserId = getBrowserId(thunkApi.getState() as RootState);
    const recordsData = await API.post(
      'Content', // function defined in our serverless.yml
      `${contentTypeSlug}/records`, // the function's path
      { responseType: 'json', body: {} },
    );
    return recordsData;
  },
);

export const validateRecordWithForm = createAsyncThunk<
  {
    cleanedData?: Record<string, PersistedFieldValue>;
    errors?: Record<string, string[]>;
    data: Record<string, PersistedFieldValue>;
    record?: DataRecord;
  },
  { contentTypeSlug: string; formViewId: string; data: Record<string, any> },
  {
    rejectValue: {
      cleanedData?: Record<string, PersistedFieldValue>;
      errors?: Record<string, string[]>;
      data: Record<string, PersistedFieldValue>;
      record?: DataRecord;
    };
  }
>('dataRecords/validate-with-form', async ({ contentTypeSlug, formViewId, data }, thunkApi) => {
  // views{viewId}/records
  try {
    const resp = await API.post(
      'Content', // function defined in our serverless.yml
      `${contentTypeSlug}/forms/${formViewId}?validate=true`, // the function's path
      { responseType: 'json', body: { fields: data } },
    );
    return resp;
  } catch (error) {
    return thunkApi.rejectWithValue((error as any).response.data);
  }
});
export const createRecordWithForm = createAsyncThunk<
  {
    cleanedData?: Record<string, PersistedFieldValue>;
    errors?: Record<string, string[]>;
    data: Record<string, PersistedFieldValue>;
    record?: DataRecord;
  },
  { formViewId: string; contentTypeSlug: string; data: Record<string, PersistedFieldValue | undefined> },
  {
    rejectValue: {
      cleanedData?: Record<string, PersistedFieldValue>;
      errors?: Record<string, string[]>;
      data: Record<string, PersistedFieldValue>;
      record?: DataRecord;
    };
  }
>('dataRecords/create-with-form', async ({ formViewId, contentTypeSlug, data }, thunkApi) => {
  // views{viewId}/records

  try {
    const resp = await API.post(
      'Content', // function defined in our serverless.yml
      `${contentTypeSlug}/forms/${formViewId}?`, // the function's path
      { responseType: 'json', body: { fields: data } },
    );

    return resp;
  } catch (error) {
    return thunkApi.rejectWithValue((error as any).response.data);
  }
});
export const updateRecordWithForm = createAsyncThunk<
  {
    cleanedData?: Record<string, PersistedFieldValue>;
    errors?: Record<string, string[]>;
    data: Record<string, PersistedFieldValue>;
    record?: DataRecord;
  },
  { formViewId: string; contentTypeSlug: string; recordId: string; data: Record<string, any> },
  {
    rejectValue: {
      cleanedData?: Record<string, PersistedFieldValue>;
      errors?: Record<string, string[]>;
      data: Record<string, PersistedFieldValue>;
      record?: DataRecord;
    };
  }
>('dataRecords/update-with-form', async ({ formViewId, contentTypeSlug, data, recordId }, thunkApi) => {
  // views{viewId}/records
  try {
    const resp = await API.post(
      'Content', // function defined in our serverless.yml
      `${contentTypeSlug}/forms/${formViewId}?`, // the function's path
      { responseType: 'json', body: { fields: data, id: recordId } },
    );
    return resp;
  } catch (error) {
    return thunkApi.rejectWithValue((error as any).response.data);
  }
});

export const updateDataRecordByField = createAsyncThunk<
  DataRecord,
  { contentTypeSlug: string; recordId: string; fieldId: string; value: string | any },
  { rejectValue: string[] }
>('dataRecords/updateByField', async ({ contentTypeSlug, recordId, fieldId, value }, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const record = state.records.records[contentTypeSlug][recordId];
  const { fields } = record;
  const updatedFields = { ...fields, [fieldId]: value };
  const recordsData = await API.post(
    'Content', // function defined in our serverless.yml
    `${contentTypeSlug}/records/${recordId}`, // the function's path
    { responseType: 'json', body: { fields: updatedFields } },
  );
  return recordsData;
});

export const updateDataRecord = createAsyncThunk<
  DataRecord,
  { record: DataRecord; contentTypeSlug: string },
  { rejectValue: string[] }
>('dataRecords/update', async ({ record, contentTypeSlug }, thunkApi) => {
  const { id, fields } = record;
  const recordsData = await API.post(
    'Content', // function defined in our serverless.yml
    `${contentTypeSlug}/records/${id}`, // the function's path
    { responseType: 'json', body: { fields } },
  );
  return recordsData;
});

export const deleteDataRecord = createAsyncThunk<
  DataRecord,
  { recordId: string; contentTypeSlug: string },
  { rejectValue: string[] }
>('dataRecords/delete', async ({ recordId, contentTypeSlug }, thunkApi) => {
  const recordsData = await API.del(
    'Content', // function defined in our serverless.yml
    `${contentTypeSlug}/records/${recordId}`, // the function's path
    { responseType: 'json', body: {} },
  );
  return recordsData;
});

export const realTimeRecordUpdated = createAction<{ record: DataRecord }>('dataRecords.updated');
export const realTimeRecordDeleted = createAction<{ record: DataRecord }>('dataRecords.deleted');
export const realTimeRecordCreated = createAction<{ record: DataRecord }>('dataRecords.created');
