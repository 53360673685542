import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AutoSizer } from 'react-virtualized';

import './Board.scss';
import ColumnList from './ColumnList';
import { FieldNumber, FieldType } from '../../../data/types';



export interface KanbanCardField {
  ogValue:any;
  displayValue: string|undefined;
  label: string,
  type: FieldType
}
export interface KanbanCard {
  id: string;
  coverImageURL?: string | undefined|null;
  fields: KanbanCardField[];
}

export interface KanbanColumn {
  id: string;
  title: string;
  cards: KanbanCard[];
  summation:number|null;
}

interface BoardProps {
  columns: KanbanColumn[];
  moveCard: (cardId: string, fromColumnId: string, toColumnId: string) => void;
  addCard: (columnId: string) => void;
  addColumn: (newColumnTitle: string) => void;
  moveColumn: (fromIndex: number, toIndex: number) => void;
  onDropColumn: (columnId: string, toIndex: number) => void;
  onDropCard: (cardId: string, columnId: string) => void;
}

export const Board = ({ columns, moveCard, moveColumn, addColumn, addCard, onDropCard, onDropColumn }: BoardProps) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="kanban-wrapper">
        <AutoSizer>
          {({ height, width }) => (
            <div className="kanban-board" style={{ height, width, overflow: 'auto' }}>
              <ColumnList
                columns={columns}
                moveCard={moveCard}
                addCard={addCard}
                moveColumn={moveColumn}
                addColumn={addColumn}
                onDropCard={onDropCard}
                onDropColumn={onDropColumn}
              />
            </div>
          )}
        </AutoSizer>
      </div>
    </DndProvider>
  );
};

export default Board;
