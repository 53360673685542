import { useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { getDealActivitiesListForSelectedDeal } from '../../../../data/deal-activity/selectors';
import { DealComment, DealLog } from '../../../../data/types';
import Message from './Message';

export const MessagesList = () => {
  const messages = useSelector(getDealActivitiesListForSelectedDeal);
  console.log('messages', messages);
  return (
    <AutoSizer>
      {({ height, width }) => (
        <div className="d-flex flex-column px-2" style={{ height: height - 40, width, overflowY: 'scroll' }}>
          <div className="d-block" style={{ minHeight: 40 }} />
          {messages.map((message) => (
            <Message key={message.dealActivityId} message={message} />
          ))}
          <div className="d-block" style={{ minHeight: 140 }} />
        </div>
      )}
    </AutoSizer>
  );
};

export default MessagesList;
