import { values as _values, orderBy as _orderBy, filter as _filter } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getSelectedViewId } from '../views/selectors';

export const getAutomationsState = (state: RootState) => state.automations;

export const getAutomations = createSelector(getAutomationsState, (state) => state.automations);
export const getIsInitialized = createSelector(getAutomationsState, (state) => state.isInitialized);
export const getIsLoading = createSelector(getAutomationsState, (state) => state.isLoading);
export const getIsAutomationCreating = createSelector(getAutomationsState, (state) => state.isCreating);

export const getAutomationsList = createSelector(getAutomations, (automations) => _values(automations));

export const getAutomationsForSelectedView = createSelector(
  getAutomationsList,
  getSelectedViewId,
  (automations, selectedViewId) => _orderBy(_filter(automations, { viewId: selectedViewId }), ['createdDT'], ['asc']),
);
