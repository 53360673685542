import React, { useCallback } from 'react';
import { FieldCheckbox, FieldCheckboxConfig, FieldText, FieldTextConfig } from '@taida-corp/taidacorp-sdk';

interface CheckboxConfigEditorProps {
  field: FieldCheckbox;
  internalConfig:FieldCheckboxConfig;
  onChangeConfig: (e: any) => void;
}

export const CheckboxConfigEditor = ({field, internalConfig:config, onChangeConfig}: CheckboxConfigEditorProps) => {
  const {
    defaultValue='',
  } = config;
  const onChange = useCallback((e:any) => {
    const newVal = e.target.checked;
    onChangeConfig({
      defaultValue: newVal
    });
  }, [onChangeConfig]);
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={!!defaultValue}
        onChange={onChange}
      />
      <label className="form-check-label">Default</label>
    </div>
  );
};

export default CheckboxConfigEditor;
