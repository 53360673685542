import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { setToken, fetchToken, connect, onOpenConnection, onCloseConnection, onReconnected, onMessageReceived, send, disconnect } from './actions';


interface RealTimeState {
  browserId: string;
  token: string|undefined;
  isLoading: boolean;
  isInitialized: boolean;
}

const initialState: RealTimeState = {
  browserId: uuidv4(),
  token: undefined,
  isLoading: false,
  isInitialized: false,
};

export const realTimeSlice = createSlice({
  name: 'realTime',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setToken, (state, { payload }) => {
      state.token = payload;
    });
    builder.addCase(fetchToken.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchToken.fulfilled, (state, { payload }) => {
      state.token = payload;
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchToken.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(connect, (state, { payload }) => {

    });
    builder.addCase(disconnect, (state, { payload }) => {

    });
    builder.addCase(send, (state, { payload }) => {

    });
    builder.addCase(onOpenConnection, (state, { payload }) => {

    });
    builder.addCase(onCloseConnection, (state, { payload }) => {

    });
    builder.addCase(onReconnected, (state, { payload }) => {

    });
    builder.addCase(onMessageReceived, (state, { payload }) => {

    });
  },
});

export default realTimeSlice;
