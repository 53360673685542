import { isFieldMultiSelect, isFieldSelect, isString, isStringArray } from '@taida-corp/taidacorp-sdk';
import React, { useCallback, useMemo } from 'react';
import { RecordInputComponentProps } from '../base';

export const SelectInputComponent = ({
  field,
  value: persistedValue,
  onChange,
  extraProps = {},
}: RecordInputComponentProps) => {
  const isMultiple = useMemo(() => isFieldMultiSelect(field), [field]);
  const errors = useMemo(() => {
    if (persistedValue === undefined || !isStringArray(persistedValue.errors)) {
      return [] as string[];
    }
    return persistedValue.errors;
  }, [persistedValue])
  const value = useMemo(() => {
    if (persistedValue === undefined) {
      return '';
    }
    if(isMultiple) {
      if(!isStringArray(persistedValue.internalValue)) {
        return '';
      }
    } else {
      if(!isString(persistedValue.internalValue)) {
        return '';
      }
    }

    return persistedValue.internalValue;
  }, [isMultiple, persistedValue]);
  const onChangeHandle = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if(isMultiple) {
        onChange({internalValue: Array.from(event.target.selectedOptions, option => option.value)});
      } else {
        if(event.target.value === '--') {
          onChange({internalValue: null});
        } else {
          onChange({internalValue: event.target.value ?? ''});
        }
      }
    },
    [onChange, isMultiple],
  );
  if(!isFieldSelect(field) && !isFieldMultiSelect(field)) {
    return <div>{`Error this is not a select field ${field.fieldId}`}</div>
  }
  console.log('value', value);
  console.log('field.config.options', field.config.options);
  return (
    <>
    <select
      className="form-select"
      id={`field-${field.fieldId}`}
      value={value}
      onChange={onChangeHandle}
      multiple={isMultiple}
      {...extraProps}
    >
      {!isMultiple && <option value={undefined}>--</option>}
      {field.config.options.map((fieldOption) => (
        <option key={fieldOption.value} value={fieldOption.value}>{fieldOption.label}</option>
      ))}
      </select>
    {errors.map((err) => <div key={err} className='mt-1'><small className='text-danger'>{err}</small></div>)}
    </>
  );
};

export default SelectInputComponent;
