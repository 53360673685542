import { useState, useEffect, useCallback, useMemo } from 'react';
import Transloadit, { Assembly, FileInfo, Result } from '@uppy/transloadit';
import Webcam from '@uppy/webcam';
import Audio from '@uppy/audio';
import ScreenCapture from '@uppy/screen-capture';
import Unsplash from '@uppy/unsplash';
import Zoom from '@uppy/zoom';
import Instagram from '@uppy/instagram';
import Url from '@uppy/url';
import ImageEditor from '@uppy/image-editor';

import '@uppy/webcam/dist/style.css';
import '@uppy/audio/dist/style.css';
import '@uppy/screen-capture/dist/style.css';
import Uppy from '@uppy/core';
import {
  Attachment,
  AudioAttachment,
  FieldAttachmentConfig,
  FileAttachment,
  ImageAttachment,
  VideoAttachment,
} from '../../data/types';
import {
  keyBy as _keyBy,
  mergeWith as _mergeWith,
  reduce as _reduce,
  get as _get,
  groupBy as _groupBy,
  map as _map,
} from 'lodash';

export const useFileUploader = (config: FieldAttachmentConfig, onCompleteIn: (results: Attachment[]) => void) => {
  const {
    fileType,
    minNumberOfFiles,
    maxNumberOfFiles,
    maxTotalFileSizeKB,
    minSizeKB,
    maxSizeKB,

    minImageWidth,
    minImageHeight,
    maxImageWidth,
    maxImageHeight,
  } = config;

  const onUpload = useCallback((fileInfo: FileInfo, assembly: Assembly) => {

  }, []);

  const onError = useCallback((error: any) => {
    if (error.assembly) {

    }
  }, []);

  const onComplete = useCallback(
    (assembly: Assembly) => {
      const results = assembly.results;
      const {
        ':original': originals,
        optimized_image_1920,
        optimized_image_landscape,
        optimized_thumbnail_150x150,
        optimized_video_cover,
        video_mp4_1080p,
        audio_aac,
      } = results;
      const optimized_image_1920ById = _groupBy(optimized_image_1920, 'original_id');
      const optimized_image_landscapeById = _groupBy(optimized_image_landscape, 'original_id');
      const optimized_thumbnail_150x150ById = _groupBy(optimized_thumbnail_150x150, 'original_id');
      const optimized_video_coverById = _groupBy(optimized_video_cover, 'original_id');
      const video_mp4_1080pById = _groupBy(video_mp4_1080p, 'original_id');
      const audio_aacById = _groupBy(audio_aac, 'original_id');

      const newResults = _reduce(
        originals,
        (prev, curr) => {
          const id = curr.original_id;
          return [
            ...prev,
            {
              original: curr,
              image_1920: _get(optimized_image_1920ById, id),
              image_landscape: _get(optimized_image_landscapeById, id),
              thumbnail_150x150: _get(optimized_thumbnail_150x150ById, id),
              video_cover: _get(optimized_video_coverById, id),
              video_mp4_1080p: _get(video_mp4_1080pById, id),
              audio_aac: _get(audio_aacById, id),
            },
          ];
        },
        [] as any[],
      );
      let attachments: any[] = _map(newResults, (result) => {
        const { original, thumbnail_150x150, video_cover, video_mp4_1080p, audio_aac, image_1920, image_landscape } =
          result;
        if (video_mp4_1080p !== undefined) {
          return {
            type: 'video-attachment',
            original,
            thumbnail_150x150,
            video_cover,
            video_mp4_1080p:
              Array.isArray(video_mp4_1080p) && video_mp4_1080p.length > 0 ? video_mp4_1080p[0] : video_mp4_1080p,
            audio_aac: Array.isArray(audio_aac) && audio_aac.length > 0 ? audio_aac[0] : audio_aac,
          } as VideoAttachment;
        }
        if (image_1920 !== undefined && (original as Result).mime.indexOf('image') !== -1) {
          return {
            type: 'image-attachment',
            original,
            thumbnail_150x150:
              Array.isArray(thumbnail_150x150) && thumbnail_150x150.length > 0
                ? thumbnail_150x150[0]
                : thumbnail_150x150,
            image_landscape:
              Array.isArray(image_landscape) && image_landscape.length > 0 ? image_landscape[0] : image_landscape,
            image_1920: Array.isArray(image_1920) && image_1920.length > 0 ? image_1920[0] : image_1920,
          } as ImageAttachment;
        }
        if (audio_aac !== undefined) {
          return {
            type: 'audio-attachment',
            original,
            audio_aac: Array.isArray(audio_aac) && audio_aac.length > 0 ? audio_aac[0] : audio_aac,
          } as AudioAttachment;
        }
        return {
          type: 'file-attachment',
          original,
          thumbnail_150x150:
            Array.isArray(thumbnail_150x150) && thumbnail_150x150.length > 0 ? thumbnail_150x150[0] : thumbnail_150x150,
          image_landscape:
            Array.isArray(image_landscape) && image_landscape.length > 0 ? image_landscape[0] : image_landscape,
          image_1920: Array.isArray(image_1920) && image_1920.length > 0 ? image_1920[0] : image_1920,
        } as FileAttachment;
      });
      onCompleteIn(attachments);
    },
    [onCompleteIn],
  );

  const allowedFileTypes = useMemo(() => {
    // transform this as needed
    if (fileType === null) {
      return null;
    }
    let allowed: any[] = [];
    if (fileType.indexOf('image') !== -1) {
      allowed = allowed.concat('image/*');
    }
    if (fileType.indexOf('video') !== -1) {
      allowed = allowed.concat('video/*');
    }
    if (fileType.indexOf('audio') !== -1) {
      allowed = allowed.concat('audio/*');
    }
    if (fileType.indexOf('pdf') !== -1) {
      allowed = allowed.concat('application/pdf');
    }
    return allowed;
  }, [fileType]);

  const [uppy, setUppy] = useState<Uppy | null>(null);
  useEffect(() => {
    const restrictions = {
      maxFileSize: maxSizeKB,
      minFileSize: minSizeKB,
      maxTotalFileSize: maxTotalFileSizeKB,
      maxNumberOfFiles,
      minNumberOfFiles,
      allowedFileTypes,
    };
    const _uppy = new Uppy({
      restrictions,
    });
    _uppy.use(ImageEditor, {
      quality: 0.8,
    });
    _uppy.use(Webcam, {
      // Options
    });
    _uppy.use(Audio, {
      // Options
    });

    _uppy.use(ScreenCapture, {
      // Options
    });

    _uppy.use(Unsplash, {
      // Options
      companionUrl: Transloadit.COMPANION,
    });
    _uppy.use(Zoom, {
      // Options
      companionUrl: Transloadit.COMPANION,
    });
    _uppy.use(Instagram, {
      // Options
      companionUrl: Transloadit.COMPANION,
    });
    _uppy.use(Url, {
      // Options
      companionUrl: Transloadit.COMPANION,
    });
    _uppy.use(Transloadit, {
      waitForEncoding: true,
      waitForMetadata: true,
      params: {
        auth: { key: 'a8fd276ca0774d008cea2113dcfa6a8a' },
        template_id: 'be00d7557d994861b6e78840ad7c580b',
      },
    });
    _uppy.on('error', onError);
    _uppy.on('transloadit:complete', onComplete);
    _uppy.on('transloadit:upload', onUpload);
    // .use(XHRUpload, { endpoint: '/api/songs/upload' })
    // .use(Webcam, { modes: ['audio-only'] })
    setUppy(_uppy);
  }, [
    allowedFileTypes,
    maxNumberOfFiles,
    maxSizeKB,
    maxTotalFileSizeKB,
    minNumberOfFiles,
    minSizeKB,
    onComplete,
    onError,
    onUpload,
  ]);

  return uppy;
};

export default useFileUploader;
