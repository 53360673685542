import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import {
  getDocumentsForSelectedDealList,
  getDocumentsList,
  getIsDocumentDownloading,
  getIsDocumentSending,
} from '../../../../data/documents/selectors';
import { DealDocument, DealRecord } from '../../../../data/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faDownload, faEnvelope, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect } from 'react';
import {
  downloadDocument,
  fetchDocumentsForDeal,
  getShareLinkAndCopy,
  sendDocument,
  setIsDownloading,
} from '../../../../data/documents/actions';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../../data/store';
import { setSelectedDocumentTemplateId } from '../../../../data/documentTemplates/actions';
import DocumentListItem from './new-document/DocumentListItem';

interface DocumentsListProps {
  deal: DealRecord;
}

export const DocumentsList = ({ deal }: DocumentsListProps) => {
  const dispatch = useAppDispatch();
  const documents = useSelector(getDocumentsForSelectedDealList) as DealDocument[];
  const download = useCallback(
    async (docId: string) => {
      await downloadDocument(docId, (val: boolean) => dispatch(setIsDownloading(val)));
    },
    [dispatch],
  );

  const onSendDocument = useCallback(
    async (documentId: string) => {
      dispatch(
        sendDocument({ documentId, message: 'Sign this PDF', subject: '[TAIDACORP] Signature Request', silent: false }),
      );
    },
    [dispatch],
  );
  const clearSelectedDocument = useCallback(() => {
    dispatch(setSelectedDocumentTemplateId(undefined));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDocumentsForDeal({ dealId: deal.dealId }));
  }, [deal.dealId, dispatch]);

  return (
    <div className="d-flex flex-column h-100">
      <div className="flex-grow-1">
        <div className="table-responsive ">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Created DT</th>
                <th>Updated DT</th>
                <th>Send</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc) => (
                <DocumentListItem key={doc.id} document={doc} onSend={onSendDocument} onDownload={download} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-end align-items-end p-3">
        <Link to="./new" className="btn btn-primary">
          New Document
        </Link>
      </div>
    </div>
  );
};

export default DocumentsList;
