import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import DealDetailPage from '../../pages/deal-detail/DealDetailPage';
import Deals from '../../pages/deals/Deals';
import NewDealFormPage from '../../pages/new-deal-form/NewDealFormPage';

export const DealsRoutes = () => {
  return (
    <>
    <Routes>
      <Route index element={<Deals />} />
      <Route path=":viewId" element={<Deals />}/>
      <Route path=":viewId/edit/:dealId/*" element={<Deals />}/>
    </Routes>
    <Routes>
      <Route path=":viewId/edit/new" element={<NewDealFormPage />} />
      <Route path=":viewId/edit/:dealId/*" element={<DealDetailPage />} />
      {/* <Route path="*" element={<Navigate to="/deals" />} /> */}
    </Routes>
    </>
    
  );
};

export default DealsRoutes;
