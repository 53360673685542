import { forEach as _forEach } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import TemplateEditor from '../../../../components/TemplateEditor/TemplateEditor';
import { getCompany } from '../../../../data/company/selectors';
import { SlackChannel, SlackUser } from '../../../../data/slack/slice';
import { Template, Field, FormulaDependency, TemplateEditorOption } from '../../../../data/types';
import { convertHTMLNodesToTemplate, convertTemplateToHTMLNodes } from '../../../../utils/templateEditor';

interface AutomationTemplateEditorProps {
  templateOptions: TemplateEditorOption[];
  name: string;
  value: Template;
  onChange: (key: string, newTemplate: Template) => void;
}

export const AutomationTemplateEditor = ({
  name,
  value,
  onChange,
  templateOptions,
}: AutomationTemplateEditorProps) => {
  const onChangeHandler = useCallback((newSource:string, newDeps:FormulaDependency[]) => {
    onChange(name, {templateSource: newSource, templateDependencies: newDeps});
  }, [name, onChange]);

  return (
    <div className="row mb-3">
      <div className="col-12">
        <div className="text-capitalize">{name}</div>
        <TemplateEditor
          value={value}
          onChange={onChangeHandler}
          templateOptions={templateOptions}
        />
      </div>
      {/* <div className="col-4 p-0">
          <div className="">Add field</div>
          <div className="border p-1">
            <div className="d-flex flex-column" style={{ maxHeight: 110, overflow: 'scroll' }}>
              {templateFields.map((field) => (
                <button
                  key={field.fieldId}
                  type="button"
                  className="btn btn-sm btn-secondary mb-1"
                  onClick={() => insertFieldPlaceholder(field)}
                >
                  {field.name}
                </button>
              ))}
            </div>
          </div>
        </div> */}
    </div>
  );
};

export default AutomationTemplateEditor;
