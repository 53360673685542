import { useState, useEffect, useCallback, useMemo } from 'react';
import Transloadit, { Assembly, FileInfo, Result } from '@uppy/transloadit';
import Webcam from '@uppy/webcam';
import Audio from '@uppy/audio';
import ScreenCapture from '@uppy/screen-capture';
import Unsplash from '@uppy/unsplash';
import Zoom from '@uppy/zoom';
import Instagram from '@uppy/instagram';
import Url from '@uppy/url';
import ImageEditor from '@uppy/image-editor';

import '@uppy/webcam/dist/style.css';
import '@uppy/audio/dist/style.css';
import '@uppy/screen-capture/dist/style.css';
import Uppy from '@uppy/core';
import {
  Attachment,
  AudioAttachment,
  FieldAttachmentConfig,
  FileAttachment,
  ImageAttachment,
  VideoAttachment,
} from '../../data/types';
import {
  keyBy as _keyBy,
  mergeWith as _mergeWith,
  reduce as _reduce,
  get as _get,
  groupBy as _groupBy,
  map as _map,
} from 'lodash';

interface SimpleImageUploaderProps {
  onComplete:(original:string, optimizedURL:string, thumbnailURL:string) => void;
}

export const useSimpleImageUploader = ({onComplete: onCompleteIn}:SimpleImageUploaderProps) => {
  
  const onUpload = useCallback((fileInfo: FileInfo, assembly: Assembly) => {

  }, []);

  const onError = useCallback((error: any) => {
    if (error.assembly) {

    }
  }, []);

  const onComplete = useCallback(
    (assembly: Assembly) => {
      const results = assembly.results;
      const {
        ':original': original,
        convert_image_jpg: optimized,
        resize_image: thumbnail,
        
      } = results;

      onCompleteIn(original[0].ssl_url, optimized[0].ssl_url, thumbnail[0].ssl_url);
    },
    [onCompleteIn],
  );

  const [uppy, setUppy] = useState<Uppy | null>(null);
  useEffect(() => {
    const restrictions = {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: ['image/*'],
    };
    const _uppy = new Uppy({
      restrictions,
    });
    _uppy.use(ImageEditor, {
      quality: 0.8,
    });
    _uppy.use(Webcam, {
      // Options
    });

    _uppy.use(Unsplash, {
      // Options
      companionUrl: Transloadit.COMPANION,
    });
    _uppy.use(Instagram, {
      // Options
      companionUrl: Transloadit.COMPANION,
    });
    _uppy.use(Url, {
      // Options
      companionUrl: Transloadit.COMPANION,
    });
    _uppy.use(Transloadit, {
      waitForEncoding: true,
      waitForMetadata: true,
      params: {
        auth: { key: 'a8fd276ca0774d008cea2113dcfa6a8a' },
        template_id: 'c1ccdf1b16f04f76b7a1d094acf1b19d',
      },
    });
    _uppy.on('error', onError);
    _uppy.on('transloadit:complete', onComplete);
    _uppy.on('transloadit:upload', onUpload);
    // .use(XHRUpload, { endpoint: '/api/songs/upload' })
    // .use(Webcam, { modes: ['audio-only'] })
    setUppy(_uppy);
  }, [onComplete, onError, onUpload]);

  return uppy;
};

export default useSimpleImageUploader;
