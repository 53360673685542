import React, { useCallback, useMemo, useState } from 'react';
import {debounce as _debounce} from 'lodash';
import Popover, { PopoverProps } from 'react-popover';
import { useSelector } from 'react-redux';
import { FilterCondition, FilterConditionGroup, FILTER_EXPRESSION, KanbanView, OPERATOR_OPTIONS, TableView } from '../../../../data/types';
import { getAllFieldsList } from '../../../../data/company/selectors';
import { useAppDispatch } from '../../../../data/store';
import { move, remove, replace } from '../../../../utils/array';
import ViewFilterPopover from './ViewFilterPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import useToolbarPopover from '../useToolbarPopover/useToolbarPopover';
import { getFieldFilterConditionDefault } from '../../../../utils/fieldFilters';
import { localUpdateView, persistViewUpdates, updateDealView } from '../../../../data/views/actions';
import { getSelectedView } from '../../../../data/views/selectors';

import './ViewFilter.scss';
import { Modal } from 'react-bootstrap';

export const ViewFilter = (props: any) => {
  const dispatch = useAppDispatch();
  const [isChildPopoverOpen, setIsChildPopoverOpen] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const view = useSelector(getSelectedView) as KanbanView | TableView;
  const allFields = useSelector(getAllFieldsList);
  const addFilteringFieldGroup = useCallback(
    () => {
      const {operator, conditions} = view.properties.filters || {operator: 'and', conditions: []};
      const newCondition = getFieldFilterConditionDefault(allFields[0]);
      const newConditionGroup:FilterConditionGroup = { operator: 'and', conditions: [
        newCondition
      ]};
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          filters: {operator, conditions: [...conditions, newConditionGroup]},
        },
      } as KanbanView | TableView;
      dispatch(updateDealView(updatedView));
    },
    [allFields, dispatch, view],
  );
  const addFilteringField = useCallback(
    () => {
      const {operator, conditions} = view.properties.filters || {operator: 'and', conditions: []};
      const newCondition = getFieldFilterConditionDefault(allFields[0]);
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          filters: {operator, conditions: [...conditions, newCondition]},
        },
      } as KanbanView | TableView;
      dispatch(updateDealView(updatedView));
    },
    [allFields, dispatch, view],
  );
  const removeFilteringField = useCallback(
    (index: number) => {
      const {operator, conditions} = view.properties.filters || {operator: 'and', conditions: []};
      const newConditions = remove(conditions, index);
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          filters: {operator, conditions: newConditions},
        },
      } as KanbanView | TableView;
      dispatch(updateDealView(updatedView));
    },
    [dispatch, view],
  );
  const debouncedPersistViewUpdates = useMemo(() => {
    return _debounce((viewId:string) => dispatch(persistViewUpdates(viewId)), 5000);
  }, [dispatch])
  const updateFilteringField = useCallback(
    (newField: string, newExpression: FILTER_EXPRESSION, newOption:string|undefined, newCompareTo: any, index: number) => {
      const {operator, conditions} = view.properties.filters || {operator: 'and', conditions: []};
      const newConditions:FilterCondition[] = replace(conditions, {
        fieldId: newField,
        expression: newExpression,
        option: newOption,
        compareTo: newCompareTo,
      } as FilterCondition, index);
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          filters: {operator, conditions: newConditions},
        },
      } as KanbanView | TableView;
      // local
      dispatch(localUpdateView({view: updatedView}));
      // debounce persist deal view
      debouncedPersistViewUpdates(view.viewId);
    },
    [debouncedPersistViewUpdates, dispatch, view],
  );
  const updateOperator = useCallback(
    (newOperator:OPERATOR_OPTIONS) => {
      const {conditions} = view.properties.filters || {operator: 'and', conditions: []};
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          filters: {operator: newOperator, conditions},
        },
      } as KanbanView | TableView;
      dispatch(updateDealView(updatedView));
    },
    [dispatch, view],
  );
  const updateFilterCondition = useCallback(
    (newFilters:FilterConditionGroup) => {
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          filters: newFilters,
        },
      } as KanbanView | TableView;
      dispatch(updateDealView(updatedView));
    },
    [dispatch, view],
  );
  const { buttonProps, ToolbarPopover, popoverProps } = useToolbarPopover({ closeOnOutsideClick: !isChildPopoverOpen });

  return (
    <>
      <div tabIndex={0} className="deals-toolbar__item" onClick={handleShow}>
        <FontAwesomeIcon className="me-2" icon={faFilter} />
        Filter
      </div>
      <Modal size='lg' show={show} onHide={handleClose}>
        <ViewFilterPopover
          view={view}
          onChildPopover={setIsChildPopoverOpen}
          allFields={allFields}
          addFilteringField={addFilteringField}
          addFilteringFieldGroup={addFilteringFieldGroup}
          updateFilteringField={updateFilteringField}
          removeFilteringField={removeFilteringField}
          updateOperator={updateOperator}
          updateFilterCondition={updateFilterCondition}
        />
      </Modal>
    </>
  );
};

export default ViewFilter;
