import { APIViewField, Field, ValidationRule } from '@taida-corp/taidacorp-sdk';
import { find as _find } from 'lodash';
import { useCallback, useMemo } from 'react';
import { API_VIEW_FIELD_HELPTEXT } from '../../../../../components/Fields';

interface APIViewFieldEditorProps {
  apiViewField: APIViewField;
  fields: Field[];
  testInputData: Record<string, any>;
  onChangeViewField: (viewField: APIViewField) => void;
}

export const APIViewFieldEditor = ({
  apiViewField,
  fields,
  testInputData,
  onChangeViewField,
}: APIViewFieldEditorProps) => {
  const field = useMemo(() => _find(fields, { fieldId: apiViewField.fieldId }), [apiViewField, fields]);
  const doUpsert = apiViewField.doUpsert ?? false;
  const isRequired = useMemo(() => {
    const _isRequired = !!_find(apiViewField.validationRules, { type: 'is required' });
    return _isRequired;
  }, [apiViewField.validationRules]);

  const onChangeTemplate = useCallback(
    (e: any) => {
      const newTemplate = e.target.value ?? ';';
      onChangeViewField({
        ...apiViewField,
        template: newTemplate,
      });
    },
    [apiViewField, onChangeViewField],
  );
  const onChangeIsRequired = useCallback(
    (e: any) => {
      const newIsRequired = !!e.target.checked;
      const validationRules: ValidationRule[] = newIsRequired
        ? [{ type: 'is required', message: 'This field is required' }]
        : [];

      onChangeViewField({
        ...apiViewField,
        validationRules,
      });
    },
    [apiViewField, onChangeViewField],
  );
  const onChangeDoUpsert = useCallback(
    (e: any) => {
      const newDoUpsert = !!e.target.checked;

      onChangeViewField({
        ...apiViewField,
        doUpsert: newDoUpsert,
      });
    },
    [apiViewField, onChangeViewField],
  );

  if (field === undefined) {
    return <div>Field not found {apiViewField.fieldId}</div>;
  }
  return (
    <div className="mb-3" key={field.fieldId}>
      <div>
        <label>{field.name}</label>
        {field.type === 'long-text' && (
        <textarea className="form-control" onChange={onChangeTemplate} value={apiViewField.template} />
        )}
        {field.type !== 'long-text' && (
        <input className="form-control" type="text" onChange={onChangeTemplate} value={apiViewField.template} />
        )}
        {/* <TemplateEditor value={template} onChange={updateTemplate} templateOptions={templateOptions} /> */}
        <small>{API_VIEW_FIELD_HELPTEXT[field.type] ?? ''}</small>
      </div>
      <div className="form-check">
        <input
          id={`is-${field.fieldId}-required`}
          className="form-check-input"
          type="checkbox"
          checked={isRequired}
          onChange={onChangeIsRequired}
        />
        <label htmlFor={`is-${field.fieldId}-required`} className="form-check-label">
          {field.name} Is Required
        </label>
      </div>
      <div className="form-check">
        <input
          id={`do-${field.fieldId}-upsert`}
          className="form-check-input"
          type="checkbox"
          checked={doUpsert}
          onChange={onChangeDoUpsert}
        />
        <label htmlFor={`do-${field.fieldId}-upsert`} className="form-check-label">
          Upsert {field.name}
        </label>
      </div>
    </div>
  );
};

export default APIViewFieldEditor;
