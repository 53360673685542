import { faPlus, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useCallback } from 'react';
import {
  Field,
  FilterCondition,
  FilterConditionGroup,
  FILTER_EXPRESSION,
  isFilterCondition,
  isFilterConditionGroup,
  OPERATOR_OPTIONS,
} from '../../../../data/types';
import { remove, replace } from '../../../../utils/array';
import { getFieldFilterConditionDefault } from '../../../../utils/fieldFilters';
import ChangeFilterOperator from './ChangeFilterOperator';
import FilterFieldChildConditionGroupRow from './FilterFieldChildConditionGroupRow';
import FilterFieldConditionRow from './FilterFieldConditionRow';

interface FilterFieldConditionGroupRowProps {
  condition: FilterConditionGroup;
  index: number;
  removeFilter: () => void;
  updateFilterCondition: (newFilters: FilterConditionGroup) => void;

  operator: OPERATOR_OPTIONS;
  fields: Field[];
  onChildPopover: Function;
}

export const FilterFieldConditionGroupRow = ({
  condition,
  index,
  removeFilter,
  updateFilterCondition,
  operator,
  fields,
  onChildPopover,
}: FilterFieldConditionGroupRowProps) => {
  const updateOperator = useCallback(
    (newOperator: OPERATOR_OPTIONS) => {
      updateFilterCondition({ conditions: condition.conditions, operator: newOperator });
    },
    [condition.conditions, updateFilterCondition],
  );
  const removeFilteringField = useCallback(
    (idx: number) => {
      const newConditions = remove(condition.conditions, idx);
      updateFilterCondition({ conditions: newConditions, operator });
    },
    [condition.conditions, operator, updateFilterCondition],
  );
  const updateFilteringField = useCallback(
    (
      newField: string,
      newExpression: FILTER_EXPRESSION,
      newOption: string | undefined,
      newCompareTo: any,
      idx: number,
    ) => {
      const newConditions: (FilterConditionGroup | FilterCondition)[] = replace(
        condition.conditions,
        {
          fieldId: newField,
          expression: newExpression,
          option: newOption,
          compareTo: newCompareTo,
        } as FilterCondition,
        idx,
      );
      updateFilterCondition({ conditions: newConditions, operator });
    },
    [condition.conditions, operator, updateFilterCondition],
  );
  const updateFilterConditionGroupAtIndex = useCallback(
    (updatedFilterConditionGroup: FilterConditionGroup, idx: number) => {
      const newConditions: (FilterConditionGroup | FilterCondition)[] = [...condition.conditions];
      newConditions.splice(idx, 1, updatedFilterConditionGroup);
      updateFilterCondition({ conditions: newConditions, operator });
    },
    [condition, operator, updateFilterCondition],
  );
  const addFilteringFieldGroup = useCallback(() => {
    const newCondition = getFieldFilterConditionDefault(fields[0]);
    const newConditionGroup: FilterConditionGroup = { operator: 'and', conditions: [newCondition] };

    const newConditions = [...condition.conditions, newConditionGroup];
    updateFilterCondition({ conditions: newConditions, operator });
  }, [condition.conditions, fields, operator, updateFilterCondition]);
  const addFilteringField = useCallback(() => {
    const newCondition = getFieldFilterConditionDefault(fields[0]);

    const newConditions = [...condition.conditions, newCondition];
    updateFilterCondition({ conditions: newConditions, operator });
  }, [condition.conditions, fields, operator, updateFilterCondition]);

  return (
    <div className="w-100 p-1">
      <div className="d-flex flex-row justify-content-between">
        <div className="text-secondary mb-1">
          {operator === 'and' ? 'All of the following are true...' : 'Any of the following are true...'}
        </div>
        <div className='btn' onClick={removeFilter}>
          <FontAwesomeIcon icon={faTrash} size="1x" />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div style={{ minWidth: 500 }} className="d-flex flex-column">
          {condition.conditions.map((childCondition, childConditionIndex) => {
            return (
              <div
                className={classNames({
                  'd-flex flex-row': true,
                  'align-items-center': !isFilterConditionGroup(childCondition),
                })}
                style={{ minWidth: 500 }}
                key={`child-${childConditionIndex}`}
              >
                <div style={{ minWidth: 100 }}>
                  <div className="filter-field-row-lead me-2">
                    {childConditionIndex === 0 ? (
                      'Where'
                    ) : (
                      <ChangeFilterOperator
                        operator={operator}
                        updateOperator={updateOperator}
                        isDisabled={childConditionIndex !== 1}
                      />
                    )}
                  </div>
                </div>
                {isFilterConditionGroup(childCondition) ? (
                  <FilterFieldChildConditionGroupRow
                    condition={childCondition}
                    index={childConditionIndex}
                    updateFilterCondition={(newChildConditionGroup: FilterConditionGroup) =>
                      updateFilterConditionGroupAtIndex(newChildConditionGroup, childConditionIndex)
                    }
                    removeFilter={() => removeFilteringField(childConditionIndex)}
                    operator={childCondition.operator}
                    fields={fields}
                    onChildPopover={onChildPopover}
                  />
                ) : (
                  <FilterFieldConditionRow
                    condition={childCondition}
                    index={childConditionIndex}
                    updateFilteringField={updateFilteringField}
                    removeFilter={() => removeFilteringField(childConditionIndex)}
                    operator={operator}
                    fields={fields}
                    onChildPopover={onChildPopover}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-flex flex-row">
        <div>
          <div className="d-flex flex-row">
            <button onClick={addFilteringField} className="btn btn-link text-black ps-0">
              <small className="text-muted">
                <FontAwesomeIcon className="ms-2" icon={faPlus} /> Add Condition
              </small>
            </button>
            <button onClick={addFilteringFieldGroup} className="btn btn-link text-black ps-0">
              <small className="text-muted">
                <FontAwesomeIcon className="ms-2" icon={faPlus} /> Add Condition Group
              </small>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterFieldConditionGroupRow;
