import {isEqual as _isEqual} from 'lodash';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { TableView } from '../../../../data/types';
import useToolbarPopover from '../useToolbarPopover/useToolbarPopover';
import { getSelectedView } from '../../../../data/views/selectors';
import FieldsToggleList from '../FieldToggle/FieldsToggleList';

const TableColumnCustomizerPopover = (props: any) => {
  const view = useSelector(getSelectedView);
  if (view === undefined) {
    return null;
  }
  return (
    <div className="deals-toolbar__popover pb-0">
      <h3>Visible Fields</h3>
      <FieldsToggleList
        view={view as TableView}
      />
    </div>
  );
};

export const TableColumnCustomizer = (props: any) => {
  const { buttonProps, ToolbarPopover, popoverProps } = useToolbarPopover({});
  return (
    <>
      <div tabIndex={0} className="deals-toolbar__item" {...buttonProps}>
        <FontAwesomeIcon className="me-2" icon={faCog} />
        Visible Fields
      </div>
      <ToolbarPopover {...popoverProps}>
        <TableColumnCustomizerPopover />
      </ToolbarPopover>
    </>
  );
};

export default TableColumnCustomizer;
