import { get as _get, find as _find } from 'lodash';

import { Attachment, ImageAttachment, VideoAttachment, FileAttachment } from "../data/types";


export const getHeaderImageFromAttachmentsArray = (attachments: Attachment[]): string | null => {
    const imageAttachment: ImageAttachment | undefined = _find(attachments, (att) => {
      return att.type === 'image-attachment';
    }) as any;
    if (imageAttachment) {
      return imageAttachment.image_landscape.ssl_url;
    }
    const videoAttachment: VideoAttachment | undefined = _find(attachments, (att) => {
      return att.type === 'video-attachment';
    }) as any;
    if (videoAttachment) {
      return videoAttachment.video_cover[0].ssl_url;
    }
    const fileAttachmentWithImage: FileAttachment | undefined = _find(attachments, (att) => {
      return att.type === 'file-attachment' && att.image_landscape !== undefined;
    }) as any;
    if (fileAttachmentWithImage) {
      return fileAttachmentWithImage.image_landscape!.ssl_url;
    }
    return null;
  };
  
  export default getHeaderImageFromAttachmentsArray;