import { values as _values, orderBy as _orderBy, filter as _filter, map as _map, forEach as _forEach, get as _get } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getSelectedDealId } from '../deals/selectors';
import { DealActivity } from '../types';
import { getUsers, getUsersDict } from '../users/selectors';
import { format } from 'date-fns';

export const getTableUIState = (state: RootState) => state.tableUI;

export const getIsAutomationsPanelVisible = createSelector(getTableUIState, (state) => state.isAutomationsPanelVisible);
export const getHoveredColumnIndex = createSelector(getTableUIState, (state) => state.hoveredColumnIndex);
export const getHoveredRowIndex = createSelector(getTableUIState, (state) => state.hoveredRowIndex);
export const getSelectedRange = createSelector(getTableUIState, (state) => state.selectedRange);
