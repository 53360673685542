import {
  values as _values,
  orderBy as _orderBy,
  filter as _filter,
  map as _map,
  keyBy as _keyBy,
  get as _get,
  find as _find,
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { getContentTypeFields, getSelectedContentType, getSelectedContentTypeString } from '../content-types/selectors';
import { RecordAutomation } from '@taida-corp/taidacorp-sdk';

export const getAutomationsState = (state: RootState) => state.recordAutomations;
export const getAutomations = (state: RootState) => state.recordAutomations.automations;
export const getIsInitialized = (state: RootState) => state.recordAutomations.isInitialized;
export const getIsUpdating = (state: RootState) => state.recordAutomations.isUpdating;
export const getIsCreating = (state: RootState) => state.recordAutomations.isCreating;
export const getSelectedAutomationId = (state: RootState) => state.recordAutomations.selectedAutomationId;

export const getSelectedAutomation = createSelector(
  getAutomations,
  getSelectedContentTypeString,
  getSelectedAutomationId,
  (automations, contentType, id) => {
    if (id === undefined || contentType === undefined) {
      return undefined;
    }
    return _get(automations, [contentType, id]);
  },
);

export const getAutomationsListForContentType = createSelector(getAutomations, getSelectedContentTypeString, (automations, contentType):RecordAutomation[] => {
    if(contentType === undefined) {
        return [] as RecordAutomation[];
    }
    const contentTypeAutomations = _get(automations, [contentType], {}) as Record<string, RecordAutomation>;
    return _orderBy(_values(contentTypeAutomations), ['createdDT'], ['asc']);
}
);

