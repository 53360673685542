import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { faGripHorizontal, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useRef } from 'react';
import useSelectOption from '../../../../hooks/select-field/useSelectOption';

interface SelectConfigOptionProps {
  option: {
    label: string;
    value: string;
  };
  index: number;
  onMoveOption: any;
  onRemoveOption: any;
  onUpdateOption: any;
}
export const SelectConfigOption = (props: SelectConfigOptionProps) => {
  const { option, index, onRemoveOption, onUpdateOption, onMoveOption } = props;
  const { value, label } = option;
  const onChange = useCallback(
    (e: any) => {
      const newOptionLabel = e.target.value || '';
      onUpdateOption(index, newOptionLabel);
    },
    [index, onUpdateOption],
  );
  const { ref, isDragging, drag } = useSelectOption({ value, onMoveOption, index });
  return (
    <div ref={ref} className="p-1 d-flex flex-row align-items-center rounded" style={{ opacity: isDragging ? 0 : 1 }}>
      <div ref={drag} className="me-2 btn px-1">
        <FontAwesomeIcon icon={faGripHorizontal} />
      </div>
      <input className="form-control" value={label} onChange={onChange} />
      <button type="button" className="ms-2 p-0 btn" onClick={() => onRemoveOption(index)}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
    </div>
  );
};

export default SelectConfigOption;
