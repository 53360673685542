import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom";
import { getContentTypes, getContentTypesList } from "../../data/content-type/content-types/selectors"
import { getLastSelectedViewIdForContentType } from "../../data/content-type/record-views/selectors";


export const ContentTypesList = (props:any) => {
    const contentTypes = useSelector(getContentTypesList);
    const lastViewIds = useSelector(getLastSelectedViewIdForContentType)
    return (
        <div>
          <ul className="nav flex-column">
            {contentTypes.map((contentType) => (
                <NavLink key={contentType.slug} to={`/${contentType.slug}/${lastViewIds[contentType.slug] ?? ''}`}>{contentType.name}</NavLink>
            ))}
            </ul>
        </div>
    )
}


export default ContentTypesList;