import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, Link, Routes, Route } from 'react-router-dom';
import { createCampaign, setSelectedCampaignId } from '../../../data/campaigns/actions';
import { getCampaignsIsSaving, getSelectedCampaign } from '../../../data/campaigns/selectors';
import { useAppDispatch } from '../../../data/store';
import { Campaign } from '../../../data/types';

export const NewCampaignForm = (props: any) => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const isSaving = useSelector(getCampaignsIsSaving);
  const selectedCampaign = useSelector(getSelectedCampaign);

  const [newCampaign, setNewCampaign] = useState<Campaign>({
    companyId: 'taidacorp',
    campaignId: 'temp',
    contentType: 'saved-search-listings',
    name: 'New Campaign',
    marketingTemplateId: undefined,
    frequency: 'hourly',
    options: {
        shouldRunOnWeekends: true,
    },
    isEnabled: false,
    createdDT: '',
    updatedDT: '',
  });
  const onChangeName = useCallback((e) => {
    setNewCampaign((curr) => ({ ...curr, name: e.target.value ?? '' }));
  }, []);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    if(newCampaign.name === '') {
        alert('Name is required');
        return;
    }
    dispatch(createCampaign(newCampaign))
  }, [dispatch, newCampaign]);

  const navigate = useNavigate();
  const back = useCallback(() => {
    setIsVisible(false);
    dispatch(setSelectedCampaignId(undefined));
    setTimeout(() => {
      navigate(`/marketing/campaigns/`);
    }, 100);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (selectedCampaign !== undefined) {
      navigate(`/marketing/campaigns/${selectedCampaign.campaignId}`);
    }
  }, [navigate, selectedCampaign]);

  return (
    <Modal show={isVisible} onHide={back} keyboard={false}>
      <Modal.Header className="d-lg-none" closeButton>
        <Modal.Title>Marketing Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row m-0 border-bottom">
          <div className="py-3 col-12">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>New Campaign</div>
              <div>
                {isSaving && (
                  <div>
                    Saving... <FontAwesomeIcon icon={faCog} title={'Saving Marketing Campaign'} className={'fa-spin'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row px-0 m-0">
          <div className="col-12 p-2">
            <form onSubmit={onSubmit}>
              <div className="mb-3">
                <label htmlFor="templateName" className="form-label">
                  Campaign Name
                </label>
                <input
                  onChange={onChangeName}
                  value={newCampaign.name}
                  type="text"
                  className="form-control"
                  id="templateName"
                  aria-describedby="nameHelp"
                />
                <div id="nameHelp" className="form-text">
                  Name the campaign. You'll edit the rest of the campaign after it is created.
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewCampaignForm;
