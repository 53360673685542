import { faFile, faFileAudio } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map as _map } from 'lodash';
import React, { useMemo } from 'react';
import { DetailFieldProps, TableCellFieldProps } from '..';
import {
  Attachment,
  FieldAttachment,
  FieldAttachmentValue,
  isAudioAttachment,
  isFieldAttachmentValue,
  isFieldFormulaValue,
  isImageAttachment,
  isVideoAttachment,
} from '../../../data/types';

interface AttachmentDisplayCellProps extends TableCellFieldProps {
  field: FieldAttachment;
  ogValue: FieldAttachmentValue | string | null | undefined;
  onChange: (newValue: FieldAttachmentValue | undefined) => void;
  internalValue: FieldAttachmentValue | undefined;
  displayValue: FieldAttachmentValue | undefined;
  onFocus?: Function;
  onBlur?: Function;
  stopEditing: Function;
  startEditing: Function;
  isRowHovered: boolean;
}

export const AttachmentDisplayCell = ({ field, ogValue }: AttachmentDisplayCellProps) => {

  if (!isFieldAttachmentValue(ogValue) || ogValue.value === null) {
    return <div/>;
  }
  let attachments = ogValue.value as Attachment[];
  if (!Array.isArray(attachments)) {
    attachments = [attachments];
  }
  if (attachments.length === 0) {
    return <div />;
  }
  return (
    <div className='row'>
      {attachments.slice(0, 3).map((attachment) => {
        if (isImageAttachment(attachment)) {
          return (
            <div className='col-4'>
              <img height={35} width={35} src={attachment.thumbnail_150x150.ssl_url} alt={attachment.thumbnail_150x150.name} />
            </div>
          );
        }
        if (isVideoAttachment(attachment)) {
          return (
            <div className='col-4'>
              <img height={35} width={35} src={attachment.thumbnail_150x150[0].ssl_url} alt={attachment.thumbnail_150x150[0].name} />
            </div>
          );
        }
        if (isAudioAttachment(attachment)) {
          return (
            <div className='col-4'>
              <FontAwesomeIcon size={'2x'} color={'black'} icon={faFileAudio} />
            </div>
          );
        }
        return (
          <div className='col-4'>
            <FontAwesomeIcon size={'2x'} color={'black'} icon={faFile} />
          </div>
        );
      })}
    </div>
  );

};

export default AttachmentDisplayCell;
