import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { getSelectedView } from '../../../../data/views/selectors';


export const ViewAutomations = ({toggleAutomationPanel}: any) => {
  const selectedView = useSelector(getSelectedView);
  if(!selectedView || selectedView.type !== 'table') {
    return null;
  }
  return (
    <div tabIndex={0} className="deals-toolbar__item" onClick={toggleAutomationPanel}>
      <FontAwesomeIcon className='me-1' icon={faBolt} /> Automations
    </div>
  );
};

export default ViewAutomations;
