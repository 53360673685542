import {
  values as _values,
  orderBy as _orderBy,
  filter as _filter,
  map as _map,
  keyBy as _keyBy,
  sortBy as _sortBy,
  findIndex as _findIndex,
  find as _find,
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ViewField, KanbanView, TableViewField, FieldSelect, FieldSelectUser, FieldNumber, isFieldAttachmentValue, FieldAttachment, isFieldAttachment, Field } from '../types';
import { getSelectedView } from '../views/selectors';

export const getCompanyState = (state: RootState) => state.company;
export const getCompany = (state: RootState) => state.company.company;
export const getCompanyIsInitialized = (state: RootState) => state.company.isInitialized;
export const getFields = (state: RootState) => state.company.fields;

export const getCompanyName = createSelector(getCompany, (company) => (company !== undefined ? company.name : ''));
export const getCompanyFieldList = createSelector(getCompany, (company) =>
  company !== undefined ? company.fields : [],
);

export const getAllFieldsList = createSelector(getFields, (fields) => _values(fields));

// Return just the fields that are visible in the current view
export const getVisibleFields = createSelector(getCompany, getSelectedView, (company, view) => {
  if (view === undefined || company === undefined) {
    return [];
  }
  let allFields: ViewField[] = view.properties.fields;

  const visibleFields = _filter(allFields, { isVisible: true }) as { fieldId: string; isVisible: true }[];
  const visibleFieldIds = _map(visibleFields, (field) => field.fieldId);
  return _filter(company.fields, (field) => visibleFieldIds.indexOf(field.fieldId) !== -1);
});

export const getToggleableFields = createSelector(getCompany, getSelectedView, (company, view) => {
  if (view === undefined || company === undefined) {
    return [];
  }
  const viewFields: TableViewField[] = view.properties.fields;
  const companyPrimaryKeyField = company.fields[0];
  const primaryKeyField:TableViewField = _find(viewFields, {fieldId: companyPrimaryKeyField.fieldId}) ?? {fieldId: companyPrimaryKeyField.fieldId, isVisible: true, width: 100};

  const allFields = company.fields;
  const viewFieldsWithoutPrimary = _orderBy(viewFields.filter((field) => {
    const hasntBeenDeleted = _find(allFields, {fieldId: field.fieldId}) !== undefined;
    return field.fieldId !== companyPrimaryKeyField.fieldId && hasntBeenDeleted;
  }), ['isVisible'], ['desc'])
  const allMissingFields:TableViewField[] = allFields.filter((field) => {
    const isMissing = _find(viewFieldsWithoutPrimary, {fieldId: field.fieldId}) === undefined;
    return isMissing && field.fieldId !== companyPrimaryKeyField.fieldId;
  }).map((field):TableViewField => ({fieldId: field.fieldId, isVisible: false}));
  return [primaryKeyField, ...viewFieldsWithoutPrimary, ...allMissingFields];
});

export const getImageFields = createSelector(getCompany, getSelectedView, (company, view) => {
  if (view === undefined || company === undefined) {
    return [];
  }

  const allFields = company.fields;
  const attachmentFields = allFields.filter((field) => isFieldAttachment(field)) as FieldAttachment[];
  return attachmentFields.filter((field) => {
    if(field.config.fileType === [] || field.config.fileType === null || field.config.fileType.indexOf('image') !== -1) {
      return true;
    }
    return false;
  })

});

const reduceFieldWidths = (curr: Record<string, number>, field: TableViewField) => {
  const width = Math.max(80, field.width ?? 140);
  return { ...curr, [field.fieldId]: width};
};
export const getColumnWidths = createSelector(getSelectedView, (view) => {
  if (view === undefined || view.type !== 'table') {
    return {};
  }

  const values = view.properties.fields.reduce(reduceFieldWidths, {});
  return values;
});

export const getKanbanGroupingFields = createSelector(getAllFieldsList, (fields) => {
  return _filter(fields, (field) => field.type === 'select' || field.type === 'select-user') as (
    | FieldSelect
    | FieldSelectUser
  )[];
});

export const getKanbanSummationFields = createSelector(getAllFieldsList, (fields) => {
  return _filter(fields, (field) => field.type === 'number') as FieldNumber[];
});
