import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Assembly } from '@uppy/transloadit';
import { API } from 'aws-amplify';
import { createFormattedNotification } from '../../utils/notifications';
import { addNotification } from '../notifications/actions';
import { DealDocument, NewDocumentForm } from '../types';

export const setIsDownloading = createAction<boolean>('documents/setIsDownloading');
export const setFieldOnNewDocumentForm = createAction<{dealId:string; templateId:string; key:string;value:string}>('documents/setFieldOnNewDocumentForm');
export const setNewDocumentForm = createAction<{dealId:string; templateId:string; form: NewDocumentForm}>('documents/setNewDocumentForm');
export const updateNewDocumentForm = createAction<{dealId:string; templateId:string; form: Partial<NewDocumentForm>}>('documents/updateNewDocumentForm');

export const fetchDocuments = createAsyncThunk<{ documents: DealDocument[] }, undefined, { rejectValue: string[] }>(
  'documents/fetchDocuments',
  async () => {
    try {
      const { documents } = await API.get(
        'Documents', // function defined in our serverless.yml
        '', // the function's path
        { responseType: 'json' },
      );
      return { documents };
    } catch (error) {
      console.log(error);
      return { documents: [] };
    }
  },
);

export const createDocumentForDeal = createAsyncThunk<
  DealDocument,
  { dealId: string; document: NewDocumentForm },
  { rejectValue: string[] }
>('documents/createDocumentForDeal', async (createOptions, thunkApi) => {
  try {
    const channelsData = await API.post(
      'Documents', // function defined in our serverless.yml
      '', // the function's path
      { responseType: 'json', body: createOptions },
    );
    return channelsData;
  } catch (error) {
    console.log(error);
  }
});

export const sendDocument = createAsyncThunk<
  DealDocument,
  { documentId: string; message: string; subject: string; silent: boolean },
  { rejectValue: string[] }
>('documents/sendDocument', async (sendOptions, thunkApi) => {
  try {
    const { docSend, error } = await API.post(
      'Documents', // function defined in our serverless.yml
      `${sendOptions.documentId}/send`, // the function's path
      { responseType: 'json', body: sendOptions },
    );
    if (error !== undefined) {
      thunkApi.dispatch(addNotification(createFormattedNotification('Error Sending Document', error, 'danger')));
      throw new Error('Error Sending Document');
    }
    thunkApi.dispatch(addNotification(createFormattedNotification('Sent Document', '')));
    thunkApi.dispatch(fetchDocuments());
    return docSend;
  } catch (error) {
    alert('Error Sending Document');
    thunkApi.dispatch(addNotification(createFormattedNotification('Error Sending Document', `${error}`, 'danger')));
    console.log(error);
  }
});

export const fetchDocumentsForDeal = createAsyncThunk<
  { documents: DealDocument[] },
  { dealId: string },
  { rejectValue: string[] }
>('documents/fetchDocumentsForDeal', async ({ dealId }) => {
  try {
    const { documents } = await API.get(
      'Documents', // function defined in our serverless.yml
      `?dealRecordId=${dealId}`, // the function's path
      { responseType: 'json' },
    );
    return { documents };
  } catch (error) {
    console.log(error);
    return { documents: [] };
  }
});

export const downloadDocument = async (documentId: string, dispatchSetIsDownloading:(isDownloading:boolean)=>void): Promise<any> => {
  dispatchSetIsDownloading(true);
  const response = await API.get(
    'Documents', // function defined in our serverless.yml
    `${documentId}/download`, // the function's path
    { responseType: 'json' },
  );

  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = response.pdfURL;
  tempLink.setAttribute('download', response.name);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  // if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  // }
  dispatchSetIsDownloading(false);
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const getShareLinkAndCopy = async (documentId: string, recipientEmail:string, setIsFetchingShareLink:(isFetching:boolean)=>void): Promise<any> => {
  setIsFetchingShareLink(true);
  const response = await API.get(
    'Documents', // function defined in our serverless.yml
    `${documentId}/share-link?recipient=${encodeURIComponent(recipientEmail)}`, // the function's path
    { responseType: 'json' },
  );
  return response.shareURL;
};
