import { keyBy as _keyBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { Automation } from '../types';
import { createAutomation, deleteAutomation, fetchAutomations } from './actions';


interface AutomationsState {
  automations: Record<string, Automation>;
  isLoading: boolean;
  isCreating: boolean;
  isInitialized: boolean;
}

const initialState: AutomationsState = {
  automations: {},
  isLoading: false,
  isCreating: false,
  isInitialized: false,
};

export const automationsSlice = createSlice({
  name: 'automations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAutomations.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAutomations.fulfilled, (state, { payload }) => {
      state.automations = _keyBy(payload, 'automationId');
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchAutomations.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(createAutomation.pending, (state, { payload }) => {
      state.isCreating = true;
    });
    builder.addCase(createAutomation.fulfilled, (state, { payload }) => {
      state.automations = {...state.automations, [payload.automationId]: payload};
      state.isCreating = false;
      state.isInitialized = true;
    });
    builder.addCase(createAutomation.rejected, (state, { payload }) => {
      state.isCreating = false;
      state.isInitialized = true;
    });
    builder.addCase(deleteAutomation.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAutomation.fulfilled, (state, { payload }) => {
      const {
        [payload]: deletedOne,
        ...remaining
      } = state.automations;
      state.automations = remaining;
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(deleteAutomation.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });
  },
});

export default automationsSlice;
