import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { remove } from '../../utils/array';
import { RootState } from '../store';
import { Company, SavedLink } from '../types';


export const fetchCompany = createAsyncThunk<Company, undefined, { rejectValue: string[] }>(
  'company/fetch',
  async () => {
      const companyData = await API.get(
        'Company', // function defined in our serverless.yml
        '', // the function's path
        { responseType: 'json' },
      );
      return companyData;
  },
);

export const addCompanyLink = createAsyncThunk<Company, SavedLink, { rejectValue: string[] }>('company/add-link', async (newLink, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const company = state.company.company;
  const updatedCompany = {
    ...company,
    links: [...company!.links, newLink]
  } as Company;
  const companyData = await API.post(
    'Company', // function defined in our serverless.yml
    '', // the function's path
    { responseType: 'json', body: {company: updatedCompany} },
  );
  return companyData;
})
export const deleteCompanyLink = createAsyncThunk<Company, number, { rejectValue: string[] }>('company/delete-link', async (linkIndex, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const company = state.company.company;
  const updatedCompany = {
    ...company,
    links: remove(company!.links, linkIndex)
  } as Company;
  const companyData = await API.post(
    'Company', // function defined in our serverless.yml
    '', // the function's path
    { responseType: 'json', body: {company: updatedCompany} },
  );
  return companyData;
})

export const updateCompany = createAsyncThunk<Company, Partial<Company>, { rejectValue: string[] }>('company/update', async (companyUpdates, thunkApi) => {
  const viewData = await API.post(
    'Company', // function defined in our serverless.yml
    '', // the function's path
    { responseType: 'json', body: {company: companyUpdates} },
  );
  return viewData;

})

export const realTimeCompanyUpdated = createAction<{company: Company}>('company.updated');
