import { isString, isStringArray } from '@taida-corp/taidacorp-sdk';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { TableCellDisplayComponentProps } from '../base';

export const TextTableCellDisplayComponent = ({
  field,
  value,
  extraProps = {},
}: TableCellDisplayComponentProps) => {

  const errors = useMemo(() => {
    if (value === undefined || value === null) {
      return [] as string[];
    }
    if (!isStringArray(value.errors)) {
        return [] as string[];
      }
    return value.errors;
  }, [value]);

  const displayValue = useMemo(() => {
    if (value === undefined || value === null) {
        return '';
      }
      return value.displayValue;
  }, [value])


  return (
    <>
    <div
      {...extraProps}
    >{displayValue}</div>
    {/* {errors.map((err) => <div key={err} className='mt-1'><small className='text-danger'>{err}</small></div>)} */}
    </>
  );
};

export default TextTableCellDisplayComponent;
