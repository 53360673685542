import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { DealActivity } from '../types';



export const fetchDealActivities = createAsyncThunk<DealActivity[], {dealId:string}, { rejectValue: string[] }>(
    'dealActivities/fetchDealActivities',
    async ({dealId}) => {
      try {
        const data = await API.get(
          'DealActivities', // function defined in our serverless.yml
          `${dealId}/`,            // the function's path
          { 'responseType': 'json' }
        );
        return data.activities;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  
  export const createDealActivity = createAsyncThunk<DealActivity, Partial<DealActivity>, { rejectValue: string[] }>(
    'dealActivities/sendDealActivity',
    async (dealActivity) => {
      try {
        const resp = await API.post(
          'DealActivities', // function defined in our serverless.yml
          `${dealActivity.dealId!}/`,            // the function's path
          { 'responseType': 'json', body: dealActivity }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  



export const realTimeDealActivityUpdated = createAction<{ dealActivity: DealActivity }>('dealActivity.updated');
export const realTimeDealActivityDeleted = createAction<{ dealActivity: DealActivity }>('dealActivity.deleted');
export const realTimeDealActivityCreated = createAction<{ dealActivity: DealActivity }>('dealActivity.created');
