import { update } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SelectTableEditCellPopover from './SelectTableEditCellPopover';
import { usePopper } from 'react-popper';
import usePortal from 'react-useportal';
import { Field, FieldSelect } from '../../../../data/types';
interface SelectTableEditCellProps {
    field: Field;
  onChange: (newValue: string | undefined | any) => void;
  internalValue: string | undefined | any;
  displayValue: string | undefined | any;
  stopEditing: Function;
}

export const SelectTableEditCell = ({ field, onChange, internalValue, displayValue, stopEditing }: SelectTableEditCellProps) => {
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start'],
        },
      },
    ],
  });
  const { Portal } = usePortal({ closeOnOutsideClick: false });
  return (
    <>
      <div ref={setReferenceElement} className="w-100 h-100 d-flex flex-row align-items-center">{displayValue}</div>
      <Portal>
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <SelectTableEditCellPopover stopEditing={stopEditing} field={field as FieldSelect} onChange={onChange} internalValue={internalValue} />
        </div>
      </Portal>
    </>
  );
};

export default SelectTableEditCell;
