import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AudioAttachment } from '@taida-corp/taidacorp-sdk';
import { useMemo } from 'react';
import getFontAwesomeIconFromMIME from '../../../../../../../utils/getFontAwesomeIconFromMime';

export const AudioAttachmentView = ({
  attachment,
  onDelete,
}: {
  attachment: AudioAttachment;
  onDelete: () => void;
}) => {
  const FaIcon = useMemo(() => {
    return getFontAwesomeIconFromMIME(attachment.original.mime);
  }, [attachment.original.mime]);
  return (
    <div className="w-100 bg-light p-4 position-relative ">
      <div>{attachment.original.name}</div>
      <div className="row mt-3">
        <div >
        <audio controls>
              <source src={attachment.audio_aac.ssl_url} type="video/mp4" />
            </audio>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <a href={attachment.original.ssl_url} target="_blank" rel="noreferrer">
            <div className="bg-white rounded p-3 d-inline-flex flex-column ">
              <FontAwesomeIcon size="10x" color={'black'} icon={FaIcon} />
              <div>{attachment.original.name}</div>
            </div>
          </a>
        </div>
      </div>
      <button type="button" onClick={onDelete} className="btn btn-sm btn-danger">
        Delete Asset
      </button>
    </div>
  );
};

export default AudioAttachmentView;
