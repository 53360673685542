import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { createFormattedNotification } from '../../utils/notifications';
import { addNotification } from '../notifications/actions';
import { Contact } from '../types';

// sendContactPreview, fetchContacts, createContact, realTimeContactCreated, realTimeContactDeleted, realTimeContactUpdated
export const setSelectedContactId = createAction<string | undefined>('contact.setSelectedContactId');

export const fetchContacts = createAsyncThunk<Contact[], undefined, { rejectValue: string[] }>(
  'contacts/fetchContacts',
  async () => {
    try {
      const data = await API.get(
        'Contacts', // function defined in our serverless.yml
        '', // the function's path
        { responseType: 'json' },
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const fetchContact = createAsyncThunk<Contact, { contactId: string }, { rejectValue: string[] }>(
  'contacts/fetchContact',
  async ({ contactId }) => {
    try {
      const data = await API.get(
        'Contacts', // function defined in our serverless.yml
        contactId, // the function's path
        { responseType: 'json' },
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createContact = createAsyncThunk<Contact, Partial<Contact>, { rejectValue: string[] }>(
  'contacts/createContact',
  async (contact, thunkApi) => {
    try {
      const resp = await API.post(
        'Contacts', // function defined in our serverless.yml
        ``, // the function's path
        { responseType: 'json', body: contact },
      );

      return resp;
    } catch (error: any) {
      if (error.response === undefined) {
        throw error;
      }
      thunkApi.dispatch(addNotification(createFormattedNotification('Error', error.response.data.error[0], 'warning')));
      return thunkApi.rejectWithValue(error.response.data.error);
    }
  },
);

export const updateContact = createAsyncThunk<Contact, Partial<Contact>, { rejectValue: string[] }>(
  'contacts/updateContact',
  async (contact, thunkApi) => {
    try {
      const resp = await API.post(
        'Contacts', // function defined in our serverless.yml
        contact.contactId, // the function's path
        { responseType: 'json', body: contact },
      );
      return resp;
    } catch (error:any) {
      if (error.response === undefined) {
        throw error;
      }
      thunkApi.dispatch(addNotification(createFormattedNotification('Error', error.response.data.error[0], 'warning')));
      thunkApi.rejectWithValue(error.response.data.error);
      throw error;    }
  },
);

export const realTimeContactUpdated = createAction<{ contact: Contact }>('contact.updated');
export const realTimeContactDeleted = createAction<{ contact: Contact }>('contact.deleted');
export const realTimeContactCreated = createAction<{ contact: Contact }>('contact.created');
