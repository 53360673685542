import { Portal } from "@headlessui/react";
import { Attachment, FieldAttachment } from "@taida-corp/taidacorp-sdk";
import { UploadResult } from "@uppy/core";
import { Dashboard, DashboardModal } from "@uppy/react";
import { useState, useCallback } from "react";
import useFileUploader from "../../../../../../hooks/useFileUploader/useFileUploader";

interface AttachmentInlineUploaderProps {
    field:FieldAttachment;
    onComplete:(newAttachments:Attachment[]) => void;
}
export const AttachmentInlineUploader = ({field, onComplete}:AttachmentInlineUploaderProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleEditing = () => setIsVisible(val => !val);
    
      const uppy = useFileUploader(field.config, onComplete);
    
      return uppy !== null && isVisible ? (
        <>
          <Dashboard
            uppy={uppy}
            closeModalOnClickOutside
            plugins={['Webcam', 'Unsplash', 'Instagram', 'ScreenCapture', 'Audio', 'Zoom', 'Url']}
          />
          <button type='button' className='btn btn-link' onClick={toggleEditing}>Toggle Uploader</button>
          </>
          ) : (
        <button type='button' className='btn btn-link' onClick={toggleEditing}>Toggle Uploader</button>
      );
}

export default AttachmentInlineUploader;