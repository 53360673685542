import { find as _find, debounce as _debounce, findIndex as _findIndex, findLastIndex as _findLastIndex, cloneDeep as _cloneDeep } from 'lodash';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { DealView, Field, KanbanView, TableView, TableViewField, ViewField } from '../../../../data/types';
import { FieldsToggle } from './FieldsToggle';
import { useAppDispatch } from '../../../../data/store';
import { move } from '../../../../utils/array';
import { localUpdateIsVisible, localUpdateViewFields, persistViewUpdates, updateDealView } from '../../../../data/views/actions';
import { KanbanCardField } from '../../../../components/Views/KanbanBoard/Board';
import { useSelector } from 'react-redux';
import { getToggleableFields } from '../../../../data/company/selectors';


export interface FieldsToggleListProps {
  view: TableView | KanbanView;
}

export const FieldsToggleList = ({ view }: FieldsToggleListProps) => {
  const fields = useSelector(getToggleableFields);
  const dispatch = useAppDispatch();

  const indexForNextToggledVisible = useMemo(() => {
    return _findLastIndex(fields, {isVisible: true}) + 1;
  }, [fields])

  const indexForNextToggledNotVisible = useMemo(() => {
    return _findIndex(fields, {isVisible: false}) - 1;
  }, [fields])

  const debouncedUpdateDealRecord = useMemo(() => {
    return _debounce(
      (viewId:string) => dispatch(persistViewUpdates(viewId)),
      3000,
    );
  }, [dispatch]);

  const onMoveFieldLocal = useCallback(
    (startIndex: number, endIndex: number) => {
      const updatedFields: ViewField[] = move(fields, startIndex, endIndex);
      dispatch(localUpdateViewFields({viewId: view!.viewId, fields: updatedFields}))
      debouncedUpdateDealRecord(view!.viewId);
    },
    [debouncedUpdateDealRecord, dispatch, fields, view],
  );
  const onDrop = useCallback(
    () => {
      debouncedUpdateDealRecord(view!.viewId);
    },
    [debouncedUpdateDealRecord, view],
  );
  const onSetFieldIsVisibleLocal = useCallback(
    (fieldId:string, isVisible:boolean, startIndex:number) => {
      const fieldsCopy = _cloneDeep(fields);
      const ogField = fieldsCopy[startIndex];
      fieldsCopy.splice(startIndex, 1, {...ogField, isVisible});
      const newIndex = isVisible ? indexForNextToggledVisible : indexForNextToggledNotVisible;
      const updatedFields: ViewField[] = move(fieldsCopy, startIndex, newIndex);
      dispatch(localUpdateViewFields({viewId: view!.viewId, fields: updatedFields}))
      debouncedUpdateDealRecord(view!.viewId);
    },
    [debouncedUpdateDealRecord, dispatch, fields, indexForNextToggledNotVisible, indexForNextToggledVisible, view],
  );  
  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{maxHeight: 200, overflow: 'scroll'}}>
      {fields.map((field, idx: number) => (
        <FieldsToggle
          key={`${field.fieldId}-${field.isVisible}`}
          field={field}
          index={idx}
          view={view}
          onMoveField={onMoveFieldLocal}
          onDropField={onDrop}
          setFieldIsVisible={onSetFieldIsVisibleLocal}
        />
      ))}
      </div>
    </DndProvider>
  );
};

export default FieldsToggleList;
