import { values as _values, orderBy as _orderBy, filter as _filter, get as _get } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getSelectedDeal, getSelectedDealId } from '../deals/selectors';
import { getSelectedDocumentTemplateId } from '../documentTemplates/selectors';
import { NewDocumentForm } from '../types';

const DEAL_FIELD_ADDRESS_LINE_1 = '689a69f8-b970-4fcf-89ce-a166732db4c0';

export const getDocumentsState = (state: RootState) => state.documents;

export const getDocuments = createSelector(getDocumentsState, (state) => state.documents);
export const getDocumentsList = createSelector(getDocuments, (documents) => _values(documents));

export const getIsInitialized = createSelector(getDocumentsState, (state) => state.isInitialized);
export const getIsLoading = createSelector(getDocumentsState, (state) => state.isLoading);
export const getNewDocumentForm = createSelector(getDocumentsState, getSelectedDealId, getSelectedDocumentTemplateId, (state, dealId, templateId) => {
  return _get(state, ['newDocumentForms', `${dealId}`, `${templateId}`], {}) as NewDocumentForm;
});
export const getIsDocumentSending = createSelector(getDocumentsState, (state) => state.isSending);
export const getIsDocumentDownloading = createSelector(getDocumentsState, (state) => state.isDownloading);

export const getDocumentsForSelectedDealList = createSelector(getDocumentsList, getSelectedDeal, (documents, deal) => {
  if (deal === undefined) {
    return [];
  }

  const dealId = deal.dealId;
  
  // const line1 = deal.fields[DEAL_FIELD_ADDRESS_LINE_1] as string;
  return _filter(documents, (document) => {
    return !!document.metadata && document.metadata.dealRecordId === dealId
  });
  
});
