import { v4 as uuidv4 } from 'uuid';
import { ToastNotification } from '../data/types';

export const createFormattedNotification = (
  title: string,
  message: string,
  type: 'primary' | 'warning' | 'danger' = 'danger',
  ttl: number = 4000,
): ToastNotification => ({
  companyId: '',
  notificationId: uuidv4(),
  createdDT: new Date().toISOString(),
  title,
  message,
  type,
  isAlertVisible: true,
  ttl: ttl === -1 ? undefined : ttl,
});
