import { values as _values } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';


export const getDocumentTemplatesState = (state: RootState) => state.documentTemplates;

export const getDocumentTemplates = createSelector(getDocumentTemplatesState, (state) => state.templates);
export const getDocumentTemplatesList = createSelector(getDocumentTemplates, (templates) => _values(templates));

export const getIsInitialized = createSelector(getDocumentTemplatesState, (state) => state.isInitialized);
export const getIsLoading = createSelector(getDocumentTemplatesState, (state) => state.isLoading);


export const getIsLoadingDetails = createSelector(getDocumentTemplatesState, (state) => state.isLoadingDetails);
export const getSelectedDocumentTemplate = createSelector(getDocumentTemplatesState, (state) => state.selectedDocumentTemplate);
export const getSelectedDocumentTemplateId = createSelector(getDocumentTemplatesState, (state) => state.selectedDocumentTemplateId);
