import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { APIView, RecordAutomation } from "@taida-corp/taidacorp-sdk";
import { API } from "aws-amplify";

export const setSelectedAutomationId = createAction<{automationId: string|undefined}>('recordAutomations.setSelectedAutomationId');
export const realTimeRecordAutomationUpdated = createAction<{ automation: RecordAutomation }>('recordAutomations.updated');
export const realTimeRecordAutomationDeleted = createAction<{ automation: RecordAutomation }>('recordAutomations.deleted');
export const realTimeRecordAutomationCreated = createAction<{ automation: RecordAutomation }>('recordAutomations.created');


export const fetchRecordAutomations = createAsyncThunk<RecordAutomation[], {contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordAutomations/fetchRecordAutomations',
    async ({contentTypeSlug}) => {
      try {
        const data = await API.get(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/automations`,            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const fetchRecordAutomation = createAsyncThunk<RecordAutomation, {contentTypeSlug:string, automationId:string}, { rejectValue: string[] }>(
    'recordAutomations/fetchRecordAutomation',
    async ({contentTypeSlug, automationId}) => {
      try {
        const data = await API.get(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/automations/${automationId}`,            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  
  export const createRecordAutomation = createAsyncThunk<RecordAutomation, {automation: Partial<RecordAutomation>, contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordAutomations/createRecordAutomation',
    async ({contentTypeSlug, automation}) => {
      try {
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/automations`,            // the function's path
          { 'responseType': 'json', body: automation }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const updateRecordAutomation = createAsyncThunk<RecordAutomation, {automation:Partial<RecordAutomation>; contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordAutomations/updateRecordAutomation',
    async ({contentTypeSlug, automation}) => {
      try {
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/automations/${automation.automationId!}`,            // the function's path
          { 'responseType': 'json', body: automation }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  

  export const enableRecordAutomation = createAsyncThunk<RecordAutomation, {automationId:string; contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordAutomations/enableRecordAutomation',
    async ({contentTypeSlug, automationId}) => {
      try {
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/automations/${automationId}/enable`,            // the function's path
          { 'responseType': 'json', body: {} }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  export const disableRecordAutomation = createAsyncThunk<RecordAutomation, {automationId:string; contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordAutomations/disableRecordAutomation',
    async ({contentTypeSlug, automationId}) => {
      try {
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/automations/${automationId}/disable`,            // the function's path
          { 'responseType': 'json', body: {} }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  export const deleteRecordAutomation = createAsyncThunk<RecordAutomation, {automationId:string, contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordAutomations/deleteRecordAutomation',
    async ({contentTypeSlug, automationId}, thunkApi) => {
      const resp = await API.del(
        'Content', // function defined in our serverless.yml
        `${contentTypeSlug}/automations/${automationId!}`,            // the function's path
        { responseType: 'json', body: {} },
      );
      return resp;
    },
  );
  
  export const createAPIViewForAutomationAction = createAsyncThunk<{automation:RecordAutomation, apiView:APIView}, {automationId:string, automationActionId:string, contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordAutomations/createAPIViewForAutomationAction',
    async ({contentTypeSlug, automationActionId, automationId}) => {
      try {
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/automations/${automationId!}/actions/${automationActionId}/inputs`,            // the function's path
          { 'responseType': 'json', body: {} }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const updateAPIViewForAutomationAction = createAsyncThunk<{automation:RecordAutomation, apiView:APIView}, {automationId:string, automationActionId:string, contentTypeSlug:string, apiView:APIView}, { rejectValue: string[] }>(
    'recordAutomations/updateAPIViewForAutomationAction',
    async ({contentTypeSlug, automationActionId, automationId, apiView}) => {
      try {
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/automations/${automationId!}/actions/${automationActionId}/inputs`,            // the function's path
          { 'responseType': 'json', body: {apiView} }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );