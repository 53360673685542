import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import DealDetailPage from '../../pages/deal-detail/DealDetailPage';
import Deals from '../../pages/deals/Deals';
import NewDealFormPage from '../../pages/new-deal-form/NewDealFormPage';
import NotificationsList from '../../pages/notifications/NotificationsList';

export const NotificationsRoutes = () => {
  return (
    <>
    <Routes>
      <Route path="*" element={<NotificationsList />} />
    </Routes>
    <Routes>
    <Route path="/modal/deals/:dealId/*" element={<DealDetailPage />}/>
      {/* <Route path="*" element={<Navigate to="/deals" />} /> */}
    </Routes>
    </>
    
  );
};

export default NotificationsRoutes;
