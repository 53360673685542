import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DealRecord, DocumentTemplate, NewDocumentForm, User } from '../../../../../data/types';
import { getRecipientsDropdownOptions } from './dropdownSelectors';
import { InputWithDropdownStrings } from './InputWithDropdownStrings';

interface Step4Props {
  form: NewDocumentForm;
  onChange: (formUpdates: Partial<NewDocumentForm>) => void;
  next: () => void;
  back: () => void;
  documentTemplates: DocumentTemplate[];
  selectedDocumentTemplate: DocumentTemplate;
  deal: DealRecord;
  users: User[];
}

const CLIENT_firstName_FIELD_ID = '';
const CLIENT_lastName_FIELD_ID = '';
const CLIENT_EMAIL_FIELD_ID = '';

export const Step4 = ({ form, onChange, next, documentTemplates, selectedDocumentTemplate, back }: Step4Props) => {
  const isNextEnabled = useMemo(() => {
    return true;
  }, []);
  const isBackEnabled = useMemo(() => {
    return true;
  }, []);

  const roleLength = selectedDocumentTemplate.roles!.length;

  const dropdownOptions = useSelector(getRecipientsDropdownOptions);

  const onChangeRecipient = useCallback(
    (index: number, newValue: string, recipientKey: 'firstName' | 'lastName' | 'email') => {
      const curr = [...form.recipients!];
      const currRecipient = { ...curr[index] };
      curr.splice(index, 1, { ...currRecipient, [recipientKey]: newValue });
      onChange({ recipients: curr });
    },
    [form.recipients, onChange],
  );

  const onAddRecipient = useCallback(() => {
    onChange({recipients: [...form.recipients!, {firstName: 'Joe', lastName: 'Smith', email: '', signingOrder: form.recipients!.length}]})
  }, [form.recipients, onChange])
  return (
    <div className="h-100 d-flex flex-column p-3">
      <div>
        <h3>Recipients</h3>
        <small>Make sure the recipients for the document match up with the deal data</small>
      </div>
      <div className="flex-grow-1">
        {selectedDocumentTemplate.roles.map((role: any, index: number) => (
          <React.Fragment key={`role-${role.name}`}>
            <h3>{role.name}</h3>
            <table className="table table-responsive">
              <thead>
                <tr>
                  <td>Recipient Field</td>
                  <td>Value</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>
                    <InputWithDropdownStrings
                      isDisabled={role.preassignedPerson !== null}
                      value={form.recipients![index].firstName ?? ''}
                      onChange={(newValue) => onChangeRecipient(index, newValue, 'firstName')}
                      dropdownOptions={dropdownOptions}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>
                    <InputWithDropdownStrings
                      isDisabled={role.preassignedPerson !== null}
                      value={form.recipients![index].lastName ?? ''}
                      onChange={(newValue) => onChangeRecipient(index, newValue, 'lastName')}
                      dropdownOptions={dropdownOptions}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <InputWithDropdownStrings
                      isDisabled={role.preassignedPerson !== null}
                      value={form.recipients![index].email ?? ''}
                      onChange={(newValue) => onChangeRecipient(index, newValue, 'email')}
                      dropdownOptions={dropdownOptions}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        ))}
        {form.recipients!.slice(roleLength).map((recipient: any, index: number) => (
          <>
            <h3>Extra Recipient {index + 1}</h3>
            <table className="table table-responsive">
              <thead>
                <tr>
                  <td>Recipient Field</td>
                  <td>Value</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>
                    <InputWithDropdownStrings
                      value={recipient.firstName ?? ''}
                      onChange={(newValue) => onChangeRecipient(index + roleLength, newValue, 'firstName')}
                      dropdownOptions={dropdownOptions}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>
                    <InputWithDropdownStrings
                      value={recipient.lastName ?? ''}
                      onChange={(newValue) => onChangeRecipient(index + roleLength, newValue, 'lastName')}
                      dropdownOptions={dropdownOptions}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>
                    <InputWithDropdownStrings
                      value={recipient.email ?? ''}
                      onChange={(newValue) => onChangeRecipient(index + roleLength, newValue, 'email')}
                      dropdownOptions={dropdownOptions}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ))}
        <button type='button' className='btn btn-secondary mb-3' onClick={onAddRecipient}>Add Recipient</button>
      </div>
      <div className="flex-shrink-1 d-flex flex-row justify-content-between">
        <button className="btn btn-primary" disabled={!isBackEnabled} onClick={back}>
          Back
        </button>

        <button className="btn btn-primary" disabled={!isNextEnabled} onClick={next}>
          Create
        </button>
      </div>
    </div>
  );
};

export default Step4;
