import { FilterConditionGroup } from "../filters";

export interface BaseCampaign {
  companyId: string;
  campaignId: string;
  contentType: 'saved-search-listings' | 'fuzzy-match-listings';
  frequency: 'hourly' | 'daily' | 'monthly' | 'weekly'|'sign-up';
  options: any;
  name: string;
  marketingTemplateId?: string;
  isEnabled: boolean;
  lastRanDT?: string;
  createdDT: string;
  updatedDT: string;
}

export const isSignUpCampaign = (campaign: unknown): campaign is SignUpCampaign => {
  return (campaign as BaseCampaign).frequency === 'sign-up';
};
export interface SignUpCampaign extends BaseCampaign {
  frequency: 'sign-up';
  options: {
      filters: FilterConditionGroup;
  };
}
export const isHourlyCampaign = (campaign: unknown): campaign is HourlyCampaign => {
  return (campaign as BaseCampaign).frequency === 'hourly';
};

export interface HourlyCampaign extends BaseCampaign {
  frequency: 'hourly';
  options: {
    shouldRunOnWeekends: boolean;
  };
}
export const isDailyCampaign = (campaign: unknown): campaign is DailyCampaign => {
  return (campaign as BaseCampaign).frequency === 'daily';
};
export interface DailyCampaign extends BaseCampaign {
  frequency: 'daily';
  options: {
    shouldRunOnWeekends: boolean;
    timeOfDay: string;
  };
}

export const isMonthlyCampaign = (campaign: unknown): campaign is MonthlyCampaign => {
  return (campaign as BaseCampaign).frequency === 'monthly';
};
export interface MonthlyCampaign extends BaseCampaign {
  frequency: 'monthly';
  options: {
    dayOfMonth: number;
    timeOfDay: string;
  };
}

export const isWeeklyCampaign = (campaign: unknown): campaign is WeeklyCampaign => {
  return (campaign as BaseCampaign).frequency === 'weekly';
};
export interface WeeklyCampaign extends BaseCampaign {
  frequency: 'weekly';
  options: {
    dayOfTheWeek: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
    timeOfDay: string;
  };
}

export type Campaign = HourlyCampaign | DailyCampaign | MonthlyCampaign | WeeklyCampaign | SignUpCampaign;
