import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import TableContentPane from './TableContentPane/TableContentPane';
import TableDataRow from './TableDataRow/TableDataRow';
import TableFooter from './TableFooter/TableFooter';
import TableHeader from './TableHeader/TableHeader';
import { DealView, TableViewProperties, TableViewField, Field, TableView } from '../../../data/types';

import './Table.scss';
import { MultiGrid } from 'react-virtualized';
import CellWrapper from './TableCells/CellWrapper';
import { useSelector } from 'react-redux';
import { getSelectedViewDeals } from '../../../data/deals/selectors';
import AddFieldButton from './AddFieldButton/AddFieldButton';
import { getColumnWidths } from '../../../data/company/selectors';
import { Link } from 'react-router-dom';

interface TableProps {
  readonly fields: Record<string, Field>;
  readonly view: DealView;
  readonly dealIds: string[];
  readonly onCreateRecord: () => void;
}

const STYLE = {

};
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: '2px solid #aaa',
  backgroundColor: '#f7f7f7',
};
const STYLE_TOP_LEFT_GRID = {
  // borderBottom: '2px solid #aaa',
  borderRight: '2px solid #aaa',
  borderTop: '1px solid #dee2e6',
  borderBottom: '1px solid #dee2e6',
  backgroundColor: '#f5f7f7',
  borderSpacing: '2px'

};
const STYLE_TOP_RIGHT_GRID = {
  borderTop: '1px solid #dee2e6',
  borderBottom: '1px solid #dee2e6',
  backgroundColor: '#f5f7f7',
  borderSpacing: '2px'
};

export const Table = ({ fields, view, onCreateRecord, dealIds }: TableProps) => {
  const grid = useRef<MultiGrid|null>();
  const [hoveredRowIndex, setHoveredRowIndex] = useState<null|number>(null);
  const tableProps = view.properties as TableViewProperties;
  const widths = useSelector(getColumnWidths);
  const fieldsCount = useMemo(() => {
    return tableProps.fields.filter((tableViewField) => tableViewField.isVisible && fields[tableViewField.fieldId] !== undefined).length;
  }, [fields, tableProps.fields]);
  const addRowLink = useMemo(() => `/deals/${view.viewId}/edit/new`, [view.viewId]);
  const dealRecords = useSelector(getSelectedViewDeals);
  const _cellRender = useCallback(
    ({ columnIndex, key, rowIndex, style, parent }: any) => {
      if(rowIndex === dealIds.length + 1 ) {
        if(columnIndex === 0) {
          return (
            <div style={style}>
              <div className='p-1'>
              <Link to={addRowLink}>Add Row</Link>
              </div>
            </div>
          )
        }
        return null;
        
      }
      if(columnIndex === fieldsCount) {
        if(rowIndex > 0) {
          return null;
        }
        return <AddFieldButton key={key} style={style} view={view}/>
      }
      return (
        <CellWrapper {...{grid, hoveredRowIndex, setHoveredRowIndex,  data: dealRecords, fields, view: view as TableView, parent, columnIndex, key, rowIndex, style }} />
      );
    },
    [dealRecords,dealIds, fields, hoveredRowIndex, view, fieldsCount, addRowLink],
  );
  const _columnWidth = useCallback(
    ({ index }: any) => {
      if(index === fieldsCount) {
        return 100;
      }
      const fieldId = tableProps.fields.filter((tableViewField) => tableViewField.isVisible)[index].fieldId;
      return widths[fieldId] ?? 140;
    },
    [tableProps, fieldsCount, widths],
  );
  useEffect(() => {
    if(grid.current) {
      grid.current.recomputeGridSize();
    }
  }, [widths])
  return (
    <div className="h-100 w-100" style={{ overflow: 'hidden' }}>
      <AutoSizer >
        {({ width, height }) => (
          <MultiGrid
          ref={(ref) => { grid.current = ref }}
          onSectionRendered={(...wut) => {
              console.log(wut);
            }}
            fixedColumnCount={1}
            fixedRowCount={1}
            scrollToColumn={0}
            scrollToRow={0}
            cellRenderer={_cellRender}
            columnWidth={_columnWidth}
            columnCount={fieldsCount + 1}
            height={height}
            rowHeight={({index}) => index === 0 ? 34 : 45}
            rowCount={dealIds.length + 2}
            style={STYLE}
            styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
            styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
            styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
            width={width}
            enableFixedColumnScroll
            enableFixedRowScroll
            hideTopRightGridScrollbar
            hideBottomLeftGridScrollbar
          />
        )}
      </AutoSizer>
      <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <TableFooter total={dealIds.length} addRowLink={addRowLink} />
        </div>
    </div>
  );

};

export default Table;
