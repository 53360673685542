import { filter as _filter, find as _find } from 'lodash';
import { faTimesCircle, faCaretDown, faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, KanbanView, SORT_OPTIONS, TableView } from '@taida-corp/taidacorp-sdk';
import SortedFieldRow from './SortedFieldRow';
import { remove } from '../../../../utils/array';

interface SorterBuilderFormProps {
  view: TableView | KanbanView;
  allFields: Field[];
  updateOrderBy: (newOrderBy: [string[], SORT_OPTIONS[]]) => void;
}
export const SorterBuilderForm = ({ view, allFields, updateOrderBy }: SorterBuilderFormProps) => {
  const [fields, ordering] = view.properties.orderBy || [[], []];
  const availableFields = useMemo(() => {
    return _filter(allFields, (field) => fields.indexOf(field.fieldId) === -1);
  }, [fields, allFields]);

  const addNextSortingField = useCallback(() => {
    if (availableFields.length === 0) {
      return;
    }
    const nextField = availableFields[0];
    const [currFields, currOrders] = view.properties.orderBy;
    updateOrderBy([
      [...currFields, nextField.fieldId],
      [...currOrders, 'asc'],
    ]);
  }, [availableFields, updateOrderBy, view.properties.orderBy]);
  const updateSortingField = useCallback(
    (newField: string, newOrder: 'asc' | 'desc', index: number) => {
      let [currFields, currOrders] = view.properties.orderBy;
      currFields[index] = newField;
      currOrders[index] = newOrder;
      updateOrderBy([[...currFields], [...currOrders]]);
    },
    [updateOrderBy, view.properties.orderBy],
  );
  const removeSortingField = useCallback(
    (index: number) => {
      let [currFields, currOrders] = view.properties.orderBy;
      const updatedFields = remove(currFields, index);
      const updatedOrders = remove(currOrders, index);
      updateOrderBy([[...updatedFields], [...updatedOrders]]);
    },
    [updateOrderBy, view.properties.orderBy],
  );

  return (
    <div >
      {fields.length === 0 && (
        <div>
          <small className="text-muted">No sorts applied to this view</small>
        </div>
      )}
      {fields.length > 0 && (
        <div className="view-sort__current-fields-list">
          {fields.map((field, orderIndex) => (
            <SortedFieldRow
              ordering={ordering}
              fields={allFields}
              availableFields={availableFields}
              field={field}
              index={orderIndex}
              updateSortingField={updateSortingField}
              removeSortingField={removeSortingField}
            />
          ))}
        </div>
      )}
      {availableFields.length !== 0 ? (
        <>
          {fields.length === 0 ? (
            <>
              <button onClick={addNextSortingField} className="btn btn-link text-black ps-0">
                <small className="text-muted">Add a field to sort by</small>
              </button>
            </>
          ) : (
            <button onClick={addNextSortingField} className="btn btn-link text-black ps-0">
              <small className="text-muted">Add another field to sort by</small>
            </button>
          )}
        </>
      ) : (
        <small className="text-muted">No more fields to order by...</small>
      )}
    </div>
  );
};

export default SorterBuilderForm;
