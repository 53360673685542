import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { RootState } from '../store';
import { Company, Field, FieldConfig, FieldType } from '../types';
import { fetchViews } from '../views/actions';
import { fetchCompany } from './companyActions';

export const createField = createAsyncThunk<
  Company,
  { name?: string; type?: FieldType; config?: FieldConfig; viewId?: string },
  { rejectValue: string[] }
>('company/create-field', async ({ viewId, ...field }, thunkApi) => {
  const fieldData = await API.post(
    'Fields', // function defined in our serverless.yml
    '', // the function's path
    {
      responseType: 'json',
      queryStringParameters: { viewId },
      body: { field },
    },
  );
  thunkApi.dispatch(fetchViews());
  thunkApi.dispatch(fetchCompany());
  return fieldData;
});

export const updateField = createAsyncThunk<Company, Field, { rejectValue: string[] }>(
  'company/update-field',
  async (updatedField, thunkApi) => {
    const fieldData = await API.post(
      'Fields', // function defined in our serverless.yml
      updatedField.fieldId, // the function's path
      { responseType: 'json', body: { field: updatedField } },
    );
    return fieldData;
  },
);

export const deleteField = createAsyncThunk<Company, string, { rejectValue: string[] }>(
  'company/delete-field',
  async (fieldId, thunkApi) => {
    const fieldData = await API.del(
      'Fields', // function defined in our serverless.yml
      fieldId, // the function's path
      { responseType: 'json', body: {} },
    );
    return fieldData;
  },
);
