import React, { useCallback } from 'react';
import { FieldText, FieldTextConfig } from '../../../data/types';

interface TextConfigEditorProps {
  field: FieldText;
  internalConfig:FieldTextConfig;
  onChangeConfig: (e: any) => void;
}

export const TextConfigEditor = ({field, internalConfig:config, onChangeConfig}: TextConfigEditorProps) => {
  const {
    defaultValue='',
  } = config;
  const onChange = useCallback((e:any) => {
    const newVal = e.target.value;
    onChangeConfig({
      defaultValue: newVal
    });
  }, [onChangeConfig]);
  return (
    <input type="text" className="form-control" placeholder="Default Value" value={defaultValue || ''} onChange={onChange} />
  );
};

export default TextConfigEditor;
