import React from 'react';
import { useSelector } from 'react-redux';
import { FieldMultiSelect, FieldMultiSelectUser, FieldSelect, FieldSelectUser } from '../../../../data/types';
import { getSelectUsersOptions } from '../../../../data/users/selectors';
import Select from '../../../Input/Select/Select';

interface UserSelectFilterDropdownProps {
    field:FieldSelectUser|FieldMultiSelectUser;
    value: string | number | string[];
    onChange:(newValue:string|number|string[]) => void;
    multiple?:boolean;
}

export const UserSelectFilterDropdown = ({field, value, multiple, onChange}:UserSelectFilterDropdownProps) => {
    const options = useSelector(getSelectUsersOptions);
    return (
        <Select
            options={options}
            multiple={multiple}
            onChange={onChange}
            value={value}
        />
    )
}

export default UserSelectFilterDropdown;