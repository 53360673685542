import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Link, Outlet } from 'react-router-dom';
import { useAppDispatch } from '../../data/store';
import { fetchMe } from '../../data/user/actions';
import { getUserIsInitialized } from '../../data/user/selectors';

export const MePage = (props: any) => {
  const dispatch = useAppDispatch();
  const isMeInitialized = useSelector(getUserIsInitialized);
  useEffect(() => {
    dispatch(fetchMe());
  }, [dispatch]);
  return (
    <div className="p-3">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} to="/me/profile">
            Me
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={(navData) => (navData.isActive ? 'nav-link active' : 'nav-link')} to="/me/signature">
            Signature
          </NavLink>
        </li>
      </ul>
      {isMeInitialized && (
        <div className="h-100">
          <Outlet />
        </div>
      )}
    </div>
  );
};
export default MePage;
