import {cloneDeep as _cloneDeep} from 'lodash';
import React, { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FieldSelectOptions } from '../../data/types';
import { move, remove } from '../../utils/array';
import { useDrop } from 'react-dnd'

interface useSelectOptionsListProps {
  options: FieldSelectOptions;
  onChangeOptions: (newOptions: FieldSelectOptions) => void;
}

export const useSelectOptionsList = (props: useSelectOptionsListProps) => {
  const { options, onChangeOptions } = props;
  const onAddNewOption = useCallback(() => {
    const newOptionId = uuidv4();
    const newOption = {
      value: newOptionId,
      label: '',
    };
    const og = options;
    const newOptions = [...og, newOption];
    onChangeOptions(newOptions);
  }, [onChangeOptions, options]);
  const onRemoveOption = useCallback(
    (index: number) => {
      const og = options;
      const newOptions = remove(og, index);
      onChangeOptions(newOptions);
    },
    [onChangeOptions, options],
  );
  const onMoveOption = useCallback(
    (from: number, to: number) => {
      const og = options;
      const newOptions = move(og, from, to);
      onChangeOptions(newOptions);
    },
    [onChangeOptions, options],
  );
  const onUpdateOption = useCallback(
    (index: number, newOption: string) => {
      let newOptions = _cloneDeep(options);
      const value = newOptions[index].value;
      newOptions[index] = {
        value,
        label: newOption,
      };
      onChangeOptions(newOptions);
    },
    [onChangeOptions, options],
  );
    // const onDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
    //   if(result.destination === undefined) {
    //     return;
    //   }
    //   onMoveOption(result.source.index, result.destination.index);
    // }, [onMoveOption])


  return {
    // onDragEnd,
    onAddNewOption,
    onRemoveOption,
    onMoveOption,
    onUpdateOption,
  };
};

export default useSelectOptionsList;
