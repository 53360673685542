import {findIndex as _findIndex, find as _find} from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { Field } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getCompany, getToggleableFields } from '../../data/company/selectors';
import { useAppDispatch } from '../../data/store';
import { DealView, FormViewField, TableView, TableViewField, ViewField } from '../../data/types';
import { localUpdateViewFields, updateDealView } from '../../data/views/actions';
import { getSelectedView } from '../../data/views/selectors';
import { move } from '../../utils/array';

interface useFieldsToggleProps {
  field:ViewField;
  index:number;
  onMoveField: (startIndex: number, endIndex: number) => void;
  setFieldIsVisible: (fieldId: string, isVisible: boolean, startIndex:number) => void
  onDropField: () => void;

}

export const useFieldsToggle = ({
  field,
  index,
  onDropField,
  onMoveField,
  setFieldIsVisible
}: useFieldsToggleProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const canDND = useMemo(() => field.isVisible && index !== 0, [field.isVisible, index]);
  const canToggle = useMemo(() => index !== 0, [index]);

  
  // this fixes the styling since we are in an absolute positioned popover
  const [{ isDragging }, drag, dragPreview] = useDrag(
    {
      type: 'fields-toggle',
      item: () => {
        return { fieldId: field.fieldId, index, dragItem: true, isDisabled: !canDND };
      },
      // canDrag: () => index !== 0,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => canDND,
      end: (draggedItem: any) => {
        onDropField();
        // if (!ref.current) {
        //   return;
        // }
        // if (draggedItem.index === index) {
        //   return;
        // }
        // onDropField(draggedItem.index, index);
        // draggedItem.index = index;
      },
    },
    [field, index, canDND],
  );
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: 'fields-toggle',
      canDrop: (item, monitor) => canDND,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      hover: (draggedItem: any, monitor: DropTargetMonitor) => {
        if (!ref.current || !canDND) {
          return;
        }

        if (draggedItem.index === index) {
          return;
        }
        onMoveField(draggedItem.index, index);
        draggedItem.index = index;
      },
    }),
    [index, onMoveField, canDND],
  );

  const onToggleVisibility = useCallback(
    (e) => {
      setFieldIsVisible(field.fieldId, e.target.checked, index);
    },
    [field.fieldId, setFieldIsVisible, index],
  );

  const combinedRef = useCallback(
    (el: any) => {
      dragPreview(el);
      drop(el);
      ref.current = el;
    },
    [dragPreview, drop],
  );
  return {
    ref: combinedRef,
    drag,
    canToggle,
    canDND,
    isDragging,
    isOver,
    onToggleVisibility
  };
};

export default useFieldsToggle;
