import React, { useCallback, useMemo } from 'react';

interface CheckboxTableEditCellProps {
  onChange: (newValue: string | undefined | any) => void;
  internalValue: string | undefined | any;
}

export const CheckboxTableEditCell = ({ internalValue, onChange, ...inputProps }: CheckboxTableEditCellProps) => {
  const onChangeEvent = useCallback(
    (e) => {
      const newValue = e.target.checked ? 'true' : 'false';
      onChange(newValue);
    },
    [onChange],
  );
  const isChecked = useMemo(() => (internalValue === 'true' || internalValue === true), [internalValue]);
  return (
    <div className="form-check d-flex flex-row justify-content-center align-items-center">
      <input className="form-check-input" type="checkbox" checked={isChecked} onChange={onChangeEvent} />
    </div>
  );
};

export default CheckboxTableEditCell;
