import React from 'react';
import { FieldMultiSelect, FieldSelect } from '../../../../data/types';
import Select from '../../../Input/Select/Select';

interface SelectFilterDropdownProps {
    field:FieldSelect|FieldMultiSelect;
    value: string | number | string[];
    onChange:(newValue:string|number|string[]) => void;
    multiple?:boolean;
}

export const SelectFilterDropdown = ({field, value, multiple, onChange}:SelectFilterDropdownProps) => {
    const {
        config={options: []}
    } = field;
    const {
        options=[]
    } = config;
    return (
        <Select
            options={options}
            multiple={multiple}
            onChange={onChange}
            value={value}
        />
    )
}

export default SelectFilterDropdown;