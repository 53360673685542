import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecordView } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { updateRecordView } from '../../../../data/content-type/record-views/actions';
import { useAppDispatch } from '../../../../data/store';
import ToolbarToggleButton from '../ToolbarToggleButton';

interface ViewMetaFormProps {
  currentView: RecordView;
}
export const ViewMetaForm = ({ currentView }: ViewMetaFormProps) => {
    const dispatch = useAppDispatch();
    const [viewName, setViewName] = useState(currentView.name);
    const [viewDescription, setViewDescription] = useState(currentView.description);
    const [nameErrors, setNameErrors] = useState<string[]>([]);
    const onChangeName = useCallback((e) => {
        setViewName(e.target.value ?? '')
    }, [])
    const onChangeDescription = useCallback((e) => {
        setViewDescription(e.target.value ?? '')
    }, [])
    const onSubmit = useCallback((e) => {
        e.preventDefault();
        if(viewName.length < 3) {
            setNameErrors(['Name must be at least 3 characters'])
        }
        const updatedView = {
            ...currentView,
            name: viewName,
            description: viewDescription
        }
        
        dispatch(updateRecordView({view: updatedView, contentTypeSlug: updatedView.contentType}))
    }, [currentView, dispatch, viewDescription, viewName])
  return (
    <Dropdown className="me-0">
      <Dropdown.Toggle as={ToolbarToggleButton} extraClassName="btn-sm">
        <FontAwesomeIcon size='sm' className='me-1' icon={faPencilAlt} />
        {currentView.name}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="p-3" style={{ width: 300 }}>
          <form onSubmit={onSubmit}>
            <div className="mb-3">
              <label htmlFor="view-name">Name</label>
              <input id="view-name" type="text" className="form-control" value={viewName} onChange={onChangeName} />
            </div>
            <div className="mb-3">
              <label htmlFor="view-description">Description</label>
              <textarea id="view-description" className="form-control" value={viewDescription} onChange={onChangeDescription} />
            </div>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </form>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ViewMetaForm;
