import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, Link, Routes, Route } from 'react-router-dom';
import { createMarketingTemplate, setSelectedMarketingTemplateId } from '../../../data/marketing-services/marketing-templates/actions';
import { getMarketingTemplatesIsSaving, getSelectedMarketingTemplate } from '../../../data/marketing-services/marketing-templates/selectors';
import { useAppDispatch } from '../../../data/store';
import { MarketingTemplate } from '../../../data/types';

export const NewMarketingTemplateForm = (props: any) => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const isSaving = useSelector(getMarketingTemplatesIsSaving);
  const selectedMarketingTemplate = useSelector(getSelectedMarketingTemplate);

  const [newMarketingTemplate, setNewMarketingTemplate] = useState<MarketingTemplate>({
    companyId: 'taidacorp',
    marketingTemplateId: 'temp',
    templateType: 'email',
    name: 'New Template',
    plaintextBody: 'Hi there!\n\nSwap out the content you actually want',
    subject: 'Email from Taidacorp',
    htmlBody: '',
    createdDT: '',
    updatedDT: '',
  });
  const onChangeName = useCallback((e) => {
    setNewMarketingTemplate((curr) => ({ ...curr, name: e.target.value ?? '' }));
  }, []);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    if(newMarketingTemplate.name === '') {
        alert('Name is required');
        return;
    }
    dispatch(createMarketingTemplate(newMarketingTemplate))
  }, [dispatch, newMarketingTemplate]);

  const navigate = useNavigate();
  const back = useCallback(() => {
    setIsVisible(false);
    dispatch(setSelectedMarketingTemplateId(undefined));
    setTimeout(() => {
      navigate(`/marketing/templates/`);
    }, 100);
  }, [navigate]);

  useEffect(() => {
    if (selectedMarketingTemplate !== undefined) {
      navigate(`/marketing/templates/${selectedMarketingTemplate.marketingTemplateId}`);
    }
  }, [navigate, selectedMarketingTemplate]);

  return (
    <Modal show={isVisible} onHide={back} keyboard={false}>
      <Modal.Header className="d-lg-none" closeButton>
        <Modal.Title>Marketing Template</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row m-0 border-bottom">
          <div className="py-3 col-12">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>New Template</div>
              <div>
                {isSaving && (
                  <div>
                    Saving... <FontAwesomeIcon icon={faCog} title={'Saving Marketing Template'} className={'fa-spin'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row px-0 m-0">
          <div className="col-12 p-2">
            <form onSubmit={onSubmit}>
              <div className="mb-3">
                <label htmlFor="templateName" className="form-label">
                  Template Name
                </label>
                <input
                  onChange={onChangeName}
                  value={newMarketingTemplate.name}
                  type="text"
                  className="form-control"
                  id="templateName"
                  aria-describedby="nameHelp"
                />
                <div id="nameHelp" className="form-text">
                  Name the template. You'll edit the template after it is created.
                </div>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewMarketingTemplateForm;
