import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import {
  DATE_FILTER_EXPRESSION_OPTIONS,
  Field,
  FieldConfig,
  FieldFormulaValue,
  FieldMultiSelect,
  FieldSelect,
  FieldType,
  FieldValue,
  NUMBER_FILTER_EXPRESSION_OPTIONS,
  TEXT_FILTER_EXPRESSION_OPTIONS,
} from '../../data/types';
import TextKanbanField from './Text/TextKanbanField';
import TextTableEditCell from './Text/TextTableEditCell';
import TextConfigEditor from './Text/TextConfigEditor';
import SelectTableEditCell from './Select/SelectTableEditCell/SelectTableEditCell';
import SelectConfigEditor from './Select/SelectConfigEditor/SelectConfigEditor';
import { TextSortAscendingComponent, TextSortDescendingComponent } from './Text/TextSortOptions';
import { SelectSortAscendingComponent, SelectSortDescendingComponent } from './Select/SelectSortOptions';
import {
  faBolt,
  faCalculator,
  faCalendarAlt,
  faCheckSquare,
  faChevronCircleDown,
  faFont,
  faHashtag,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import FormulaConfigEditor from './Formula/FormulaConfigEditor';
import FormulaTableEditCell from './Formula/FormulaTableEditCell';
import CheckboxTableDisplayCell from './Checkbox/CheckboxTableDisplayCell';
import CheckboxTableEditCell from './Checkbox/CheckboxTableEditCell';
import CheckboxConfigEditor from './Checkbox/CheckboxConfigEditor';
import { CheckboxSortAscendingComponent, CheckboxSortDescendingComponent } from './Checkbox/CheckboxSortOptions';
import DatetimeKanbanField from './Datetime/DatetimeKanbanField';
import DatetimeTableEditCell from './Datetime/DatetimeTableEditCell';
import DatetimeConfigEditor from './Datetime/DatetimeConfigEditor';
import { DatetimeSortAscendingComponent, DatetimeSortDescendingComponent } from './Datetime/DatetimeSortOptions';
import UserSelectTableEditCell from './UserSelect/UserSelectTableEditCell/UserSelectTableEditCell';
import UserSelectConfigEditor from './UserSelect/UserSelectConfigEditor';
import {
  UserSelectSortAscendingComponent,
  UserSelectSortDescendingComponent,
} from './UserSelect/UserSelectSortOptions';
import { KanbanCardField } from '../Views/KanbanBoard/Board';
import { NumberSortAscendingComponent, NumberSortDescendingComponent } from './Number/NumberSortOptions';
import TextDetailField from './Text/TextDetailField';
import NumberDetailField from './Number/NumberDetailField';
import DatetimeDetailField from './Datetime/DatetimeDetailField';
import CheckboxDetailField from './Checkbox/CheckboxDetailField';
import ReadOnlyDetailField from './Text/ReadOnlyDetailField';
import SelectDetailField from './Select/SelectDetailField/SelectDetailField';
import UserSelectMultipleDetailField from './UserSelect/UserSelectDetailField/UserSelectMultipleDetailField';
import UserSelectDetailField from './UserSelect/UserSelectDetailField/UserSelectDetailField';
import SelectMultipleDetailField from './Select/SelectDetailField/SelectMultipleDetailField';
import AttachmentDetailField from './Attachment/AttachmentDetailField';
import { AttachmentSortAscendingComponent, AttachmentSortDescendingComponent } from './Attachment/AttachmentSortOptions';
import AttachmentConfigEditor from './Attachment/AttachmentConfigEditor';
import AttachmentTableEditCell from './Attachment/AttachmentTableEditCell';
import AttachmentDisplayCell from './Attachment/AttachmentDisplayCell';
import LongTextKanbanField from './LongText/LongTextKanbanField';
import ReadOnlyLongTextField from './LongText/ReadOnlyDetailField';
import LongTextTableEditCell from './LongText/LongTextTableEditCell';
import LongTextConfigEditor from './LongText/LongTextConfigEditor';
import { LongTextSortAscendingComponent, LongTextSortDescendingComponent } from './LongText/LongTextSortOptions';
import LongTextDetailField from './LongText/LongTextDetailField';

export const FieldIcons: Record<FieldType, IconDefinition> = {
  text: faFont,
  'long-text': faFont,
  number: faHashtag,
  formula: faCalculator,
  date: faCalendarAlt,
  datetime: faCalendarAlt,
  checkbox: faCheckSquare,
  select: faChevronCircleDown,
  'multi-select': faFont,
  'select-user': faUser,
  'multi-select-user': faUsers,
  phone: faFont,
  email: faFont,
  color: faFont,
  slider: faFont,
  url: faFont,
  attachment: faFont,
};

interface KanbanFieldProps {
  field: KanbanCardField;
}

export const FieldKanbanComponents: Record<FieldType, (props: KanbanFieldProps) => JSX.Element> = {
  text: TextKanbanField,
  "long-text": LongTextKanbanField,
  number: TextKanbanField,
  formula: TextKanbanField,
  date: DatetimeKanbanField,
  datetime: DatetimeKanbanField,
  checkbox: TextKanbanField,
  select: TextKanbanField,
  'multi-select': TextKanbanField,
  'select-user': TextKanbanField,
  'multi-select-user': TextKanbanField,
  phone: TextKanbanField,
  email: TextKanbanField,
  color: TextKanbanField,
  slider: TextKanbanField,
  url: TextKanbanField,
  attachment: TextKanbanField,
};

export interface TableCellFieldProps {
  field: Field | any;
  onChange: (newValue: string | undefined | any) => void;
  internalValue: string | undefined | any;
  displayValue: string | undefined | any;
  ogValue: FieldValue | undefined | any;
  onFocus?: Function;
  onBlur?: Function;
  stopEditing: Function;
  startEditing: Function;
  isRowHovered: boolean;
}

export const FieldTableDisplayCellComponents: Record<
  FieldType,
  undefined | ((props: TableCellFieldProps) => JSX.Element)
> = {
  "long-text": undefined,// ReadOnlyLongTextField,
  text: undefined,
  number: undefined,
  formula: undefined,
  date: undefined,
  datetime: undefined,
  checkbox: CheckboxTableDisplayCell,
  select: undefined,
  'multi-select': undefined,
  'select-user': undefined,
  'multi-select-user': undefined,
  phone: undefined,
  email: undefined,
  color: undefined,
  slider: undefined,
  url: undefined,
  attachment: AttachmentDisplayCell,
};

export const FieldTableEditCellComponents: Record<FieldType, (props: TableCellFieldProps) => JSX.Element> = {
  "long-text": LongTextTableEditCell,
  text: TextTableEditCell,
  number: TextTableEditCell,
  formula: FormulaTableEditCell,
  date: DatetimeTableEditCell,
  datetime: DatetimeTableEditCell,
  checkbox: CheckboxTableEditCell,
  select: SelectTableEditCell,
  'multi-select': TextTableEditCell,
  'select-user': UserSelectTableEditCell,
  'multi-select-user': TextTableEditCell,
  phone: TextTableEditCell,
  email: TextTableEditCell,
  color: TextTableEditCell,
  slider: TextTableEditCell,
  url: TextTableEditCell,
  attachment: AttachmentTableEditCell,
};

interface FieldConfigEditorProps {
  field: any;
  internalConfig: any;
  onChangeConfig: (e: any) => void;
}
interface FieldConfigEditorProps {
  field: any;
  internalConfig: any;
  onChangeConfig: (e: any) => void;
}

export const FieldConfigEditors: Record<FieldType, (props: FieldConfigEditorProps) => JSX.Element> = {
  "long-text": LongTextConfigEditor,
  text: TextConfigEditor,
  number: TextConfigEditor,
  formula: FormulaConfigEditor,
  date: DatetimeConfigEditor,
  datetime: DatetimeConfigEditor,
  checkbox: CheckboxConfigEditor,
  select: SelectConfigEditor,
  'multi-select': TextConfigEditor,
  'select-user': UserSelectConfigEditor,
  'multi-select-user': TextConfigEditor,
  phone: TextConfigEditor,
  email: TextConfigEditor,
  color: TextConfigEditor,
  slider: TextConfigEditor,
  url: TextConfigEditor,
  attachment: AttachmentConfigEditor,
};

interface FieldSortAscendingComponentProps {
  isSelected: boolean;
  setSelected: () => void;
}

export const FieldSortAscendingComponents: Record<FieldType, (props: FieldSortAscendingComponentProps) => JSX.Element> =
  {
    "long-text": LongTextSortAscendingComponent,
    text: TextSortAscendingComponent,
    number: NumberSortAscendingComponent,
    formula: TextSortAscendingComponent,
    date: DatetimeSortAscendingComponent,
    datetime: DatetimeSortAscendingComponent,
    checkbox: CheckboxSortAscendingComponent,
    select: SelectSortAscendingComponent,
    'multi-select': TextSortAscendingComponent,
    'select-user': UserSelectSortAscendingComponent,
    'multi-select-user': TextSortAscendingComponent,
    phone: TextSortAscendingComponent,
    email: TextSortAscendingComponent,
    color: TextSortAscendingComponent,
    slider: TextSortAscendingComponent,
    url: TextSortAscendingComponent,
    attachment: AttachmentSortAscendingComponent,
  };
export const FieldSortDescendingComponents: Record<
  FieldType,
  (props: FieldSortAscendingComponentProps) => JSX.Element
> = {
  "long-text": LongTextSortDescendingComponent,
  text: TextSortDescendingComponent,
  number: NumberSortDescendingComponent,
  formula: TextSortDescendingComponent,
  date: DatetimeSortDescendingComponent,
  datetime: DatetimeSortDescendingComponent,
  checkbox: CheckboxSortDescendingComponent,
  select: SelectSortDescendingComponent,
  'multi-select': TextSortDescendingComponent,
  'select-user': UserSelectSortDescendingComponent,
  'multi-select-user': TextSortAscendingComponent,
  phone: TextSortDescendingComponent,
  email: TextSortDescendingComponent,
  color: TextSortDescendingComponent,
  slider: TextSortDescendingComponent,
  url: TextSortDescendingComponent,
  attachment: AttachmentSortDescendingComponent,
};
export type ExpressionWithIsOption = 'is' | 'is not' | 'is before' | 'is after' | 'is on or before' | 'is on or after';
export const dateExpressionWithIsOptions: ExpressionWithIsOption[] = [
  'is',
  'is not',
  'is before',
  'is after',
  'is on or before',
  'is on or after',
];
export type ExpressionWithIsWithinOption = 'is within';

export const dateExpressionWithWithinOptions: ExpressionWithIsWithinOption[] = ['is within'];
export type ExpressionIsOptionWithNumericCompareTo = 'number of days ago' | 'number of days from now';
export const dateIsOptionsWithNumericCompareTo: ExpressionIsOptionWithNumericCompareTo[] = [
  'number of days ago',
  'number of days from now',
];
export type ExpressionIsOptionWithDateCompareTo = 'exact date...';
export const dateIsOptionsWithDateCompareTo: ExpressionIsOptionWithDateCompareTo[] = ['exact date...'];
export type ExpressionIsOptionWithoutCompareTo =
  | 'today'
  | 'tomorrow'
  | 'one week ago'
  | 'one week from now'
  | 'one month ago'
  | 'one month from now';

export const dateIsOptionsWithoutCompareTo: ExpressionIsOptionWithoutCompareTo[] = [
  'today',
  'tomorrow',
  'one week ago',
  'one week from now',
  'one month ago',
  'one month from now',
];
export type ExpressionIsWithinOptionWithNumericCompareTo = 'the next number of days' | 'the past number of days';
export const dateIsWithinOptionsWithCompareTo: ExpressionIsWithinOptionWithNumericCompareTo[] = [
  'the next number of days',
  'the past number of days',
];
export type ExpressionIsWithinOptionWithoutCompareTo =
  | 'the past week'
  | 'the past month'
  | 'the past year'
  | 'the next week'
  | 'the next month'
  | 'the next year'
  | 'the next number of days'
  | 'the past number of days';
export const dateIsWithinOptionsWithoutCompareTo: ExpressionIsWithinOptionWithoutCompareTo[] = [
  'the past week',
  'the past month',
  'the past year',
  'the next week',
  'the next month',
  'the next year',
  'the next number of days',
  'the past number of days',
];
const dateFilterExpressionOptions = [
  ...dateExpressionWithIsOptions.map((expression) => {
    return {
      expression,
      options: [
        ...dateIsOptionsWithoutCompareTo,
        ...dateIsOptionsWithDateCompareTo,
        ...dateIsOptionsWithNumericCompareTo,
      ],
      compareToControl: (
        selectedOption:
          | ExpressionIsOptionWithoutCompareTo
          | ExpressionIsOptionWithNumericCompareTo
          | ExpressionIsOptionWithDateCompareTo,
      ) => {
        if (dateIsOptionsWithoutCompareTo.indexOf(selectedOption as ExpressionIsOptionWithoutCompareTo) !== -1) {
          return undefined;
        }
        if (
          dateIsOptionsWithNumericCompareTo.indexOf(selectedOption as ExpressionIsOptionWithNumericCompareTo) !== -1
        ) {
          return 'number';
        }
        if (dateIsOptionsWithDateCompareTo.indexOf(selectedOption as ExpressionIsOptionWithDateCompareTo) !== -1) {
          return 'datepicker';
        }
      },
    };
  }),
  ...dateExpressionWithWithinOptions.map((expression) => {
    return {
      expression,
      options: [...dateIsWithinOptionsWithoutCompareTo, ...dateIsWithinOptionsWithCompareTo],
      compareToControl: (
        selectedOption: ExpressionIsWithinOptionWithoutCompareTo | ExpressionIsWithinOptionWithNumericCompareTo,
      ) => {
        if (
          dateIsWithinOptionsWithoutCompareTo.indexOf(selectedOption as ExpressionIsWithinOptionWithoutCompareTo) !== -1
        ) {
          return undefined;
        }
        if (dateIsWithinOptionsWithCompareTo.indexOf(selectedOption as ExpressionIsWithinOptionWithNumericCompareTo)) {
          return 'number';
        }
      },
    };
  }),
  { expression: 'is empty', options: undefined, compareToControl: undefined },
  { expression: 'is not empty', options: undefined, compareToControl: undefined },
];
const textFilterExpressionOptions = [
  { expression: 'contains', options: undefined, compareToControl: 'text' },
  { expression: 'does not contain', options: undefined, compareToControl: 'text' },
  { expression: 'is', options: undefined, compareToControl: 'text' },
  { expression: 'is not', options: undefined, compareToControl: 'text' },
  { expression: 'is empty', options: undefined, compareToControl: undefined },
  { expression: 'is not empty', options: undefined, compareToControl: undefined },
];
const numberFilterExpressionOptions = [
  { expression: '=', options: undefined, compareToControl: 'number' },
  { expression: '!=', options: undefined, compareToControl: 'number' },
  { expression: '<', options: undefined, compareToControl: 'number' },
  { expression: '<=', options: undefined, compareToControl: 'number' },
  { expression: '>', options: undefined, compareToControl: 'number' },
  { expression: '>=', options: undefined, compareToControl: 'number' },
  { expression: 'is empty', options: undefined, compareToControl: undefined },
  { expression: 'is not empty', options: undefined, compareToControl: undefined },
];
export const FieldFilterExpressionOptions: Record<
  FieldType,
  {
    expression: string;
    options: undefined | string[];
    compareToControl: undefined | string | ((selectedOption: string) => string | undefined);
  }[]
> = {
  text: textFilterExpressionOptions,
  "long-text": textFilterExpressionOptions,
  formula: textFilterExpressionOptions,
  number: numberFilterExpressionOptions,
  date: dateFilterExpressionOptions as any,
  datetime: dateFilterExpressionOptions as any,
  checkbox: [
    { expression: 'is', options: undefined, compareToControl: 'checkbox' },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    { expression: 'is not empty', options: undefined, compareToControl: undefined },
  ],
  select: [
    { expression: 'is', options: undefined, compareToControl: 'select' },
    { expression: 'is not', options: undefined, compareToControl: 'select' },
    { expression: 'is any of', options: undefined, compareToControl: 'multi-select' },
    { expression: 'is none of', options: undefined, compareToControl: 'multi-select' },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    { expression: 'is not empty', options: undefined, compareToControl: undefined },
  ],
  'multi-select': [
    { expression: 'has any of', options: undefined, compareToControl: 'multi-select' },
    { expression: 'has all of', options: undefined, compareToControl: 'multi-select' },
    { expression: 'has none of', options: undefined, compareToControl: 'multi-select' },
    { expression: 'is exactly', options: undefined, compareToControl: 'multi-select' },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    { expression: 'is not empty', options: undefined, compareToControl: undefined },
  ],
  'select-user': [
    { expression: 'is me', options: undefined, compareToControl: undefined },
    { expression: 'is', options: undefined, compareToControl: 'select-user' },
    { expression: 'is not', options: undefined, compareToControl: 'select-user' },
    { expression: 'is any of', options: undefined, compareToControl: 'multi-select-user' },
    { expression: 'is none of', options: undefined, compareToControl: 'multi-select-user' },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    { expression: 'is not empty', options: undefined, compareToControl: undefined },
  ],
  'multi-select-user': [
    { expression: 'has me', options: undefined, compareToControl: undefined },
    { expression: 'has any of', options: undefined, compareToControl: 'multi-select-user' },
    { expression: 'has all of', options: undefined, compareToControl: 'multi-select-user' },
    { expression: 'has none of', options: undefined, compareToControl: 'multi-select-user' },
    { expression: 'is exactly', options: undefined, compareToControl: 'multi-select-user' },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    { expression: 'is not empty', options: undefined, compareToControl: undefined },
  ],
  phone: textFilterExpressionOptions,
  email: textFilterExpressionOptions,
  color: textFilterExpressionOptions,
  slider: numberFilterExpressionOptions,
  url: textFilterExpressionOptions,
  attachment: [
    // { expression: 'filename contains', options: undefined, compareToControl: 'text' },
    // { expression: 'has file type', options: undefined, compareToControl: 'select' },
    { expression: 'is empty', options: undefined, compareToControl: undefined },
    { expression: 'is not empty', options: undefined, compareToControl: undefined },
  ],
};
export const FieldCompareToControls = {
  // select: (props:{field:FieldSelect, }) => <Select
};

// This enables the field to go "live" as an option in the column header
export const EnabledFieldTypeSelectOptions = [
  { value: 'text', label: 'Text', helpText: 'A single line of text.' },
  { value: 'long-text', label: 'Long Text', helpText: 'Multiline text field. Good for markdown.' },
  { value: 'number', label: 'Number', helpText: '' },
  { value: 'checkbox', label: 'Checkbox', helpText: 'A true or false value' },
  { value: 'select', label: 'Select', helpText: 'Choose one from a list' },
  // { value: 'multi-select', label: 'Multi-Select', helpText: 'Choose multiple items from a list' },
  { value: 'select-user', label: 'User', helpText: 'Choose one from a list of users' },
  // { value: 'multi-select-user', label: 'Multi-Select Users', helpText: 'Choose multiple users from a list' },
  { value: 'date', label: 'Date', helpText: '' },
  { value: 'datetime', label: 'Date and Time', helpText: '' },
  { value: 'formula', label: 'Formula', helpText: 'A formula that currently must return text.' },
  { value: 'attachment', label: 'Attachment', helpText: 'File attachments' },
  { value: 'phone', label: 'Phone', helpText: 'A phone number' },
];
export const API_DISABLED_FIELDS: FieldType[] = ['formula'];
export const API_VIEW_FIELD_HELPTEXT: Record<FieldType, string | undefined> = {
  text: undefined,
  "long-text": undefined,
  number: 'We will try to parse a number out of this for validation before saving it. Still saved as a string though.',
  formula: undefined,
  date: 'We will try to parse a Date object form this. It expects iso datetime format.',
  datetime: 'We will try to parse a Datetime object form this. It expects iso datetime format.',
  checkbox: 'We will try to parse boolean out of this from 1, 0, true, false, "true", "false", etc',
  select: 'We will try to match this value by the select options value or identifier.',
  'multi-select': 'We will try to match this value by the select option values or identifiers.',
  'select-user': 'We will try to match this value by email or full name.',
  'multi-select-user': 'We will try to match these values by email or full name.',
  phone: undefined,
  email: undefined,
  color: undefined,
  slider: undefined,
  url: undefined,
  attachment: undefined,
};
export const ALLOWED_FILTER_TYPES: FieldType[] = [
  'text',
  'long-text',
  'phone',
  'number',
  'select',
  'select-user',
  'formula',
  'checkbox',
  'date',
  'datetime',
  'attachment',
];

export interface DetailFieldProps {
  field: Field|any;
  displayValue: string | any;
  internalValue: string | any;
  error?: string;
  ogValue: FieldValue;
  onChange: (newValue: string | undefined | any) => void;
}
export const FieldDetailViewComponents: Record<FieldType, (props: DetailFieldProps) => JSX.Element> = {
  text: TextDetailField,
  "long-text": LongTextDetailField,
  number: NumberDetailField,
  formula: ReadOnlyDetailField,
  date: DatetimeDetailField,
  datetime: DatetimeDetailField,
  checkbox: CheckboxDetailField,
  select: SelectDetailField,
  'multi-select': SelectMultipleDetailField,
  'select-user': UserSelectDetailField,
  'multi-select-user': UserSelectMultipleDetailField,
  phone: TextDetailField,
  email: TextDetailField,
  color: TextDetailField,
  slider: TextDetailField,
  url: TextDetailField,
  attachment: AttachmentDetailField,
};

export const FieldComponents = {
  FieldIcons,
  EnabledFieldTypeSelectOptions,
  FieldTableEditCellComponents,
  FieldKanbanComponents,
  FieldConfigEditors,
  FieldFilterExpressionOptions,
};

export default FieldComponents;
