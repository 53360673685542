import { ContentType, Field, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import { find, get } from 'lodash';
import { useCallback } from 'react';
import { FieldMapping } from '../../../../hooks/useCSVImporter/useCSVImporter';
import ColumnMapperRow from './ColumnMapperRow';

interface ColumnMapperProps {
  contentType: ContentType;
  fieldMappings: FieldMapping[];
  fields: Field[];
  columns: {
    header: string;
    columnIndex: number;
  }[];
  updateFieldMapping: (columnIndex: number | null, defaultValue: PersistedFieldValue, fieldId: string) => void;
}
export const ColumnMapper = ({
  contentType,
  fieldMappings,
  fields,
  columns,
  updateFieldMapping
}: ColumnMapperProps) => {
  return (
    <div className="col-12">      
      <h3>Field Mappings</h3>
      <table className="table table-responsive">
        <tr>
          <th>{contentType.name} Field</th>
          <th>Select Column From Data</th>
          <th>Set Default Value</th>
          <th></th>
        </tr>
        {fields.map((field) => (
          <ColumnMapperRow
            key={`mapped-field-${field.fieldId}`}
            columns={columns}
            field={field}
            fieldMapping={find(fieldMappings, {fieldId: field.fieldId})!}
            updateFieldMapping={updateFieldMapping}
          />
        ))}
      </table>
    </div>
  );
};

export default ColumnMapper;
