import { v4 as uuidv4 } from 'uuid';
import { RecordAutomation, RecordAutomationAction } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep, isEqual as _isEqual, debounce as _debounce } from 'lodash';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedContentType } from '../../../../../data/content-type/content-types/selectors';
import {
  disableRecordAutomation,
  enableRecordAutomation,
  setSelectedAutomationId,
  updateRecordAutomation,
} from '../../../../../data/content-type/record-automations/actions';
import { useAppDispatch } from '../../../../../data/store';
import ActionEditor from './AutomationActionForm/ActionEditor';
import { AutomationTriggerForm } from './AutomationTriggerForm/AutomationTriggerForm';
import ScheduleAutomationForm from './AutomationTriggerForm/ScheduleAutomationForm/ScheduleAutomationForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { replace } from '../../../../../utils/array';

interface AutomationEditorProps {
  persistedRecordAutomation: RecordAutomation;
}

export const AutomationEditor = ({ persistedRecordAutomation }: AutomationEditorProps) => {
  const contentType = useSelector(getSelectedContentType);
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<RecordAutomation>(persistedRecordAutomation);
  const debouncedSyncUpdates = useMemo(() => {
    return _debounce(
      (updatedAutomation: RecordAutomation) =>
        dispatch(
          updateRecordAutomation({
            automation: updatedAutomation,
            contentTypeSlug: contentType!.slug,
          }),
        ),
      1500,
    );
  }, [contentType, dispatch]);
  const onChangeIsEnabledAutomation = useCallback(
    (e) => {
      const isEnabled = e.target.checked;
      const now = new Date().toISOString();
      const updatedAutomation: RecordAutomation = {
        ...formData,
        enabledDT: isEnabled ? now : undefined,
        'type#enabledDT': isEnabled ? `${formData.type}#${now}` : undefined,
      };
      setFormData(updatedAutomation);
      if (isEnabled) {
        dispatch(
          enableRecordAutomation({ automationId: formData.automationId, contentTypeSlug: formData.contentType }),
        );
      } else {
        dispatch(
          disableRecordAutomation({ automationId: formData.automationId, contentTypeSlug: formData.contentType }),
        );
      }
    },
    [dispatch, formData],
  );
  const onChangeName = useCallback(
    (e) => {
      const newName = e.target.value ?? '';
      const updatedAutomation = {
        ...formData,
        name: newName,
      };
      setFormData(updatedAutomation);
      debouncedSyncUpdates(updatedAutomation);
    },
    [formData, debouncedSyncUpdates],
  );
  const onChangeDescription = useCallback(
    (e) => {
      const newDescription = e.target.value ?? '';
      const updatedAutomation = {
        ...formData,
        description: newDescription,
      };
      setFormData(updatedAutomation);
      debouncedSyncUpdates(updatedAutomation);
    },
    [formData, debouncedSyncUpdates],
  );

  const onClose = useCallback(() => {
    dispatch(setSelectedAutomationId({ automationId: undefined }));
  }, [dispatch]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(updateRecordAutomation({ automation: formData, contentTypeSlug: formData.contentType }));
    },
    [dispatch, formData],
  );

  const onAddAction = useCallback(() => {
    const newAction: RecordAutomationAction = {
      automationId: persistedRecordAutomation.automationId,
      automationActionId: uuidv4(),
      integrationId: 'taidacorp-email',
      integrationActionId: 'taidacorp-email-send-email',
      apiViewId: undefined,
      cachedTestOutputs: {},
    };
    const updatedRecordAutomation = {
      ...formData,
      actions: [...formData.actions, newAction],
    };
    setFormData(updatedRecordAutomation);
    debouncedSyncUpdates(updatedRecordAutomation);
  }, [debouncedSyncUpdates, formData, persistedRecordAutomation.automationId]);
  const onChangeAction = useCallback(
    (action: RecordAutomationAction, index: number) => {
      const updatedActions = replace(formData.actions, action, index);
      const updatedRecordAutomation = {
        ...formData,
        actions: updatedActions,
      };
      setFormData(updatedRecordAutomation);
      debouncedSyncUpdates(updatedRecordAutomation);
    },
    [debouncedSyncUpdates, formData],
  );

  const updateAndSyncAutomation = useCallback(
    (updated: RecordAutomation) => {
      setFormData(updated);
      debouncedSyncUpdates(updated);
    },
    [debouncedSyncUpdates],
  );

  // useEffect(() => {
  //   if (
  //     !_isEqual(formData.actions, persistedRecordAutomation.actions) ||
  //     !_isEqual(formData.name, persistedRecordAutomation.name) ||
  //     !_isEqual(formData.description, persistedRecordAutomation.description)
  //   ) {
  //     debouncedSyncUpdates(formData);
  //   }
  // }, [
  //   debouncedSyncUpdates,
  //   formData,
  //   persistedRecordAutomation.actions,
  //   persistedRecordAutomation.description,
  //   persistedRecordAutomation.name,
  // ]);

  return (
    <>
      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-column flex-0">
          <div className="mb-3 d-flex flex-row justify-content-start align-items-center">
          <button onClick={onClose} type="button" className="btn btn-light" aria-label="back">
            <FontAwesomeIcon icon={faChevronLeft}/>
          </button>
            <h3>{formData.name}</h3>
          </div>
          <div className="mb-3">
            <form onSubmit={onSubmit} id="automation-form" name="automation-form">
              <div className="card card-body mb-3">
                <div className="mb-2">
                  <label htmlFor="automation-name">Name</label>
                  <input
                    name="automation-name"
                    className="form-control"
                    type="text"
                    value={formData.name ?? ''}
                    onChange={onChangeName}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="automation-description">Description</label>
                  <textarea
                    name="automation-description"
                    className="form-control"
                    value={formData.description ?? ''}
                    onChange={onChangeDescription}
                  />
                </div>
                <div className="mb-2">
                  <div className="form-check form-switch">
                    <input
                      checked={formData.enabledDT !== undefined}
                      onChange={onChangeIsEnabledAutomation}
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="filterByView"
                    />
                    <label className="form-check-label" htmlFor="filterByView">
                      Is Enabled
                    </label>
                  </div>
                </div>
              </div>
              <AutomationTriggerForm formData={formData} updateAutomation={updateAndSyncAutomation} />
              {formData.actions.map((action, idx) => (
                <ActionEditor
                  key={action.automationActionId}
                  index={idx}
                  action={action}
                  onChangeAction={onChangeAction}
                  automation={formData}
                />
              ))}
            </form>
            <div className="d-flex flex-column align-items-center">
              <button onClick={onAddAction} type="button" className="btn btn-light">
                Add Action
                <FontAwesomeIcon className="text-primary ms-2" icon={faPlus} />
              </button>
            </div>
          </div>
        </div>
        {/* <button form='automation-form' className='btn btn-primary'>Save</button> */}
      </div>
    </>
  );
};

export default AutomationEditor;
