import { useMemo } from 'react';

interface StaticMapViewProps {
  center: { lat: number; long: number } | string;
  zoom: number;
  height: number;
  width: number;
}
const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export const StaticMapView = (props: StaticMapViewProps) => {
  const imageURL = useMemo(() => {
    const center = typeof props.center === 'string' ? encodeURIComponent(props.center) : props.center;
    return `https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&center=${center}&zoom=${props.zoom}&size=${props.width}x${props.height}
            &markers=color:blue%7C${center}
            &key=${GOOGLE_MAPS_KEY}`;
  }, [props.center, props.height, props.width, props.zoom]);
  return <img className="img-fluid" alt="" src={imageURL} />;
};

export default StaticMapView;
