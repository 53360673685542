import { useCallback } from 'react';
import { Campaign, DailyCampaign, HourlyCampaign } from '../../../../../data/types';

interface RunOnWeekendsInputProps {
  form: DailyCampaign | HourlyCampaign;
  setForm: React.Dispatch<React.SetStateAction<Campaign>>;
}

export const RunOnWeekendsInput = ({ form, setForm }: RunOnWeekendsInputProps) => {
  const onChange = useCallback(
    (e) => {
      setForm({
        ...form,
        options: {
          ...form.options,
          shouldRunOnWeekends: e.target.value === 'Yes',
        },
      } as any);
    },
    [form, setForm],
  );

  return (
    <div className="mb-3">
      <label htmlFor="runOnWeekends" className="form-label">
        Run On Weekends
      </label>
      <select
        onChange={onChange}
        value={form.options.shouldRunOnWeekends ? 'Yes' : 'No'}
        className="form-control"
        id="runOnWeekends"
        aria-describedby="runOnWeekendsHelp"
      >
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      <div id="runOnWeekendsHelp" className="form-text"></div>
    </div>
  );
};

export default RunOnWeekendsInput;
