import { useCallback } from 'react';
import { Campaign, WeeklyCampaign } from '../../../../../data/types';

interface DayOfWeekInputProps {
  form: WeeklyCampaign;
  setForm: React.Dispatch<React.SetStateAction<Campaign>>;
}

export const DayOfWeekInput = ({ form, setForm }: DayOfWeekInputProps) => {
  const onChange = useCallback(
    (e) => {
      setForm({
        ...form,
        options: {
          ...form.options,
          dayOfTheWeek: e.target.value,
        },
      });
    },
    [form, setForm],
  );

  return (
    <div className="mb-3">
      <label htmlFor="dayOfWeek" className="form-label">
        Day of the Week
      </label>
      <select
        onChange={onChange}
        value={form.options.dayOfTheWeek}
        className="form-control"
        id="dayOfWeek"
        aria-describedby="dayOfWeekHelp"
      >
        <option value="monday">Monday</option>
        <option value="tuesday">Tuesday</option>
        <option value="wednesday">Wednesday</option>
        <option value="thursday">Thursday</option>
        <option value="friday">Friday</option>
        <option value="saturday">Saturday</option>
        <option value="sunday">Sunday</option>
      </select>
      <div id="dayOfWeekHelp" className="form-text"></div>
    </div>
  );
};

export default DayOfWeekInput;
