import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RecordView } from "@taida-corp/taidacorp-sdk";
import { API } from "aws-amplify";
import { RootState } from "../../store";

export const setSelectedViewId = createAction<{contentTypeSlug:string; viewId: string|undefined}>('recordViews.setSelectedViewId');
export const realTimeRecordViewUpdated = createAction<{ view: RecordView }>('recordViews.updated');
export const realTimeRecordViewDeleted = createAction<{ view: RecordView }>('recordViews.deleted');
export const realTimeRecordViewCreated = createAction<{ view: RecordView }>('recordViews.created');


export const fetchRecordViews = createAsyncThunk<RecordView[], {contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordViews/fetchRecordViews',
    async ({contentTypeSlug}) => {
      try {
        const data = await API.get(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/views`,            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const fetchRecordView = createAsyncThunk<RecordView, {contentTypeSlug:string, viewId:string}, { rejectValue: string[] }>(
    'recordViews/fetchRecordView',
    async ({contentTypeSlug, viewId}) => {
      try {
        const data = await API.get(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/views/${viewId}`,            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  
  export const createRecordView = createAsyncThunk<RecordView, {view: Partial<RecordView>, contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordViews/createRecordView',
    async ({contentTypeSlug, view}) => {
      try {
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/views`,            // the function's path
          { 'responseType': 'json', body: view }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const updateRecordView = createAsyncThunk<RecordView, {view:Partial<RecordView>; contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordViews/updateRecordView',
    async ({contentTypeSlug, view}) => {
      try {
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          `${contentTypeSlug}/views/${view.viewId!}`,            // the function's path
          { 'responseType': 'json', body: {view} }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  export const deleteRecordView = createAsyncThunk<RecordView, {viewId:string, contentTypeSlug:string}, { rejectValue: string[] }>(
    'recordViews/deleteRecordView',
    async ({contentTypeSlug, viewId}, thunkApi) => {
      const resp = await API.del(
        'Content', // function defined in our serverless.yml
        `${contentTypeSlug}/views/${viewId!}`,            // the function's path
        { responseType: 'json', body: {} },
      );
      return resp;
    },
  );
  


export const persistViewUpdates = createAsyncThunk<RecordView, {viewId:string; contentTypeSlug:string}, { rejectValue: string[] }>(
  'recordViews/persistViewUpdates',
  async ({viewId, contentTypeSlug}, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    // get current state values and write them to the API...
    const updatedView = state.views.views[viewId];

    const viewData = await API.post(
      'Content', // function defined in our serverless.yml
      `${contentTypeSlug}/views/${viewId}`, // the function's path
      { responseType: 'json', body: { view: updatedView } },
    );
    return viewData;
  },
);

export const localUpdateColumnWidth =
  createAction<{ contentTypeSlug:string; viewId: string; fieldId: string; deltaX: number }>('recordViews.localUpdateColumnWidth');

export const localUpdateIsVisible =
  createAction<{ contentTypeSlug:string; viewId: string; fieldId: string; isVisible: boolean }>('recordViews.localUpdateIsVisible');

export const localUpdateViewFields =
  createAction<{ contentTypeSlug:string; viewId: string; fields:any[] }>('recordViews.localUpdateViewFields');

export const localUpdateView =
  createAction<{ contentTypeSlug:string; view:RecordView }>('recordViews.localUpdateView');

