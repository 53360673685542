import { Field, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import { useCallback, useMemo, useState } from 'react';
import { FieldMapping } from '../../../../hooks/useCSVImporter/useCSVImporter';
import { getRecordFormInputComponent } from '../../components/Fields/RecordFormInputComponents';

interface ColumnMapperRowProps {
  field: Field;
  fieldMapping: FieldMapping;
  columns: {
    header: string;
    columnIndex: number;
  }[];
  updateFieldMapping: (columnIndex: number | null, defaultValue: PersistedFieldValue, fieldId: string) => void;
}

export const ColumnMapperRow = ({ fieldMapping, updateFieldMapping, columns, field }: ColumnMapperRowProps) => {
  const onChangeMappedColumn = useCallback(
    (e) => {
      const valString = e.target.value ?? '';
      if (valString === '' || valString === '-1') {
        return;
      }
      const columnIndex = parseInt(e.target.value);
      updateFieldMapping(columnIndex, fieldMapping.defaultValue, field.fieldId);
    },
    [updateFieldMapping, fieldMapping.defaultValue, field.fieldId],
  );

  const onChange = useCallback(
    (newValue: PersistedFieldValue) => {
      updateFieldMapping(fieldMapping.columnIndex, newValue, fieldMapping.fieldId);
    },
    [fieldMapping.columnIndex, fieldMapping.fieldId, updateFieldMapping],
  );
  const DetailField = useMemo(() => {
    return getRecordFormInputComponent(field.type);
  }, [field]);


  return (
    <tr>
      <td>{field.name}</td>
      <td>
        <select className="form-control" onChange={onChangeMappedColumn}>
          <option value={-1}>--</option>
          {columns.map((col) => (
            <option key={col.columnIndex} value={col.columnIndex}>
              Column {col.columnIndex} - {col.header}
            </option>
          ))}
        </select>
      </td>
      <td>
        <DetailField field={field} value={fieldMapping.defaultValue} onChange={onChange} />
      </td>
    </tr>
  );
};

export default ColumnMapperRow;
