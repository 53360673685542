import { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../data/store';
import NewContentModal from './NewContentModal';
import { setSelectedRecordId } from '../../../data/content-type/records/actions';
import { getSelectedContentTypeAdminNewRecordForm } from '../../../data/content-type/record-views/selectors';

export const NewContentPage = (props: any) => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const adminNewRecordForm = useSelector(getSelectedContentTypeAdminNewRecordForm);
  const location = useLocation();
  const navigate = useNavigate();
  const back = useCallback(() => {
    const parts = location.pathname.split('/');
    const root = parts[1];
    setIsVisible(false);
    setTimeout(() => {
      navigate(`/${root}`);
      dispatch(setSelectedRecordId(undefined));
    }, 100);
  }, [location.pathname, navigate, dispatch]);
  
  if(adminNewRecordForm === undefined) {
    return (
        <Modal size="xl" fullscreen="sm-down" show={isVisible} onHide={back} keyboard={false}>
          <Modal.Header className="d-sm-none" closeButton>
            <Modal.Title>Loading Content</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            Loading...
          </Modal.Body>
        </Modal>
      );
  }

  return (
    <NewContentModal isVisible={isVisible} back={back} newContentForm={adminNewRecordForm} contentTypeSlug={adminNewRecordForm.contentType} />
  );
};

export default NewContentPage;
