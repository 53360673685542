import _ from 'lodash';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [productionRedirectSignIn, localRedirectSignIn] = awsconfig.oauth.redirectSignIn.split(',');

const [productionRedirectSignOut, localRedirectSignOut] = awsconfig.oauth.redirectSignOut.split(',');

const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    domain: 'auth.taidacorp.com',
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  },
};

const TaidacorpApiConfig = {
  aws_cloud_logic_custom: [
    {
      name: 'Properties',
      endpoint: 'https://api.taidacorp.com/properties/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Users',
      endpoint: 'https://api.taidacorp.com/users/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Automations',
      endpoint: 'https://api.taidacorp.com/automations/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Slack',
      endpoint: 'https://api.taidacorp.com/slack/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Company',
      endpoint: 'https://api.taidacorp.com/company/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Deals',
      endpoint: 'https://api.taidacorp.com/deals/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Fields',
      endpoint: 'https://api.taidacorp.com/fields/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Views',
      endpoint: 'https://api.taidacorp.com/views/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Admin',
      endpoint: 'https://api.taidacorp.com/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Scrapers',
      endpoint: 'https://api.taidacorp.com/scrapers/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Documents',
      endpoint: 'https://api.taidacorp.com/documents/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'DocumentTemplates',
      endpoint: 'https://api.taidacorp.com/document-templates/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'TextMessages',
      endpoint: 'https://api.taidacorp.com/text-messages/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Notifications',
      endpoint: 'https://api.taidacorp.com/notifications/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'DealActivities',
      endpoint: 'https://api.taidacorp.com/deal-activity/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'MarketingTemplates',
      endpoint: 'https://api.taidacorp.com/marketing-templates/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Contacts',
      endpoint: 'https://api.taidacorp.com/contacts/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'ContactSavedSearches',
      endpoint: 'https://api.taidacorp.com/contact-saved-searches/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Campaigns',
      endpoint: 'https://api.taidacorp.com/campaigns/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Tokens',
      endpoint: 'https://api.taidacorp.com/tokens/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
    {
      name: 'Content',
      endpoint: 'https://api.taidacorp.com/content/',
      region: 'us-east-1',
      custom_header: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        return { Authorization: `Bearer ${token}` };
      },
    },
  ],
};

function customizer(objValue: any, srcValue: any) {
  if (_.isArray(objValue) && _.isArray(srcValue)) {
    return [...objValue, ...srcValue];
  }
}

const merged = _.mergeWith(updatedAwsConfig, TaidacorpApiConfig, customizer);

export default merged;
