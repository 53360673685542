import {cloneDeep as _cloneDeep, replace as _replace} from 'lodash';

export const move = (input: any[], from: number, to: number) => {
  const arr = _cloneDeep(input);
  let numberOfDeletedElm = 1;

  const elm = arr.splice(from, numberOfDeletedElm)[0];

  numberOfDeletedElm = 0;

  arr.splice(to, numberOfDeletedElm, elm);
  return arr;
};

export const remove = (input: any[], index:number) => {
    const arr = _cloneDeep(input);
    arr.splice(index, 1);
    return arr;
  };
  
  export const replace = (input: any[], newItem:any, index:number) => {
    const arr = _cloneDeep(input);
    arr[index] = newItem;
    return arr;
  };
  