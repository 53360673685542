import {
  values as _values,
  orderBy as _orderBy,
  keyBy as _keyBy,
  groupBy as _groupBy,
  reduce as _reduce,
  find as _find,
  map as _map,
  get as _get,
  forEach as _forEach,
  findIndex as _findIndex,
  toNumber as _toNumber,
  filter as _filter,
  countBy as _countBy,
  every as _every,
  some as _some,
  mapKeys as _mapKeys
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getAllFieldsList, getCompanyFieldList, getFields } from '../company/selectors';
import {
  Attachment,
  DealRecord,
  FieldNumber,
  FieldPhone,
  FieldSelect,
  FieldSelectOption,
  FieldSelectOptions,
  FieldType,
  isFieldAttachmentValue,
  isFieldDateValue,
  isFieldFormulaValue,
  isFieldMultiSelectValue,
} from '../types';
import { format } from 'date-fns';

import { KanbanColumn, KanbanCard } from '../../components/Views/KanbanBoard/Board';
import { toCheckbox, toNumber, toSelectIndex, toSelectValue } from '../../utils/fieldValueCasting';
import { filterDeals, getFilterFunction } from '../../utils/fieldFilters';
import { getSelectedView } from '../views/selectors';
import { getSelectUsersOptions, getUsers } from '../users/selectors';
import { getUser } from '../user/selectors';
import Fuse from 'fuse.js';
import getHeaderImageFromAttachmentsArray from '../../utils/getHeaderImage';
import { GalleryViewCard } from '../../components/Views/GalleryView/GalleryView';

export const getDealsState = (state: RootState) => state.deals;

export const getDeals = createSelector(getDealsState, (state) => state.deals);
export const getDealsIsInitialized = createSelector(getDealsState, (state) => state.isInitialized);
export const getDealsIsLoading = createSelector(getDealsState, (state) => state.isLoading);
export const getDealIsSaving = createSelector(getDealsState, (state) => state.isSaving);
export const getPrimaryFieldValueCounts = createSelector(getDealsState, (state) => state.primaryFieldValueCounts);
export const getSelectedDealId = (state: RootState) => state.deals.selectedDealId;
export const getDealsSearchTerm = (state: RootState) => state.deals.dealsSearchTerm;

export const getDealFormCleanedData = (state: RootState) => state.deals.dealFormCleanedData as Record<string, any>;
export const getDealFormErrors = (state: RootState) => state.deals.dealFormErrors as Record<string, string[]>;

export const getAllDeals = createSelector(getDeals, (deals) => {
  const allDeals = _values(deals);
  return allDeals;
});

export const getAllDealsWithValuesCastForSortingAndFiltering = createSelector(
  getAllDeals,
  getFields,
  getSelectUsersOptions,
  (deals, fields, userOptions) => {
    const updatedDeals = _map(deals, (deal) => {
      let updatedFields: Record<string, any> = {};
      _forEach(fields, (field) => {
        let value: any = _get(deal.fields, field.fieldId);
        updatedFields[`${field.fieldId}-og`] = value;
        if (field.type === 'select') {
          // TODO: add checks for when field missing config or options
          const optionsArray = (field as FieldSelect).config.options as FieldSelectOptions;
          const labelValue = toSelectValue(value, optionsArray);
          value = labelValue;
        }
        if (field.type === 'select-user') {
          // TODO: add checks for when field missing config or options
          const indexValue = toSelectValue(value, userOptions);
          value = indexValue;
        }
        if (field.type === 'formula') {
          value = value !== undefined ? value.value ?? '' : '';
        }
        if (field.type === 'attachment' && isFieldAttachmentValue(value)) {
          value = value !== undefined ? value.value ?? '' : '';
        }
        if (field.type === 'number') {
          value = toNumber(value);
        }
        if (field.type === 'checkbox') {
          value = toCheckbox(value ?? 'false');
        }
        if (field.type === 'datetime') {
          // value = isFieldDateValue(value) ? value.dtValue ?? '' : value ?? '';
        }
        if (field.type === 'date') {
          // value = isFieldDateValue(value) ? value.dtValue ?? '' : value ?? '';
        }
        if (field.type === 'text') {
          value = value ?? '';
        }

        updatedFields[field.fieldId] = value;
      });

      return {
        ...deal,
        fields: updatedFields,
      } as DealRecord;
    });
    return updatedDeals;
  },
);

export const getRawSelectedDeal = createSelector(
  getDeals,
  getSelectedDealId,
  (deals, id) => {
    if (id === undefined) {
      return undefined;
    }
    const deal = _get(deals, id);
    return deal;
  },
);


export const getSelectedDeal = createSelector(
  getAllDealsWithValuesCastForSortingAndFiltering,
  getSelectedDealId,
  (deals, id) => {
    if (id === undefined) {
      return undefined;
    }
    const deal = _find(deals, { dealId: id });
    return deal;
  },
);

export const getSelectedViewDeals = createSelector(
  getSelectedView,
  getAllDealsWithValuesCastForSortingAndFiltering,
  getDealsSearchTerm,
  getFields,
  getUser,
  (view, deals, searchTerm, fields, currentUser) => {
    if (view === undefined || view.type === 'form' || view.type === 'api') {
      return [];
    }
    // return [] as DealRecord[];
    // return deals;

    const viewProperties = view.properties;
    // const visibleFields = viewProperties.fieldIds;
    // const justFields = (item:Deal) => _.pick(item.fields, visibleFields);
    const [orderFields, order] = viewProperties.orderBy.length > 0 && viewProperties.orderBy[0].length > 0 ? viewProperties.orderBy : [['createdDT'], ['asc']] as [string[], ['asc']];

    const viewFilters = viewProperties.filters ?? { operator: 'and', conditions: [] };

    const filtered = filterDeals(deals, viewFilters, fields, currentUser!)
    /*
  const filteredOld = _filter(deals, (deal) => {
    if(conditions.length === 0) {
      return true;
    }
    if(operator === 'or') {
      for(let i=0; i < conditions.length; i++) {
        const filterFunc = getFilterFunction(conditions[i], fields, currentUser!);
        const passesFilter = filterFunc(deal);
        if(passesFilter) {
          return true;
        }
      }
      return false;
    }
    if(operator === 'and') {
      for(let i=0; i < conditions.length; i++) {
        const filterFunc = getFilterFunction(conditions[i], fields, currentUser!);
        const passesFilter = filterFunc(deal);
        if(!passesFilter) {
          return false;
        }
      }
      return true;
    }
    return true;
  })
  */
    if (searchTerm !== '') {
      const zillowStatus = 'fields.8ec75c1d-f9a4-4933-8898-6fa2d9f3371e';
      const status = 'fields.2efffc79-63d4-4a0d-aa37-32880a314963';
      const rep = 'fields.85c46e42-0f90-425e-9999-5048e308c764';
      const sellerName = 'fields.846fc2af-0cb4-41b1-a9db-50228b650c07';
      const searchableFields = ['primaryFieldValue', zillowStatus, status, rep, sellerName];
      const fuse = new Fuse(filtered, { keys: searchableFields });
      return fuse.search(searchTerm).map((item) => item.item);
    }

    const ordered = _orderBy(
      filtered,
      orderFields.map((field) => (field !== 'createdDT' ? `fields.${field}` : field)),
      order,
    );
    return ordered;
  },
);

export const getSelectedViewDealIds = createSelector(getSelectedViewDeals, (deals) => {
  return deals.map((deal) => deal.dealId);
});

export const getFirstDealInSelectedView = createSelector(getSelectedViewDeals, (deals) =>
  deals && deals.length > 0 ? deals[0] : undefined,
);

export const getSelectedViewDealsWithFieldNameKeys = createSelector(getSelectedViewDeals, getCompanyFieldList, (deals, fields) => {

  const fieldsById = _keyBy(fields, 'fieldId');
  const defaultValue = fields.map((field) => ({name: field.name, value: ''}));
  const dealFieldValuesWithNames = _map(deals, (deal) => {
    const fieldValues = deal.fields;
    return _mapKeys(fieldValues, (fieldValue, fieldId) => {
      return _get(fieldsById, [fieldId], {name: 'deleted-field'}).name;
    })
  })
  return dealFieldValuesWithNames;
})

export const makeGetDealById = () =>
  createSelector(
    getDeals,
    (_: any, dealId: string) => dealId,
    (deals, dealId) => {
      const { [dealId]: deal } = deals;
      return deal;
    },
  );

export const getUncategorizedDealsForSelectedKanabanView = createSelector(
  getSelectedView,
  getFields,
  getAllDeals,
  (view, fields, deals) => {
    if (view === undefined || view.type !== 'kanban') {
      return [];
    }
    return deals;
  },
);

export const getDealsForSelectedKanbanView = createSelector(
  getSelectedView,
  getFields,
  getSelectedViewDeals,
  (view, fields, deals) => {
    if (view === undefined || view.type !== 'kanban') {
      return {};
    }
    const viewProperties = view.properties;
    const groupingFieldId = viewProperties.groupingFieldId;
    const groupingField = fields[groupingFieldId] as FieldSelect;
    if (groupingField === undefined) {
      return {};
    }
    const config = groupingField.config;
    if (config === undefined || config.options === undefined) {
      return {};
    }

    const options = _keyBy(config.options, 'value');
    let starter: Record<string, DealRecord[]> = {
      Uncategorized: [],
    };
    config.options.forEach((option, index: number) => {
      starter[option.value] = [];
    });
    const reducerFunc = (cum: Record<string, DealRecord[]>, curr: DealRecord) => {
      let { [`${groupingFieldId}-og`]: valueForRecord } = curr.fields;
      if (valueForRecord === null) {
        return cum;
      }
      if (isFieldFormulaValue(valueForRecord)) {
        return cum;
      }
      if (isFieldAttachmentValue(valueForRecord)) {
        return cum;
      }
      if (isFieldDateValue(valueForRecord)) {
        return cum;
      }
      if (isFieldMultiSelectValue(valueForRecord) && Array.isArray(valueForRecord)) {
        return cum;
      }
      if (valueForRecord === undefined || options[valueForRecord as string] === undefined) {
        valueForRecord = 'Uncategorized';
      }
      const { [valueForRecord as string]: currentGroup } = cum;
      const newDeals = [...currentGroup, curr];
      const newCum = { ...cum, [valueForRecord as string]: newDeals };
      return newCum;
    };

    // [{title, id, deals}, {}]

    const groups = _reduce(deals, reducerFunc, starter);
    return groups;
  },
);

export const getKanbanFormattedDeals = createSelector(
  getSelectedView,
  getFields,
  getSelectedViewDeals,
  (view, fields, deals) => {
    if (view === undefined || view.type !== 'kanban') {
      return [];
    }
    const viewProperties = view.properties;
    const cardCoverFieldId = viewProperties.cardCoverFieldId;
    const groupingFieldId = viewProperties.groupingFieldId;
    const summationFieldId = viewProperties.summationFieldId;
    const summationField = summationFieldId !== undefined ? (fields[summationFieldId] as FieldNumber) : undefined;
    const groupingField = fields[groupingFieldId] as FieldSelect;
    if (groupingField === undefined) {
      return [];
    }
    const config = groupingField.config;
    if (config === undefined || config.options === undefined) {
      return [];
    }

    const options = _keyBy(config.options, 'value');
    let columnIndicesLookup: Record<string, number> = { Uncategorized: 0 };
    let starterColumns: KanbanColumn[] = [
      {
        id: 'Uncategorized',
        title: 'Uncategorized',
        cards: [],
        summation: summationField ? 0 : null,
      },
    ];
    config.options.forEach((option, index: number) => {
      columnIndicesLookup[option.value] = index + 1;
      starterColumns[index + 1] = {
        id: option.value,
        title: option.label,
        cards: [],
        summation: summationField ? 0 : null,
      };
    });
    const reducerFunc = (cum: KanbanColumn[], curr: DealRecord) => {
      let { [`${cardCoverFieldId}-og`]: cardCoverFieldValue } = curr.fields;
      let { [`${groupingFieldId}-og`]: valueForRecord } = curr.fields;
      if (valueForRecord === null) {
        return cum;
      }
      if (isFieldFormulaValue(valueForRecord)) {
        return cum;
      }
      if (isFieldDateValue(valueForRecord)) {
        return cum;
      }
      if (isFieldAttachmentValue(valueForRecord)) {
        return cum;
      }
      if (isFieldMultiSelectValue(valueForRecord) && Array.isArray(valueForRecord)) {
        return cum;
      }
      if (valueForRecord === undefined || options[valueForRecord as string] === undefined) {
        valueForRecord = 'Uncategorized';
      }
      const index = columnIndicesLookup[valueForRecord as string];
      const currentCards = cum[index].cards;

      let coverImageURL: string | null = null;
      if (cardCoverFieldId !== null) {
        if(isFieldAttachmentValue(cardCoverFieldValue) && cardCoverFieldValue.value !== null) {
          let attachments: Attachment[] = cardCoverFieldValue.value as Attachment[];
          if (attachments !== null && attachments !== undefined) {
            if (!Array.isArray(attachments)) {
              attachments = [attachments];
            }
            coverImageURL = getHeaderImageFromAttachmentsArray(attachments);
          }
        }
      }

      const newCard: KanbanCard = {
        id: curr.dealId,
        coverImageURL,
        fields: viewProperties.fields
          .filter((value) => value.isVisible && fields.hasOwnProperty(value.fieldId))
          .map((value) => {
            const field = fields[value.fieldId];
            let displayValue:any = curr.fields[value.fieldId];
            const ogValue = curr.fields[value.fieldId];
            if (field.type === 'select' && !isFieldAttachmentValue(displayValue)  && !isFieldFormulaValue(displayValue) && !isFieldDateValue(displayValue)) {
              const options = field.config!.options!;
              const currOption = _find(options, { value: displayValue });
              displayValue = currOption !== undefined ? currOption.label : 'Uncatgorized';
            }else if (isFieldAttachmentValue(displayValue)) {
              displayValue = undefined;
            }
             else if (isFieldFormulaValue(displayValue)) {
              displayValue = displayValue.value;
            } else if (isFieldDateValue(displayValue)) {
              displayValue = displayValue.dtValue;
              if (field.type === 'date') {
                try {
                  displayValue = format(new Date(displayValue), 'P');
                } catch (error) {
                  displayValue = 'INVALID DATE';
                }
              } else if (field.type === 'datetime') {
                try {
                  displayValue = format(new Date(displayValue), 'Pp');
                } catch (error) {
                  displayValue = 'INVALID DATE';
                }
              }
            }
            return { displayValue, label: field.name, type: field.type, ogValue };
          }),
      };
      const newCards = [...currentCards, newCard];
      cum[index].cards = newCards;

      cum[index].summation =
        summationField !== undefined
          ? (cum[index].summation ?? 0) + parseFloat((curr.fields[summationField.fieldId] as string) ?? 0)
          : null;
      return cum;
    };

    // [{title, id, deals}, {}]

    const groups = _reduce(deals, reducerFunc, starterColumns);
    return groups;
  },
);

export const getFieldForSelectedKanbanView = createSelector(
  getSelectedView,
  getFields,
  getAllDeals,
  (view, fields, deals) => {
    if (view === undefined || view.type !== 'kanban') {
      return undefined;
    }
    const viewProperties = view.properties;
    const groupingFieldId = viewProperties.groupingFieldId;
    const groupingField = fields[groupingFieldId] as FieldSelect;
    return groupingField;
  },
);


export const getGalleryFormattedDeals = createSelector(
  getSelectedView,
  getFields,
  getSelectedViewDeals,
  (view, fields, deals) => {
    if (view === undefined || view.type !== 'gallery') {
      return [];
    }
    const viewProperties = view.properties;
    const cardCoverFieldId = viewProperties.cardCoverFieldId;

    const reducerFunc = (cum: GalleryViewCard[], curr: DealRecord) => {
      let { [`${cardCoverFieldId}-og`]: cardCoverFieldValue } = curr.fields;
      
      let coverImageURL: string | null = null;
      if (cardCoverFieldId !== null) {
        if(isFieldAttachmentValue(cardCoverFieldValue) && cardCoverFieldValue.value !== null) {
          let attachments: Attachment[] = cardCoverFieldValue.value as Attachment[];
          if (attachments !== null && attachments !== undefined) {
            if (!Array.isArray(attachments)) {
              attachments = [attachments];
            }
            coverImageURL = getHeaderImageFromAttachmentsArray(attachments);
          }
        }
      }

      const newCard: GalleryViewCard = {
        id: curr.dealId,
        coverImageURL,
        primaryFieldValue: curr.primaryFieldValue,
        fields: viewProperties.fields
          .filter((value) => value.isVisible && fields.hasOwnProperty(value.fieldId))
          .map((value) => {
            const field = fields[value.fieldId];
            let displayValue:any = curr.fields[value.fieldId];
            const ogValue = curr.fields[value.fieldId];
            if (field.type === 'select' && !isFieldAttachmentValue(displayValue)  && !isFieldFormulaValue(displayValue) && !isFieldDateValue(displayValue)) {
              const options = field.config!.options!;
              const currOption = _find(options, { value: displayValue });
              displayValue = currOption !== undefined ? currOption.label : 'Uncatgorized';
            }else if (isFieldAttachmentValue(displayValue)) {
              displayValue = undefined;
            }
             else if (isFieldFormulaValue(displayValue)) {
              displayValue = displayValue.value;
            } else if (isFieldDateValue(displayValue)) {
              displayValue = displayValue.dtValue;
              if (field.type === 'date') {
                try {
                  displayValue = format(new Date(displayValue), 'P');
                } catch (error) {
                  displayValue = 'INVALID DATE';
                }
              } else if (field.type === 'datetime') {
                try {
                  displayValue = format(new Date(displayValue), 'Pp');
                } catch (error) {
                  displayValue = 'INVALID DATE';
                }
              }
            }
            return { displayValue, label: field.name, type: field.type, ogValue };
          }),
      };
      const newCards = [...cum, newCard];
      return newCards;
    };

    // [{title, id, deals}, {}]

    const cards = _reduce(deals, reducerFunc, []);
    return cards;
  },
);

export const getPhoneNumbersForSelectedDeal = createSelector(getSelectedDeal, getCompanyFieldList, (deal, fields) => {

  const phoneFields = _filter(fields, (field) => field.type === 'phone') as FieldPhone[];

  const phoneValues:{field: FieldPhone; value: string}[] = _map(phoneFields, (phoneField:FieldPhone) => {

    // clean up fix phone values
    let dealValue = (_get(deal, `fields.${phoneField.fieldId}`, '') as string).replace(/[^0-9]/g, '');
    if(!dealValue.startsWith('1')) {
      dealValue = `1${dealValue}`;
    }
    return {
      field: phoneField,
      value: dealValue
    } as {field: FieldPhone; value: string}
  })
  return phoneValues;
})

export const DEAL_FIELD_ADDRESS_LINE_1 = '689a69f8-b970-4fcf-89ce-a166732db4c0';

export const getSelectedDealAddressLine1 = createSelector(getSelectedDeal, (deal) => {
  return _get(deal, `fields.${DEAL_FIELD_ADDRESS_LINE_1}`, '');
})