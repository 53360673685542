import { flatten as _flatten } from 'lodash';
import React, { useRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getAllFieldsList, getCompany } from '../../data/company/selectors';
import { getSlackChannels, getSlackUsers } from '../../data/slack/selectors';
import { Template, Field, FormulaDependency, TemplateEditorOption } from '../../data/types';
import { getUsers } from '../../data/users/selectors';
import { convertHTMLNodesToTemplate, convertTemplateToHTMLNodes } from '../../utils/templateEditor';

interface TemplateEditorProps {
  value: Template;
  onChange: (newTemplateString: string, newTemplateDependencies: FormulaDependency[]) => void;
  templateOptions: TemplateEditorOption[];
}

export const TemplateEditor = (props: TemplateEditorProps) => {
  const { onChange, templateOptions } = props;
  const company = useSelector(getCompany);
  const [templateSource] = useState<string>(props.value.templateSource);
  const formulaDivRef = useRef<HTMLDivElement>(null);
  const placeholders = useMemo(() => {
    const optionsArrs = _flatten(templateOptions.map((top) => top.options));
    return optionsArrs;
  }, [templateOptions]);
  const onChangeContentEditable = useCallback(
    (e: any) => {
      const ele = formulaDivRef.current;
      if (ele === undefined || ele === null) {
        return;
      }
      const { templateSource: newSource, templateDependencies: newDeps } = convertHTMLNodesToTemplate(ele);
      onChange(newSource, newDeps);
    },
    [onChange],
  );
  useEffect(() => {
    if (templateSource === undefined || formulaDivRef.current === undefined || formulaDivRef.current === null) {
      return;
    }
    convertTemplateToHTMLNodes(templateSource, formulaDivRef.current, placeholders);
  }, [company, templateSource, placeholders]);

  const insertPlaceholder = useCallback(
    ({ identifier, label }: FormulaDependency) => {
      const ele = formulaDivRef.current;
      if (ele === undefined || ele === null) {
        return;
      }

      // get the caret position in the input
      const sel = ele.ownerDocument!.defaultView!.getSelection();
      if (sel === null) {
        return;
      }
      // make sure the sel is inside our formula contenteditable
      if (!ele.contains(sel.anchorNode)) {
        sel.removeAllRanges();
        const endOfDivRange = document.createRange();
        if (ele.lastChild !== null) {
          endOfDivRange.setStartAfter(ele.lastChild!);
          endOfDivRange.setEndAfter(ele.lastChild!);
        } else {
          endOfDivRange.setStart(ele, 0);
          endOfDivRange.setEnd(ele, 0);
        }
        sel.addRange(endOfDivRange);
      }
      const range = sel.getRangeAt(0);
      // the new pill that we will insert
      const newTagEle = document.createElement('span');
      newTagEle.contentEditable = 'false';
      newTagEle.className = 'badge rounded-pill bg-secondary';
      newTagEle.textContent = label;
      newTagEle.setAttribute('data-identifier', identifier);
      newTagEle.onclick = (e: any) => e.stopPropagation();
      // newTagEle.oncontextmenu = (e: any) => {
      //   e.preventDefault();
      //   newTagEle.remove();
      // };

      const spaceNode1 = document.createTextNode('\u200B');
      const spaceNode = document.createTextNode('\u200B');

      // first we add the tag at the sel range
      range.insertNode(newTagEle);
      range.insertNode(spaceNode1);

      // then we shift the sel range to after the tag
      range.setStartAfter(newTagEle);
      range.setEndAfter(newTagEle);

      // and insert the space
      range.insertNode(spaceNode);

      // finally we update our cursor range to the end
      // the user can type after the tag+space
      range.setStartAfter(spaceNode);
      range.setEndAfter(spaceNode);
      sel.removeAllRanges();
      sel.addRange(range);

      const { templateSource: newSource, templateDependencies: newDeps } = convertHTMLNodesToTemplate(ele);
      onChange(newSource, newDeps);
    },
    [onChange],
  );
  const onPaste = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      event.preventDefault();
      let value = '';

      // Does method exist?
      const hasEventClipboard = !!(
        event.clipboardData &&
        typeof event.clipboardData === 'object' &&
        typeof event.clipboardData.getData === 'function'
      );
      // Get clipboard data?
      if (hasEventClipboard) {
        value = event.clipboardData.getData('text/plain');
      }

      const ele = formulaDivRef.current;
      if (ele === undefined || ele === null) {
        return;
      }
      const sel = ele.ownerDocument!.defaultView!.getSelection();
      if (sel === null) {
        return;
      }

      const range = sel.getRangeAt(0);
      range.deleteContents();
      const newTagEle = document.createElement('div');
      convertTemplateToHTMLNodes(value, newTagEle, placeholders);
      range.insertNode(newTagEle);
      range.setStartAfter(newTagEle);
      const { templateSource: newSource, templateDependencies: newDeps } = convertHTMLNodesToTemplate(ele);
      onChange(newSource, newDeps);
    },
    [onChange, placeholders],
  );
  const onCopy = useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const documentFragment = window!.getSelection()!.getRangeAt(0).cloneContents();

    const { templateSource } = convertHTMLNodesToTemplate(documentFragment);
    event.clipboardData.setData('text/plain', templateSource);
  }, []);
  return (
    <div className="input-group">
      <div
        ref={formulaDivRef}
        onInput={onChangeContentEditable}
        onBlur={onChangeContentEditable}
        className="form-control"
        contentEditable={true}
        onPaste={onPaste}
        onCopy={onCopy}
        dangerouslySetInnerHTML={{ __html: templateSource }}
      />
      <Dropdown>
        <Dropdown.Toggle variant="btn btn-outline-secondary"></Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: 200, overflow: 'scroll' }}>
          {templateOptions.map((option, idx) => {
            return (
              <React.Fragment key={option.label}>
                <Dropdown.ItemText>
                  <b>{option.label}</b>
                </Dropdown.ItemText>
                {option.options.map((placeholder) => (
                  <Dropdown.Item key={placeholder.identifier} onClick={() => insertPlaceholder(placeholder)}>
                    <div className="d-flex flex-row justify-content-between">
                      <div>{placeholder.label}</div>
                    </div>
                  </Dropdown.Item>
                ))}
                {idx !== templateOptions.length - 1 && <Dropdown.Divider />}
              </React.Fragment>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default TemplateEditor;
