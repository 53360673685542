import { isString, isStringArray } from '@taida-corp/taidacorp-sdk';
import React, { useCallback, useMemo } from 'react';
import { RecordInputComponentProps } from '../base';

export const CheckboxInputComponent = ({
  field,
  value: persistedValue,
  onChange,
  extraProps = {},
}: RecordInputComponentProps) => {
  const errors = useMemo(() => {
    if (persistedValue === undefined || !isStringArray(persistedValue.errors)) {
      return [] as string[];
    }
    return persistedValue.errors;
  }, [persistedValue])
  const value = useMemo(() => {
    if (persistedValue === undefined || !isString(persistedValue.internalValue)) {
      return false;
    }
    return persistedValue.internalValue === 'true' ? true : false;
  }, [persistedValue]);
  const onChangeHandle = useCallback(
    (e) => {
      onChange({internalValue: e.target.checked ? 'true' : 'false'});
    },
    [onChange],
  );
  return (
    <>
    <input
      type="checkbox"
      className="form-checkbox"
      id={`field-${field.fieldId}`}
      checked={value}
      onChange={onChangeHandle}
      {...extraProps}
    />
    <label htmlFor={`field-${field.fieldId}`} className='ms-1'>{field.name}</label>
    {errors.map((err) => <div key={err} className='mt-1'><small className='text-danger'>{err}</small></div>)}
    </>
  );
};

export default CheckboxInputComponent;
