import _, { keys as _keys, findIndex as _findIndex } from 'lodash';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchDocumentTemplateDetails,
  fetchDocumentTemplates,
  setSelectedDocumentTemplateId,
} from '../../../../../data/documentTemplates/actions';
import {
  getDocumentTemplatesList,
  getIsInitialized,
  getIsLoading,
  getSelectedDocumentTemplate,
} from '../../../../../data/documentTemplates/selectors';
import { useAppDispatch } from '../../../../../data/store';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { DealRecord, Field, NewDocumentForm } from '../../../../../data/types';
import { getCompany } from '../../../../../data/company/selectors';
import { getUsers } from '../../../../../data/users/selectors';
import { createDocumentForDeal, setNewDocumentForm as dispatchSetNewDocumentForm, updateNewDocumentForm as dispatchUpdateNewDocumentForm } from '../../../../../data/documents/actions';
import { getUser } from '../../../../../data/user/selectors';
import { getNewDocumentForm } from '../../../../../data/documents/selectors';
import { clearNewDealFormResp } from '../../../../../data/deals/actions';

export const DEAL_FIELD_ADDRESS_LINE_1 = '689a69f8-b970-4fcf-89ce-a166732db4c0';


export const NewDocument = ({ deal, fields }: { deal: DealRecord; fields: Field[] }) => {
  const dispatch = useAppDispatch();
  const company = useSelector(getCompany);
  const users = useSelector(getUsers);
  const me = useSelector(getUser);
  const documentTemplates = useSelector(getDocumentTemplatesList);
  const selectedDocumentTemplate = useSelector(getSelectedDocumentTemplate);
  const isLoading = useSelector(getIsLoading);
  const isInitialized = useSelector(getIsInitialized);
  const newDocumentForm = useSelector(getNewDocumentForm);
  const updateNewDocumentForm = useCallback((value:NewDocumentForm) => {
    if(selectedDocumentTemplate === undefined) {
      return;
    }
      dispatch(dispatchUpdateNewDocumentForm({dealId: deal.dealId, templateId: selectedDocumentTemplate.id, form: value}))
  }, [deal.dealId, dispatch, selectedDocumentTemplate])
  const setNewDocumentForm = useCallback((form:NewDocumentForm) => {
    if(selectedDocumentTemplate === undefined) {
      return;
    }
    dispatch(dispatchSetNewDocumentForm({dealId: deal.dealId, templateId: selectedDocumentTemplate.id, form}))
}, [deal.dealId, dispatch, selectedDocumentTemplate])
  const clearNewDocumentForm = useCallback(() => {
    if(selectedDocumentTemplate === undefined) {
      return;
    }
    dispatch(dispatchSetNewDocumentForm({dealId: deal.dealId, templateId: selectedDocumentTemplate.id, form: {}}))
}, [deal.dealId, dispatch, selectedDocumentTemplate])
  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5>(1);
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();
  const onNext = useCallback(() => {
    if (step === 1) {
      // validate
      setStep(3);
    } else if (step === 2) {
      // validate
      setStep(3);
    } else if (step === 3) {
      // validate
      setStep(4);
    } else if (step === 4) {
      // validate
      dispatch(createDocumentForDeal({dealId: deal.dealId, document: newDocumentForm}));
      setIsSending(true);
      setTimeout(() => {
        navigate('../documents');
        clearNewDocumentForm();
      }, 5000);
    } else if (step === 5) {
      // validate
      // send
      // back to document list
      
    }
  }, [step, dispatch, deal.dealId, newDocumentForm, navigate, clearNewDocumentForm]);
  const onBack = useCallback(() => {
    if (step === 1) {
      // validate
      return;
    } else if (step === 2) {
      // validate
      setStep(1);
    } else if (step === 3) {
      // validate
      setStep(1);
    } else if (step === 4) {
      // validate
      setStep(3);
    } else if (step === 5) {
      setStep(4);
    }
  }, [step]);
  const onChange = useCallback(
    (formUpdates: Partial<NewDocumentForm>) => {
      let autoNext = false;
      if (_keys(formUpdates).indexOf('template_uuid') !== -1) {
        if (_keys(newDocumentForm).length > 1) {
          // eslint-disable-next-line no-restricted-globals
          const doChange = confirm('This will reset the other document data, okay?');
          if (!doChange) {
            return;
          }
        }
        dispatch(fetchDocumentTemplateDetails({ templateId: formUpdates.template_uuid! }));
        dispatch(setSelectedDocumentTemplateId(formUpdates.template_uuid!));
      }
      updateNewDocumentForm(formUpdates);
      if (autoNext) {
        onNext();
      }
    },
    [dispatch, newDocumentForm, onNext, updateNewDocumentForm],
  );

  useEffect(() => {
    dispatch(fetchDocumentTemplates());
  }, [dispatch]);
  useEffect(() => {
    if (selectedDocumentTemplate !== undefined && selectedDocumentTemplate.id !== newDocumentForm.template_uuid) {
      let recipients = selectedDocumentTemplate.roles.map((role, signingOrder) => {
        if(role.preassignedPerson !== null) {
          return {
            email: role.preassignedPerson.email,
            firstName: role.preassignedPerson.email === 'tyler@taidacorp.com' ? 'Tyler' : '',
            lastName: role.preassignedPerson.email === 'tyler@taidacorp.com' ? 'Jackson' : '',
            signingOrder,
          }
        }
        return {email: '', role: role.name, firstName: '', lastName: '', signingOrder }
      })

      if(_findIndex(recipients, { email: me!.email}) === -1) {
        const names = me!.name.split(' ');
        const meFirstName = names[0];
        const meLastName = names.length > 1 ? names[1] : '';
        recipients = [...recipients, {email: me!.email, firstName: meFirstName, lastName: meLastName, signingOrder: recipients.length}]
      }
      updateNewDocumentForm({
        metadata: {dealRecordId: deal.dealId},
        name: `${deal.fields[DEAL_FIELD_ADDRESS_LINE_1]} ${selectedDocumentTemplate.name}`,
        tokens: selectedDocumentTemplate.tokens,
        recipients,
        template_uuid: selectedDocumentTemplate.id,
      });
    } else if(selectedDocumentTemplate !== undefined && selectedDocumentTemplate.id === newDocumentForm.template_uuid) {
      // noop

      
    } else {
      clearNewDocumentForm()
    }
  }, [selectedDocumentTemplate, deal, me, clearNewDocumentForm, updateNewDocumentForm, newDocumentForm.template_uuid]);

  if (isSending) {
    return (
      <div>
        <FontAwesomeIcon icon={faCog} title={'Sending document'} className={'fa-spin'} />
      </div>
    );
  }
  if (!isInitialized) {
    return (
      <div>
        <FontAwesomeIcon icon={faCog} title={'Loading templates'} className={'fa-spin'} />
      </div>
    );
  }

  return (
    <div className="h-100 d-flex flex-column">
      {newDocumentForm.name ? (<div className='px-3 pt-1 flex-shrink-0'><h3>{newDocumentForm.name}</h3></div>) : undefined }
      {newDocumentForm.template_uuid ? (<div className='px-3 text-secondary flex-shrink-0'><small>Template UUID: {newDocumentForm.template_uuid}</small></div>) : undefined}
      <div className='flex-grow-1'>
      {step === 1 && (
        <Step1
          form={newDocumentForm}
          selectedDocumentTemplate={selectedDocumentTemplate}
          onChange={onChange}
          documentTemplates={documentTemplates}
          next={onNext}
        />
      )}
      {step === 2 && (
        <Step2
          selectedDocumentTemplate={selectedDocumentTemplate!}
          form={newDocumentForm}
          onChange={onChange}
          documentTemplates={documentTemplates}
          next={onNext}
          back={onBack}
        />
      )}
      {step === 3 && (
        <Step3
          selectedDocumentTemplate={selectedDocumentTemplate!}
          form={newDocumentForm}
          onChange={onChange}
          documentTemplates={documentTemplates}
          next={onNext}
          back={onBack}
        />
      )}
      {step === 4 && (
        <Step4
          selectedDocumentTemplate={selectedDocumentTemplate!}
          form={newDocumentForm}
          onChange={onChange}
          documentTemplates={documentTemplates}
          next={onNext}
          deal={deal}
          users={users}
          back={onBack}
        />
      )}
      {step === 5 && (
        <Step5
          selectedDocumentTemplate={selectedDocumentTemplate}
          form={newDocumentForm}
          onChange={onChange}
          documentTemplates={documentTemplates}
          next={onNext}
          back={onBack}
        />
      )}
      </div>
    </div>
  );
};

export default NewDocument;
