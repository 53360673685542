import React, { useCallback } from 'react';

interface TextTableEditCellProps {
  onChange: (newValue: string | undefined | any) => void;
  internalValue: string | undefined | any;
}

export const LongTextTableEditCell = ({ internalValue, onChange, ...inputProps }: TextTableEditCellProps) => {
    const onChangeEvent = useCallback((e) => {
        const newValue = e.target.value ?? '';
        onChange(newValue);
    }, [onChange]);
  return (
    <textarea
      autoFocus
      className="form-control border-0 px-0"
      value={internalValue}
      onChange={onChangeEvent}
      {...inputProps}
    />
  );
};

export default LongTextTableEditCell;
