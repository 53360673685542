import React, { useCallback, useMemo } from 'react';
import { FieldCheckbox } from '../../../data/types';

interface CheckboxTableDisplayCellProps {
  field: FieldCheckbox;
  onChange: (newValue: string | undefined | any) => void;
  internalValue: string | undefined | any;
  displayValue: string | undefined | any;
  onFocus?: Function;
  onBlur?: Function;
  stopEditing: Function;
  isRowHovered: boolean;
}

export const CheckboxTableDisplayCell = ({
  internalValue,
  displayValue,
  isRowHovered,
  field,
  onChange,
}: CheckboxTableDisplayCellProps) => {
  const onChangeEvent = useCallback(
    (e) => {
      const newValue = e.target.checked ? 'true' : 'false';
      onChange(newValue);
    },
    [onChange],
  );
  const isChecked = useMemo(() => (internalValue === 'true' || internalValue === true), [internalValue]);
  if (isRowHovered) {
    return (
      <div className="form-check d-flex flex-row justify-content-center align-items-center">
        <input className="form-check-input" type="checkbox" checked={isChecked} onChange={onChangeEvent} />
      </div>
    );
  }

  if (!internalValue && !displayValue) {
    return <div></div>;
  }

  return (
    <div className="form-check d-flex flex-row justify-content-center align-items-center">
      <input key={`${field.fieldId}-${isChecked}`} className="form-check-input" type="checkbox" defaultChecked={isChecked} />
    </div>
  );
};

export default CheckboxTableDisplayCell;
