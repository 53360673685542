import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { DealActivity } from '../types';
import {
  setHoverColumnIndex,
  setHoverCoords,
  setHoverRowIndex,
  deselectRange,
  dragSelectionCoords,
  startSelectionCoords,
  setSelectedRangeCoords,
  toggleIsAutomationsPanelVisible
} from './actions';

interface TableUIState {
  isAutomationsPanelVisible: boolean;
  hoveredRowIndex: number | null;
  hoveredColumnIndex: number | null;
  selectedRange: {
    startRowIndex: number;
    startColumnIndex: number;
    endRowIndex: number;
    endColumnIndex: number;
  } | null;
}

const initialState: TableUIState = {
  isAutomationsPanelVisible: false,
  hoveredRowIndex: null,
  hoveredColumnIndex: null,
  selectedRange: null,
};

export const dealActivitiesSlice = createSlice({
  name: 'table-ui',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(toggleIsAutomationsPanelVisible, (state, { payload }) => {
      state.isAutomationsPanelVisible = !state.isAutomationsPanelVisible;
    });
    builder.addCase(setHoverColumnIndex, (state, { payload }) => {
      state.hoveredColumnIndex = payload.hoverColumnIndex;
    });
    builder.addCase(setHoverRowIndex, (state, { payload }) => {
      state.hoveredRowIndex = payload.hoverRowIndex;
    });
    builder.addCase(setHoverCoords, (state, { payload }) => {
      if (payload === null) {
        state.hoveredRowIndex = null;
        state.hoveredColumnIndex = null;
      } else {
        state.hoveredRowIndex = payload.hoverRowIndex;
        state.hoveredColumnIndex = payload.hoverColumnIndex;
      }
    });
    builder.addCase(deselectRange, (state, { payload }) => {
      state.selectedRange = null;
    });
    builder.addCase(dragSelectionCoords, (state, { payload }) => {
      if (state.selectedRange !== null) {
        state.selectedRange.endColumnIndex = payload.dragColumnIndex;
        state.selectedRange.endRowIndex = payload.dragRowIndex;
      } else {
        state.selectedRange = {
          startColumnIndex: payload.dragColumnIndex,
          startRowIndex: payload.dragRowIndex,
          endColumnIndex: payload.dragColumnIndex,
          endRowIndex: payload.dragRowIndex,
        };
      }
    });
    builder.addCase(startSelectionCoords, (state, { payload }) => {
      state.selectedRange = {
        startColumnIndex: payload.startColumnIndex,
        startRowIndex: payload.startRowIndex,
        endColumnIndex: payload.startColumnIndex,
        endRowIndex: payload.startRowIndex,
      };
    });
    builder.addCase(setSelectedRangeCoords, (state, { payload }) => {
      state.selectedRange = payload;
    });
  },
});

export default dealActivitiesSlice;
