import { useEffect, useMemo } from 'react';
import { DealRecord } from '../../../../data/types';
import GoogleMapReact from 'google-map-react';
import StaticMapView from '../../../GoogleMaps/StaticMapView';
import StaticStreetView from '../../../GoogleMaps/StaticStreetView';
import DealForm from '../Form/DealForm';

interface DealMapViewProps {
  deal: DealRecord;
}

export const DealMapView = ({ deal }: DealMapViewProps) => {
  return (
    <div className="row">
      <div className="col-6">
        <div className="mb-3">
          <StaticMapView center={deal.primaryFieldValue!} zoom={19} width={600} height={300} />
        </div>
        <div>
          <StaticStreetView location={deal.primaryFieldValue!} fov={120} width={600} height={300} />
        </div>
        <div >
          <a
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&zoom=19&query=${encodeURIComponent(deal.primaryFieldValue!)}`}
            rel="noreferrer"
          >
            Open Google Maps
          </a>
        </div>
      </div>
      <div className="col-6">
          <DealForm dealRecord={deal} formViewId={'4154765f-90ba-4e22-97f0-8319b18213dd'} />
      </div>
    </div>
  );
};

export default DealMapView;
