import { useCallback } from 'react';
import { Campaign, MonthlyCampaign } from '../../../../../data/types';

interface DayOfMonthInputProps {
  form: MonthlyCampaign;
  setForm: React.Dispatch<React.SetStateAction<Campaign>>;
}

export const DayOfMonthInput = ({ form, setForm }: DayOfMonthInputProps) => {
  const onChange = useCallback(
    (e) => {
      setForm({
        ...form,
        options: {
          ...form.options,
          dayOfMonth: parseInt(e.target.value),
        },
      });
    },
    [form, setForm],
  );

  return (
    <div className="mb-3">
      <label htmlFor="dayOfMonth" className="form-label">
        Day of the Month
      </label>
      <select
        onChange={onChange}
        value={form.options.dayOfMonth}
        className="form-control"
        id="dayOfMonth"
        aria-describedby="dayOfMonthHelp"
      >
        {[...Array(31)].map((x, idx) => (
          <option key={idx} value={idx + 1}>
            {idx + 1}
          </option>
        ))}
      </select>
      <div id="dayOfMonthHelp" className="form-text"></div>
    </div>
  );
};

export default DayOfMonthInput;
