import { useCallback, useState } from 'react';

interface CopyButtonProps {
  text: string;
}
export const CopyButton = ({ text }: CopyButtonProps) => {
  const [copyText, setCopyText] = useState('Copy');
  const onClickCopy = useCallback(() => {
    navigator.clipboard.writeText(text);
    setCopyText('Copied');
    setTimeout(() => setCopyText('Copy'), 3000);
  }, [text]);

  return (
    <div className="btn btn-primary btn-sm p-0" onClick={onClickCopy}>
      {copyText}
    </div>
  );
};

export default CopyButton;
