import { createSlice } from '@reduxjs/toolkit';
import { fetchProperties } from './actions';

export interface Property {
  propertyId?: string;
  address: any;

}
interface PropertiesState {
  properties: Property[];
  isLoading: boolean;
  isInitialized: boolean;
}

const initialState: PropertiesState = {
  properties: [],
  isLoading: false,
  isInitialized: false,
};

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProperties.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProperties.fulfilled, (state, { payload }) => {
      state.properties = payload;
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchProperties.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });
  },
});

export default propertiesSlice;
