import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import CampaignDetailPage from '../../pages/marketing/campaigns/CampaignDetail/CampaignDetailPage';
import CampaignsList from '../../pages/marketing/campaigns/CampaignsList';
import NewCampaignForm from '../../pages/marketing/campaigns/NewCampaignForm';
import MarketingTemplateEditorPage from '../../pages/marketing/marketing-templates/MarketingTemplateEditor/MarketingTemplateEditorPage';
import MarketingTemplatesList from '../../pages/marketing/marketing-templates/MarketingTemplatesList';
import NewMarketingTemplateForm from '../../pages/marketing/marketing-templates/NewMarketingTemplateForm';

export const MarketingRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="campaigns" element={<CampaignsList />} />
        <Route path="campaigns/:campaignId" element={<CampaignsList />} />
        <Route path="campaigns/:campaignId/*" element={<CampaignsList />} />


        <Route path="templates/" element={<MarketingTemplatesList />} />
        <Route path="templates/:marketingTemplateId" element={<MarketingTemplatesList />} />
        <Route path="templates/:marketingTemplateId/*" element={<MarketingTemplatesList />} />

        <Route path="*" element={<Navigate to="/campaigns/" />} />
      </Routes>
      <Routes>
        <Route path="/campaigns/new" element={<NewCampaignForm />} />
        <Route path="/campaigns/:campaignId/*" element={<CampaignDetailPage />} />
      </Routes>
      <Routes>
        <Route path="/templates/new" element={<NewMarketingTemplateForm />} />
        <Route path="/templates/:marketingTemplateId/*" element={<MarketingTemplateEditorPage />} />
      </Routes>
    </>
  );
};

export default MarketingRoutes;
