import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ContentType } from "@taida-corp/taidacorp-sdk";
import { API } from "aws-amplify";

export const setSelectedContentType = createAction<string|undefined>('contact.setSelectedContentType');
export const realTimeContentTypeUpdated = createAction<{ contentType: ContentType }>('contentTypes.updated');
export const realTimeContentTypeDeleted = createAction<{ contentType: ContentType }>('contentTypes.deleted');
export const realTimeContentTypeCreated = createAction<{ contentType: ContentType }>('contentTypes.created');


export const fetchContentTypes = createAsyncThunk<ContentType[], undefined, { rejectValue: string[] }>(
    'contentTypes/fetchContentTypes',
    async () => {
        const data = await API.get(
          'Content', // function defined in our serverless.yml
          '',            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    },
  );

  export const fetchContentType = createAsyncThunk<ContentType, {contentTypeSlug:string}, { rejectValue: string[] }>(
    'contentTypes/fetchContentType',
    async ({contentTypeSlug}) => {
        const data = await API.get(
          'Content', // function defined in our serverless.yml
          contentTypeSlug,            // the function's path
          { 'responseType': 'json' }
        );
        return data;
    },
  );
  
  
  export const createContentType = createAsyncThunk<ContentType, Partial<ContentType>, { rejectValue: string[] }>(
    'contentTypes/createContentType',
    async (contentType) => {
      console.log('hello', contentType);
      try {
        
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          ``,            // the function's path
          { 'responseType': 'json', body: contentType }
        );
        console.log('resp', resp);
        return resp;

      } catch (error) {
          console.log('error', error);
      }
    },
  );

  export const updateContentType = createAsyncThunk<ContentType, Partial<ContentType>, { rejectValue: string[] }>(
    'contentTypes/updateContentType',
    async (contentType) => {
        const resp = await API.post(
          'Content', // function defined in our serverless.yml
          contentType.slug,            // the function's path
          { 'responseType': 'json', body: contentType }
        );
        return resp;
    },
  );
  
  export const deleteContentType = createAsyncThunk<ContentType, string, { rejectValue: string[] }>(
    'contentTypes/deleteContentType',
    async (contentTypeSlug) => {
      const resp = await API.del(
        'Content', // function defined in our serverless.yml
        contentTypeSlug, // the function's path
        { responseType: 'json', body: {} },
      );
      return resp;
    },
  );
  
