import { faArrowLeft, faList, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIndex as _findIndex } from 'lodash';
import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Company, DealRecord, DocumentTemplate, Field, NewDocumentForm, User } from '../../../../../data/types';
import { getTokensDropdownOptions } from './dropdownSelectors';
import { DropDownGroupOptions, DropDownOption, InputWithDropdownStrings } from './InputWithDropdownStrings';


interface Step3Props {
  form: NewDocumentForm;
  onChange: (formUpdates: Partial<NewDocumentForm>) => void;
  next: () => void;
  back: () => void;
  documentTemplates: DocumentTemplate[];
  selectedDocumentTemplate: DocumentTemplate;
}

export const Step3 = ({
  form,
  onChange,
  next,
  back,
  documentTemplates,
  selectedDocumentTemplate,
}: Step3Props) => {
  const isNextEnabled = useMemo(() => {
    return true;
  }, []);
  const isBackEnabled = useMemo(() => {
    return true;
  }, []);
  const onChangeTokens = useCallback(
    (index, newValue) => {
      const tokenKey = form.tokens![index].name;
      const curr = [...form.tokens!];
      curr.splice(index, 1, { name: tokenKey, value: newValue });
      onChange({ tokens: curr });
    },
    [form.tokens, onChange],
  );

  const dropdownOptions: DropDownGroupOptions[] = useSelector(getTokensDropdownOptions);
  return (
    <div className="h-100 d-flex flex-column p-3">
      <div>
        <h3>Template Tokens</h3>
        <small>These need to be filled in now or they will be blank at signing.</small>
      </div>
      <div className="flex-grow-1">
        <table className="table table-responsive">
          <thead>
            <tr>
              <td>Name</td>
              <td>Value</td>
            </tr>
          </thead>
          <tbody>
            {form.tokens!.map((token: any, idx:number) => (
              <tr key={token.name}>
                <td>{token.name}</td>
                <td>
                  <InputWithDropdownStrings value={token.value} onChange={(val) => onChangeTokens(idx, val)} dropdownOptions={dropdownOptions} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex-shrink-1 d-flex flex-row justify-content-between">
      <button className="btn btn-primary" disabled={!isBackEnabled} onClick={back}>
          Back
        </button>

        <button className="btn btn-primary" disabled={!isNextEnabled} onClick={next}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Step3;
