
import { cloneDeep as _cloneDeep, isEqual as _isEqual } from 'lodash';
import { useCallback, useMemo } from 'react';
import ScheduleAutomationForm from './ScheduleAutomationForm/ScheduleAutomationForm';
import { AutomationWithFilterForm } from './AutomationWithFilterForm/AutomationWithFilterForm';
import { useSelector } from 'react-redux';
import { getSelectedViewId } from '../../../../../../data/content-type/record-views/selectors';
import AutomationViewPicker from './AutomationViewPicker/AutomationViewPicker';
import { RecordAutomation, defaultScheduleOptionsForFrequency, AutomationTypeSelectOptions, ScheduledWithViewAutomation, NewRecordInViewAutomation, UpdatedRecordInViewAutomation, ScheduledWithFilterAutomation, NewRecordWithFilterAutomation, UpdatedRecordWithFilterAutomation, DeletedRecordWithFilterAutomation, RecordAutomationTriggerType } from '@taida-corp/taidacorp-sdk';

interface AutomationTriggerProps {
  updateAutomation: (updatedRecordAutomation:RecordAutomation) => void;
  formData: RecordAutomation;
}

export const AutomationTriggerForm = ({ formData, updateAutomation }: AutomationTriggerProps) => {
  const currentViewId = useSelector(getSelectedViewId);
  const onChangeTrigger = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();
      const updated = _cloneDeep(formData);
      updated.type = event.target.value as RecordAutomationTriggerType;
        updated.triggerConfig = {};
        if (updated.type === 'scheduled-in-view-automation' || updated.type === 'scheduled-with-filter-automation') {
          updated.triggerConfig.scheduleOptions = defaultScheduleOptionsForFrequency('daily');
        }
        if (
          updated.type === 'scheduled-in-view-automation' ||
          updated.type === 'new-record-in-view-automation' ||
          updated.type === 'updated-record-in-view-automation'
        ) {
          updated.triggerConfig.tableViewId = currentViewId!;
        }
        if (
          updated.type === 'scheduled-with-filter-automation' ||
          updated.type === 'new-record-with-filter-automation' ||
          updated.type === 'deleted-record-with-filter-automation' ||
          updated.type === 'updated-record-with-filter-automation'
        ) {
          updated.triggerConfig.filters = { operator: 'and', conditions: [] };
        }
        updateAutomation(updated)
    },
    [updateAutomation, formData, currentViewId],
  );
  const hasFilters = useMemo(() => {
    return (
      formData.type === 'scheduled-with-filter-automation' ||
      formData.type === 'deleted-record-with-filter-automation' ||
      formData.type === 'updated-record-with-filter-automation' ||
      formData.type === 'new-record-with-filter-automation'
    );
  }, [formData.type]);
  const hasSchedule = useMemo(() => {
    return formData.type === 'scheduled-in-view-automation' || formData.type === 'scheduled-with-filter-automation';
  }, [formData.type]);
  const hasView = useMemo(() => {
    return (
      formData.type === 'scheduled-in-view-automation' ||
      formData.type === 'new-record-in-view-automation' ||
      formData.type === 'updated-record-in-view-automation'
    );
  }, [formData.type]);
  return (
    <div className='card card-body mb-3'>
      <div className="mb-3">
        <label htmlFor="frequency" className="form-label">
          Trigger
        </label>
        <select
          onChange={onChangeTrigger}
          value={formData.type}
          className="form-select"
          id="frequency"
          aria-describedby="frequencyHelp"
        >
          {AutomationTypeSelectOptions.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </div>
      {hasView && (
        <AutomationViewPicker
        formData={formData as ScheduledWithViewAutomation|NewRecordInViewAutomation|UpdatedRecordInViewAutomation}
          updateAutomation={updateAutomation}
        />
      )}
      {hasSchedule && (
        <ScheduleAutomationForm
          formData={formData as ScheduledWithFilterAutomation | ScheduledWithViewAutomation}
          updateAutomation={updateAutomation}
        />
      )}
      {hasFilters && (
        <AutomationWithFilterForm
          formData={
            formData as
              | NewRecordWithFilterAutomation
              | UpdatedRecordWithFilterAutomation
              | DeletedRecordWithFilterAutomation
              | ScheduledWithFilterAutomation
          }
          updateAutomation={updateAutomation}
        />
      )}
    </div>
  );
};
