import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';

import { debounce as _debounce, find as _find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDealRecord, updateDealRecord, updateDealRecordByField } from '../../data/deals/actions';
import { getColumnWidths, getFields } from '../../data/company/selectors';
import {
  DealRecord,
  FieldAttachmentValue,
  FieldDateValue,
  FieldFormulaValue,
  FieldMultiSelectValue,
  FieldSelectConfig,
  FieldSelectOptions,
  isFieldAttachmentValue,
  isFieldDate,
  isFieldDatetime,
  isFieldDateValue,
  isFieldFormulaValue,
  isFieldMultiSelectValue,
  TableViewField,
} from '../../data/types';
import useOnClickOutside from '../useOnClickOutside/useOnClickOutside';
import useDoubleClick from '../useDoubleClick/useDoubleClick';
import { format } from 'date-fns';
import { getSelectUsersOptions } from '../../data/users/selectors';
import { useNavigate, useParams } from 'react-router-dom';

function isNumeric(str: any) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export const useDataCell = (
  cellRef: RefObject<HTMLTableDataCellElement | null>,
  dealRecord: DealRecord,
  field: TableViewField,
) => {
  const initialValue = isFieldFormulaValue(dealRecord.fields![field.fieldId])
    ? (dealRecord.fields![field.fieldId] as FieldFormulaValue).value
    : dealRecord.fields![field.fieldId];
  if (isFieldDateValue(initialValue)) {

  }
  const ogValue = useMemo(() => {
    return dealRecord.fields![`${field.fieldId}-og`];
  }, [dealRecord, field.fieldId]);
  const [isContextVisible, setIsContextVisible] = useState(false);
  const [isEditing, _setIsEditing] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [internalValue, _setInternalValue] = useState(initialValue);
  const [displayValue, setDisplayValue] = useState<string>('');
  const widths = useSelector(getColumnWidths);
  const width = useMemo(() => widths[field.fieldId] ?? 140, [widths, field]);
  const userOptions = useSelector(getSelectUsersOptions);
  const navigate = useNavigate();
  const { viewId = '2a4abdcd-4f47-4276-8840-b7e20f85f6f8' } = useParams<{ viewId?: string }>();
  useEffect(() => {
    if (!isEditing || isFieldDateValue(initialValue)) {
      _setInternalValue(initialValue);
    }
  }, [initialValue, isEditing]);

  const dispatch = useDispatch();
  const fields = useSelector(getFields);
  const dealField = fields[field.fieldId];
  const fieldType = dealField.type;
  const fieldConfig = dealField.config;
  const canEdit = useMemo(() => {
    if (fieldType === 'formula') {
      return false;
    }
    return true;
  }, [fieldType]);
  const setIsEditing = useCallback(
    (value: React.SetStateAction<boolean>) => {
      if (!canEdit) {
        return;
      }
      _setIsEditing(value);
    },
    [canEdit],
  );
  const startEditing = useCallback(() => {
    if(fieldType === 'attachment') {
      navigate(`/deals/${viewId}/edit/${dealRecord.dealId}`);
      return;
    }
    setIsEditing(true);
  }, [dealRecord.dealId, fieldType, navigate, setIsEditing, viewId]);
  const setInternalValue = useCallback(
    (value: React.SetStateAction<string | FieldFormulaValue | FieldDateValue | FieldAttachmentValue | FieldMultiSelectValue | null>) => {
      if (!canEdit) {
        return;
      }
      _setInternalValue(value);
    },
    [canEdit],
  );

  const isInvalid = useMemo(() => {
    if (fieldType === 'number') {
      return !isNumeric(internalValue);
    }
    return false;
  }, [internalValue, fieldType]);

  const debouncedUpdateDealRecord = useMemo(() => {
    return _debounce(
      (updates: { dealId: string; fieldId: string; value: string }) => dispatch(updateDealRecordByField(updates)),
      300,
    );
  }, [dispatch]);

  useEffect(() => {
    let _displayValue = '';
    if (internalValue === null || internalValue === undefined || internalValue === '') {
      _displayValue = '';
    } else if (fieldType === 'select-user' && !isFieldFormulaValue(internalValue)) {
      // console.log('in usedat cell', internalValue);
      // const currentValue = _find(userOptions, { value: internalValue });
      // console.log(userOptions);
      _displayValue = internalValue as string; //currentValue !== undefined ? currentValue.label : '';
    } else if (fieldType === 'select' && !isFieldFormulaValue(internalValue)) {
      // const options = (fieldConfig as FieldSelectConfig).options as FieldSelectOptions;
      // const currentValue = _find(options, { value: internalValue });
      // _displayValue = currentValue !== undefined ? currentValue.label : '';
      _displayValue = internalValue as string;
    } else if (isFieldAttachmentValue(internalValue)) {
      _displayValue = 'Attachment';
    } else if (isFieldFormulaValue(internalValue)) {
      _displayValue = internalValue.value;
    } else if (isFieldDate(dealField) && isFieldDateValue(internalValue)) {
      try {
        _displayValue = format(new Date(internalValue.dtValue), 'P');
      } catch (error) {
        _displayValue = 'INVALID DATE';
      }
    } else if (isFieldDatetime(dealField) && isFieldDateValue(internalValue)) {
      try {
        _displayValue = format(new Date(internalValue.dtValue), 'Pp');
      } catch (error) {
        _displayValue = 'INVALID DATE';
      }
    } else if (isFieldDateValue(internalValue)) {
      _displayValue = 'INVALID DATE';
    } else if (isFieldMultiSelectValue(internalValue)) {
      if(Array.isArray(internalValue)) {
        _displayValue = internalValue.join(',');
      } if(Array.isArray(internalValue.value)) {
        _displayValue = internalValue.value.join(',');
      }  else if(internalValue.value === null) {
        _displayValue = '';
      } else {
        _displayValue = internalValue.value as string;
      }

    }
    else {
      _displayValue = internalValue;
    }
    setDisplayValue(_displayValue);
  }, [fieldType, fieldConfig, internalValue, dealField, userOptions, field]);

  const onChange = useCallback(
    (newValue) => {
      if (!canEdit) {
        return;
      }
      setInternalValue(newValue);
      //onChangeValue(newTitle);
      debouncedUpdateDealRecord({ dealId: dealRecord.dealId, fieldId: field.fieldId, value: newValue });
      // debouncedUpdateDealRecord({
      //   ...dealRecord,
      //   fields: { ...dealRecord.fields, [field.fieldId]: newValue },
      // });
    },
    [canEdit, setInternalValue, debouncedUpdateDealRecord, dealRecord.dealId, field.fieldId],
  );

  const onSingleClick = useCallback(() => {}, []);
  const onDoubleClick = useCallback(() => {
    
    startEditing();
  }, [startEditing]);
  const onClickOutside = useCallback(() => {
    setIsSelected(false);
    setIsEditing(false);
    setIsContextVisible(false);
  }, [setIsEditing]);
  useOnClickOutside(cellRef, onClickOutside, true);

  useDoubleClick({
    ref: cellRef,
    latency: 250,
    onSingleClick,
    onDoubleClick: onDoubleClick,
  });

  const onKeyDown = useCallback(
    (event: any) => {
      console.log(event.code);
      const cell = cellRef.current!;
      const prevElement = cell.previousElementSibling as HTMLElement;
      const nextElement = cell.nextElementSibling as HTMLElement;
      const upElement = cell.parentElement?.previousElementSibling?.children[cell.cellIndex] as HTMLElement;
      const downElement = cell.parentElement?.nextElementSibling?.children[cell.cellIndex] as HTMLElement;

      if (isEditing) {
        if (event.key === 'Enter') {
          if (downElement) {
            downElement.focus();
          } else {
            if (fieldType === 'attachment') {
              return;
            }
            cell.blur();
          }
          setIsSelected(false);
          setIsEditing(false);
        }
        if (event.key === 'Escape') {
          cell.blur();
          setIsSelected(false);
          setIsEditing(false);
        }
        return;
      }

      switch (event.key) {
        case 'ArrowLeft':
          if (prevElement) {
            prevElement.focus();
          } else {
            cell.blur();
          }
          setIsSelected(false);
          setIsEditing(false);
          return;
        case 'ArrowRight':
          if (nextElement) {
            nextElement.focus();
          } else {
            cell.blur();
          }
          setIsSelected(false);
          setIsEditing(false);
          return;
        case 'ArrowDown':
          if (downElement) {
            downElement.focus();
          } else {
            cell.blur();
          }
          setIsSelected(false);
          setIsEditing(false);
          return;
        case 'ArrowUp':
          if (upElement) {
            upElement.focus();
          } else {
            cell.blur();
          }
          setIsSelected(false);
          setIsEditing(false);
          return;
        case 'Escape':
          if (!isEditing) {
            return;
          }
          cell.blur();
          setIsSelected(false);
          setIsEditing(false);
          return;
        case 'Enter':
          if (!isEditing) {
            //setInternalValue('');
            startEditing();
            return;
          }
          if (downElement) {
            downElement.focus();
          } else {
            if (fieldType === 'attachment') {
              return;
            }
            cell.blur();
          }
          setIsSelected(false);
          setIsEditing(false);
          return;
        default:
          break;
      }

      if (event.code === 'Tab') {
        cellRef.current!.blur();
        setIsSelected(false);
        setIsEditing(false);
        return;
      }
      if (event.code === 'Delete' || event.code === 'Backspace') {
        if (fieldType === 'attachment') {
          onChange('');
          setInternalValue('');
          return;
        }
        onChange('');
        setInternalValue('');
        startEditing();
        return;
      }

      if (!isEditing) {
        setInternalValue('');
        startEditing();
      }
    },
    [cellRef, fieldType, isEditing, onChange, setInternalValue, setIsEditing, startEditing],
  );
  const onFocus = useCallback((e) => {
    if (e.currentTarget === e.target) {
      setIsSelected(true);
    }
  }, []);
  const deselect = useCallback(() => {
    setIsSelected(false);
  }, []);
  const stopEditing = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);
  
  const onBlur = useCallback(
    (e) => {
      setIsEditing(false);
      setIsSelected(false);
    },
    [setIsEditing],
  );

  const onContextMenu = useCallback(
    (e) => {
      if (!e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey) {
        e.preventDefault();
        navigate(`/deals/${viewId}/edit/${dealRecord.dealId}`);
      }
      /*
      e.preventDefault();
      // TODO: implement an actual right click menu
      // eslint-disable-next-line no-restricted-globals
      const shouldDelete = confirm('You sure you want to delete this row?');
      if (shouldDelete) {
        dispatch(deleteDealRecord(dealRecord));
      }
      */
    },
    [dealRecord.dealId, navigate, viewId],
  );

  return {
    cellProps: {
      onKeyDown,
      onFocus,
      tabIndex: 0,
      onContextMenu,
    },
    inputProps: {
      onBlur,
    },
    stopEditing,
    startEditing,
    deselect,
    isContextVisible,
    isSelected,
    isEditing,
    isInvalid,
    setIsEditing,
    setIsSelected,
    internalValue,
    setInternalValue,
    displayValue,
    ogValue,
    fieldType,
    field: dealField,
    onChange,
    width,
  };
};
