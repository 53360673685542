import { RecordAutomationAction, RecordAutomation, APIView, APIViewField, RecordView } from '@taida-corp/taidacorp-sdk';
import { cloneDeep, cloneDeep as _cloneDeep, debounce as _debounce, find as _find, findIndex } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getContentTypes } from '../../../../../../../data/content-type/content-types/selectors';
import { updateRecordView } from '../../../../../../../data/content-type/record-views/actions';
import { useAppDispatch } from '../../../../../../../data/store';
import APIViewFieldEditor from './APIViewFieldEditor';

interface ActionAPIViewEditorProps {
  automation:RecordAutomation;
  apiView:APIView;
  testInputData: Record<string, any>;
}
export const ActionAPIViewEditor = ({ apiView, automation, testInputData }: ActionAPIViewEditorProps) => {
    const dispatch = useAppDispatch();
  const contentTypes = useSelector(getContentTypes);
  const apiViewContentType = useMemo(() => _find(contentTypes, {slug: apiView.contentType}), [apiView.contentType, contentTypes]);
    const fields = useMemo(() => apiViewContentType !== undefined ? apiViewContentType.fields : undefined, [apiViewContentType]);

  const [formData, setFormData] = useState(apiView);
  const debouncedSyncUpdates = useMemo(() => {
    return _debounce(
      (updatedView: RecordView) => dispatch(updateRecordView({view: updatedView, contentTypeSlug: updatedView.contentType})),
      1500,
    );
  }, [dispatch]);
  const onChangeViewField = useCallback(
    (viewField:APIViewField) => {
      const newView = cloneDeep(formData);
      const index = findIndex(formData.properties.fields, {fieldId: viewField.fieldId});
      newView.properties.fields[index] = viewField;
      setFormData(newView);
      debouncedSyncUpdates(newView);
    },
    [formData, debouncedSyncUpdates],
  );

  if(apiViewContentType === undefined || fields === undefined) {
    return (<div>Loading Fields</div>)
  }
  

  return (
    <div>
      {formData.properties.fields.map((apiViewField) => (
        <APIViewFieldEditor onChangeViewField={onChangeViewField} key={apiViewField.fieldId} apiViewField={apiViewField} fields={fields} testInputData={testInputData} />
      ))}
    </div>
  );
};

export default ActionAPIViewEditor;
