import { values as _values, orderBy as _orderBy } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const getSlackState = (state: RootState) => state.slack;

export const getSlackChannels = createSelector(getSlackState, (state) => state.channels);
export const getSlackUsers = createSelector(getSlackState, (state) => state.users);
export const getIsInitialized = createSelector(getSlackState, (state) => state.isInitialized);
export const getIsLoading = createSelector(getSlackState, (state) => state.isLoading);

