import { find as _find, includes as _includes, debounce as _debounce, isEqual as _isEqual, isEqual } from 'lodash';
import { Modal } from 'react-bootstrap';
import { DealRecord, Field, FormView, User } from '../../../data/types';
import { usePlacesWidget } from 'react-google-autocomplete';

import './NewDealForm.scss';
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import NewDealFormInput from './NewDealFormInput';
import { API } from 'aws-amplify';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDeQhH5kh3QQxEw-I7FwNodpbOSUFPf5PY';

const ADDRESS_LINE_1 = '689a69f8-b970-4fcf-89ce-a166732db4c0';
const ADDRESS_LINE_2 = 'aae87b72-1afe-4c3b-8560-2c733787033e';
const CITY = 'a5a1c876-f9b7-4fc6-936e-02ba59237ede';
const STATE = 'e1319715-d721-4eb2-b1eb-d387df7d35d6';
const ZIPCODE = '6e42f36c-d440-4490-91de-fc0e1023a0fa';
const COUNTRY = '69909b84-ef50-475e-8f55-61846dfc8169';
const COUNTY = '984eb1a0-dc64-445f-b838-f8d91cb8df34';
const GOOGLE_PLACE_ID = 'fdbdb022-bc28-435b-9256-4b6812ddc007';

const BUY_BOX_CITY = 'c7d27f27-abf8-4793-86ad-ffc9607f8925';

const ZILLOW_LINK = '9dbc6cbc-bc4f-4e18-bebe-40a098a89e39';
const REP_FIELD = '85c46e42-0f90-425e-9999-5048e308c764';

interface NewDealFormProps {
  formView: FormView;
  validateNewDeal: (fieldValues: Record<string, any>) => void;
  saveDeal: (fieldValues: Record<string, any>) => void;
  errors: Record<string, string[]>;
  cleanedData?: Record<string, any>;
  isVisible: boolean;
  back: any;
  fields: Field[];
  defaultUser: User|null;
}

export const NewDealForm = ({
  formView,
  isVisible,
  back,
  fields,
  validateNewDeal,
  saveDeal,
  cleanedData,
  errors,
  defaultUser,
}: NewDealFormProps) => {
  const [fieldValues, setFieldValues] = useState({});
  const [zillowLink, setZillowLink] = useState('');
  const prevCleanedData = useRef<any>({});
  const inputRef = useRef<any|null>(null);
  const debouncedValidateDealData = useMemo(() => {
    return _debounce((fieldValuesIn: Record<string, any>) => validateNewDeal(fieldValuesIn), 1000);
  }, [validateNewDeal]);
  const onChangeZillowLink = useCallback((e) => {
    setZillowLink(e.target.value ?? '');
  }, []);
  const onSubmitZillowLink = useCallback(
    async (e) => {
      e.preventDefault();
      const zillowData = await API.post(
        'Scrapers', // function defined in our serverless.yml
        'zillow', // the function's path
        { responseType: 'json', body: { url: zillowLink } },
      );
      console.log('zillowData', zillowData);
    },
    [zillowLink],
  );
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      saveDeal(fieldValues);
    },
    [saveDeal, fieldValues],
  );
  const onClose = useCallback(() => {
    setFieldValues({});
    back();
  }, [back]);

  const setFieldValue = useCallback((fieldId: string, newValue: any) => {
    setFieldValues((curr) => {
      return { ...curr, [fieldId]: newValue };
    });
  }, []);
  
  const onPlaceSelected = useCallback((place: google.maps.places.PlaceResult) => {
    inputRef!.current!.value = place.formatted_address ?? '';
    const country = _find(
      place.address_components,
      (x) => _includes(x.types, 'country') && _includes(x.types, 'political'),
    )?.long_name;
    const city = _find(
      place.address_components,
      (x) => _includes(x.types, 'locality') && _includes(x.types, 'political'),
    )?.long_name;
    const stateObj = _find(
      place.address_components,
      (x) => _includes(x.types, 'administrative_area_level_1') && _includes(x.types, 'political'),
    );
    const county = _find(
      place.address_components,
      (x) => _includes(x.types, 'administrative_area_level_2') && _includes(x.types, 'political'),
    )?.long_name;

    console.log('place.address_components', place.address_components);
    const stateAbr = stateObj?.short_name;
    const stateFull = stateObj?.long_name;
    const streetNumber = _find(place.address_components, (x) => _includes(x.types, 'street_number'))?.long_name ?? '';
    const route = _find(place.address_components, (x) => _includes(x.types, 'route'))?.short_name;
    const line1 = `${streetNumber} ${route}`.trim();
    const postalCode = _find(place.address_components, (x) => _includes(x.types, 'postal_code'))?.long_name;
    const postalCodeSuffix = _find(place.address_components, (x) =>
      _includes(x.types, 'postal_code_suffix'),
    )?.long_name;
    setFieldValues((curr) => ({
      ...curr,
      [ADDRESS_LINE_1]: line1,
      [CITY]: city,
      [STATE]: stateAbr,
      [ZIPCODE]: postalCode,
      [COUNTRY]: country,
      [COUNTY]: county,
      [GOOGLE_PLACE_ID]: place.place_id,
    }));
  }, []);
  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected,
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  });
  
  useEffect(() => {

    if (!_isEqual(fieldValues, {}) && !_isEqual(fieldValues, prevCleanedData.current)) {
      // TODO: change this so we only run it on fields that impact primary key
      // can hardcode those field id checks
      debouncedValidateDealData(fieldValues);
    } else {
      // don't try to validate the form before we've changed it
    }
  }, [fieldValues, debouncedValidateDealData]);
  useEffect(() => {
    if (!_isEqual(cleanedData, prevCleanedData.current)) {
      prevCleanedData.current = cleanedData;
      setFieldValues({ ...cleanedData });
    }
  }, [cleanedData]);

  useEffect(() => {
    if(ref.current) {
      inputRef.current = ref.current;
    }
  }, [ref]);

  useEffect(() => {
    setFieldValues({});
  }, [])

  return (
    <Modal size="xl" fullscreen="lg-down" show={isVisible} onHide={onClose}  keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>New Deal</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row px-0 m-0 pb-5 pt-2" style={{ minHeight: 'calc(100vh - 200px)' }}>
          <div>
            <form onSubmit={onSubmitZillowLink}>
              {/* <input type="text" className="form-control" placeholder='zillow detail link' value={zillowLink} onChange={onChangeZillowLink} /> */}
            </form>
          </div>
          <form onSubmit={onSubmit}>
            <div>
              <input placeholder="Google Maps Address Search" type="text" className="form-control" ref={ref} />

              {formView.properties.fields.map((field) => {
                const fullField = _find(fields, { fieldId: field.fieldId })!;
                const defaultValue = field.fieldId === REP_FIELD && !!defaultUser ? defaultUser.userId : '';
                return (
                  <NewDealFormInput
                    key={`field-${field.fieldId}`}
                    field={fullField}
                    data={fieldValues}
                    defaultValue={defaultValue}
                    setFieldValue={setFieldValue}
                    errors={errors[field.fieldId]}
                  />
                );
              })}
            </div>
            <div>
              <button type="submit" className="btn btn-primary">
                Create Deal
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};
