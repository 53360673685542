import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import ContentRoutes from '../../navigation/routes/ContentRoutes';
import ContentDetailPage from '../../pages/ContentPage/content-detail-page/ContentDetailPage';
import ContentPage from '../../pages/ContentPage/ContentPage';
import NewContentPage from '../../pages/ContentPage/new-content-page/NewContentPage';
import ContentTypesList from './ContentTypesList';

export const ContentTypeRoutes = () => {
  return (
    <>
      <Routes>
        <Route index element={<ContentTypesList />} />
      </Routes>
    </>
  );
};

export default ContentTypeRoutes;
