import { Result } from "@uppy/transloadit";
import { Template } from "./template";
import { has as _has } from "lodash";

export type FieldType =
  | "long-text"
  | "text"
  | "number"
  | "formula"
  | "datetime"
  | "date"
  | "phone"
  | "email"
  | "url"
  | "attachment"
  | "select"
  | "multi-select"
  | "select-user"
  | "multi-select-user"
  | "checkbox"
  | "slider"
  | "color";
export type FieldTextConfig = {
  defaultValue: string | null;
};
export type FieldText = {
  fieldId: string;
  type: "text";
  name: string;
  config: FieldTextConfig;
};
export type FieldLongText = {
  fieldId: string;
  type: "long-text";
  name: string;
  config: FieldTextConfig;
};
export type FieldNumberConfig = {
  defaultValue: number | null;
};
export type FieldNumber = {
  fieldId: string;
  type: "number";
  name: string;
  config: FieldNumberConfig;
};
export type FieldCheckboxConfig = {
  defaultValue: boolean | null;
};
export type FieldCheckbox = {
  fieldId: string;
  type: "checkbox";
  name: string;
  config: FieldCheckboxConfig;
};
export type FieldColor = {
  fieldId: string;
  type: "color";
  name: string;
  config: FieldTextConfig;
};
export type FieldFormulaConfig = {
  template: Template;
};
export const isFieldFormula = (field: Field): field is FieldFormula => {
  if (field === undefined) {
    return false;
  }
  return field.type === "formula";
};
export const isFieldSelect = (field: Field): field is FieldSelect => {
  if (field === undefined) {
    return false;
  }
  return field.type === "select";
};
export type FieldFormula = {
  fieldId: string;
  type: "formula";
  name: string;
  config: FieldFormulaConfig;
};
export type FieldSelectOption = { label: string; value: string };
export type FieldSelectOptions = FieldSelectOption[];
export type FieldSelectConfig = {
  defaultValue: string | null;
  options: FieldSelectOptions;
};
export type FieldSelect = {
  fieldId: string;
  type: "select";
  name: string;
  config: {
    defaultValue: string | null;
    options: FieldSelectOptions;
  };
};
export type FieldMultiSelectConfig = {
  defaultValue: string[] | null;
  options: { label: string; value: string }[];
};
export type FieldMultiSelect = {
  fieldId: string;
  name: string;
  type: "multi-select";
  config: FieldMultiSelectConfig;
};
export type FieldSelectUserConfig = {
  defaultValue: string | null;
};
export type FieldSelectUser = {
  fieldId: string;
  type: "select-user";
  name: string;
  config: FieldSelectUserConfig;
};
export type FieldMultiSelectUserConfig = {
  defaultValue: string[] | null;
};
export type FieldMultiSelectUser = {
  fieldId: string;
  name: string;
  type: "multi-select-user";
  config: FieldMultiSelectUserConfig;
};
export type FieldSliderConfig = {
  defaultValue: string | null;
  min: number | null;
  max: number | null;
};
export type FieldSlider = {
  fieldId: string;
  name: string;
  type: "slider";
  config: FieldSliderConfig;
};
export type FieldDateConfig = {
  defaultValue: string | null;
  min: string | null;
  max: string | null;
  googleCalendarId?: string | null;
};
export const isFieldDatetime = (field: Field): field is FieldDatetime => {
  if (field === undefined) {
    return false;
  }
  return field.type === "datetime";
};
export type FieldDatetime = {
  fieldId: string;
  name: string;
  type: "datetime";
  config: FieldDateConfig;
};
export const isFieldDate = (field: Field): field is FieldDatetime => {
  if (field === undefined) {
    return false;
  }
  return field.type === "date";
};
export type FieldDate = {
  fieldId: string;
  name: string;
  type: "date";
  config: FieldDateConfig;
};
export type FieldPhone = {
  fieldId: string;
  name: string;
  type: "phone";
  config: FieldTextConfig;
};
export type FieldEmail = {
  fieldId: string;
  name: string;
  type: "email";
  config: FieldTextConfig;
};
export type FieldURL = {
  fieldId: string;
  name: string;
  type: "url";
  config: FieldTextConfig;
};

export type FieldAttachmentType =
  | "plain-text"
  | "image"
  | "audio"
  | "video"
  | "pdf"
  | "rich-text"
  | "presentation"
  | "spreadsheet"
  | "archive"
  | "code"
  | "markup";
export type FieldAttachmentConfig = {
  fileType: FieldAttachmentType[] | null;
  minNumberOfFiles: number | null;
  maxNumberOfFiles: number | null;
  maxTotalFileSizeKB: number | null;
  minSizeKB: number | null;
  maxSizeKB: number | null;

  minImageWidth: number | null;
  minImageHeight: number | null;
  maxImageWidth: number | null;
  maxImageHeight: number | null;
};
export const isFieldAttachment = (field: unknown): field is FieldAttachment => {
  try {
    return (field as Field).type === "attachment";
  } catch {
    return false;
  }
};
export type FieldAttachment = {
  fieldId: string;
  name: string;
  type: "attachment";
  config: FieldAttachmentConfig;
};

export type FieldConfig =
  | FieldTextConfig
  | FieldFormulaConfig
  | FieldNumberConfig
  | FieldCheckboxConfig
  | FieldDateConfig
  | FieldSliderConfig
  | FieldSelectConfig
  | FieldMultiSelectConfig
  | FieldSelectUserConfig
  | FieldMultiSelectUserConfig
  | FieldAttachmentConfig;
export type Field =
  | FieldLongText
  | FieldText
  | FieldFormula
  | FieldNumber
  | FieldCheckbox
  | FieldColor
  | FieldSelect
  | FieldMultiSelect
  | FieldSelectUser
  | FieldMultiSelectUser
  | FieldSlider
  | FieldDatetime
  | FieldDate
  | FieldEmail
  | FieldPhone
  | FieldURL
  | FieldAttachment;

export interface AttachmentMeta {}
export const isVideoAttachment = (value: unknown): value is VideoAttachment => {
  if (value === undefined) {
    return false;
  }
  const requiredKeys = ["type", "original"];
  if (
    requiredKeys.every((k) => k in (value as any)) &&
    (value as any).type === "video-attachment"
  ) {
    return true;
  }
  return false;
};
export interface VideoAttachment {
  type: "video-attachment";
  original: Result;
  video_mp4_1080p: Result;
  thumbnail_150x150: Result[];
  video_cover: Result[];
  audio_aac: Result | undefined;
}
export const isImageAttachment = (value: unknown): value is ImageAttachment => {
  if (value === undefined) {
    return false;
  }
  const requiredKeys = ["type", "original"];
  if (
    requiredKeys.every((k) => k in (value as any)) &&
    (value as any).type === "image-attachment"
  ) {
    return true;
  }
  return false;
};
export interface ImageAttachment {
  type: "image-attachment";
  original: Result;
  image_landscape: Result;
  thumbnail_150x150: Result;
  image_1920: Result;
}
export const isAudioAttachment = (value: unknown): value is AudioAttachment => {
  if (value === undefined) {
    return false;
  }
  const requiredKeys = ["type", "original"];
  if (
    requiredKeys.every((k) => k in (value as any)) &&
    (value as any).type === "audio-attachment"
  ) {
    return true;
  }
  return false;
};
export interface AudioAttachment {
  type: "audio-attachment";
  original: Result;
  audio_aac: Result;
}
export const isFileAttachment = (value: unknown): value is FileAttachment => {
  if (value === undefined) {
    return false;
  }
  const requiredKeys = ["type", "original"];
  if (
    requiredKeys.every((k) => k in (value as any)) &&
    (value as any).type === "file-attachment"
  ) {
    return true;
  }
  return false;
};
export interface FileAttachment {
  type: "file-attachment";
  original: Result;
  image_landscape: Result | undefined;
  thumbnail_150x150: Result | undefined;
  image_1920: Result | undefined;
}
export type Attachment =
  | FileAttachment
  | AudioAttachment
  | ImageAttachment
  | VideoAttachment;
export interface FieldAttachmentValue {
  value: Attachment | Attachment[] | null;
  error?: string;
}
export const isFieldAttachmentValue = (
  value: unknown
): value is FieldAttachmentValue => {
  if (typeof value === "string") {
    return false;
  }
  if (!_has(value, ["value"])) {
    return false;
  }
  if ((value as any).value === null) {
    return true;
  }
  try {
    const requiredKeys = ["type", "original"];
    if (
      requiredKeys.every((k) => k in (value as any).value) ||
      requiredKeys.every((k) => k in (value as any).value[0])
    ) {
      return true;
    }
  } catch (error) {}
  return false;
};

export interface FieldFormulaValue {
  value: string;
  error?: string;
}
export const isFieldFormulaValue = (
  value: unknown
): value is FieldFormulaValue => {
  if (typeof value === "string") {
    return false;
  }
  if (Array.isArray(value)) {
    return false;
  }
  if (typeof value === "object" && (value as FieldFormulaValue).value) {
    return true;
  }
  return false;
};
export interface FieldDateValue {
  dtValue: string;
  googleCalendarEventId: string | null;
}
export const isFieldDateValue = (value: unknown): value is FieldDateValue => {
  if (typeof value === "string") {
    return false;
  }
  if (Array.isArray(value)) {
    return false;
  }
  if (typeof value === "object" && (value as FieldDateValue).dtValue) {
    return true;
  }
  return false;
};

export interface FieldMultiSelectValue {
  value: string | string[] | null;
  error?: string;
}
export const isFieldMultiSelectValue = (
  value: unknown
): value is FieldMultiSelectValue => {
  if (typeof value === "string") {
    return true;
  }
  if (!_has(value, ["value"])) {
    return false;
  }
  if ((value as any).value === null) {
    return true;
  }
  if (!Array.isArray(value)) {
    return false;
  }
  if (value.some((v) => typeof v !== "string")) {
    return false;
  }

  return true;
};

export type FieldValue =
  | string
  | null
  | FieldFormulaValue
  | FieldDateValue
  | FieldAttachmentValue
  | FieldMultiSelectValue;
