import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { NavItem } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { useLocation, useResolvedPath } from 'react-router-dom';

interface CollapseNavItemProps {
  buttonChild: React.ReactNode;
  children?: React.ReactNode;
  to: string;
}

export const CollapseNavItem = ({ buttonChild, children, to }: CollapseNavItemProps) => {
  const location = useLocation();
  const path = useResolvedPath(to);
  const isActive = useMemo(() => {
    const locationPathname = location.pathname;
    const toPathname = path.pathname;
    return (
      locationPathname === toPathname ||
      (locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length - 1) === '/')
    );
  }, [location.pathname, path.pathname]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <NavItem
        className={isActive ? 'nav-link active' : 'nav-link'}
        onClick={() => setOpen(!open)}
        aria-controls="collapse-nav-item"
        aria-expanded={open}
      >
        {buttonChild} <FontAwesomeIcon fixedWidth className="me-2" icon={isActive ? faCaretUp : faCaretDown} />
      </NavItem>
      <Collapse in={open || isActive}>
        <div className="ms-3" id="collapse-nav-item">
          {children}
        </div>
      </Collapse>
    </>
  );
};
