import { RecordAutomationAction, RecordAutomation, APIView, EnrichedDataRecord } from '@taida-corp/taidacorp-sdk';
import { flatten } from 'flat';
import { find as _find, keyBy, map  as _map, mapValues, merge} from 'lodash';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { fetchContentTypes } from '../../../../../../../data/content-type/content-types/actions';
import {
  createAPIViewForAutomationAction,
  updateRecordAutomation,
} from '../../../../../../../data/content-type/record-automations/actions';
import { getIsUpdating } from '../../../../../../../data/content-type/record-automations/selectors';
import { fetchRecordView } from '../../../../../../../data/content-type/record-views/actions';
import { getAllViewsList, getViews } from '../../../../../../../data/content-type/record-views/selectors';
import { getFirstRecordInSelectedView } from '../../../../../../../data/content-type/records/selectors';
import { useAppDispatch } from '../../../../../../../data/store';
import ActionAPIViewEditor from './ActionAPIViewEditor';

interface AutomationActionInputsFormProps {
  automation: RecordAutomation;
  action: RecordAutomationAction;
  onChangeAction: (updatedAction: RecordAutomationAction, index: number) => void;
  index: number;
}
export const AutomationActionInputsForm = ({ action, automation, index }: AutomationActionInputsFormProps) => {
  const dispatch = useAppDispatch();
  const isUpdating = useSelector(getIsUpdating);
  const views = useSelector(getAllViewsList);
  const firstRecord = useSelector(getFirstRecordInSelectedView);
  const apiView = useMemo<APIView|undefined>(() => {
    const viewId = action.apiViewId;
    if(viewId === undefined) {
      return undefined;
    }
    return _find(views, {viewId}) as APIView|undefined;
  }, [action.apiViewId, views])

  const cachedInputData = useMemo(() => {
    console.log('full', automation.actions);
    const actionsUpToIndex = [...automation.actions].splice(0, index);
    console.log('spliced', actionsUpToIndex);
    const actionsObj = keyBy(actionsUpToIndex, 'automationActionId');
    console.log('actionsObj', actionsObj);
    let outputs:Record<string, any> = mapValues(actionsObj, (act) => act.cachedTestOutputs);
    if(firstRecord !== undefined) {
      outputs['record'] = firstRecord.enrichedFields;
    }
    console.log('outputs', outputs);
    return outputs;
  }, [automation.actions, index, firstRecord])


  const hasIntegration = useMemo(() => {
    return action.integrationId !== undefined && action.integrationId !== '';
  }, [action]);
  const hasIntegrationAction = useMemo(() => {
    return action.integrationActionId !== undefined;
  }, [action]);
  const hasAPIView = useMemo(() => {
    return action.apiViewId !== undefined;
  }, [action]);
  useEffect(() => {
    if (!hasAPIView && hasIntegration && hasIntegrationAction && !isUpdating) {
      dispatch(
        createAPIViewForAutomationAction({
          automationActionId: action.automationActionId,
          automationId: action.automationId,
          contentTypeSlug: automation.contentType,
        }),
      );
    }
  }, [
    action.automationActionId,
    action.automationId,
    automation.contentType,
    dispatch,
    hasAPIView,
    hasIntegration,
    hasIntegrationAction,
    isUpdating,
  ]);
  useEffect(() => {
    if(action.apiViewId !== undefined && apiView === undefined) {
      // TODO: actually load in the integrations and integration actions
      // so we can get the content type dynamically
      dispatch(fetchRecordView({contentTypeSlug: 'send-email-inputs', viewId: action.apiViewId!}))
    }
  }, [action.apiViewId, apiView, dispatch])

  if(action.apiViewId === undefined || apiView === undefined) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  return (
    <div>
      <code>
        <pre>
        {JSON.stringify(flatten(cachedInputData), null, 4)}
        </pre>
      </code>
      <ActionAPIViewEditor apiView={apiView} testInputData={cachedInputData} automation={automation} />
    </div>
  );
};

export default AutomationActionInputsForm;
