import { useState, useEffect, useCallback, useMemo } from 'react';
import Transloadit, { Assembly, FileInfo, Result } from '@uppy/transloadit';
import Queue from "queue-promise";

import '@uppy/webcam/dist/style.css';
import '@uppy/audio/dist/style.css';
import '@uppy/screen-capture/dist/style.css';
import Uppy, { UppyFile } from '@uppy/core';
import { ContentType, FieldAttachmentConfig, isString, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import { resourceUsage } from 'process';
import { cloneDeep, find, findIndex, get, keyBy, mapKeys, mapValues } from 'lodash';
import { remove } from '../../utils/array';
import { parseCSVFile } from '../../utils/csvImporter';
import { API } from 'aws-amplify';
import { throttleActions } from '../../utils/throttleActions';

export interface FieldMapping {
  columnIndex: number | null;
  defaultValue: PersistedFieldValue;
  fieldId: string;
}

export const useCSVImporter = (contentType: ContentType) => {
  const [fieldMappings, setFieldMappings] = useState<FieldMapping[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [rowState, setRowState] = useState<('IDLE'|'PENDING'|'POSTING'|'DONE'|'ERROR')[]>([]);
  const [rowErrors, setRowErrors] = useState<(string | null)[]>([]);
  const [, _forceUpdate] = useState<any>();
  const forceUpdate = useCallback(() => {
    _forceUpdate({});
  }, []);


  const columns = useMemo(() => {
    const headersWithOriginalColumnIndex = headers.map((header: string, columnIndex: number) => ({
      header,
      columnIndex,
    }));
    return headersWithOriginalColumnIndex;
  }, [headers]);
  useEffect(() => {
    if (columns.length > 0) {
      const newFieldMappings: FieldMapping[] = contentType.fields.map((field) => ({
        fieldId: field.fieldId,
        defaultValue: { internalValue: null },
        columnIndex: null,
      }));
      setFieldMappings(newFieldMappings);
    }
  }, [columns, contentType.fields]);
  const updateFieldMapping = useCallback(
    (columnIndex: number | null, defaultValue: PersistedFieldValue, fieldId: string) => {
      setFieldMappings((curr) => {
        const updated = cloneDeep(curr);
        const idx = findIndex(curr, { fieldId });
        if (idx === -1) {
          return curr;
        }
        updated[idx] = { columnIndex, defaultValue, fieldId };
        return updated;
      });
    },
    [],
  );

  const [isParsing, setIsParsing] = useState(false);
  const [isDoneParsing, setIsDoneParsing] = useState(false);

  const [isConfiguringHeaders, setIsConfiguringHeaders] = useState(false);
  const [isDoneConfiguringHeaders, setIsDoneConfiguringHeaders] = useState(false);

  const isUploading = useMemo(() => {
    if (rowState.length === 0) {
      return false;
    }
    return rowState.some((val) => val === 'POSTING');
  }, [rowState]);

  const [isFileAdded, setIsFileAdded] = useState(false);
  const [file, setFile] = useState<UppyFile | undefined>(undefined);

  const onStartedParsing = useCallback(() => {
    setHeaders([]);
    setDataRows([]);
    setIsParsing(true);
    setIsDoneParsing(false);
    setIsConfiguringHeaders(false);
    setIsDoneConfiguringHeaders(false);
    setRowState([]);
  }, []);
  const onDoneParsing = useCallback(() => {
    setIsParsing(false);
    setIsDoneParsing(true);
  }, []);

  const onFileAdded = useCallback(
    (addedFile: UppyFile) => {
      console.log(addedFile.data);
      setIsFileAdded(true);
      setFile(addedFile);
      parseCSVFile(addedFile, onStartedParsing, setHeaders, onDoneParsing).then((rows) => {
        const newDataRows = rows;
        setRowErrors(newDataRows.map(() => null));
        setRowState(newDataRows.map(() => 'IDLE'));
        setDataRows(newDataRows);
      });
    },
    [onDoneParsing, onStartedParsing],
  );

  const onError = useCallback((error: any) => {}, []);

  const [uppy, setUppy] = useState<Uppy | null>(null);

  const reset = useCallback(() => {
    if (uppy !== null) {
      uppy.reset();
      setHeaders([]);
      setDataRows([]);
      setIsParsing(false);
      setIsDoneParsing(false);
      setIsConfiguringHeaders(false);
      setIsDoneConfiguringHeaders(false);
      setRowState([]);
      setRowErrors([]);
      setFile(undefined);
      setIsFileAdded(false);
      setFieldMappings([]);
    }
  }, [uppy]);

  useEffect(() => {
    const restrictions = {
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: ['text/csv'],
    };
    const _uppy = new Uppy({
      restrictions,
      onBeforeFileAdded: (currentFile: UppyFile, files) => {
        return true;
      },
    });

    _uppy.on('error', onError);
    _uppy.on('file-added', onFileAdded);
    // .use(XHRUpload, { endpoint: '/api/songs/upload' })
    // .use(Webcam, { modes: ['audio-only'] })
    setUppy(_uppy);
  }, [isFileAdded, onError, onFileAdded]);

  const startRunning = useCallback(() => {
    setRowState(dataRows.map(() => 'PENDING'));
    const tasks = dataRows.map((row, rowIdx) => {
      const data: Record<string, PersistedFieldValue> = mapValues(keyBy(fieldMappings, 'fieldId'), (fieldMapping) => {
        let value: PersistedFieldValue = fieldMapping.defaultValue ?? { internalValue: null };
        if (fieldMapping.columnIndex !== null) {
          // do something better here for select and multi select
          const rowValue = get(row, headers[fieldMapping.columnIndex]);
          if (isString(rowValue) && rowValue !== '') {
            value = { internalValue: rowValue };
          }
        }
        return value;
      });
        return async () => {
          console.log(`SETTING ROW IS UPLOADING ${rowIdx}`);
          setRowState(curr => {
            curr[rowIdx] = 'POSTING';
            return curr;
          })
          forceUpdate();
          try {
            const response = await API.post(
              'Content', // function defined in our serverless.yml
              `${contentType.slug}/records?enforcePrimaryFieldValue=true`, // the function's path
              { responseType: 'json', body: {fields: data} },
            );
            setRowState(curr => {
              curr[rowIdx] = 'DONE';
              return curr;
            })
            forceUpdate();
            return response;
          } catch (error) {
            setRowState(curr => {
              curr[rowIdx] = 'ERROR';
              return curr;
            })
            forceUpdate();
            return;
          }
      };
    });
    const queue = new Queue({concurrent: 5, interval: 100, start: true});
    queue.enqueue(tasks);

  }, [contentType.slug, dataRows, fieldMappings, forceUpdate, headers]);

  return {
    uppy,
    isFileAdded,
    file,
    headers,
    dataRows,
    isParsing,
    isDoneParsing,
    isConfiguringHeaders,
    isDoneConfiguringHeaders,
    updateFieldMapping,

    columns,
    fields: contentType.fields,
    fieldMappings,

    isUploading,
    rowState,
    rowErrors,

    reset,
    startRunning,
  };
};

export default useCSVImporter;
