import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { sendMarketingTemplatePreview, updateMarketingTemplate } from '../../../../data/marketing-services/marketing-templates/actions';
import {
  getMarketingTemplatesIsSaving,
  getSelectedMarketingTemplate,
} from '../../../../data/marketing-services/marketing-templates/selectors';
import { useAppDispatch } from '../../../../data/store';
import { MarketingTemplate } from '../../../../data/types';
import './MarketingTemplateEditorModal.scss';

export const MarketingTemplateEditorModal = ({
  marketingTemplate,
  isVisible,
  back,
}: {
  marketingTemplate: MarketingTemplate;
  isVisible: boolean;
  back: any;
}) => {
  const dispatch = useAppDispatch();
  const isSaving = useSelector(getMarketingTemplatesIsSaving);
  const [marketingTemplateForm, setMarketingTemplateForm] = useState<MarketingTemplate>(marketingTemplate);
  const onUpdateField = useCallback((key, value) => {
    setMarketingTemplateForm((curr) => ({ ...curr, [key]: value }));
  }, []);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(updateMarketingTemplate(marketingTemplateForm));
    },
    [dispatch, marketingTemplateForm],
  );

  const preview1 = useCallback(
    (e) => {
      dispatch(sendMarketingTemplatePreview({marketingTemplate: marketingTemplateForm, numberOfListings: 1}));
    },
    [dispatch, marketingTemplateForm],
  );
  const preview10 = useCallback(
    (e) => {
      dispatch(sendMarketingTemplatePreview({marketingTemplate: marketingTemplateForm, numberOfListings: 10}));
    },
    [dispatch, marketingTemplateForm],
  );

  useEffect(() => {
    setMarketingTemplateForm(marketingTemplate);
  }, [marketingTemplate]);

  return (
    <Modal fullscreen="lg-down" show={isVisible} onHide={back} keyboard={false}>
      <Modal.Header className="d-lg-none" closeButton>
        <Modal.Title>Marketing Template Form</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="row m-0 border-bottom">
          <div className="py-3 col-12">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div>{marketingTemplate.name}</div>
              <div>
                {isSaving && (
                  <div>
                    Saving... <FontAwesomeIcon icon={faCog} title={'Saving Marketing Template'} className={'fa-spin'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row px-0 m-0 py-3" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
            <div className="col-12 px-0">
              <form id="marketing-template-form" onSubmit={onSubmit}>
                <div className="mb-3">
                  <label htmlFor="templateName" className="form-label">
                    Template Name
                  </label>
                  <input
                    onChange={(e) => onUpdateField('name', e.target.value ?? '')}
                    value={marketingTemplateForm.name}
                    type="text"
                    className="form-control"
                    id="templateName"
                    aria-describedby="nameHelp"
                  />
                  <div id="nameHelp" className="form-text">
                    Name the template.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">
                    Subject
                  </label>
                  <input
                    onChange={(e) => onUpdateField('subject', e.target.value ?? '')}
                    value={marketingTemplateForm.subject ?? ''}
                    type="text"
                    className="form-control"
                    id="subject"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="plaintextBody" className="form-label">
                    Plaintext Body
                  </label>
                  <textarea
                    onChange={(e) => onUpdateField('plaintextBody', e.target.value ?? '')}
                    value={marketingTemplateForm.plaintextBody ?? ''}
                    className="form-control"
                    id="plaintextBody"
                    rows={10}
                    aria-describedby="plaintextBodyHelp"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="plaintextBody" className="form-label">
                    HTML Body
                  </label>
                  <textarea
                    onChange={(e) => onUpdateField('htmlBody', e.target.value ?? '')}
                    value={marketingTemplateForm.htmlBody ?? ''}
                    className="form-control"
                    id="htmlBody"
                    rows={20}
                    aria-describedby="htmlBodyHelp"
                  />
                  <div id="htmlBodyHelp" className="form-text">
                    Subject, plaintext body, and HTML body can use variables{' '}
                    <a href="/" target="_blank">
                      Docs
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex flex-row justify-content-between">
          <div className="d-flex flex-row justify-content-start">
            <button type="button" className="btn btn-secondary me-1" onClick={preview1}>
              Preview 1
            </button>
            <button type="button" className="btn btn-secondary" onClick={preview10}>
              Preview 10
            </button>
          </div>
          <button type="submit" className="btn btn-primary" form="marketing-template-form">
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MarketingTemplateEditorModal;
