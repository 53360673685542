import { debounce as _debounce } from 'lodash';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useToolbarPopover from '../useToolbarPopover/useToolbarPopover';
import { useAppDispatch } from '../../../../data/store';
import { DealView, KanbanView } from '../../../../data/types';
import { deleteDealView, updateDealView } from '../../../../data/views/actions';
import { getSelectedView } from '../../../../data/views/selectors';
import { useNavigate } from 'react-router-dom';

const ViewNamePopover = ({ view }: {view:DealView}) => {
  const navigate = useNavigate();
  const [internalName, setInternalName] = useState(view.name ?? '');
  const [internalDescription, setInternalDescription] = useState(view.description ?? '');
  const dispatch = useAppDispatch();
  const debouncedUpdateDealRecord = useMemo(() => {
    return _debounce((updatedDealView: DealView) => dispatch(updateDealView(updatedDealView)), 1000);
  }, [dispatch]);

  const handleNameChange = useCallback((e) => {
    if(view.name === 'Index') {
      alert('Cannot rename Index');
      return;
    }
    const newName = e.target.value ?? '';
    setInternalName(newName);
    debouncedUpdateDealRecord({
      ...view,
      name: newName
    })
  }, [debouncedUpdateDealRecord, view]);
  const handleDescriptionChange = useCallback((e) => {
    const newDescription = e.target.value ?? '';
    setInternalDescription(newDescription);
    debouncedUpdateDealRecord({
      ...view,
      description: newDescription
    })
  }, [debouncedUpdateDealRecord, view]);

  const handleDeleteClicked = useCallback(() => {
    if(view.name === 'Index') {
      alert('Cannot Delete Index');
    }
    // eslint-disable-next-line no-restricted-globals
    const doDelete = confirm('Are you sure?');
    if(doDelete) {
      dispatch(deleteDealView(view.viewId));
      navigate('/deals');
    }
  }, [dispatch, navigate, view.name, view.viewId]);

  return (
    <div className="deals-toolbar__popover d-grid gap-1">
      <input
        name="view-name"
        className="form-control"
        type="text"
        value={internalName}
        onChange={handleNameChange}
      />
      <textarea
        placeholder="Describe this view"
        className="form-control"
        value={internalDescription}
        onChange={handleDescriptionChange}
      />
      <div>
        <button onClick={handleDeleteClicked} className='btn btn-link text-danger'>Delete</button>
      </div>
    </div>
  );
};

export const ViewName = (props: any) => {
  const { buttonProps, ToolbarPopover, popoverProps } = useToolbarPopover({});
  const view = useSelector(getSelectedView);

  if (view === undefined) {
    return null;
  }
  return (
    <>
      <div tabIndex={0} className="deals-toolbar__item" {...buttonProps}>
        <div className="d-flex flex-row align-items-center">
          <FontAwesomeIcon className="me-2" icon={faPencilAlt} />
          {view.name}
        </div>
      </div>
      <ToolbarPopover {...popoverProps}>
        <ViewNamePopover view={view} />
      </ToolbarPopover>
    </>
  );
};

export default ViewName;
