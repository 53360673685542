import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import Amplify, { API, Auth, Hub } from 'aws-amplify';
import { User } from '../types';

export const setUser = createAction<User|undefined>('user/set');

export const fetchMe = createAsyncThunk<User|undefined, undefined, { rejectValue: string[] }>(
    'user/fetchMe',
    async () => {
      try {
        const userData = await Auth.currentAuthenticatedUser();
        return {email: userData.attributes.email, name: userData.attributes.name, userId: userData.username, username: userData.username};
    } catch (error) {
        console.log(error);
      }
    },
  );
  