import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ContentType, Field, FieldConfig, FieldType } from '@taida-corp/taidacorp-sdk';
import { API } from 'aws-amplify';
import { fetchCompany } from '../../company/companyActions';
import { fetchViews } from '../../views/actions';

export const createField = createAsyncThunk<
  ContentType,
  { contentTypeSlug:string; name?: string; type?: FieldType; config?: FieldConfig; viewId?: string },
  { rejectValue: string[] }
>('contentTypes/create-field', async ({ contentTypeSlug, viewId, ...field }, thunkApi) => {
  const fieldData = await API.post(
    'Content', // function defined in our serverless.yml
    `${contentTypeSlug}/fields`, // the function's path
    {
      responseType: 'json',
      queryStringParameters: { viewId },
      body: { field },
    },
  );
  thunkApi.dispatch(fetchViews());
  thunkApi.dispatch(fetchCompany());
  return fieldData;
});

export const updateField = createAsyncThunk<ContentType, {field: Field, contentTypeSlug:string}, { rejectValue: string[] }>(
  'contentTypes/update-field',
  async ({field, contentTypeSlug}, thunkApi) => {
    const fieldData = await API.post(
      'Content', // function defined in our serverless.yml
      `${contentTypeSlug}/fields/${field.fieldId}`, // the function's path
      { responseType: 'json', body: { field } },
    );
    return fieldData;
  },
);

export const deleteField = createAsyncThunk<ContentType, {contentTypeSlug:string; fieldId:string}, { rejectValue: string[] }>(
  'contentTypes/delete-field',
  async ({fieldId, contentTypeSlug}, thunkApi) => {
    const fieldData = await API.del(
      'Content', // function defined in our serverless.yml
      `${contentTypeSlug}/fields/${fieldId}`, // the function's path
      { responseType: 'json', body: {} },
    );
    return fieldData;
  },
);
