import { find as _find } from 'lodash';
import { FormulaDependency } from '../data/types';

const FORMULA_PLACEHOLDER_REGEX = /{{(?<identifier>[^{}]+)}}|(?<text>((?!{{).)|(?<br>\n)+)/gm;

export const recurseOverElementsToGetTextValues = (
  element: HTMLDivElement | ChildNode | DocumentFragment,
  currentTextValues: string[],
  currentDeps: FormulaDependency[],
) => {
  let textValues = [...currentTextValues];
  let deps = [...currentDeps];
  element.childNodes.forEach((childNode, idx: number) => {
    if (childNode.nodeType === Node.TEXT_NODE) {
      textValues.push(childNode.textContent!);
    } else if (childNode.nodeType === Node.ELEMENT_NODE && childNode.nodeName === 'SPAN') {
      const identifier = (childNode as Element).getAttribute('data-identifier');
      const text = (childNode as Element).textContent;
      if (!!identifier) {
        textValues.push(`{{${identifier}}}`);
        deps.push({ identifier, label: text! });
      }
    } else if (childNode.nodeType === Node.ELEMENT_NODE && childNode.nodeName === 'BR') {
      // textValues.push(`\n`);
    } else if (childNode.nodeType === Node.ELEMENT_NODE && childNode.nodeName === 'DIV') {
      if (idx !== 0) {
        textValues.push(`\n`);
      }
      const output = recurseOverElementsToGetTextValues(childNode, textValues, deps);
      textValues = output.textValues;
      deps = output.templateDependencies;
    }
  });
  return {
    textValues,
    templateDependencies: deps,
  };
};

export const convertHTMLNodesToTemplate = (
  element: HTMLDivElement | DocumentFragment,
): { templateSource: string; templateDependencies: FormulaDependency[] } => {
  const { textValues, templateDependencies } = recurseOverElementsToGetTextValues(element, [], []);
  let templateSource = textValues.join('').replace(/([\u200b]|[\u200C]|[\u200D]|[\uFEFF])/gmu, '');
  return { templateSource, templateDependencies };
};

export const clearHTMLNode = (element: HTMLElement) => {
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
};

export const convertTemplateToHTMLNodes = (
  formulaIn: string,
  element: HTMLElement,
  templateOptions:FormulaDependency[],
) => {
  clearHTMLNode(element);
  const matches = formulaIn.matchAll(FORMULA_PLACEHOLDER_REGEX);
  const matchesArray = Array.from(matches, (m) => m.groups).filter(Boolean);

  for (const match of matchesArray) {
    const { text, identifier, br } = match!;
    if (text !== undefined && !text.includes('\n')) {
      const newTextNode = document.createTextNode(text);
      element.append(newTextNode);
    } 
     if (br !== undefined) {
      const newLineElement = document.createElement('div');
      element.append(newLineElement);
    }
      if (identifier !== undefined) {
      const templateOption:FormulaDependency|undefined = _find<FormulaDependency[]>(templateOptions, {identifier}) as FormulaDependency|undefined;
      let label = 'MISSING FIELD';
      if(templateOption !== undefined) {
        label = templateOption.label;
      }
      
      const newTagElement = document.createElement('span');
      newTagElement.contentEditable = 'false';
      if (label !== 'MISSING FIELD') {
        newTagElement.className = 'badge rounded-pill bg-secondary';
        newTagElement.textContent = label;
        newTagElement.setAttribute('data-identifier', identifier);
      } else {
        newTagElement.className = 'badge rounded-pill bg-danger';
        newTagElement.textContent = label;
        newTagElement.setAttribute('data-identifier', identifier);
      }
      element.append(newTagElement);
    }
  }
};

const templateEditor = {
  convertTemplateToHTMLNodes,
  convertHTMLNodesToTemplate,
};
export default templateEditor;
