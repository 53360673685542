import { useCallback, useMemo } from 'react';
import { DocumentTemplate, NewDocumentForm } from '../../../../../data/types';

interface Step2Props {
  form: NewDocumentForm;
  onChange: (formUpdates: Partial<NewDocumentForm>) => void;
  next: () => void;
  back: () => void;
  documentTemplates: DocumentTemplate[];
  selectedDocumentTemplate: DocumentTemplate;
}
export const Step2 = ({ form, onChange, next, documentTemplates, selectedDocumentTemplate, back }: Step2Props) => {
  const isNextEnabled = useMemo(() => {
    return true;
  }, []);
  const isBackEnabled = useMemo(() => {
    return true;
  }, []);

  return (
    <div className="h-100 d-flex flex-column p-3">
      <div>
        <h3>Template Fields</h3>
        <small>These get filled in by recipients based on their role. Verify the expected inputs are present.</small>
      </div>
      <div className="flex-grow-1">
        <table className="table table-responsive">
          <thead>
            <tr>
              <td>Role</td>
              <td>Field</td>
              <td>Type</td>
            </tr>
          </thead>
          <tbody>
            {selectedDocumentTemplate.fields.map((field: any) => (
              <tr key={field.uuid}>
                <td>{field.assigned_to.name}</td>
                <td>{field.name}</td>
                <td>{field.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex-shrink-1 d-flex flex-row justify-content-between">
        <button className="btn btn-primary" disabled={!isBackEnabled} onClick={back}>
          Back
        </button>

        <button className="btn btn-primary" disabled={!isNextEnabled} onClick={next}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Step2;
