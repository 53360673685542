import flatten from 'flat';
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { API_VIEW_FIELD_HELPTEXT } from '../../../components/Fields';
import TemplateEditor from '../../../components/TemplateEditor/TemplateEditor';
import APITemplateField from '../../../components/Views/APIView/APITemplateField';
import { useAppDispatch } from '../../../data/store';
import { APIView, Field, FieldType, FormulaDependency, TemplateEditorOption } from '../../../data/types';
import { getUsers } from '../../../data/users/selectors';
import { updateDealView } from '../../../data/views/actions';

interface DealsAPIViewProps {
  view: APIView;
  fields: Field[];
}

export const DealsAPIView = (props: DealsAPIViewProps) => {
  const fields = props.fields;
  const view = props.view;
  const dispatch = useAppDispatch();
  const [internalView, setInternalView] = useState<APIView>(view);
  const { inputJson = '' } = internalView.properties;
  const users = useSelector(getUsers);
  const [jsonInput, setJSONInput] = useState<string>(inputJson);
  const [templateOptionsFromInput, setTemplateOptionsFromInput] = useState<TemplateEditorOption[]>([]);
  const handleJSONInputChange = useCallback((e: any) => {
    const newText = e.target.value ?? '';
    setJSONInput(newText);
  }, []);
  const parseJson = useCallback(() => {
    try {
      const jsonObj = JSON.parse(jsonInput);
      setJSONInput(JSON.stringify(jsonObj, null, 2));
      const options = Object.keys(flatten(jsonObj)).map(
        (dataKey): FormulaDependency => ({
          identifier: `data.${dataKey}`,
          label: dataKey,
        }),
      );
      setTemplateOptionsFromInput([{ label: 'Input', options }]);
    } catch (error: any) {
      alert(error.message);
    }
  }, [jsonInput]);
  const saveJson = useCallback(() => {
    dispatch(
      updateDealView({
        ...internalView,
        properties: {
          ...internalView.properties,
          inputJson: jsonInput,
        },
      }),
    );
  }, [dispatch, jsonInput, internalView]);
  const saveView = useCallback(() => {
    dispatch(
      updateDealView(internalView),
    );
  }, [dispatch, internalView]);
  useEffect(() => {
    try {
      if (inputJson !== '') {
        const jsonObj = JSON.parse(inputJson);
        setJSONInput(JSON.stringify(jsonObj, null, 2));
        const options = Object.keys(flatten(jsonObj)).map(
          (dataKey): FormulaDependency => ({
            identifier: `data.${dataKey}`,
            label: dataKey,
          }),
        );
        setTemplateOptionsFromInput([{ label: 'Input', options }]);
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [inputJson]);
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <h3>API View</h3>
          <p>
            API views expose an endpoint that data can be posted to for creating deals.
            <br />
            You can use this view to configure the endpoint and how the data is used to create a deal.
          </p>
          <ul>
            <li>Paste json below and map the json keys to deal fields</li>
            <li>Expose the endpoint with or without authorization checks</li>
            <li>Use the provided api url in automation tools like Zapier</li>
            <li>Provide default values for missing fields</li>
            <li>Mark fields as required or optional</li>
            <li>Add other validation rules like min and max number values, character length, data type, etc</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Paste JSON:
          </label>
          <textarea
            id="api-view-json-input"
            className="form-control mb-3"
            rows={10}
            value={jsonInput}
            onChange={handleJSONInputChange}
          />
          <button onClick={parseJson} type="button" className="btn btn-outline-primary">
            Format JSON
          </button>
          <button onClick={saveJson} type="button" className="btn btn-primary ms-2">
            Save JSON
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 mt-2">
          {fields.map((field) => (
            <APITemplateField
              setInternalView={setInternalView}
              view={internalView}
              key={field.fieldId}
              field={field}
              defaultTemplateOptions={templateOptionsFromInput}
              users={users}
            />
          ))}
          <button onClick={saveView} type="button" className="btn btn-primary">
            Save API View
          </button>
        </div>
      </div>
    </div>
  );
};

export default DealsAPIView;
