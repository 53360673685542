import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';

export interface DropDownOption {
  value: string;
  label: string;
}

export type DropDownGroupOptions = {
  label: string;
  options: DropDownOption[];
};

const CustomToggle = React.forwardRef(({ children, disabled = false, onClick }: any, ref: any) => (
  <button
    disabled={disabled}
    className="btn btn-secondary"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon={faList} size="1x" color="white" />
  </button>
));

interface InputWithDropdownStringsProps {
  onChange: (val: string) => void;
  value: string;
  dropdownOptions: DropDownGroupOptions[];
  isDisabled?: boolean;
}

export const InputWithDropdownStrings = ({
  onChange,
  value,
  dropdownOptions,
  isDisabled,
}: InputWithDropdownStringsProps) => {
  const onChangeEvent = useCallback((e) => onChange(e.target.value ?? ''), [onChange]);
  const insertOptionString = useCallback(
    (optionValue: string) => {
      onChange(`${value}${optionValue}`);
    },
    [onChange, value],
  );
  return (
    <div className="input-group">
      <input disabled={isDisabled} className="form-control" onChange={onChangeEvent} value={value} />
      <Dropdown>
        <Dropdown.Toggle disabled={isDisabled} as={CustomToggle} id="dropdown-custom-components" />
        <Dropdown.Menu style={{ maxHeight: 200, overflow: 'scroll' }}>
          {dropdownOptions.map((option, idx: number) => {
            return (
              <React.Fragment key={option.label}>
                <Dropdown.ItemText>
                  <b>{option.label}</b>
                </Dropdown.ItemText>
                {option.options.map((optionValue) => (
                  <Dropdown.Item key={optionValue.label} onClick={() => insertOptionString(optionValue.value)}>
                    <div className="d-flex flex-row justify-content-between">
                      <small>{optionValue.label}</small>
                      <div>{optionValue.value}</div>
                    </div>
                  </Dropdown.Item>
                ))}
                {idx !== dropdownOptions.length - 1 && <Dropdown.Divider />}
              </React.Fragment>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
