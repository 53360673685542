import React, { useCallback } from 'react';
import { useListItem } from '../../../../hooks/useListItem/useListItem';
import classNames from 'classnames';
import { FieldSelectOption } from '../../../../data/types';

interface CellPopoverOptionItemProps {
  option?: FieldSelectOption;
  onEnter: Function;
}

export const CellPopoverOptionItem = ({ option, onEnter:_onEnter }: CellPopoverOptionItemProps) => {
  const onEnter = useCallback(() => {
    _onEnter(option);
}, [option, _onEnter]);
  const onEscape = useCallback(() => {

}, []);
  const { listItemProps, isSelected, deselect } = useListItem({ onEnter, onEscape });
  const itemClass = classNames('cell-popover__options__item text-truncate', {
    'cell-popover__options__item--selected': isSelected,
  });
  return (
    <div className={itemClass} {...listItemProps}>
      {option !== undefined ? option.label : ''}
    </div>
  );
};

export default CellPopoverOptionItem;
