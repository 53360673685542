import React from 'react';
import { useDrop } from 'react-dnd';
import { FieldSelectOptions } from '@taida-corp/taidacorp-sdk';
import SelectConfigOption from './SelectConfigOption';
import useSelectOptionsList from '../../../../../../hooks/select-field/useSelectOptionsList';

interface SelectConfigOptionsListProps {
  options: FieldSelectOptions;
  onChangeOptions: (newOptions: any) => void;
}
export const SelectConfigOptionsList = (props: SelectConfigOptionsListProps) => {
  const { options, onChangeOptions } = props;
  const { onAddNewOption, onMoveOption, onRemoveOption, onUpdateOption } = useSelectOptionsList({
    options,
    onChangeOptions,
  });
  return (
    <>
      <div className="options-list">
        {options.map((option, index: number) => {
          return (
            <SelectConfigOption
              key={option.value}
              onMoveOption={onMoveOption}
              onRemoveOption={onRemoveOption}
              onUpdateOption={onUpdateOption}
              option={option}
              index={index}
            />
          );
        })}
      </div>
      <button type="button" className="btn btn-link ps-0 mt-2" onClick={onAddNewOption}>
        Add Option
      </button>
    </>
  );
};

export default SelectConfigOptionsList;
