import { DataRecord, EnrichedDataRecord, FormView, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import { filter as _filter, sortBy as _sortBy, find as _find, findIndex as _findIndex } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { getContentTypeFields } from '../../../../data/content-type/content-types/selectors';
import {
  createRecordWithForm,
  setRecordFormData,
  updateDataRecord,
  updateRecordWithForm,
} from '../../../../data/content-type/records/actions';
import { getRecordFormCleanedData, getRecordFormGeneralErrors } from '../../../../data/content-type/records/selectors';
import { useAppDispatch } from '../../../../data/store';
import RecordFormInput from './RecordFormInput';

interface CreateRecordFormViewProps {
  formView: FormView;
}
export const CreateRecordFormView = ({ formView }: CreateRecordFormViewProps) => {
  const dispatch = useAppDispatch();
  const formData = useSelector(getRecordFormCleanedData);
  const generalErrors = useSelector(getRecordFormGeneralErrors);
  const updateField = useCallback(
    (fieldId: string, internalValue: PersistedFieldValue) => {
      dispatch(setRecordFormData({ ...formData, [fieldId]: internalValue }));
    },
    [dispatch, formData],
  );
  const fields = useSelector(getContentTypeFields);
  const formFields = useMemo(() => {
    const _ogFormFields = formView.properties.fields;
    const fieldsInForm = _filter(fields, (field) => {
      return !!_find(_ogFormFields, { fieldId: field.fieldId });
    });
    const orderedFields = _sortBy(fieldsInForm, (field) => {
      return _findIndex(_ogFormFields, { fieldId: field.fieldId });
    });
    return orderedFields;
  }, [fields, formView]);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        createRecordWithForm({ formViewId: formView.viewId, contentTypeSlug: formView.contentType, data: formData }),
      );
    },
    [dispatch, formData, formView.contentType, formView.viewId],
  );
  useEffect(() => {
    dispatch(setRecordFormData({}));
  }, [dispatch, formView]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <form onSubmit={onSubmit} className="pb-5 px-3" style={{ height, width, overflowY: 'scroll' }}>
          {formFields.map((field) => (
            <RecordFormInput
              key={`field-${formView.viewId}-${field.fieldId}`}
              field={field}
              updateField={updateField}
              formData={formData}
            />
          ))}
          {generalErrors && (
            <div className="mb-3">
              {generalErrors.map((error) => (
                <div>
                  <small className="text-danger">{error}</small>
                </div>
              ))}
            </div>
          )}

          <div className="mb-3">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </form>
      )}
    </AutoSizer>
  );
};

export default CreateRecordFormView;
