import { DataRecord, EnrichedDataRecord, FormView, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import { filter as _filter, sortBy as _sortBy, find as _find, findIndex as _findIndex } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';
import { getContentTypeFields } from '../../../../data/content-type/content-types/selectors';
import { getViewsListForContentType } from '../../../../data/content-type/record-views/selectors';
import { setRecordFormData, setRecordFormFieldValue, updateDataRecord, updateRecordWithForm } from '../../../../data/content-type/records/actions';
import { getRecordFormCleanedData, getRecordFormGeneralErrors } from '../../../../data/content-type/records/selectors';
import { useAppDispatch } from '../../../../data/store';
import RecordFormInput from './RecordFormInput';

interface UpdateRecordFormViewProps {
  formId: string;
  record: EnrichedDataRecord;
}
export const UpdateRecordFormView = ({ record, formId }: UpdateRecordFormViewProps) => {
  const dispatch = useAppDispatch();
  const viewsList = useSelector(getViewsListForContentType);
  const formView = useMemo(() => _find(viewsList, {viewId: formId}), [formId, viewsList])
  const formData = useSelector(getRecordFormCleanedData);
  const generalErrors = useSelector(getRecordFormGeneralErrors);
  const updateField = useCallback((fieldId: string, internalValue: PersistedFieldValue) => {
    dispatch(setRecordFormFieldValue({fieldId, value: internalValue}));
  }, [dispatch]);
  const fields = useSelector(getContentTypeFields);
  const formFields = useMemo(() => {
    if(formView === undefined) {
      return []
    }
    const _ogFormFields = formView.properties.fields;
    const fieldsInForm = _filter(fields, (field) => {
      return !!_find(_ogFormFields, { fieldId: field.fieldId });
    });
    const orderedFields = _sortBy(fieldsInForm, (field) => {
      return _findIndex(_ogFormFields, { fieldId: field.fieldId });
    });
    return orderedFields;
  }, [fields, formView]);
  const onSubmit = useCallback((e) => {
    e.preventDefault();
    if(formView === undefined) {
      return;
    }
    const {
      enrichedFields,
      ...rawRecord
    } = record;
    dispatch(updateRecordWithForm({formViewId: formView.viewId, contentTypeSlug: record.contentType, data: formData, recordId: record.id}));
  }, [dispatch, formData, formView, record]);
  

  if(formView === undefined) {
    alert('uuu');
    return <div>Hii</div>
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <form onSubmit={onSubmit} className="pb-5 px-3" style={{ height, width, overflowY: 'scroll' }}>
          {formFields.map((field) => (
            <RecordFormInput
              key={`field-${formView.viewId}-${field.fieldId}`}
              field={field}
              updateField={updateField}
              formData={formData}
            />
          ))}
          {generalErrors && (
            <div className="mb-3">
              {generalErrors.map((error) => (
                <div>
                  <small className="text-danger">{error}</small>
                </div>
              ))}
            </div>
          )}
          <div className='mb-3'>
            <button type='submit' className='btn btn-primary'>Save</button>
          </div>
        </form>
      )}
    </AutoSizer>
  );
};

export default UpdateRecordFormView;
