import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPhoneNumbersForSelectedDeal } from '../../../../data/deals/selectors';

export const ConversationsByNumbers = (props:any) => {

    const phoneNumbers = useSelector(getPhoneNumbersForSelectedDeal);


    // load messages for each phone number

    // display messages for phone numbers

    return (
        <div>{phoneNumbers.map((phone) => (
            <div key={phone.field.fieldId}>
                <Link to={`./${phone.value}`}>{phone.field.name} {phone.value}</Link></div>
        ))}</div>
    )
}

export default ConversationsByNumbers;