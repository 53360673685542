import classNames from "classnames";
import React from "react";

export const ToolbarToggleButton = React.forwardRef(({ children, onClick, extraClassName='', style={} }: any, ref: any) => (
    <button
      className={classNames('btn', extraClassName)}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={style}
    >
      {children}
    </button>
  ));


  export default ToolbarToggleButton;