import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchMarketingTemplates } from '../../../data/marketing-services/marketing-templates/actions';
import {
  getMarketingTemplatesIsInitialized,
  getMarketingTemplatesIsLoading,
  getMarketingTemplatesList,
} from '../../../data/marketing-services/marketing-templates/selectors';
import { useAppDispatch } from '../../../data/store';

export const MarketingTemplatesList = (props: any) => {
    const dispatch = useAppDispatch();
  const isLoading = useSelector(getMarketingTemplatesIsLoading);
  const isInitialized = useSelector(getMarketingTemplatesIsInitialized);
  const _marketingTemplates = useSelector(getMarketingTemplatesList);
  const marketingTemplates = useMemo(() => {
    // filter and search
    return _marketingTemplates;
  }, [_marketingTemplates]);

  useEffect(() => {
    dispatch(fetchMarketingTemplates())
  }, [dispatch]);

  console.log('_marketingTemplates', _marketingTemplates);

  return (
    <div className="container-fluid my-3">
      <h4>Marketing Templates <Link className='btn btn-primary' to={`/marketing/templates/new`}>New</Link></h4>
      <div className="row">
        <div className="col-12">
          <div className="d-none d-flex flex-row justify-content-between">
            <div className="d-flex flex-row">
                <div>
                    <input className='form-control' type='text' placeholder='Search'/>
                </div>
            </div>
            <div className="">
                <Link className='btn btn-primary' to={`/marketing/templates/new`}>New</Link>
            </div>
          </div>
        </div>
      </div>
      {marketingTemplates.map((template) => (
        <div key={template.marketingTemplateId} className="row">
          <Link to={`/marketing/templates/${template.marketingTemplateId}`}>
            <div>{template.name}</div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default MarketingTemplatesList;
