import { keyBy as _keyBy, get as _get } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import { RecordView, TableViewField } from '@taida-corp/taidacorp-sdk';
import {
  deleteRecordView,
  fetchRecordView,
  fetchRecordViews,
  localUpdateColumnWidth,
  localUpdateIsVisible,
  localUpdateView,
  localUpdateViewFields,
  realTimeRecordViewCreated,
  realTimeRecordViewDeleted,
  realTimeRecordViewUpdated,
  updateRecordView,
  setSelectedViewId,
} from './actions';
import { createAPIViewForAutomationAction, updateAPIViewForAutomationAction } from '../record-automations/actions';

interface RecordViewState {
  views: Record<string, Record<string, RecordView>>;
  lastSelectedViewIdForContentType: Record<string, string>;
  selectedViewId: string | undefined;
  isLoading: boolean;
  isCreating: boolean;
  isInitialized: boolean;
  isUpdating: boolean;
  errors: string[];
}

const initialState: RecordViewState = {
  views: {},
  lastSelectedViewIdForContentType: {},
  selectedViewId: undefined,
  isLoading: false,
  isCreating: false,
  isInitialized: false,
  isUpdating: false,
  errors: [],
};

export const recordViewsSlice = createSlice({
  name: 'recordViews',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedViewId, (state, { payload }) => {
      state.selectedViewId = payload.viewId;
      if(payload.viewId !== undefined) {
        state.lastSelectedViewIdForContentType[payload.contentTypeSlug] = payload.viewId;
      }
    });
    builder.addCase(realTimeRecordViewUpdated, (state, { payload }) => {
      const { view } = payload;
      const currentRecordView = current(state).views[view.contentType][view.viewId];
      if (
        currentRecordView === undefined ||
        currentRecordView.updatedDT === undefined ||
        currentRecordView.updatedDT < view.updatedDT
      ) {
        state.views[view.contentType][view.viewId] = view;
      }
    });
    builder.addCase(realTimeRecordViewCreated, (state, { payload }) => {
      const { view } = payload;
      state.views[view.contentType][view.viewId] = view;
    });
    builder.addCase(realTimeRecordViewDeleted, (state, { payload }) => {
      const { view } = payload;
      delete state.views[view.contentType][view.viewId];
    });

    builder.addCase(fetchRecordViews.pending, (state) => {
      state.isLoading = true;
      state.isInitialized = false;
      state.errors = [];
    });
    builder.addCase(fetchRecordViews.fulfilled, (state, { payload, meta }) => {
      const currViews = _get(current(state), ['views', meta.arg.contentTypeSlug], {});
      state.views = { ...state.views, [meta.arg.contentTypeSlug]: { ...currViews, ..._keyBy(payload, 'viewId') } };
      state.isInitialized = true;
      state.isLoading = false;
    });
    builder.addCase(fetchRecordViews.rejected, (state, { payload }) => {
      state.isInitialized = true;
      state.isLoading = false;
      state.errors = payload ?? [];
    });
    builder.addCase(updateRecordView.pending, (state, {meta}) => {
      state.isLoading = true;
      const currViews = _get(current(state), ['views', meta.arg.contentTypeSlug], {});
      const currView = state.views[meta.arg.contentTypeSlug][meta.arg.view.viewId!];
      state.views = { ...state.views, [meta.arg.contentTypeSlug]: { ...currViews, [meta.arg.view.viewId!]: {...currView, ...meta.arg.view} as RecordView } };
    });
    builder.addCase(updateRecordView.fulfilled, (state, { payload, meta }) => {
      const currViews = _get(current(state), ['views', meta.arg.contentTypeSlug], {});
      state.views = { ...state.views, [meta.arg.contentTypeSlug]: { ...currViews, [payload.viewId]: payload } };
      state.isLoading = false;
    });
    builder.addCase(updateRecordView.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });

    builder.addCase(fetchRecordView.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRecordView.fulfilled, (state, { payload, meta }) => {
      const currViews = _get(current(state), ['views', meta.arg.contentTypeSlug], {});
      state.views = { ...state.views, [meta.arg.contentTypeSlug]: { ...currViews, [payload.viewId]: payload } };
      state.isLoading = false;
    });
    builder.addCase(fetchRecordView.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });

    builder.addCase(deleteRecordView.pending, (state) => {
      state.isUpdating = true;
    });
    builder.addCase(deleteRecordView.fulfilled, (state, { payload, meta }) => {
      delete state.views[meta.arg.contentTypeSlug][meta.arg.viewId];
      state.isUpdating = false;
    });
    builder.addCase(deleteRecordView.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isUpdating = false;
    });

    builder.addCase(localUpdateColumnWidth, (state, { payload }) => {
      const { contentTypeSlug, viewId, fieldId, deltaX } = payload;
      const { [viewId]: currentView } = current(state).views[contentTypeSlug];
      if (currentView === undefined || currentView.type !== 'table') {
        return;
      }
      const currentFieldIdx = currentView.properties.fields.findIndex((field) => field.fieldId === fieldId);
      if (currentFieldIdx === -1) {
        return;
      }
      const width = currentView.properties.fields[currentFieldIdx].width ?? 140;
      const updatedField: TableViewField = { ...currentView.properties.fields[currentFieldIdx], width: width - deltaX };
      state.views[contentTypeSlug][viewId].properties.fields[currentFieldIdx] = updatedField;
    });
    builder.addCase(localUpdateIsVisible, (state, { payload }) => {
      const { contentTypeSlug, viewId, fieldId, isVisible } = payload;
      const { [viewId]: currentView } = current(state).views[contentTypeSlug];
      if (currentView === undefined || currentView.type !== 'table') {
        return;
      }
      const currentFieldIdx = currentView.properties.fields.findIndex((field) => field.fieldId === fieldId);
      if (currentFieldIdx === -1) {
        return;
      }
      const updatedField: TableViewField = { ...currentView.properties.fields[currentFieldIdx], isVisible };
      state.views[contentTypeSlug][viewId].properties.fields[currentFieldIdx] = updatedField;
    });
    builder.addCase(localUpdateViewFields, (state, { payload }) => {
      const { contentTypeSlug, viewId, fields } = payload;
      const { [viewId]: currentView } = current(state).views[contentTypeSlug];
      if (currentView === undefined) {
        return;
      }
      state.views[contentTypeSlug][viewId].properties.fields = fields;
    });
    builder.addCase(localUpdateView, (state, { payload }) => {
      state.views[payload.contentTypeSlug][payload.view.viewId] = payload.view;
    });

    // NOTE: this comes from the automation actions
    builder.addCase(createAPIViewForAutomationAction.pending, (state) => {
      // noop
    });
    builder.addCase(createAPIViewForAutomationAction.fulfilled, (state, { payload, meta }) => {
      state.views[payload.apiView.contentType][payload.apiView.viewId] = payload.apiView;
    });
    builder.addCase(createAPIViewForAutomationAction.rejected, (state, { payload }) => {
      // noop
    });

    builder.addCase(updateAPIViewForAutomationAction.pending, (state) => {
      // noop
    });
    builder.addCase(updateAPIViewForAutomationAction.fulfilled, (state, { payload, meta }) => {
      state.views[payload.apiView.contentType][payload.apiView.viewId] = payload.apiView;
    });
    builder.addCase(updateAPIViewForAutomationAction.rejected, (state, { payload }) => {
      // noop
    });

  },
});

export default recordViewsSlice;
