import React, { useCallback, useMemo } from 'react';
import { DetailFieldProps } from '..';
import { isFieldFormulaValue } from '../../../data/types';


export const NumberDetailField = ({field, internalValue, onChange}: DetailFieldProps) => {
  const onChangeHandle = useCallback((e) => {
    onChange(e.target.value ?? '');
  }, [onChange])
  return (
      <input
      type="number"
      className="form-control"
      id={`field-${field.fieldId}`}
      value={internalValue}
      onChange={onChangeHandle}
    />
  )
}

export default NumberDetailField;