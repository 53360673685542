import { keyBy as _keyBy, get as _get } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import {
  deleteRecordAutomation,
  fetchRecordAutomation,
  fetchRecordAutomations,
  realTimeRecordAutomationCreated,
  realTimeRecordAutomationDeleted,
  realTimeRecordAutomationUpdated,
  updateRecordAutomation,
  setSelectedAutomationId,
  createAPIViewForAutomationAction,
  updateAPIViewForAutomationAction
} from './actions';
import { RecordAutomation } from '@taida-corp/taidacorp-sdk';


interface RecordAutomationsState {
  automations: Record<string, Record<string, RecordAutomation>>;
  selectedAutomationId: string | undefined;
  isLoading: boolean;
  isCreating: boolean;
  isInitialized: boolean;
  isUpdating: boolean;
  errors: string[];
}

const initialState: RecordAutomationsState = {
  automations: {},
  selectedAutomationId: undefined,
  isLoading: false,
  isCreating: false,
  isInitialized: false,
  isUpdating: false,
  errors: [],
};

export const recordAutomationsSlice = createSlice({
  name: 'recordAutomations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedAutomationId, (state, { payload }) => {
      state.selectedAutomationId = payload.automationId;
    });
    builder.addCase(realTimeRecordAutomationUpdated, (state, { payload }) => {
      const { automation } = payload;
      const currentRecordAutomation = state.automations[automation.contentType][automation.automationId];
      if (
        currentRecordAutomation === undefined ||
        currentRecordAutomation.updatedDT === undefined ||
        currentRecordAutomation.updatedDT < automation.updatedDT
      ) {
        state.automations[automation.contentType][automation.automationId] = automation;
      }
    });
    builder.addCase(realTimeRecordAutomationCreated, (state, { payload }) => {
      const { automation } = payload;
      state.automations[automation.contentType][automation.automationId] = automation;
    });
    builder.addCase(realTimeRecordAutomationDeleted, (state, { payload }) => {
      const { automation } = payload;
      delete state.automations[automation.contentType][automation.automationId];
    });

    builder.addCase(fetchRecordAutomations.pending, (state) => {
      state.isLoading = true;
      state.isInitialized = false;
      state.errors = [];
    });
    builder.addCase(fetchRecordAutomations.fulfilled, (state, { payload, meta }) => {
      const currAutomations = _get(state, ['automations', meta.arg.contentTypeSlug], {});
      state.automations = { ...state.automations, [meta.arg.contentTypeSlug]: { ...currAutomations, ..._keyBy(payload, 'automationId') } };
      state.isInitialized = true;
      state.isLoading = false;
    });
    builder.addCase(fetchRecordAutomations.rejected, (state, { payload }) => {
      state.isInitialized = true;
      state.isLoading = false;
      state.errors = payload ?? [];
    });
    builder.addCase(updateRecordAutomation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateRecordAutomation.fulfilled, (state, { payload, meta }) => {
      const currAutomations = _get(state, ['automations', meta.arg.contentTypeSlug], {});
      state.automations = { ...state.automations, [meta.arg.contentTypeSlug]: { ...currAutomations, [payload.automationId]: payload } };
      state.isLoading = false;
    });
    builder.addCase(updateRecordAutomation.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });

    builder.addCase(fetchRecordAutomation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRecordAutomation.fulfilled, (state, { payload, meta }) => {
      const currAutomations = _get(state, ['automations', meta.arg.contentTypeSlug], {});
      state.automations = { ...state.automations, [meta.arg.contentTypeSlug]: { ...currAutomations, [payload.automationId]: payload } };
      state.isLoading = false;
    });
    builder.addCase(fetchRecordAutomation.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });

    builder.addCase(createAPIViewForAutomationAction.pending, (state) => {
      state.isUpdating = true;
    });
    builder.addCase(createAPIViewForAutomationAction.fulfilled, (state, { payload, meta }) => {
      const currAutomations = _get(state, ['automations', meta.arg.contentTypeSlug], {});
      state.automations = { ...state.automations, [meta.arg.contentTypeSlug]: { ...currAutomations, [payload.automation.automationId]: payload.automation } };
      state.isUpdating = false;
    });
    builder.addCase(createAPIViewForAutomationAction.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isUpdating = false;
    });

    builder.addCase(updateAPIViewForAutomationAction.pending, (state) => {
      state.isUpdating = true;
    });
    builder.addCase(updateAPIViewForAutomationAction.fulfilled, (state, { payload, meta }) => {
      const currAutomations = _get(state, ['automations', meta.arg.contentTypeSlug], {});
      state.automations = { ...state.automations, [meta.arg.contentTypeSlug]: { ...currAutomations, [payload.automation.automationId]: payload.automation } };
      state.isUpdating = false;
    });
    builder.addCase(updateAPIViewForAutomationAction.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isUpdating = false;
    });

    builder.addCase(deleteRecordAutomation.pending, (state) => {
      state.isUpdating = true;
    });
    builder.addCase(deleteRecordAutomation.fulfilled, (state, { payload, meta }) => {
      delete state.automations[meta.arg.contentTypeSlug][meta.arg.automationId];
      state.isUpdating = false;
    });
    builder.addCase(deleteRecordAutomation.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isUpdating = false;
    });
  },
});

export default recordAutomationsSlice;
