import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { createFormattedNotification } from '../../utils/notifications';
import { addNotification } from '../notifications/actions';
import { Automation, AutomationLog, DealRecord } from '../types';



export const fetchAutomations = createAsyncThunk<Automation[], undefined, { rejectValue: string[] }>(
  'automations/fetchAutomations',
  async () => {
      try {
        const automationsData = await API.get(
          'Automations', // function defined in our serverless.yml
          '',            // the function's path
          { 'responseType': 'json' }
        );
        return automationsData;
    } catch (error) {
        console.log(error);
        return {channels: [], users: []};
      }
    },
  );
  
  export const createAutomation = createAsyncThunk<Automation, Partial<Automation>, { rejectValue: string[] }>(
    'automations/createAutomation',
    async (automation, thunkApi) => {
      try {
        const createdAutomation = await API.post(
          'Automations', // function defined in our serverless.yml
          '',            // the function's path
          { responseType: 'json', body: { automation } },
          );
          thunkApi.dispatch(addNotification(createFormattedNotification('Created automation', '')))
        return createdAutomation;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  export const updateAutomation = createAsyncThunk<Automation, Automation, { rejectValue: string[] }>(
    'automations/updateAutomation',
    async (automation, thunkApi) => {
      try {
        const updatedAutomation = await API.post(
          'Automations', // function defined in our serverless.yml
          automation.automationId,            // the function's path
          { responseType: 'json', body: { automation } },
          );
          // thunkApi.dispatch(addNotification(createFormattedNotification('Updated automation', '')))
        return updatedAutomation;
    } catch (error) {
        console.log(error);
      }
    },
  );

  export const deleteAutomation = createAsyncThunk<string, Automation, { rejectValue: string[] }>(
    'automations/deleteAutomation',
    async (automation, thunkApi) => {
      try {
        const remainingAutomations = await API.del(
          'Automations', // function defined in our serverless.yml
          automation.automationId,            // the function's path
          { responseType: 'json' },
          );
          thunkApi.dispatch(addNotification(createFormattedNotification('Deleted automation', '')))
        return automation.automationId;
    } catch (error) {
        console.log(error);
        throw new Error('Did not delete automation')
      }
    },
  );
  
  export const testAutomation = createAsyncThunk<AutomationLog, {automation:Automation; deal:DealRecord}, { rejectValue: string[] }>(
    'automations/testAutomation',
    async (testInfo, thunkApi) => {
      try {
        const testResult = await API.post(
          'Automations', // function defined in our serverless.yml
          `${testInfo.automation.automationId}/test`,            // the function's path
          { responseType: 'json', body: { automation: testInfo.automation, deal: testInfo.deal } },
          );
          if(testResult.result.error !== undefined) {
            thunkApi.dispatch(addNotification(createFormattedNotification('Tested Automation', testResult.result.error)))
          } else {
            thunkApi.dispatch(addNotification(createFormattedNotification('Tested Automation', testResult.result.status, 'primary')))
          }
        return testResult;
    } catch (error) {
      console.log('here is')
        console.log(error);
      }
    },
  );
  