import { Middleware, MiddlewareAPI, Action } from 'redux';
import { connect, disconnect, send, onError, WEBSOCKET_ACTION_PREFIX } from './actions';

import ReduxWebSocket from './ReduxWebSocket';

interface ReduxWebSocketOptions {

}
const defaultOptions = {
  reconnectInterval: 2000,
  reconnectOnClose: false,
  reconnectOnError: true,
  serializer: JSON.stringify,
};


export const createRealtimeWebSocketMiddleware = (rawOptions?: ReduxWebSocketOptions): Middleware => {
  const options = { ...defaultOptions, ...rawOptions };

  // Create a new redux websocket instance.
  const reduxWebsocket = new ReduxWebSocket(options);
  // Define the list of handlers, now that we have an instance of ReduxWebSocket.
  const handlers = {
    [connect.toString()]: reduxWebsocket.connect,
    [disconnect.toString()]: reduxWebsocket.disconnect,
    [send.toString()]: reduxWebsocket.send,
  };

  // Middleware function.
  return (store: MiddlewareAPI) => (next) => (action: Action) => {
    const { dispatch } = store;
    const { type: actionType } = action;

    // Check if action type matches prefix
    if (actionType && (actionType as string).startsWith(WEBSOCKET_ACTION_PREFIX)) {
      const handler = Reflect.get(handlers, actionType);

    //   if (action.meta && action.meta.timestamp && dateSerializer) {
    //     // eslint-disable-next-line no-param-reassign
    //     action.meta.timestamp = dateSerializer(action.meta.timestamp);
    //   }

      if (handler) {
        try {
          handler(store, action);
        } catch (err) {
          dispatch(onError({action, error: err}));
        }
      }
    }

    return next(action);
  };
};

export default createRealtimeWebSocketMiddleware;