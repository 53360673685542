import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import { TableViewField, DealRecord } from '../../../../data/types';
import { useDataCell } from '../../../../hooks/deal-table/useDataCell';
import TextTableEditCell from '../../../Fields/Text/TextTableEditCell';
import { FieldTableDisplayCellComponents, FieldTableEditCellComponents } from '../../../Fields';

export const TableDataCell = ({
  field,
  dealRecord,
  index,
  error:propsError,
  isRowHovered,
}: {
  field: TableViewField;
  dealRecord: DealRecord;
  index: number;
  row: number;
  error:string|undefined,
  isRowHovered: boolean,
}) => {
  const cellRef = useRef<HTMLTableDataCellElement>(null);
  const {
    field: dealField,
    cellProps,
    inputProps,
    isEditing,
    isSelected,
    isInvalid,
    internalValue,
    displayValue,
    ogValue,
    fieldType,
    stopEditing,
    startEditing,
    onChange,
    width
  } = useDataCell(cellRef, dealRecord, field);

  const cellClassname = classNames({
    'p-1 border flex-row align-items-center': true,
    'bg-light': isRowHovered,
    'bg-white': !isRowHovered,
    'border-top-white border-left-white': !isSelected && !isEditing,
    'border border-primary shadow-lg': isSelected,
    'border border-dark shadow-lg': isEditing,
    'border border-danger': propsError !== undefined,
  });

  const valueClassname = classNames({
    'cell text-truncate': true,
    'cell--text': fieldType === 'text',
    'cell--number': fieldType === 'number',
    'cell--invalid': isInvalid,
  });

  const EditControl = useMemo(() => {
    return FieldTableEditCellComponents[fieldType];
  }, [fieldType]);
  const DisplayControl = useMemo(() => {
    return FieldTableDisplayCellComponents[fieldType];
  }, [fieldType]);
  
  return (
    <div ref={cellRef} className="data-cell" {...cellProps}>
      <div
        className={cellClassname}
        style={{
          width: width,
          minWidth: width,
          maxWidth: width,
          height: 45,
          minHeight: 45,
          maxHeight: 45,
          zIndex: isSelected || isEditing ? 1000 : 500,
        }}
      >
        {isEditing ? (
          <div className={`cell__${fieldType} text-truncate h-100`}>
            <EditControl ogValue={ogValue} stopEditing={stopEditing} startEditing={startEditing} field={dealField} displayValue={displayValue} internalValue={internalValue} onChange={onChange}  isRowHovered={isRowHovered} {...inputProps} />
          </div>
        ) : (
          <>
          {DisplayControl !== undefined ? (
            <div className={valueClassname}>
            <DisplayControl ogValue={ogValue} stopEditing={stopEditing} startEditing={startEditing} field={dealField} displayValue={displayValue} internalValue={internalValue} onChange={onChange}  isRowHovered={isRowHovered} {...inputProps} />
          </div>
          ) : (
            <div className={valueClassname}>
            <div className="form-control border-0 px-0 text-truncate d-block">
             {displayValue ?? internalValue ?? ''}
            </div>
          </div>
          )}
          </>
        )}
        <div className="cell-seperator">
          <div className="handle" />
        </div>
      </div>
    </div>
  );
};
export default TableDataCell;
