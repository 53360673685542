import { faTrello, IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faCalendar, faImages, faPhoneSquareAlt, faRocket, faTable } from '@fortawesome/free-solid-svg-icons';
import { DealViewType } from '../../data/types';

export const ViewIconDefinitions: Record<DealViewType, IconDefinition> = {
  table: faTable,
  form: faTable,
  kanban: faTrello,
  api: faRocket,
  gallery: faImages,
  calendar: faCalendar,
};

export const ViewIconColors: Record<DealViewType, string> = {
  table: '#3498db',
  form: '#9b59b6',
  kanban: '#2ecc71',
  api: '#7f8c8d',
  calendar: '#7f8c8d',
  gallery: '#7f8c8d',
};

export const ViewIcons = {
  icons: ViewIconDefinitions,
  colors: ViewIconColors,
};

export default ViewIcons;
