import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { sendSlackMessage } from '../../../../data/slack/actions';
import { SlackMessage } from '../../../../data/slack/slice';
import { useAppDispatch } from '../../../../data/store';
import { Automation } from '../../../../data/types';
import AutomationEditor from './AutomationEditor';

function CustomToggle({ children, eventKey, callback, automation }: any) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  if (isCurrentEventKey) {
    return (
      <div className="d-grid gap-2">
        <div className="ps-3 d-flex flex-row justify-content-between align-items-center">
          <h5>{automation.name}</h5>
          <div onClick={decoratedOnClick} className="btn btn-lg">
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-grid gap-2">
      <button type="button" className="btn btn-lg" onClick={decoratedOnClick}>
        {automation.name}
      </button>
    </div>
  );
}

export const SendSlackChannelNotification = ({ view, automation, saveUpdatedAutomation, testUpdatedAutomation, deleteAutomation }: any) => {
    const dispatch = useAppDispatch();
    const [internalAutomation, setInternalAutomation] = useState<Automation>(automation);
    const onChange = useCallback((newAutomation:Automation) => {
      setInternalAutomation(newAutomation);
    }, []);
  const onSave = useCallback(() => {
    saveUpdatedAutomation(internalAutomation);
  }, [internalAutomation, saveUpdatedAutomation])
  const testAutomation = useCallback(() => {
    testUpdatedAutomation(internalAutomation);
  }, [internalAutomation, testUpdatedAutomation])
  useEffect(() => {
    if(!!internalAutomation && internalAutomation.updatedDT !== automation.updatedDT) {
      setInternalAutomation(automation);
    }
  }, [automation, internalAutomation]);
  
  return (
    <Accordion>
      <div className="border bg-light p-1">
        <CustomToggle eventKey="0" automation={automation} />
        <Accordion.Collapse eventKey="0">
          <AutomationEditor
            internalAutomation={internalAutomation}
            testAutomation={testAutomation}
            onChange={onChange}
            saveAutomation={onSave}
            deleteAutomation={deleteAutomation}

        />
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};

export default SendSlackChannelNotification;
