import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce as _debounce, get as _get } from 'lodash';
import { useLocation, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import {
  EnrichedDataRecord,
  TableViewField,
  Field,
  isFieldFormula,
  PersistedFieldValue,
  FieldValue,
} from '@taida-corp/taidacorp-sdk';
import { useAppDispatch } from '../../../../../data/store';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside/useOnClickOutside';
import useDoubleClick from '../../../../../hooks/useDoubleClick/useDoubleClick';
import { useSelector } from 'react-redux';
import { getHoveredColumnIndex, getHoveredRowIndex, getSelectedRange } from '../../../../../data/table-ui/selectors';
import { deselectRange, dragSelectionCoords, setHoverCoords, setSelectedRangeCoords } from '../../../../../data/table-ui/actions';
import { makeGetRecordById } from '../../../../../data/content-type/records/selectors';
import { deleteDataRecord } from '../../../../../data/content-type/records/actions';

export const useDataCell = (
  cellRef: RefObject<HTMLDivElement | null>,
  id: string,
  tableViewField: TableViewField,
  field: Field,
  rowIndex: number,
  columnIndex: number,
) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const editLink = useMemo(() => {
    const parts = location.pathname.split('/');
    const indexOfEdit = parts.indexOf('edit');
    return parts.slice(0, indexOfEdit > 0 ? indexOfEdit : undefined).join('/') + `/edit/${id}`;
  }, [id, location.pathname]);
  const getRecordById = useMemo(
    () => (state: any) => {
      return makeGetRecordById(state as any, id);
    },
    [id],
  );
  const dataRecord = useSelector(getRecordById);
  const hoveredRowIndex = useSelector(getHoveredRowIndex);
  const hoveredColumnIndex = useSelector(getHoveredColumnIndex);
  const selectedRange = useSelector(getSelectedRange);

  const fieldValue: FieldValue = useMemo(() => {
    if (dataRecord === undefined) {
      return {
        displayValue: '',
        internalValue: '',
        compareValue: '',
        errors: undefined,
      } as FieldValue;
    }
    return _get(dataRecord, ['enrichedFields', field.fieldId], {
      displayValue: '',
      internalValue: '',
      compareValue: '',
      errors: undefined,
    }) as FieldValue;
  }, [dataRecord, field.fieldId]);
  const displayValue = useMemo(() => {
    if(fieldValue === null) {
      return '';
    }
    return fieldValue.displayValue;
  }, [fieldValue])

  const [isContextVisible, setIsContextVisible] = useState(false);
  const [isEditing, _setIsEditing] = useState(false);
  const isRowHovered = useMemo(() => hoveredRowIndex === rowIndex, [hoveredRowIndex, rowIndex]);
  const isColumnHovered = useMemo(() => hoveredColumnIndex === columnIndex, [columnIndex, hoveredColumnIndex]);
  const isInSelectedRange = useMemo(() => {
    if (selectedRange === null) {
      return false;
    }
    let leftEdge = Math.min(selectedRange.startColumnIndex, selectedRange.endColumnIndex);
    let rightEdge = Math.max(selectedRange.startColumnIndex, selectedRange.endColumnIndex);
    let topEdge = Math.min(selectedRange.startRowIndex, selectedRange.endRowIndex);
    let bottomEdge = Math.max(selectedRange.startRowIndex, selectedRange.endRowIndex);
    
    return (
      leftEdge <= columnIndex &&
      columnIndex <= rightEdge &&
      topEdge <= rowIndex &&
      rowIndex <= bottomEdge
    );
  }, [columnIndex, rowIndex, selectedRange]);
  const isFocused = useMemo(() => {
    if (selectedRange === null) {
      return false;
    }
    return selectedRange.startColumnIndex === columnIndex && selectedRange.startRowIndex === rowIndex;
  }, [columnIndex, rowIndex, selectedRange]);
  const isOnlySelectedCell = useMemo(() => {
    if (selectedRange === null) {
      return false;
    }
    return (
      selectedRange.startColumnIndex === columnIndex &&
      selectedRange.startRowIndex === rowIndex &&
      selectedRange.endColumnIndex === columnIndex &&
      selectedRange.endRowIndex === rowIndex
    );
  }, [columnIndex, rowIndex, selectedRange]);
  const width = useMemo(() => tableViewField.width ?? 140, [tableViewField]);
  const navigate = useNavigate();

  const canEdit = useMemo(() => {
    if (isFieldFormula(field)) {
      return false;
    }
    return true;
  }, [field]);

  const setIsEditing = useCallback(
    (value: React.SetStateAction<boolean>) => {
      if (!canEdit) {
        return;
      }
      _setIsEditing(value);
    },
    [canEdit],
  );
  const startEditing = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);

  const debouncedUpdateRecord = useMemo(() => {
    return _debounce((updates: { id: string; fieldId: string; value: string }) => {
      throw new Error('update generic data record');
    }, 300);
  }, []);

  const onMouseOver = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(event.buttons === 1 || event.buttons === 3) {
      dispatch(
        dragSelectionCoords({
          dragColumnIndex: columnIndex,
          dragRowIndex: rowIndex,
        }),
      );
    } else {
      dispatch(
        setHoverCoords({
          hoverColumnIndex: columnIndex,
          hoverRowIndex: rowIndex,
        }),
      );
    }
    
  }, [columnIndex, dispatch, rowIndex]);
  const onSingleClick = useCallback(() => {}, []);
  const onDoubleClick = useCallback(() => {
    startEditing();
    navigate(editLink);
  }, [startEditing, navigate, editLink]);
  const onClickOutside = useCallback(() => {
    setIsEditing(false);
    setIsContextVisible(false);
  }, [setIsEditing]);
  useOnClickOutside(cellRef, onClickOutside, true);

  useDoubleClick({
    ref: cellRef,
    latency: 250,
    onSingleClick,
    onDoubleClick: onDoubleClick,
  });

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      console.log(event.code);
      const cell = cellRef.current!;

      // we don't really need to do this since we have the row and column
      // we just set the selected range to row + or - 1 , col + or - 1 etc
      const prevElement = undefined as HTMLElement | undefined; //cell.previousElementSibling as HTMLElement;
      const nextElement = undefined as HTMLElement | undefined; //cell.nextElementSibling as HTMLElement;
      const upElement = undefined as HTMLElement | undefined; //cell.parentElement?.previousElementSibling?.children[cell.cellIndex] as HTMLElement;
      const downElement = undefined as HTMLElement | undefined; // cell.parentElement?.nextElementSibling?.children[cell.cellIndex] as HTMLElement;

      setIsContextVisible(false);
      if (isEditing) {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          if (downElement) {
            downElement.focus();
          } else {
            cell.blur();
          }
          setIsEditing(false);
        }
        if (event.key === 'Escape') {
          cell.blur();
          setIsEditing(false);
        }
        return;
      }

      switch (event.key) {
        case 'ArrowLeft':
          if (prevElement) {
            prevElement.focus();
          } else {
            cell.blur();
          }
          setIsEditing(false);
          return;
        case 'ArrowRight':
          if (nextElement) {
            nextElement.focus();
          } else {
            cell.blur();
          }
          setIsEditing(false);
          return;
        case 'ArrowDown':
          if (downElement) {
            downElement.focus();
          } else {
            cell.blur();
          }
          setIsEditing(false);
          return;
        case 'ArrowUp':
          if (upElement) {
            upElement.focus();
          } else {
            cell.blur();
          }
          setIsEditing(false);
          return;
        case 'Escape':
          if (!isEditing) {
            return;
          }
          cell.blur();
          setIsEditing(false);
          return;
        case 'Enter':
          if (!isEditing) {
            //setInternalValue('');
            startEditing();
            return;
          }
          if (downElement) {
            downElement.focus();
          } else {
            cell.blur();
          }
          setIsEditing(false);
          return;
        default:
          break;
      }

      if (event.code === 'Tab') {
        cellRef.current!.blur();
        setIsEditing(false);
        return;
      }
      if (event.code === 'Delete' || event.code === 'Backspace') {
        // TODO: clear the value

        return;
      }

      if (!isEditing) {
        startEditing();
      }
    },
    [cellRef, isEditing, setIsEditing, startEditing],
  );
  const onFocus = useCallback(
    (e) => {
      if (e.currentTarget === e.target) {
        dispatch(
          setSelectedRangeCoords({
            startColumnIndex: columnIndex,
            startRowIndex: rowIndex,
            endRowIndex: rowIndex,
            endColumnIndex: columnIndex,
          }),
        );
      }
    },
    [columnIndex, dispatch, rowIndex],
  );

  const stopEditing = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const onBlur = useCallback(
    (e) => {
      setIsEditing(false);
      dispatch(deselectRange());
    },
    [dispatch, setIsEditing],
  );

  const onContextMenu = useCallback((e) => {
    if (!isEditing) {
      setIsContextVisible(true);
    }
    if (!e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey) {
      e.preventDefault();
       // TODO: implement an actual right click menu
      if(dataRecord === undefined) {
        return;
      }
      // eslint-disable-next-line no-restricted-globals
      const shouldDelete = confirm('You sure you want to delete this row?');
      if (shouldDelete) {
        dispatch(deleteDataRecord({contentTypeSlug: dataRecord.contentType, recordId: dataRecord.id}));
      }
      // navigate(`/datas/${viewId}/edit/${dataRecord.id}`);
    }
    /*
      e.preventDefault();
     
      */
  }, []);

  return {
    cellProps: {
      onKeyDown,
      onFocus,
      tabIndex: 0,
      onContextMenu,
      onMouseOver,
      draggable: false,
    } as React.HTMLAttributes<HTMLDivElement>,
    inputProps: {
      onBlur,
    },
    fieldValue,
    displayValue,
    isContextVisible,
    isFocused,
    isEditing,
    isRowHovered,
    isColumnHovered,
    isInSelectedRange,
    isOnlySelectedCell,
    width,
  };
};
