import { isEqual as _isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DealDetailModal from '../../components/Views/DetailModal/DealDetailModal';
import { NewDealForm } from '../../components/Views/NewDealForm/NewDealForm';
import { fetchCompany } from '../../data/company/companyActions';
import { getCompany } from '../../data/company/selectors';
import {
  clearNewDealFormResp,
  createDealWithForm,
  setSelectedDealId,
  validateDealWithForm,
} from '../../data/deals/actions';
import { getDealFormCleanedData, getDealFormErrors, getSelectedDeal, getSelectedDealId } from '../../data/deals/selectors';
import { useAppDispatch } from '../../data/store';
import { getUser } from '../../data/user/selectors';
import { fetchViews } from '../../data/views/actions';
import { getDefaultFormView } from '../../data/views/selectors';

const newDealFormId = '8be27736-b347-49cb-916d-4264f1c4c3d2';

export const NewDealFormPage = (props: any) => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const { viewId } = useParams<{ viewId: string }>();
  const me = useSelector(getUser)!;
  const formView = useSelector(getDefaultFormView);
  const company = useSelector(getCompany);
  const errors = useSelector(getDealFormErrors);
  const cleanedData = useSelector(getDealFormCleanedData);
  const selectedDealId = useSelector(getSelectedDealId);
  const fields = useMemo(() => {
    if (company !== undefined) {
      return company.fields;
    }
    return [];
  }, [company]);
  const navigate = useNavigate();
  const back = useCallback(() => {
    dispatch(clearNewDealFormResp());
    setIsVisible(false);
    setTimeout(() => {
      navigate(`/deals/${viewId}`);
    }, 100);
  }, [dispatch, navigate, viewId]);
  const saveDeal = useCallback(
    (data: any) => {
      if (_isEqual(errors, {})) {
        dispatch(createDealWithForm({ viewId: newDealFormId, data }));
      } else {
        alert('Please fix the errors in red first');
      }
    },
    [dispatch, errors],
  );
  const validateNewDeal = useCallback(
    (data: any) => {
      dispatch(validateDealWithForm({ viewId: newDealFormId, data }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchViews());
    dispatch(fetchCompany());
  }, [dispatch]);

  useEffect(() => {
    if(selectedDealId !== undefined) {
      navigate(`/deals/${viewId}/edit/${selectedDealId}`)
    }
  }, [navigate, selectedDealId, viewId])

  if (formView === undefined || company === undefined) {
    return null;
  }
  return (
    <NewDealForm
      defaultUser={me}
      formView={formView}
      fields={fields}
      errors={errors}
      cleanedData={cleanedData}
      saveDeal={saveDeal}
      validateNewDeal={validateNewDeal}
      isVisible={isVisible}
      back={back}
    />
  );
};

export default NewDealFormPage;
