import React, { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import usePortal from 'react-useportal';
import { CSSTransition } from 'react-transition-group';

export const ToolbarPopover = (props: any) => {
  const { Portal, popperAttributes, isPopoverOpen, children } = props;
  return (
    <Portal>
      <div {...popperAttributes}>
        <CSSTransition in={isPopoverOpen} timeout={200} classNames="popover-up" unmountOnExit>
          {children}
        </CSSTransition>
      </div>
    </Portal>
  );
};

export const useToolbarPopover = ({
  closeOnOutsideClick = true,
  strategy = 'fixed',
  zIndex = 900,
}: {
  closeOnOutsideClick?: boolean;
  strategy?: 'fixed' | 'absolute';
  zIndex?: number;
}) => {
  const {
    Portal,
    isOpen: isPopoverOpen,
    closePortal,
    openPortal,
  } = usePortal({
    closeOnOutsideClick,
    onOpen({ portal }) {
      portal.current.style.cssText = `
        z-index: ${zIndex};
      `;
    },
  });
  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy,
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start'],
          
        },
      },
    ],
  });

  return {
    isPopoverOpen,
    closePopover: closePortal,
    openPopover: openPortal,
    buttonProps: {
      ref: setReferenceElement,
      onClick: openPortal,
    },
    ToolbarPopover,
    popoverProps: {
      Portal,
      isPopoverOpen,
      popperAttributes: {
        ref: setPopperElement,
        style: styles.popper,
        ...attributes.popper,
      },
    },
  };
};

export default useToolbarPopover;
