import { useMemo } from 'react';

interface StaticStreetViewProps {
  location: { lat: number; long: number } | string;
  fov: number;
  height: number;
  width: number;
}
const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export const StaticStreetView = (props: StaticStreetViewProps) => {
  const imageURL = useMemo(() => {
    const location = typeof props.location === 'string' ? encodeURIComponent(props.location) : props.location;
    return `https://maps.googleapis.com/maps/api/streetview?location=${location}&size=${props.width}x${props.height}&fov=${props.fov}
        &key=${GOOGLE_MAPS_KEY}`;
  }, [props.location, props.height, props.width, props.fov]);
  return <img className="img-fluid" alt="" src={imageURL} />;
};

export default StaticStreetView;
