import { Field, FieldType, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import AttachmentInputComponent from './attachment/AttachmentInputComponent';
import { RecordFormInputComponent } from './base';
import CheckboxInputComponent from './checkbox/CheckboxInputComponent';
import ColorInputComponent from './color/ColorInputComponent';
import DateInputComponent from './date/DateInputComponent';
import EmailInputComponent from './email/EmailInputComponent';
import LongTextInputComponent from './long-text/LongTextInputComponent';
import NumberInputComponent from './number/NumberInputComponent';
import PhoneInputComponent from './phone/PhoneInputComponent';
import ReadOnlyInputComponent from './readonly/ReadOnlyInputComponent';
import SelectUserInputComponent from './select-user/SelectUserInputComponent';
import SelectInputComponent from './select/SelectInputComponent';
import TextInputComponent from './text/TextInputComponent';
import URLInputComponent from './url/URLInputComponent';

export const getRecordFormInputComponent = (fieldType: FieldType): RecordFormInputComponent => {
  // exhaustive switch here gives us a nice exception if we're missing any
  switch (fieldType) {
    case 'text':
      return TextInputComponent;
    case 'long-text':
      return LongTextInputComponent;
    case 'email':
      return EmailInputComponent;
    case 'phone':
      return PhoneInputComponent;
    case 'url':
      return URLInputComponent;
    case 'attachment':
      return AttachmentInputComponent;
    case 'number':
      return NumberInputComponent;
    case 'slider':
      return TextInputComponent;
    case 'checkbox':
      return CheckboxInputComponent;
    case 'color':
      return ColorInputComponent;
    case 'date':
      return DateInputComponent;
    case 'datetime':
      return DateInputComponent;
    case 'formula':
      return ReadOnlyInputComponent;
    case 'select':
      return SelectInputComponent;
    case 'multi-select':
      return SelectInputComponent;
    case 'select-user':
      return SelectUserInputComponent;
    case 'multi-select-user':
      return TextInputComponent;

    default:
      throw new Error(`Missing detail field component for ${fieldType}`);
  }
};
