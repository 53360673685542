import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import Popover, { PopoverProps } from "react-popover";

import "./TableHeaderCell.scss";
import TableHeaderCellPopover from "./TableHeaderCellPopover";
import { Field, TableViewField } from "../../../../data/types";
import { useHeaderCell } from "../../../../hooks/deal-table/useHeaderCell";
import { FieldIcons } from "../../../Fields";

interface TableHeaderCellProps {
  readonly tableField: TableViewField;
  readonly field: Field;
  readonly canHide:boolean
}

export const TableHeaderCell = ({
  tableField,
  field,
  canHide
}: TableHeaderCellProps) => {
  const {
    isPopoverOpen,
    name,
    fieldType,
    config,
    toggleIsOpen,
    onSave,
    onDelete,
    toggleFieldVisibility,
    handleProps,
    width,
  } = useHeaderCell(tableField, field);

  const popoverProps: PopoverProps = useMemo(
    () => ({
      tipSize: 0.01,
      isOpen: isPopoverOpen,
      preferPlace: "below",
      onOuterAction: toggleIsOpen,
      body: [
        <TableHeaderCellPopover
          key={`popover`}
          name={name}
          field={field}
          fieldType={fieldType}
          config={config}
          onSave={onSave}
          onDelete={onDelete}
          onHide={toggleFieldVisibility}
          canHide={canHide}
        />,
      ],
    }),
    [isPopoverOpen, toggleIsOpen, name, field, fieldType, config, onSave, onDelete, toggleFieldVisibility, canHide]
  );
  const FTIcon = FieldIcons[fieldType];
  return (
    <div
      key={`header-${field.fieldId}`}
      className="table-header__cell"
      style={{
        width,
        minWidth: width,
        maxWidth: width,
      }}
    >
      <Popover {...popoverProps}>
        <div className="table-header__cell__inner d-flex flex-row align-items-center justify-content-between px-2">
          <div
            className="d-flex flex-row align-items"
            style={{ width: width - 40 }}
          >
            <div className="table-header__cell__icon">
              <FontAwesomeIcon icon={FTIcon} />
            </div>
            <div className="table-header__cell__name text-truncate ms-1">
              {field.name}
            </div>
          </div>
          <div className="popover-btn">
            <button className="btn p-0" onClick={toggleIsOpen}>
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
          </div>
        </div>
      </Popover>

      <div className="table-header__cell__cell-seperator" {...handleProps}>
        <div className="handle"  />
      </div>
    </div>
  );
};
export default TableHeaderCell;
