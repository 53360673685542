import { isEqual as _isEqual, cloneDeep as _cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateCampaign } from '../../../../data/campaigns/actions';
import { getCampaignsIsSaving } from '../../../../data/campaigns/selectors';
import { useAppDispatch } from '../../../../data/store';
import {
  Campaign,
  DailyCampaign,
  HourlyCampaign,
  isDailyCampaign,
  isHourlyCampaign,
  isMonthlyCampaign,
  isSignUpCampaign,
  isWeeklyCampaign,
  MarketingTemplate,
  MonthlyCampaign,
  SignUpCampaign,
  WeeklyCampaign,
} from '../../../../data/types';
import DayOfWeekInput from './views/DayOfWeekInput';
import DayOfMonthInput from './views/DayOfMonthInput';
import RunOnWeekendsInput from './views/RunOnWeekendsInput';
import TimeOfDayInput from './views/TimeOfDayInput';
import { getMarketingTemplatesList } from '../../../../data/marketing-services/marketing-templates/selectors';
import { SignUpCampaignOptions } from './views/SignUpCampaignOptions';

interface CampaignFormProps {
  campaign: Campaign;
  templates: MarketingTemplate[];
}

export const CampaignForm = ({ campaign, templates }: CampaignFormProps) => {
  const dispatch = useAppDispatch();
  const isSaving = useSelector(getCampaignsIsSaving);
  const [form, setForm] = useState<Campaign>(campaign);
  const onChangeName = useCallback((e) => {
    setForm((curr) => ({ ...curr, name: e.target.value ?? '' }));
  }, []);
  const onChangeTemplate = useCallback((e) => {
    setForm((curr) => ({ ...curr, marketingTemplateId: e.target.value }));
  }, []);
  const onChangeFrequency = useCallback((e) => {
    setForm((curr) => ({ ...curr, frequency: e.target.value }));
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (form.name === '') {
        alert('Name is required');
        return;
      }
      dispatch(updateCampaign(form));
    },
    [dispatch, form],
  );
  useEffect(() => {
    if (form.frequency === 'sign-up' && !_isEqual(Object.keys(form.options), ['filters'])) {
      setForm((curr) => {
        const updated = _cloneDeep(curr);
        updated.options = {
          filters: (curr as SignUpCampaign).options.filters ?? { operator: 'and', conditions: [] },
        };
        return updated;
      });
    }
    if (form.frequency === 'hourly' && !_isEqual(Object.keys(form.options), ['shouldRunOnWeekends'])) {
      setForm((curr) => {
        const updated = _cloneDeep(curr);
        updated.options = {
          shouldRunOnWeekends: (curr as HourlyCampaign).options.shouldRunOnWeekends ?? true,
        };
        return updated;
      });
    }
    if (form.frequency === 'daily' && !_isEqual(Object.keys(form.options), ['shouldRunOnWeekends', 'timeOfDay'])) {
      setForm((curr) => {
        const updated = _cloneDeep(curr);

        updated.options = {
          shouldRunOnWeekends: (curr as DailyCampaign).options.shouldRunOnWeekends ?? true,
          timeOfDay: (curr as DailyCampaign).options.timeOfDay ?? '9am',
        };
        return updated;
      });
    }
    if (form.frequency === 'weekly' && !_isEqual(Object.keys(form.options), ['dayOfTheWeek', 'timeOfDay'])) {
      setForm((curr) => {
        const updated = _cloneDeep(curr);

        updated.options = {
          dayOfTheWeek: (curr as WeeklyCampaign).options.dayOfTheWeek ?? 'friday',
          timeOfDay: (curr as WeeklyCampaign).options.timeOfDay ?? '9am',
        };
        return updated;
      });
    }
    if (form.frequency === 'monthly' && !_isEqual(Object.keys(form.options), ['dayOfMonth', 'timeOfDay'])) {
      setForm((curr) => {
        const updated = _cloneDeep(curr);

        updated.options = {
          dayOfMonth: (curr as MonthlyCampaign).options.dayOfMonth ?? 1,
          timeOfDay: (curr as MonthlyCampaign).options.timeOfDay ?? '9am',
        };
        return updated;
      });
    }
  }, [form.frequency, form.options]);

  return (
    <form onSubmit={onSubmit} className="mb-5">
      <div className="mb-3">
        <label htmlFor="templateName" className="form-label">
          Campaign Name
        </label>
        <input
          onChange={onChangeName}
          value={form.name}
          type="text"
          className="form-control"
          id="templateName"
          aria-describedby="nameHelp"
        />
        <div id="nameHelp" className="form-text">
          Name the campaign. You'll edit the rest of the campaign after it is created.
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="marketingTemplateId" className="form-label">
          Marketing Template
        </label>
        <select
          onChange={onChangeTemplate}
          value={form.marketingTemplateId}
          className="form-control"
          id="marketingTemplateId"
          aria-describedby="marketingTemplateIdHelp"
        >
          {templates.map((template) => (
            <option key={template.marketingTemplateId} value={template.marketingTemplateId}>
              {template.name}
            </option>
          ))}
        </select>
        <div id="marketingTemplateIdHelp" className="form-text">
          {form.marketingTemplateId !== undefined && (
            <a href={`/marketing/templates/${form.marketingTemplateId}`} target="_blank" rel="noreferrer">
              Current template
            </a>
          )}
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="frequency" className="form-label">
          Frequency
        </label>
        <select
          onChange={onChangeFrequency}
          value={form.frequency}
          className="form-select"
          id="frequency"
          aria-describedby="frequencyHelp"
        >
          <option value="hourly">Hourly</option>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="sign-up">Sign Up</option>
        </select>
        <div id="frequencyHelp" className="form-text">
          Contacts will only ever receive a single sign up email.
        </div>
      </div>
      {isHourlyCampaign(form) && <RunOnWeekendsInput form={form} setForm={setForm} />}
      {isDailyCampaign(form) && (
        <>
          <RunOnWeekendsInput form={form} setForm={setForm} />
          <TimeOfDayInput form={form} setForm={setForm} />
        </>
      )}
      {isWeeklyCampaign(form) && (
        <>
          <DayOfWeekInput form={form} setForm={setForm} />
          <TimeOfDayInput form={form} setForm={setForm} />
        </>
      )}
      {isMonthlyCampaign(form) && (
        <>
          <DayOfMonthInput form={form} setForm={setForm} />
          <TimeOfDayInput form={form} setForm={setForm} />
        </>
      )}
      {isSignUpCampaign(form) && (
        <>
          <SignUpCampaignOptions form={form} setForm={setForm} />
        </>
      )}
      <button type="submit" className="btn btn-primary">
        Save
      </button>
    </form>
  );
};

export default CampaignForm;
