import { keyBy as _keyBy, find as _find } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import { DealView, TableViewField } from '../types';
import {
  createDealView,
  updateDealView,
  setSelectedView,
  setSelectedViewId,
  fetchViews,
  realTimeViewCreated,
  realTimeViewUpdated,
  realTimeViewDeleted,
  localUpdateColumnWidth,
  localUpdateIsVisible,
  localUpdateViewFields,
  localUpdateView,
} from './actions';

interface ViewsState {
  selectedView: {viewId:string; contentType:string}|undefined;
  lastSelectedViewId?: string;
  views: Record<string, DealView>;
  isCreating: boolean;
  isLoading: boolean;
  isInitialized: boolean;
  isUpdating: boolean;
  errors: string[];
}

const initialState: ViewsState = {
  selectedView: undefined,
  lastSelectedViewId: undefined,
  isCreating: false,
  isLoading: false,
  isInitialized: false,
  isUpdating: false,
  views: {},
  errors: [],
};

export const viewsSlice = createSlice({
  name: 'views',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSelectedViewId, (state, { payload }) => {
      if(payload === undefined) {
        state.selectedView = payload;
      } else {
        state.selectedView = {viewId: payload, contentType: 'deals'};
        state.lastSelectedViewId = payload;
      }
    });
    builder.addCase(setSelectedView, (state, { payload }) => {
      state.selectedView = payload;
      if(payload !== undefined && payload.contentType === 'deals') {
        state.lastSelectedViewId = payload.viewId;
      }
    });

    builder.addCase(createDealView.pending, (state) => {
      state.isLoading = true;
      state.isCreating = true;
      state.selectedView = undefined;
    });
    builder.addCase(createDealView.fulfilled, (state, { payload }) => {
      const oldViews = state.views;
      const updatedViews = { ...oldViews, [payload.viewId]: payload };
      state.views = updatedViews;
      state.isLoading = false;
      state.selectedView = {viewId: payload.viewId, contentType: 'deals'};
      state.isCreating = false;
    });
    builder.addCase(createDealView.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
      state.isCreating = false;
    });
    builder.addCase(updateDealView.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateDealView.fulfilled, (state, { payload }) => {
      const oldViews = state.views;
      state.views = { ...oldViews, [payload.viewId]: payload };
      state.isLoading = false;
    });
    builder.addCase(updateDealView.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });
    builder.addCase(fetchViews.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchViews.fulfilled, (state, { payload }) => {
      state.views = _keyBy(payload, 'viewId');
      if (state.selectedView === undefined) {
        const firstDealView = _find(payload, (view) => (view as any).contentType === undefined || (view as any).contentType === 'deals');
        state.selectedView = {viewId: firstDealView!.viewId, contentType: 'deals'};
      }
      state.isLoading = false;
    });
    builder.addCase(fetchViews.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });
    builder.addCase(realTimeViewUpdated, (state, { payload }) => {
      const { view: updatedView } = payload;
      const { [updatedView.viewId]: currentView } = current(state).views;
      if (
        currentView === undefined ||
        currentView.updatedDT === undefined ||
        currentView.updatedDT < updatedView.updatedDT
      ) {
        state.views[updatedView.viewId] = updatedView;
      }
    });
    builder.addCase(realTimeViewCreated, (state, { payload }) => {
      const { view } = payload;
      state.views[view.viewId] = view;
    });
    builder.addCase(realTimeViewDeleted, (state, { payload }) => {
      const { view } = payload;
      delete state.views[view.viewId];
    });
    builder.addCase(localUpdateColumnWidth, (state, { payload }) => {
      const { viewId, fieldId, deltaX } = payload;
      const { [viewId]: currentView } = current(state).views;
      if (currentView === undefined || currentView.type !== 'table') {
        return;
      }
      const currentFieldIdx = currentView.properties.fields.findIndex((field) => field.fieldId === fieldId);
      if (currentFieldIdx === -1) {
        return;
      }
      const width = currentView.properties.fields[currentFieldIdx].width ?? 140;
      const updatedField: TableViewField = { ...currentView.properties.fields[currentFieldIdx], width: width - deltaX };
      state.views[viewId].properties.fields[currentFieldIdx] = updatedField;
    });
    builder.addCase(localUpdateIsVisible, (state, { payload }) => {
      const { viewId, fieldId, isVisible } = payload;
      const { [viewId]: currentView } = current(state).views;
      if (currentView === undefined || currentView.type !== 'table') {
        return;
      }
      const currentFieldIdx = currentView.properties.fields.findIndex((field) => field.fieldId === fieldId);
      if (currentFieldIdx === -1) {
        return;
      }
      const updatedField: TableViewField = { ...currentView.properties.fields[currentFieldIdx], isVisible };
      state.views[viewId].properties.fields[currentFieldIdx] = updatedField;
    });
    builder.addCase(localUpdateViewFields, (state, { payload }) => {
      const { viewId, fields } = payload;
      const { [viewId]: currentView } = current(state).views;
      if (currentView === undefined) {
        return;
      }
      state.views[viewId].properties.fields = fields;
    });
    builder.addCase(localUpdateView, (state, { payload }) => {
      state.views[payload.view.viewId] = payload.view;
    });
  },
});

export default viewsSlice;
