import {
  values as _values,
  orderBy as _orderBy,
  keyBy as _keyBy,
  groupBy as _groupBy,
  reduce as _reduce,
  find as _find,
  map as _map,
  get as _get,
  forEach as _forEach,
  findIndex as _findIndex,
  toNumber as _toNumber,
  filter as _filter,
  countBy as _countBy,
  every as _every,
  some as _some,
  mapKeys as _mapKeys,
  mapValues
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import { format } from 'date-fns';

import { KanbanColumn, KanbanCard } from '../../../components/Views/KanbanBoard/Board';
import { getSelectUsersOptions, getUsers } from '../../users/selectors';
import { getUser } from '../../user/selectors';
import Fuse from 'fuse.js';
import { DataRecord, EnrichedDataRecord, enrichRecords, FieldPhone, FieldSelect, filterRecords as filterEnrichedRecords } from '@taida-corp/taidacorp-sdk';
import { getContentTypeFields, getContentTypeFieldsObject, getSelectedContentTypeString } from '../content-types/selectors';
import { getSelectedView } from '../record-views/selectors';

export const getRecordsState = (state: RootState) => state.records;

export const getSelectedContentTypeRecords = createSelector(getRecordsState, getSelectedContentTypeString, (state, contentType):Record<string, DataRecord> => contentType !== undefined ? _get(state, ['records', contentType], {}) : {});
export const getSelectedContentTypeRecordsList = createSelector(getSelectedContentTypeRecords, (records):DataRecord[] => _values(records));

export const getIsSelectedContentTypeInitialized = createSelector(getRecordsState, getSelectedContentTypeString, (state, contentType):boolean => contentType !== undefined ? _get(state.isInitialized, contentType, false) : false);
export const getIsSelectedContentTypeLoading = createSelector(getRecordsState, getSelectedContentTypeString, (state, contentType):boolean => contentType !== undefined ? _get(state.isLoading, contentType, false) : false);

export const getRecordIsSaving = createSelector(getRecordsState, (state) => state.isSaving);
export const getPrimaryFieldValueCounts = createSelector(getRecordsState, (state) => state.primaryFieldValueCounts);

export const getSelectedRecordId = createSelector(getRecordsState, (state) => state.selectedRecordId);
export const getRecordsSearchTerm = createSelector(getRecordsState, (state) => state.recordsSearchTerm);

export const getRecordFormCleanedData = createSelector(getRecordsState, (state) => state.recordFormData);
export const getRecordFormGeneralErrors = createSelector(getRecordsState, (state) => state.generalFormErrors);



export const getEnrichedRecordsForSelectedContentType = createSelector(
  getSelectedContentTypeRecordsList,
  getContentTypeFieldsObject,
  getSelectUsersOptions,
  (records, fields, userOptions) => {
    return enrichRecords(records, _keyBy(fields, 'fieldId'), userOptions)
  },
);

export const getRawSelectedRecord = createSelector(
  getSelectedRecordId,
  getSelectedContentTypeRecordsList,
  (id, records):DataRecord|undefined => {
    if (id === undefined) {
      return undefined;
    }
    const record = _find(records, {id});
    return record;
  },
);
export const getEnrichedSelectedRecord = createSelector(
  getSelectedRecordId,
  getEnrichedRecordsForSelectedContentType,
  (id, records):EnrichedDataRecord|undefined => {
    if (id === undefined) {
      return undefined;
    }
    const record = _find(records, {id});
    return record;
  },
);

export const getSelectedViewRecords = createSelector(
  getSelectedView,
  getEnrichedRecordsForSelectedContentType,
  getRecordsSearchTerm,
  getContentTypeFieldsObject,
  getUsers,
  getUser,
  (view, enrichedRecords, searchTerm, fieldsObject, users, currentUser) => {
    if (view === undefined || view.type === 'form' || view.type === 'api') {
      return [];
    }
    // return [] as RecordRecord[];
    // return deals;

    const viewProperties = view.properties;
    // const visibleFields = viewProperties.fieldIds;
    // const justFields = (item:Record) => _.pick(item.fields, visibleFields);
    const [orderFields, order] = viewProperties.orderBy.length > 0 && viewProperties.orderBy[0].length > 0 ? viewProperties.orderBy : [['createdDT'], ['asc']] as [string[], ['asc']];

    const viewFilters = viewProperties.filters ?? { operator: 'and', conditions: [] };
    console.log('viewFilters', viewFilters);
    console.log('selectedView', view);

    const filtered = filterEnrichedRecords(enrichedRecords, viewFilters, fieldsObject, users, currentUser!)
    
    // TODO: fix this up...
    // maybe get all the text fields???
    if (searchTerm !== '') {
      const zillowStatus = 'fields.8ec75c1d-f9a4-4933-8898-6fa2d9f3371e';
      const status = 'fields.2efffc79-63d4-4a0d-aa37-32880a314963';
      const rep = 'fields.85c46e42-0f90-425e-9999-5048e308c764';
      const sellerName = 'fields.846fc2af-0cb4-41b1-a9db-50228b650c07';
      const searchableFields = ['primaryFieldValue', zillowStatus, status, rep, sellerName];
      const fuse = new Fuse(filtered, { keys: searchableFields });
      return fuse.search(searchTerm).map((item) => item.item);
    }

    const ordered = _orderBy(
      filtered,
      orderFields.map((field) => (field !== 'createdDT' ? `fields.${field}` : field)),
      order,
    );
    return ordered;
  },
);

export const getSelectedViewRecordIds = createSelector(getSelectedViewRecords, (records) => {
  return records.map((record) => record.id);
});

export const getFirstRecordInSelectedView = createSelector(getSelectedViewRecords, (records) =>
records && records.length > 0 ? records[0] : undefined,
);

export const getSelectedViewRecordsWithFieldNameKeys = createSelector(getSelectedViewRecords, getSelectedView, getContentTypeFields, (records, view, fields) => {
  const fieldsByName = _keyBy(fields, 'name');
  const recordFieldValuesWithNames = _map(records, (record) => {
    
    return mapValues(fieldsByName, (fieldValue, key) => {
      const value = _get(record, ['enrichedFields', fieldValue.fieldId, 'displayValue'], '');
      return value;
    })
  })
  console.log(recordFieldValuesWithNames);
  return recordFieldValuesWithNames;
})

export const makeGetRecordById = createSelector(
  [
    getSelectedViewRecords,
    (_: any, id: string) => id
  ],
  // Output selector gets (`items, category)` as args
  (records, id) => {
    return _find(records, {id});
  },
)
export const _makeGetRecordById = () =>
  createSelector(
    getSelectedViewRecords,
    (_: any, id: string) => id,
    (records, id) => {
      
      return _find(records, {id});
    },
  );

export const getUncategorizedRecordsForSelectedKanabanView = createSelector(
  getSelectedView,
  getContentTypeFields,
  getSelectedContentTypeRecordsList,
  (view, fields, records) => {
    if (view === undefined || view.type !== 'kanban') {
      return [];
    }
    return records;
  },
);

export const getRecordsForSelectedKanbanView = createSelector(
  getSelectedView,
  getContentTypeFieldsObject,
  getSelectedViewRecords,
  (view, fields, records) => {
    if (view === undefined || view.type !== 'kanban') {
      return {};
    }
    const viewProperties = view.properties;
    const groupingFieldId = viewProperties.groupingFieldId;
    if (groupingFieldId === undefined) {
      return {};
    }
    const groupingField = fields[groupingFieldId] as FieldSelect;
    if (groupingField === undefined) {
      return {};
    }
    const config = groupingField.config;
    if (config === undefined || config.options === undefined) {
      return {};
    }

    const options = _keyBy(config.options, 'value');
    let starter: Record<string, EnrichedDataRecord[]> = {
      Uncategorized: [],
    };
    config.options.forEach((option, index: number) => {
      starter[option.value] = [];
    });
    const reducerFunc = (cum: Record<string, EnrichedDataRecord[]>, curr: EnrichedDataRecord) => {
      let valueForRecord = _get(curr, ['enrichedFields', groupingFieldId, 'displayValue'], null);
      
      if (valueForRecord === null) {
        valueForRecord = 'Uncategorized';
      }
      const { [valueForRecord as string]: currentGroup } = cum;
      const newRecords = [...currentGroup, curr];
      const newCum = { ...cum, [valueForRecord as string]: newRecords };
      return newCum;
    };

    const groups = _reduce(records, reducerFunc, starter);
    return groups;
  },
);

export const getFieldForSelectedKanbanView = createSelector(
  getSelectedView,
  getContentTypeFieldsObject,
  (view, fieldsObj) => {
    if (view === undefined || view.type !== 'kanban') {
      return undefined;
    }
    const viewProperties = view.properties;
    const groupingFieldId = viewProperties.groupingFieldId;
    if(groupingFieldId === undefined) {
      return undefined;
    }
    const groupingField = fieldsObj[groupingFieldId] as FieldSelect;
    return groupingField;
  },
);


export const getPhoneNumbersForSelectedRecord = createSelector(getEnrichedSelectedRecord, getContentTypeFields, (record, fields) => {

  const phoneFields = _filter(fields, (field) => field.type === 'phone') as FieldPhone[];

  const phoneValues:{field: FieldPhone; value: string}[] = _map(phoneFields, (phoneField:FieldPhone) => {

    // clean up fix phone values
    let val = (_get(record, `fields.${phoneField.fieldId}.internalValue`, '') as string).replace(/[^0-9]/g, '');
    if(!val.startsWith('1')) {
      val = `1${val}`;
    }
    return {
      field: phoneField,
      value: val
    } as {field: FieldPhone; value: string}
  })
  return phoneValues;
})

export const DEAL_FIELD_ADDRESS_LINE_1 = '689a69f8-b970-4fcf-89ce-a166732db4c0';

export const getSelectedRecordAddressLine1 = createSelector(getEnrichedSelectedRecord, (deal) => {
  return _get(deal, `fields.${DEAL_FIELD_ADDRESS_LINE_1}`, '');
})