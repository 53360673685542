import { keyBy as _keyBy } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import { ContentType } from '@taida-corp/taidacorp-sdk';
import {  updateField, createField, deleteField  } from '../fields/actions';
import { fetchContentType, fetchContentTypes, setSelectedContentType, updateContentType, realTimeContentTypeCreated, realTimeContentTypeDeleted, realTimeContentTypeUpdated } from './actions';

interface ContentTypeState {
  contentTypes: Record<string, ContentType>;
  selectedContentType: string | undefined;
  isLoading: boolean;
  isInitialized: boolean;
  isUpdating: boolean;
  errors: string[];
}

const initialState: ContentTypeState = {
  contentTypes: {},
  selectedContentType: undefined,
  isLoading: false,
  isInitialized: false,
  isUpdating: false,
  errors: [],
};

export const contentTypesSlice = createSlice({
  name: 'contentTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(setSelectedContentType, (state, { payload }) => {
      state.selectedContentType = payload;
    });
    builder.addCase(realTimeContentTypeUpdated, (state, { payload }) => {
      const { contentType } = payload;
      const currentContentType = current(state).contentTypes[contentType.slug];
      if (
        currentContentType === undefined ||
        currentContentType.updatedDT === undefined ||
        currentContentType.updatedDT < contentType.updatedDT
      ) {
        state.contentTypes[contentType.slug] = contentType;
      }
    });
    builder.addCase(realTimeContentTypeCreated, (state, { payload }) => {
      const { contentType } = payload;
      state.contentTypes[contentType.slug] = contentType;
    });
    builder.addCase(realTimeContentTypeDeleted, (state, { payload }) => {
      const { contentType } = payload;
      delete state.contentTypes[contentType.slug];
    });

    builder.addCase(fetchContentTypes.pending, (state) => {
      state.isLoading = true;
      state.isInitialized = false;
      state.errors = [];
    });
    builder.addCase(fetchContentTypes.fulfilled, (state, { payload }) => {
      state.contentTypes = _keyBy(payload, 'slug');
      state.isInitialized = true;
      state.isLoading = false;
    });
    builder.addCase(fetchContentTypes.rejected, (state, { payload }) => {
      state.isInitialized = true;
      state.isLoading = false;
      state.errors = payload ?? [];
    });
    builder.addCase(updateContentType.pending, (state) => {
      state.isUpdating = true;
    });
    builder.addCase(updateContentType.fulfilled, (state, { payload }) => {
      state.contentTypes = { ...state.contentTypes, [payload.slug]: payload };
      state.isUpdating = false;
    });
    builder.addCase(updateContentType.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isUpdating = false;
    });

    builder.addCase(fetchContentType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchContentType.fulfilled, (state, { payload }) => {
      state.contentTypes = { ...state.contentTypes, [payload.slug]: payload };
      state.isLoading = false;
    });
    builder.addCase(fetchContentType.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });

    builder.addCase(createField.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createField.fulfilled, (state, { payload }) => {
      state.contentTypes = { ...state.contentTypes, [payload.slug]: payload };
      state.isLoading = false;
    });
    builder.addCase(createField.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });

    builder.addCase(updateField.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateField.fulfilled, (state, { payload }) => {
      state.contentTypes = { ...state.contentTypes, [payload.slug]: payload };
      state.isLoading = false;
    });
    builder.addCase(updateField.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });

    builder.addCase(deleteField.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteField.fulfilled, (state, { payload }) => {
      state.contentTypes = { ...state.contentTypes, [payload.slug]: payload };
      state.isLoading = false;
    });
    builder.addCase(deleteField.rejected, (state, { payload }) => {
      state.errors = payload ?? [];
      state.isLoading = false;
    });
  },
});

export default contentTypesSlice;
