import { bind, findIndex as _findIndex } from 'lodash';
import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteField, updateField } from '../../data/company/fieldActions';
import { getColumnWidths } from '../../data/company/selectors';
import { useAppDispatch } from '../../data/store';
import { Field, FieldType, TableViewField, TableView } from '../../data/types';
import { localUpdateColumnWidth, persistViewUpdates, updateDealView } from '../../data/views/actions';
import { getSelectedView } from '../../data/views/selectors';

const isTouchEvent = (event: MouseEvent | TouchEvent): event is TouchEvent => {
  return Boolean((event as TouchEvent).touches && (event as TouchEvent).touches.length);
};

const isMouseEvent = (event: MouseEvent | TouchEvent): event is MouseEvent => {
  return Boolean(
    ((event as MouseEvent).clientX || (event as MouseEvent).clientX === 0) &&
      ((event as MouseEvent).clientY || (event as MouseEvent).clientY === 0),
  );
};

export const useHeaderCell = (tableViewField: TableViewField, field: Field) => {
  const { name, type: fieldType, config } = field;
  const view = useSelector(getSelectedView) as TableView;
  const widths = useSelector(getColumnWidths);
  const width = useMemo(() => widths[field.fieldId] ?? 140, [widths, field]);
  const [isResizing, setIsResizing] = useState(false);
  const resizeCursorPos = useRef<{ x: number; y: number } | undefined>(undefined);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const toggleIsOpen = useCallback(() => setIsPopoverOpen((val) => !val), []);
  const dispatch = useAppDispatch();
  const onSave = useCallback(
    (newName: string, newFieldType: FieldType, newConfig?: any | undefined) => {
      dispatch(updateField({ fieldId: field.fieldId, name: newName, type: newFieldType, config: newConfig }));
      setIsPopoverOpen(false);
    },
    [dispatch, field.fieldId],
  );
  const updateWidthWithDeltaX = useCallback(
    (deltaX: number) => {
      dispatch(localUpdateColumnWidth({ viewId: view.viewId, fieldId: field.fieldId, deltaX }));
    },
    [dispatch, field.fieldId, view.viewId],
  );

  const onMouseUp = useCallback(() => {
    setIsResizing(false);
    resizeCursorPos.current = undefined;
    dispatch(persistViewUpdates(view.viewId));
  }, [dispatch, view.viewId]);
  const onMouseMove = useCallback(
    (event) => {
      if (!isResizing || resizeCursorPos.current === undefined) {
        return;
      }
      if (window.TouchEvent && isTouchEvent(event)) {
        try {
          event.preventDefault();
          event.stopPropagation();
        } catch (e) {
          // Ignore on fail
        }
      }
      const clientX = isTouchEvent(event) ? event.touches[0].clientX : (event.clientX as number);
      const clientY = isTouchEvent(event) ? event.touches[0].clientY : (event.clientY as number);
      const deltaX = resizeCursorPos.current.x - clientX;
      // const deltaY = resizeCursorPos.y - clientY;
      if (Math.abs(deltaX) < 3) {
        return;
      }
      updateWidthWithDeltaX(deltaX);
      resizeCursorPos.current = { x: clientX, y: clientY };
    },
    [isResizing, updateWidthWithDeltaX],
  );

  const bindEvents = useCallback(() => {
    if (window) {
      window.addEventListener('mouseup', onMouseUp);
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseleave', onMouseUp);
      window.addEventListener('touchmove', onMouseMove, {
        capture: true,
        passive: false,
      });
      window.addEventListener('touchend', onMouseUp);
    }
  }, [onMouseMove, onMouseUp]);
  const unbindEvents = useCallback(() => {
    if (window) {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseleave', onMouseUp);
      window.removeEventListener('touchmove', onMouseMove, true);
      window.removeEventListener('touchend', onMouseUp);
    }
  }, [onMouseMove, onMouseUp]);
  useEffect(() => {
    if (isResizing) {
      bindEvents();
    }
    return () => {
      unbindEvents();
    };
  }, [bindEvents, isResizing, unbindEvents]);

  const onMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    let clientX = 0;
    let clientY = 0;
    if (event.nativeEvent && isMouseEvent(event.nativeEvent)) {
      clientX = event.nativeEvent.clientX;
      clientY = event.nativeEvent.clientY;
    } else if (event.nativeEvent && isTouchEvent(event.nativeEvent)) {
      clientX = (event.nativeEvent as TouchEvent).touches[0].clientX;
      clientY = (event.nativeEvent as TouchEvent).touches[0].clientY;
    }
    resizeCursorPos.current = { x: clientX, y: clientY };
    setIsResizing(true);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(deleteField(field.fieldId));
    setIsPopoverOpen(false);
  }, [dispatch, field.fieldId]);

  const toggleFieldVisibility = useCallback(() => {
    const { fieldId, isVisible } = tableViewField;
    const oldFields = view!.properties.fields as TableViewField[];
    const fieldIndex = _findIndex(oldFields, { fieldId: field.fieldId });
    const updatedField = { ...tableViewField, isVisible: !isVisible, fieldId };
    const newFields = [...oldFields.slice(0, fieldIndex), updatedField, ...oldFields.slice(fieldIndex + 1)];
    const updatedView: TableView = { ...(view as TableView), properties: { ...view.properties, fields: newFields } };
    dispatch(updateDealView(updatedView));
    setIsPopoverOpen(false);
  }, [dispatch, field.fieldId, tableViewField, view]);

  return {
    width,
    handleProps: {
      onMouseDown,
      // onTouchStart: onDragStart,
      onMouseUp,
      // onTouchEnd: onDragEnd,
    },
    name,
    fieldType,
    config,
    isPopoverOpen,
    setIsPopoverOpen,
    toggleIsOpen,
    onSave,
    onDelete,
    toggleFieldVisibility,
  };
};
