import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import {matchSorter} from 'match-sorter'
import { Field, FieldSelect, FieldSelectOption } from '../../../../data/types';
import CellPopoverOptionItem from './CellPopoverOptionItem';

import './UserSelectTableEditCellPopover.scss';
import { useSelector } from 'react-redux';
import { getSelectUsersOptions } from '../../../../data/users/selectors';

interface UserSelectTableEditCellPopoverProps {
  field: FieldSelect;
  onChange: (newValue: string | undefined | any) => void;
  internalValue: string | undefined | any;
  stopEditing: Function;

}

export const UserSelectTableEditCellPopover = ({ field, onChange, internalValue, stopEditing }: UserSelectTableEditCellPopoverProps) => {
  const userOptions = useSelector(getSelectUsersOptions);
  const [inputElement, setInputElement] = useState<any>(null);
  const [searchValue, setSearchValue] = useState('');
  const onChangeSearchValue = useCallback((e:any) => setSearchValue(e.target.value ?? ''), []);
  const options = useMemo(() => {
    
    if(searchValue === '') {
      return userOptions;
    }
    return matchSorter(userOptions, searchValue, {keys: ['label']});
  }, [userOptions, searchValue]);
  useEffect(() => {
    if (inputElement !== null) {
      inputElement.focus();
    }
  }, [inputElement]);
  const onSelectOption = useCallback((option:FieldSelectOption|undefined) => {
    if(option === undefined) {
        onChange(undefined);
    } else {
        onChange(option.value);
    }
    stopEditing();
  }, [onChange, stopEditing]);
  const onEscape = useCallback((option:FieldSelectOption|undefined) => {

}, []);
  
  return (
    <div className="cell-popover" style={{ width: 200, minHeight: 200 }}>
      <input
        className="form-control border-0 mb-1 border-bottom"
        placeholder="Find an option"
        ref={setInputElement}
        autoFocus
        value={searchValue}
        onChange={onChangeSearchValue}
      />
      <div className="cell-popover__options">
        <CellPopoverOptionItem option={{ value: '', label: '' }} onEnter={onSelectOption} />
        {options.map((option) => (
          <CellPopoverOptionItem key={option.value} option={option} onEnter={onSelectOption} />
        ))}
      </div>
    </div>
  );
};

export default UserSelectTableEditCellPopover;
