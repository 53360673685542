import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { TextMessage } from '../types';

export const setSelectedPhoneNumber = createAction<string | undefined>('text-messages/setSelectedPhoneNumber');


export const fetchTextMessages = createAsyncThunk<TextMessage[], undefined, { rejectValue: string[] }>(
    'text-messages/fetchTextMessages',
    async () => {
      try {
        const texts = await API.get(
          'TextMessages', // function defined in our serverless.yml
          '',            // the function's path
          { 'responseType': 'json' }
        );
        return texts;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  export const fetchTextMessagesForPhoneNumber = createAsyncThunk<TextMessage[], {phoneNumber:string}, { rejectValue: string[] }>(
    'text-messages/fetchTextMessagesForPhoneNumber',
    async ({phoneNumber}) => {
      try {
        const texts = await API.get(
          'TextMessages', // function defined in our serverless.yml
          `?externalNumber=${encodeURIComponent(phoneNumber)}`,            // the function's path
          { 'responseType': 'json' }
        );
        return texts;
    } catch (error) {
        console.log(error);
      }
    },
  );
  
  export const sendTextMessage = createAsyncThunk<TextMessage, Partial<TextMessage>, { rejectValue: string[] }>(
    'text-messages/sendTextMessage',
    async (textMessage) => {
      try {
        const resp = await API.post(
          'TextMessages', // function defined in our serverless.yml
          '',            // the function's path
          { 'responseType': 'json', body: textMessage }
        );
        return resp;
    } catch (error) {
        console.log(error);
      }
    },
  );
  



export const realTimeTextMessageUpdated = createAction<{ textMessage: TextMessage }>('textMessage.updated');
export const realTimeTextMessageDeleted = createAction<{ textMessage: TextMessage }>('textMessage.deleted');
export const realTimeTextMessageCreated = createAction<{ textMessage: TextMessage }>('textMessage.created');
