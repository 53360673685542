import { debounce as _debounce, get as _get } from 'lodash';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { Field, isFieldFormulaValue } from '../../../data/types';
import { FieldDetailViewComponents } from '../../Fields';

interface NewDealFormInputProps {
  field: Field;
  data: Record<string, any>;
  defaultValue: string|null;
  setFieldValue: (fieldId: string, newValue: any) => void;
  errors: undefined|string[];
}

export const NewDealFormInput = ({ field, data, setFieldValue, errors, defaultValue='', }: NewDealFormInputProps) => {
  const { fieldId } = field;

  const initialValue: string = useMemo(() => _get(data, field.fieldId, defaultValue), [data, defaultValue, field.fieldId]);
  const [internalValue, _setInternalValue] = useState<string>(initialValue);
  const [displayValue, _setDisplayValue] = useState(initialValue);
  const DetailField = useMemo(() => {
    return FieldDetailViewComponents[field.type];
  }, [field]);

  // const debouncedUpdateDealRecord = useMemo(() => {
  //   return _debounce((fieldId: string, value: string) => setFieldValue(fieldId, value), 1000);
  // }, [setFieldValue]);

  const onChange = useCallback(
    (newValue) => {
      _setInternalValue(newValue);
      setFieldValue(fieldId, newValue);
    },
    [fieldId, setFieldValue],
  );

  useEffect(() => {
    _setInternalValue(initialValue);
    if(initialValue !== null && isFieldFormulaValue(initialValue)) {
      _setDisplayValue(initialValue.value);

    } else {
      _setDisplayValue(initialValue);
    }
  }, [initialValue]);

  return (
    <div className="mb-3 row">
      <label className="col-sm-2 col-form-label" htmlFor={`field-${field.fieldId}`}>
        {field.name}
        {/* {field.fieldId} */}
      </label>
      <div className="col-sm-10">
        <DetailField
          // dealId={'new'}
          field={field}
          ogValue={internalValue}
          internalValue={internalValue}
          displayValue={displayValue}
          onChange={onChange}
        />
        {errors && errors.map((error) => (<span className='text-danger'>{error}</span>))}
      </div>
    </div>
  );
};

export default NewDealFormInput;
