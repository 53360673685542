import {keyBy as _keyBy} from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { DealDocument, NewDocumentForm } from '../types';
import { fetchDocuments, sendDocument, fetchDocumentsForDeal, setIsDownloading, setNewDocumentForm, setFieldOnNewDocumentForm, updateNewDocumentForm } from './actions';

interface DocumentsState {
  documents: Record<string, DealDocument>;
  newDocumentForms: Record<string, Record<string, NewDocumentForm>>;
  isLoading: boolean;
  isInitialized: boolean;
  isCreating: boolean;
  isSending:boolean;
  isDownloading:boolean;
}

const initialState: DocumentsState = {
  documents: {},
  newDocumentForms: {},
  isLoading: false,
  isInitialized: false,
  isCreating: false,
  isSending: false,
  isDownloading: false,
};

export const slackSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setIsDownloading, (state, { payload }) => {
      state.isDownloading = payload;
    });
    builder.addCase(setNewDocumentForm, (state, { payload }) => {
      state.newDocumentForms = {
        ...state.newDocumentForms,
        [payload.dealId]: {
          ...state.newDocumentForms[payload.dealId] ?? {},
          [payload.templateId]: payload.form
        }
      }
    });
    builder.addCase(setFieldOnNewDocumentForm, (state, { payload }) => {
      const currDeal = state.newDocumentForms[payload.dealId] ?? {};
      const currTemplate = currDeal[payload.templateId] ?? {};
      state.newDocumentForms = {
        ...state.newDocumentForms,
        [payload.dealId]: {
          ...state.newDocumentForms[payload.dealId] ?? {},
          [payload.templateId]: {...currTemplate,  [payload.key]: payload.value}
        }
      }
    });
    builder.addCase(updateNewDocumentForm, (state, { payload }) => {
      const currDeal = state.newDocumentForms[payload.dealId] ?? {};
      const currTemplate = currDeal[payload.templateId] ?? {};
      state.newDocumentForms = {
        ...state.newDocumentForms,
        [payload.dealId]: {
          ...state.newDocumentForms[payload.dealId] ?? {},
          [payload.templateId]: {...currTemplate,  ...payload.form}
        }
      }
    });

    builder.addCase(fetchDocuments.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocuments.fulfilled, (state, { payload }) => {
      state.documents = _keyBy(payload.documents, 'id');
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchDocuments.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchDocumentsForDeal.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDocumentsForDeal.fulfilled, (state, { payload }) => {
      state.documents = {...state.documents, ..._keyBy(payload.documents, 'id')};
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchDocumentsForDeal.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });

    builder.addCase(sendDocument.pending, (state, { payload }) => {
      state.isSending = true;
    });
    builder.addCase(sendDocument.fulfilled, (state, { payload }) => {
      // state.documents = {...state.documents, [payload.id]: payload}
      state.isSending = false;
    });
    builder.addCase(sendDocument.rejected, (state, { payload }) => {
      state.isSending = false;
    });
    
  },
});

export default slackSlice;
