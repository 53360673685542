import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setSelectedCampaignId } from '../../../../data/campaigns/actions';
import { getSelectedCampaign } from '../../../../data/campaigns/selectors';
import { getCompany } from '../../../../data/company/selectors';
import { fetchMarketingTemplates } from '../../../../data/marketing-services/marketing-templates/actions';
import { getMarketingTemplatesList } from '../../../../data/marketing-services/marketing-templates/selectors';
import { useAppDispatch } from '../../../../data/store';
import CampaignDetailModal from './CampaignDetailModal';

export const CampaignDetailPage = (props: any) => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(true);
  const { campaignId } = useParams<{ campaignId: string; }>();
  const selectedCampaign = useSelector(getSelectedCampaign);
  const templates = useSelector(getMarketingTemplatesList);
  
  const navigate = useNavigate();
  const back = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      navigate(`/marketing/campaigns/`);
      dispatch(setSelectedCampaignId(undefined));
    }, 100);
  }, [navigate, dispatch]);
  useEffect(() => {
    if (selectedCampaign === undefined || selectedCampaign.campaignId !== campaignId) {
      dispatch(setSelectedCampaignId(campaignId));
    }
    dispatch(fetchMarketingTemplates());

  }, [selectedCampaign, campaignId, dispatch]);

  if(selectedCampaign === undefined /* || selectedCampaign.campaignId !== campaignId*/) {
    return (
        <Modal size="xl" fullscreen="sm-down" show={isVisible} onHide={back} keyboard={false}>
          <Modal.Header className="d-sm-none" closeButton>
            <Modal.Title>Loading Campaign</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            Loading...
          </Modal.Body>
        </Modal>
      );
  }

  return (
    <CampaignDetailModal campaign={selectedCampaign} isVisible={isVisible} templates={templates} back={back} />
  );
};

export default CampaignDetailPage;
