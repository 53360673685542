import {
  values as _values,
  orderBy as _orderBy,
  filter as _filter,
  map as _map,
  forEach as _forEach,
  get as _get,
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const getMarketingTemplatesState = (state: RootState) => state.marketingTemplates;

export const getMarketingTemplates = createSelector(getMarketingTemplatesState, (state) => state.marketingTemplates);
export const getMarketingTemplatesIsInitialized = createSelector(
  getMarketingTemplatesState,
  (state) => state.isInitialized,
);
export const getMarketingTemplatesIsLoading = createSelector(getMarketingTemplatesState, (state) => state.isLoading);
export const getMarketingTemplatesIsSaving = createSelector(getMarketingTemplatesState, (state) => state.isSaving);
export const getMarketingTemplatesIsSendingPreview = createSelector(
  getMarketingTemplatesState,
  (state) => state.isSendingPreview,
);

export const getMarketingTemplatesList = createSelector(getMarketingTemplates, (templates) => _values(templates));

export const getSelectedMarketingTemplateId = createSelector(
  getMarketingTemplatesState,
  (state) => state.selectedMarketingTemplateId,
);

export const getSelectedMarketingTemplate = createSelector(
  getMarketingTemplates,
  getSelectedMarketingTemplateId,
  (templates, marketingTemplateId) =>
    marketingTemplateId === undefined ? undefined : _get(templates, marketingTemplateId, undefined),
);
