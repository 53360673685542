import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { move, remove } from '../../../utils/array';
import { findIndex as _findIndex, cloneDeep as _cloneDeep, find as _find } from 'lodash';

import { useSelector } from 'react-redux';
import { getKanbanFormattedDeals } from '../../../data/deals/selectors';
import { Field, FieldNumber, FieldSelect, KanbanView, KanbanViewProperties } from '../../../data/types';
import { useAppDispatch } from '../../../data/store';
import { createField, updateField } from '../../../data/company/fieldActions';
import { updateDealRecordByField } from '../../../data/deals/actions';
import Board, { KanbanColumn } from '../../../components/Views/KanbanBoard/Board';
import GroupingFieldSelect from '../../../components/Views/KanbanBoard/GroupingFieldSelect';
import { updateDealView } from '../../../data/views/actions';
import { getKanbanGroupingFields, getKanbanSummationFields } from '../../../data/company/selectors';


export const DealsKanban = ({ view, fields, isEditingGroupingField, setIsEditingGroupingField }: { view: KanbanView; fields: Record<string, Field>, isEditingGroupingField:boolean, setIsEditingGroupingField:any }) => {
  const dispatch = useAppDispatch();
  const columns = useSelector(getKanbanFormattedDeals);
  const kanbanProps = view.properties as KanbanViewProperties;
  const groupingFields = useSelector(getKanbanGroupingFields);
  const summationFields = useSelector(getKanbanSummationFields);
  
  const groupingFieldId = kanbanProps.groupingFieldId;
  const summationFieldId = kanbanProps.summationFieldId;
  const summationField = useMemo(() => {
    return _find(fields, { fieldId: summationFieldId }) as FieldNumber|undefined;
  }, [summationFieldId, fields]);
  const groupingField = useMemo(() => {
    return _find(fields, { fieldId: groupingFieldId }) as FieldSelect|undefined;
  }, [groupingFieldId, fields]);

  const [state, setState] = useState<{ columns: KanbanColumn[] }>({
    columns: [],
  });
  useEffect(() => {
    setState({ columns });
  }, [columns]);
  const moveCard = useCallback((cardId: string, fromColumnId: string, toColumnId: string) => {
    setState((oldState) => {
      const columns = oldState.columns;
      const fromColumnIndex = _findIndex(columns, { id: fromColumnId });
      const toColumnIndex = _findIndex(columns, { id: toColumnId });
      if (fromColumnIndex === -1 || toColumnIndex === -1) {
        return oldState;
      }
      const fromColumn = columns[fromColumnIndex];
      const toColumn = columns[toColumnIndex];
      const cardIndex = _findIndex(fromColumn.cards, { id: cardId });
      const checkDuplicateCard = _findIndex(toColumn.cards, { id: cardId });
      if (cardIndex === -1 || checkDuplicateCard !== -1) {
        return oldState;
      }
      const card = fromColumn.cards[cardIndex];
      const newFromColumnCards = remove(fromColumn.cards, cardIndex);
      const updatedFromColumn = { ...fromColumn, cards: newFromColumnCards };
      const updatedToColumnCards = [...toColumn.cards, card];
      const updatedToColumn = { ...toColumn, cards: updatedToColumnCards };
      const newState = _cloneDeep(oldState);
      newState.columns[fromColumnIndex] = updatedFromColumn;
      newState.columns[toColumnIndex] = updatedToColumn;
      return newState;
    });
  }, []);
  const addCard = useCallback((columnId: string) => {}, [state]);
  const addColumn = useCallback((title: string) => {}, [state]);
  const moveColumn = useCallback((fromIndex: number, toIndex: number) => {
    if (fromIndex === 0 || toIndex === 0) {
      return;
    }
    setState((oldState) => {
      const newColumns = move(oldState.columns, fromIndex, toIndex);
      return {
        ...oldState,
        columns: newColumns,
      };
    });
  }, []);
  const onDropColumn = useCallback(
    (columnId: string, toIndex: number) => {
      if (columnId === 'Uncategorized' || groupingField === undefined) {
        return;
      }
      const newOptions = state.columns
        .map((column) => {
          return {
            value: column.id,
            label: column.title,
          };
        })
        .filter((option) => option.value !== 'Uncategorized');
      dispatch(
        updateField({
          fieldId: groupingField.fieldId,
          name: groupingField.name,
          type: groupingField.type,
          config: { ...groupingField.config, options: newOptions },
        }),
      );
    },
    [dispatch, groupingField, state.columns],
  );
  const onDropCard = useCallback(
    (cardId: string, columnId: string) => {
      if(groupingField === undefined) {
        return;
      }
      dispatch(updateDealRecordByField({ dealId: cardId, fieldId: groupingField.fieldId, value: columnId }));
    },
    [dispatch, groupingField],
  );
  const updateGroupingField = useCallback(
    (newGroupingFieldId: string, newSummationFieldId?: string) => {
      const updatedView: KanbanView = {
        ...view,
        properties: {
          ...view.properties,
          groupingFieldId: newGroupingFieldId,
          summationFieldId: newSummationFieldId
        },
      };
      dispatch(updateDealView(updatedView));
      setIsEditingGroupingField(false);
    },
    [dispatch, setIsEditingGroupingField, view],
  );

  const createSelectField = useCallback(
    (name: string) => {
      dispatch(createField({ name, type: 'select', config: {options: [], defaultValue: null} }));
    },
    [dispatch],
  );


  if (groupingField === undefined || isEditingGroupingField) {
    return (
      <div className="h-100 w-100 kanban-wrapper d-flex flex-column justify-content-center align-items-center">
        <GroupingFieldSelect
          createSelectField={createSelectField}
          groupingFieldId={groupingFieldId}
          summationFieldId={summationFieldId}
          updateGroupingField={updateGroupingField}
          groupingFields={groupingFields}
          summationFields={summationFields}
          setIsEditingGroupingField={setIsEditingGroupingField}
        />
      </div>
    );
  }


  return (
    <Board
      columns={state.columns}
      onDropCard={onDropCard}
      onDropColumn={onDropColumn}
      moveCard={moveCard}
      addCard={addCard}
      moveColumn={moveColumn}
      addColumn={addColumn}
    />
  );
};

export default DealsKanban;
