import { keyBy as _keyBy, countBy as _countBy } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import {
  clearNewDealFormResp,
  createDealRecord,
  createDealWithForm,
  deleteDealRecord,
  fetchDealRecords,
  realTimeDealCreated,
  realTimeDealDeleted,
  realTimeDealUpdated,
  setDealsSearchTerm,
  setSelectedDealId,
  updateDealRecord,
  updateDealRecordByField,
  validateDealWithForm,
} from './actions';
import { DealRecord, FieldValue, isValidateDataResponse } from '../types';

export interface DealNote {
  noteId: string;
  userId: string;
  note: string;
  createdDT: string;
  updatedDT: string;
}
export interface DealActivity {
  eventId: string;
  eventType: string;
  eventDescription: string;
  eventObjectId: any;
}

interface DealsState {
  deals: Record<string, DealRecord>;
  dealsSearchTerm: string;
  primaryFieldValueCounts: Record<string, number>;
  isLoading: boolean;
  isInitialized: boolean;
  isSaving: boolean;
  selectedDealId?: string;

  isValidating: boolean;
  isCreating: boolean;
  dealFormErrors: Record<string, string[]>;
  dealFormCleanedData: Record<string, FieldValue>;
}

const initialState: DealsState = {
  deals: {},
  dealsSearchTerm: '',
  primaryFieldValueCounts: {},
  isLoading: false,
  isSaving: false,
  isInitialized: false,

  isCreating: false,
  isValidating: false,
  dealFormErrors: {},
  dealFormCleanedData: {},
};

export const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDealsSearchTerm, (state, { payload }) => {
      state.dealsSearchTerm = payload ?? '';
    });
    builder.addCase(setSelectedDealId, (state, { payload }) => {
      state.selectedDealId = payload;
    });
    builder.addCase(clearNewDealFormResp, (state, { payload }) => {
      state.isValidating = false;
      state.isCreating = false;
      state.dealFormCleanedData = {};
      state.dealFormErrors = {};
    });
    builder.addCase(createDealRecord.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(createDealRecord.fulfilled, (state, { payload }: { payload: DealRecord }) => {
      state.deals = { ...state.deals, [payload.dealId]: payload };
      state.primaryFieldValueCounts = _countBy(state.deals, 'primaryFieldValue');
      state.isLoading = false;
    });
    builder.addCase(createDealRecord.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    // validate with deal form
    builder.addCase(validateDealWithForm.pending, (state, { payload }) => {
      state.isValidating = true;
    });
    builder.addCase(validateDealWithForm.fulfilled, (state, { payload }) => {
      const { errors, cleanedData } = payload;

      state.isValidating = false;
      state.dealFormCleanedData = { ...cleanedData };
      state.dealFormErrors = { ...errors };
    });
    builder.addCase(validateDealWithForm.rejected, (state, { payload }) => {
      state.isValidating = false;
    });

    builder.addCase(createDealWithForm.pending, (state, { payload }) => {
      state.isValidating = true;
    });
    builder.addCase(createDealWithForm.fulfilled, (state, { payload }) => {
      if (isValidateDataResponse(payload)) {
        const { errors, cleanedData } = payload;
        state.isCreating = false;
        state.dealFormCleanedData = { ...cleanedData };
        state.dealFormErrors = { ...errors };
      } else {
        state.isCreating = false;
        state.deals = { ...state.deals, [payload.dealId]: payload };
        state.primaryFieldValueCounts = _countBy(state.deals, 'primaryFieldValue');
        state.selectedDealId = payload.dealId;
      }
    });
    builder.addCase(createDealWithForm.rejected, (state, { payload }) => {
      state.isValidating = false;
    });

    builder.addCase(fetchDealRecords.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDealRecords.fulfilled, (state, { payload }: { payload: DealRecord[] }) => {
      state.deals = { ...state.deals, ..._keyBy(payload, 'dealId') };
      state.primaryFieldValueCounts = _countBy(state.deals, 'primaryFieldValue');
      state.isLoading = false;
    });
    builder.addCase(fetchDealRecords.rejected, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(updateDealRecordByField.pending, (state, { payload }) => {
      state.isSaving = true;
    });
    builder.addCase(updateDealRecordByField.fulfilled, (state, { payload }: { payload: DealRecord }) => {
      state.deals = { ...state.deals, [payload.dealId]: payload };
      state.primaryFieldValueCounts = _countBy(state.deals, 'primaryFieldValue');
      state.isSaving = false;
    });
    builder.addCase(updateDealRecordByField.rejected, (state, { payload }) => {
      state.isSaving = false;
    });
    builder.addCase(updateDealRecord.pending, (state, { payload }) => {
      state.isLoading = true;
      state.isSaving = true;
    });
    builder.addCase(updateDealRecord.fulfilled, (state, { payload }: { payload: DealRecord }) => {
      state.deals = { ...state.deals, [payload.dealId]: payload };
      state.primaryFieldValueCounts = _countBy(state.deals, 'primaryFieldValue');
      state.isLoading = false;
      state.isSaving = false;
    });
    builder.addCase(updateDealRecord.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isSaving = false;
    });
    builder.addCase(deleteDealRecord.pending, (state, { payload }) => {
      state.isLoading = true;
      state.isSaving = true;

    });
    builder.addCase(deleteDealRecord.fulfilled, (state, { payload }: { payload: DealRecord }) => {
      const { [payload.dealId]: deletedRecord, ...remainingRecords } = state.deals;
      state.deals = remainingRecords;
      state.primaryFieldValueCounts = _countBy(state.deals, 'primaryFieldValue');
      state.isLoading = false;
      state.isSaving = false;

    });
    builder.addCase(deleteDealRecord.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isSaving = false;

    });
    builder.addCase(realTimeDealUpdated, (state, { payload }) => {
      const { deal: updatedDeal } = payload;
      const { [updatedDeal.dealId]: currentDeal } = current(state).deals;
      if (
        currentDeal === undefined ||
        currentDeal.updatedDT === undefined ||
        currentDeal.updatedDT < updatedDeal.updatedDT
      ) {
        state.deals[updatedDeal.dealId] = updatedDeal;
      }
    });
    builder.addCase(realTimeDealCreated, (state, { payload }) => {
      const { deal } = payload;
      state.deals[deal.dealId] = deal;
    });
    builder.addCase(realTimeDealDeleted, (state, { payload }) => {
      const { deal } = payload;
      delete state.deals[deal.dealId];
    });
  },
});

export default dealsSlice;
