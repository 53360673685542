import React, { useCallback, useMemo, useState } from 'react';
import Popover, { PopoverProps } from 'react-popover';
import { useSelector } from 'react-redux';
import { KanbanView, TableView } from '../../../../data/types';
import { getAllFieldsList } from '../../../../data/company/selectors';
import { useAppDispatch } from '../../../../data/store';
import { move, remove, replace } from '../../../../utils/array';
import ViewSortPopover from './ViewSortPopover';

import './ViewSort.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import useToolbarPopover from '../useToolbarPopover/useToolbarPopover';
import { updateDealView } from '../../../../data/views/actions';
import { getSelectedView } from '../../../../data/views/selectors';

export const ViewSort = (props: any) => {
  const dispatch = useAppDispatch();
  const [isChildPopoverOpen, setIsChildPopoverOpen] = useState(false);

  const view = useSelector(getSelectedView) as KanbanView | TableView;
  const allFields = useSelector(getAllFieldsList);
  const addSortingField = useCallback(
    (fieldId: string, order: 'asc' | 'desc' | undefined = 'asc') => {
      const [fields, ordering] = view.properties.orderBy || [[], []];
      const newFields = [...fields, fieldId];
      const newOrdering = [...ordering, order];
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          orderBy: [newFields, newOrdering],
        },
      } as KanbanView | TableView;
      dispatch(updateDealView(updatedView));
    },
    [dispatch, view],
  );
  const removeSortingField = useCallback(
    (index: number) => {
      const [fields, ordering] = view.properties.orderBy || [[], []];
      const newFields = remove(fields, index);
      const newOrdering = remove(ordering, index);
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          orderBy: [newFields, newOrdering],
        },
      } as KanbanView | TableView;
      dispatch(updateDealView(updatedView));
    },
    [dispatch, view],
  );
  const updateSortingField = useCallback(
    (newField: string, newOrder: 'asc' | 'desc', index: number) => {
      const [fields, ordering] = view.properties.orderBy || [[], []];
      const newFields = replace(fields, newField, index);
      const newOrdering = replace(ordering, newOrder, index);
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          orderBy: [newFields, newOrdering],
        },
      } as KanbanView | TableView;
      dispatch(updateDealView(updatedView));
    },
    [dispatch, view],
  );
  const moveSortingField = useCallback(
    (fromIndex: number, toIndex) => {
      const [fields, ordering] = view.properties.orderBy || [[], []];
      const newFields = move(fields, fromIndex, toIndex);
      const newOrdering = replace(ordering, fromIndex, toIndex);
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          orderBy: [newFields, newOrdering],
        },
      } as KanbanView | TableView;
      dispatch(updateDealView(updatedView));
    },
    [dispatch, view],
  );
  const { buttonProps, ToolbarPopover, popoverProps } = useToolbarPopover({ closeOnOutsideClick: !isChildPopoverOpen });

  return (
    <>
      <div tabIndex={0} className="deals-toolbar__item" {...buttonProps}>
        <FontAwesomeIcon className="me-2" icon={faSort} />
        Sort
      </div>
      <ToolbarPopover {...popoverProps}>
        <ViewSortPopover
          view={view}
          onChildPopover={setIsChildPopoverOpen}
          allFields={allFields}
          addSortingField={addSortingField}
          updateSortingField={updateSortingField}
          removeSortingField={removeSortingField}
        />
      </ToolbarPopover>
    </>
  );
};

export default ViewSort;
