import { DailyScheduledFilterAutomation, DailyScheduledViewAutomation, WeeklyScheduledFilterAutomation, WeeklyScheduledViewAutomation, MonthlyScheduledFilterAutomation, MonthlyScheduledViewAutomation, RecordAutomation } from '@taida-corp/taidacorp-sdk';
import { cloneDeep as _cloneDeep } from 'lodash';
import { useCallback } from 'react';

interface TimeOfDayInputProps {
  form:
    | DailyScheduledFilterAutomation
    | DailyScheduledViewAutomation
    | WeeklyScheduledFilterAutomation
    | WeeklyScheduledViewAutomation
    | MonthlyScheduledFilterAutomation
    | MonthlyScheduledViewAutomation;
    updateAutomation: (updatedRecordAutomation:RecordAutomation) => void;
  }

export const TimeOfDayInput = ({ form, updateAutomation }: TimeOfDayInputProps) => {
  const onChangeTimeOfDay = useCallback(
    (e) => {
      const updated = _cloneDeep(form) as MonthlyScheduledViewAutomation;
      updated.triggerConfig.scheduleOptions.timeOfDay = e.target.value;
    updateAutomation(updated)
    },
    [form, updateAutomation],
  );

  return (
    <div className="mb-3">
      <label htmlFor="timeOfDay" className="form-label">
        Time of Day
      </label>
      <select
        onChange={onChangeTimeOfDay}
        value={form.triggerConfig.scheduleOptions.timeOfDay}
        className="form-control"
        id="timeOfDay"
        aria-describedby="timeOfDayHelp"
      >
        <option value="midnight">Midnight</option>
        <option value="1am">1am</option>
        <option value="2am">2am</option>
        <option value="3am">3am</option>
        <option value="4am">4am</option>
        <option value="5am">5am</option>
        <option value="6am">6am</option>
        <option value="7am">7am</option>
        <option value="8am">8am</option>
        <option value="9am">9am</option>
        <option value="10am">10am</option>
        <option value="11am">11am</option>
        <option value="noon">Noon</option>
        <option value="1pm">1pm</option>
        <option value="2pm">2pm</option>
        <option value="3pm">3pm</option>
        <option value="4pm">4pm</option>
        <option value="5pm">5pm</option>
        <option value="6pm">6pm</option>
        <option value="7pm">7pm</option>
        <option value="8pm">8pm</option>
        <option value="9pm">9pm</option>
        <option value="10pm">10pm</option>
        <option value="11pm">11pm</option>
      </select>
      <div id="timeOfDayHelp" className="form-text"></div>
    </div>
  );
};

export default TimeOfDayInput;
