import {map as _map, result, values as _values} from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DashboardModal } from '@uppy/react';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import Uppy from '@uppy/core';
import { Portal } from '@headlessui/react';

import { TableCellFieldProps } from '..';
import { Attachment, AudioAttachment, FieldAttachment, FieldAttachmentValue, FileAttachment, ImageAttachment, VideoAttachment } from '../../../data/types';
import useFileUploader from '../../../hooks/useFileUploaderOld/useFileUploader';
import { Assembly, Result } from '@uppy/transloadit';

interface AttachmentTableEditCellProps extends TableCellFieldProps {
  field: FieldAttachment;
  onChange: (newValue:FieldAttachmentValue) => void;
}

export const AttachmentTableEditCell = ({
  internalValue,
  onChange,
  field,
  stopEditing,
  ...inputProps
}: AttachmentTableEditCellProps) => {
  const config = field.config;
  
  const onChangeEvent = useCallback(
    (value:Attachment[]) => {
      if(field.config.maxNumberOfFiles === 1 && value.length > 0) {
        // value = value.pop() as Attachment;
      }
      const newAttachmentValue:FieldAttachmentValue = {
        value,
        error: undefined,
      }
      onChange(newAttachmentValue);
      stopEditing();
    },
    [field.config.maxNumberOfFiles, onChange, stopEditing],
  );

  const uppy = useFileUploader(config, onChangeEvent);

  return uppy !== null ? (
    <Portal>
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        open={true}
        onRequestClose={() => stopEditing()}
        plugins={['Webcam', 'Unsplash', 'Instagram', 'ScreenCapture', 'Audio', 'Zoom', 'Url']}
      />
    </Portal>
  ) : (
    <div>Initing uploader...</div>
  );
};

export default AttachmentTableEditCell;
