import { find as _find } from 'lodash';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import React, { useCallback, useEffect, useState } from 'react';
import { DealView, Field, FieldAttachment, GalleryView, KanbanView, TableView, TableViewField, ViewField } from '../../../../data/types';
import { FieldsToggle } from './FieldsToggle';
import { useAppDispatch } from '../../../../data/store';
import { move } from '../../../../utils/array';
import { updateDealView } from '../../../../data/views/actions';
import { KanbanCardField } from '../../../../components/Views/KanbanBoard/Board';

type ToggleableField = Field & {
  isVisible: boolean;
  isDisabled: boolean;
};

interface CardHeaderSelectProps {
  fields: FieldAttachment[];
  view: GalleryView | KanbanView;
}

export const CardHeaderSelect = ({ fields, view }: CardHeaderSelectProps) => {
  const [selectedField, setSelectedField] = useState(view.properties.cardCoverFieldId !== null ? view.properties.cardCoverFieldId : undefined);
  const dispatch = useAppDispatch();

  const onChangeField = useCallback(
    (e) => {
      setSelectedField(e.target.value);
      const newValue = e.target.value ?? null;
      const updatedView = {
        ...view,
        properties: {
          ...view.properties,
          cardCoverFieldId: newValue,
        },
      } as DealView;
      dispatch(updateDealView(updatedView));
    },
    [dispatch, view],
  );

  return (
   <select onChange={onChangeField} value={selectedField}>
     <option>--</option>
     {fields.map((field) => (
       <option key={field.fieldId} value={field.fieldId}>{field.name}</option>
     ))}
   </select>
  );
};

export default CardHeaderSelect;
