import {
    faArrowCircleDown,
    faCaretDown,
    faChevronCircleDown,
    faPlusCircle,
    faUser,
  } from '@fortawesome/free-solid-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import React, { useCallback, useState } from 'react';
  import { useSelector } from 'react-redux';
import { FieldNumber, FieldSelect, FieldSelectUser } from '../../../data/types';
  
  interface GroupingFieldSelectProps {
    summationFieldId: string | undefined;
    groupingFieldId: string | undefined;
    groupingFields: (FieldSelect|FieldSelectUser)[];
    summationFields: FieldNumber[];
    createSelectField: (name: string) => void;
    updateGroupingField: (fieldId: string, newSummationFieldId?:string) => void;
    setIsEditingGroupingField:any;
  }
  export const GroupingFieldSelect = (props: GroupingFieldSelectProps) => {
    const { summationFieldId, groupingFields, summationFields, groupingFieldId, updateGroupingField, createSelectField, setIsEditingGroupingField } = props;
    const [internalGroupingFieldId, setInternalGroupingFieldId] = useState<string | undefined>(groupingFieldId);
    const [internalSummationFieldId, setInternalSummationFieldId] = useState<string | undefined>(summationFieldId);
    const onSubmit = useCallback((e) => {
      e.preventDefault();
        if(internalGroupingFieldId !== undefined) {
          updateGroupingField(internalGroupingFieldId, internalSummationFieldId);
      }
    }, [internalGroupingFieldId, updateGroupingField, internalSummationFieldId]);
    const onChange = useCallback((e: any) => {
      const newValue = e.target.value;
      setInternalGroupingFieldId(newValue);
    }, []);
    const onChangeSummationField = useCallback((e: any) => {
      const newValue = e.target.value;
      setInternalSummationFieldId(newValue);
    }, []);
  
    return (
      <div className="card">
        <div className="card-header">Configure Board</div>
        <div className="card-body">
          <p>
            Which single select or user field would you like to use for this kanban view? Your deals will be
            stacked based on this field.
          </p>
          <form onSubmit={onSubmit}>
            {groupingFields.map((field) => {
              return (
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="grouping-field"
                    id={`field-${field.fieldId}`}
                    value={field.fieldId}
                    checked={internalGroupingFieldId === field.fieldId}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor={`field-${field.fieldId}`}>
                    {field.name}
                  </label>
                </div>
              );
            })}
  
            <div className="form-group mb-3">
              <button className="btn btn-link text-black ps-0" type="button">
                <FontAwesomeIcon className="me-2" icon={faChevronCircleDown} /> Create new Select field
              </button>
            </div>
            <div className="form-group mb-3">
              <button className="btn btn-link text-black ps-0" type="button">
                <FontAwesomeIcon className="me-2" icon={faUser} /> Create new User field
              </button>
            </div>
            <hr/>
            <p>
            Summation field for top of each column
          </p>
            {summationFields.map((field) => {
              return (
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="summation-field"
                    id={`field-${field.fieldId}`}
                    value={field.fieldId}
                    checked={internalSummationFieldId === field.fieldId}
                    onChange={onChangeSummationField}
                  />
                  <label className="form-check-label" htmlFor={`field-${field.fieldId}`}>
                    {field.name}
                  </label>
                </div>
              );
            })}
            <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="summation-field"
                    id={`field-und`}
                    value={undefined}
                    checked={internalSummationFieldId === undefined}
                    onChange={onChangeSummationField}
                  />
                  <label className="form-check-label" htmlFor={`field-und`}>
                    No Summation Field
                  </label>
                </div>
            <div className="d-flex flex-row justify-content-end">
             {groupingFieldId !== undefined &&  <button type='button' onClick={() => setIsEditingGroupingField(false)} className='btn btn-link'>Cancel</button>}
              <button className="btn btn-primary" type="submit" disabled={internalGroupingFieldId === undefined}>
                Done
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  export default GroupingFieldSelect;
  