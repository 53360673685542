import {
  values as _values,
  orderBy as _orderBy,
  filter as _filter,
  map as _map,
  keyBy as _keyBy,
  sortBy as _sortBy,
  findIndex as _findIndex,
  find as _find,
  get as _get
} from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Field, FormView } from '@taida-corp/taidacorp-sdk';
import { getViewsListForContentType } from '../record-views/selectors';

export const getContentTypeState = (state: RootState) => state.contentTypes;
export const getSelectedContentTypeString = (state: RootState) => state.contentTypes.selectedContentType;
export const getIsInitialized = (state: RootState) => state.contentTypes.isInitialized;
export const getIsLoading = (state: RootState) => state.contentTypes.isLoading;
export const getIsUpdating = (state: RootState) => state.contentTypes.isUpdating;
export const getContentTypes = (state: RootState) => state.contentTypes.contentTypes;
export const getContentTypesList = createSelector(getContentTypes, (contentTypes) => _values(contentTypes));

export const getSelectedContentType = createSelector(getContentTypes, getSelectedContentTypeString, (contentTypes, selectedContentTypeString) => {
  console.log('selectedContentTypeString', selectedContentTypeString);
  return selectedContentTypeString !== undefined ? _get(contentTypes, selectedContentTypeString) : undefined;
});

export const getContentTypeFields = createSelector(getSelectedContentType, (contentType):Field[] => {
  console.log('contentType', contentType);
  if(contentType === undefined) {
    return [];
  }
  return contentType.fields;
});

export const getContentTypeFieldsObject = createSelector(getContentTypeFields, (fields):Record<string, Field> => {
  return _keyBy(fields, 'fieldId');
});
