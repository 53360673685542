import React, { useCallback, useEffect, useState } from 'react';
import { FieldText, FieldDateConfig } from '../../../data/types';

interface DatetimeConfigEditorProps {
  field: FieldText;
  internalConfig:FieldDateConfig;
  onChangeConfig: (e: any) => void;
}

export const DatetimeConfigEditor = ({field, internalConfig:config, onChangeConfig}: DatetimeConfigEditorProps) => {
  const [internalDefaultValue, setInternalDefaultValue] = useState('');
  const [internalGoogleCalId, setInternalGoogleCalId] = useState('');
  
  const onChangeDefaultValue = useCallback((e:any) => {
    const newVal = e.target.value;
    onChangeConfig({
      defaultValue: newVal,
      googleCalendarId: internalGoogleCalId
    });
    setInternalDefaultValue(newVal);
  }, [internalGoogleCalId, onChangeConfig]);
  const onChangeGoogleCalIdValue = useCallback((e:any) => {
    const newVal = e.target.value;
    onChangeConfig({
      defaultValue: internalDefaultValue,
      googleCalendarId: newVal,
    });
    setInternalGoogleCalId(newVal);
  }, [internalDefaultValue, onChangeConfig]);
  useEffect(() => {
    if(config.defaultValue !== null && config.defaultValue !== undefined) {
      setInternalDefaultValue(config.defaultValue);
    }
    if(config.googleCalendarId !== null && config.googleCalendarId !== undefined) {
      setInternalGoogleCalId(config.googleCalendarId);
    }
    
  }, [config.googleCalendarId, config.defaultValue, config]);

  return (
    <div>
    <input type="text" className="form-control mb-2" placeholder="Default Value" value={internalDefaultValue} onChange={onChangeDefaultValue} />
    <input type="text" className="form-control" placeholder="Google Cal ID" value={internalGoogleCalId} onChange={onChangeGoogleCalIdValue} />
    </div>
  );
};

export default DatetimeConfigEditor;
