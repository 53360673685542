import { keyBy as _keyBy, countBy as _countBy, values as _values, mapValues as _mapValues } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';
import {
  clearNewRecordFormResp,
  createDataRecord,
  createRecordWithForm,
  deleteDataRecord,
  fetchDataRecords,
  realTimeRecordCreated,
  realTimeRecordDeleted,
  realTimeRecordUpdated,
  setRecordFormData,
  setRecordFormFieldValue,
  setRecordsSearchTerm,
  setSelectedRecordId,
  updateDataRecord,
  updateDataRecordByField,
  updateRecordWithForm,
  validateRecordWithForm,
} from './actions';
import { DataRecord, FieldValue, PersistedFieldValue } from '@taida-corp/taidacorp-sdk';
import { ValidateDataResponse } from '../../types';

interface RecordsState {
  records: Record<string, Record<string, DataRecord>>;
  selectedRecordId: string | undefined;
  recordsSearchTerm: string;
  primaryFieldValueCounts: Record<string, number>;
  isLoading: Record<string, boolean>;
  isInitialized: Record<string, boolean>;
  isSaving: boolean;

  isValidating: boolean;
  isCreating: boolean;

  recordFormData: Record<string, PersistedFieldValue|undefined>;
  generalFormErrors: undefined | string[]
}

const initialState: RecordsState = {
  records: {},
  selectedRecordId: undefined,
  recordsSearchTerm: '',
  primaryFieldValueCounts: {},
  isLoading: {},
  isInitialized: {},
  isSaving: false,

  isCreating: false,
  isValidating: false,
  recordFormData: {},
  generalFormErrors: undefined,
};

export const recordsSlice = createSlice({
  name: 'records',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setRecordFormData, (state, { payload }) => {
      state.recordFormData = payload ?? {};
    });
    builder.addCase(setRecordFormFieldValue, (state, { payload }) => {
      state.recordFormData[payload.fieldId] = payload.value;
    });
    builder.addCase(setRecordsSearchTerm, (state, { payload }) => {
      state.recordsSearchTerm = payload ?? '';
    });
    builder.addCase(setSelectedRecordId, (state, { payload }) => {
      state.selectedRecordId = payload;
    });
    builder.addCase(clearNewRecordFormResp, (state, { payload }) => {
      state.isValidating = false;
      state.isCreating = false;
      state.recordFormData = {};
    });
    builder.addCase(createDataRecord.pending, (state, { payload }) => {
      state.isCreating = true;
    });
    builder.addCase(createDataRecord.fulfilled, (state, { payload, meta }) => {
      state.records[meta.arg.contentTypeSlug] = { ...state.records[meta.arg.contentTypeSlug], [payload.id]: payload };
      state.primaryFieldValueCounts = _countBy(_values(state.records), 'primaryFieldValue');
      state.isCreating = false;
    });
    builder.addCase(createDataRecord.rejected, (state, { payload }) => {
      state.isCreating = false;
    });

    // validate with record form
    builder.addCase(validateRecordWithForm.pending, (state, { payload }) => {
      state.isValidating = true;
      state.generalFormErrors = undefined;
    });
    builder.addCase(validateRecordWithForm.fulfilled, (state, { payload }) => {
      state.isValidating = false;
      state.recordFormData = payload.cleanedData!;
    });
    builder.addCase(validateRecordWithForm.rejected, (state, { payload }) => {
      state.isValidating = false;
      if(payload && !!payload.cleanedData) {
        state.recordFormData = payload.cleanedData!;
      }
      if(payload && !!payload.errors) {
        state.generalFormErrors = payload.errors._general;
      }
    });

    builder.addCase(createRecordWithForm.pending, (state, { payload }) => {
      state.isValidating = true;
      state.generalFormErrors = undefined;
    });
    builder.addCase(createRecordWithForm.fulfilled, (state, { payload, meta }) => {
      state.isCreating = false;
      state.records[meta.arg.contentTypeSlug] = { ...state.records[meta.arg.contentTypeSlug], [payload.record!.id]: payload.record! };
      state.primaryFieldValueCounts = _countBy(_values(state.records), 'primaryFieldValue');
      state.recordFormData = payload.record!.fields;
      state.selectedRecordId = payload.record!.id;
    });
    builder.addCase(createRecordWithForm.rejected, (state, { payload }) => {
      state.isValidating = false;
      state.isCreating = false;

      if(payload && !!payload.cleanedData) {
        state.recordFormData = payload.cleanedData!;
      }
      if(payload && !!payload.errors) {
        state.generalFormErrors = payload.errors._general;
      }
    });

    builder.addCase(updateRecordWithForm.pending, (state, { payload }) => {
      state.isSaving = true;
      state.generalFormErrors = undefined;
    });
    builder.addCase(updateRecordWithForm.fulfilled, (state, { payload, meta }) => {
      state.isSaving = false;
      state.records[meta.arg.contentTypeSlug] = { ...state.records[meta.arg.contentTypeSlug], [payload.record!.id]: payload.record! };
      state.primaryFieldValueCounts = _countBy(_values(state.records), 'primaryFieldValue');
      state.recordFormData = payload.record!.fields;
    });
    builder.addCase(updateRecordWithForm.rejected, (state, { payload}) => {
      state.isSaving = false;
      console.log('inrejected payload', payload);
      if(payload && !!payload.cleanedData) {
        state.recordFormData = payload.cleanedData!;
      }
      if(payload && !!payload.errors) {
        state.generalFormErrors = payload.errors._general;
      }
    });

    builder.addCase(fetchDataRecords.pending, (state, { payload, meta }) => {
      state.isLoading[meta.arg.contentTypeSlug] = true;
    });
    builder.addCase(fetchDataRecords.fulfilled, (state, { payload, meta }) => {
      state.records[meta.arg.contentTypeSlug] = { ...state.records[meta.arg.contentTypeSlug], ..._keyBy(payload, 'id') };
      state.primaryFieldValueCounts = _countBy(_values(state.records), 'primaryFieldValue');
      state.isLoading[meta.arg.contentTypeSlug] = false;
    });
    builder.addCase(fetchDataRecords.rejected, (state, { payload, meta }) => {
      state.isLoading[meta.arg.contentTypeSlug] = false;
    });
    builder.addCase(updateDataRecordByField.pending, (state, { payload }) => {
      state.isSaving = true;
    });
    builder.addCase(updateDataRecordByField.fulfilled, (state, { payload, meta }) => {
      state.records[meta.arg.contentTypeSlug] = { ...state.records[meta.arg.contentTypeSlug], [payload.id]: payload };
      state.primaryFieldValueCounts = _countBy(_values(state.records), 'primaryFieldValue');
      state.isSaving = false;
      state.recordFormData = payload.fields;
    });
    builder.addCase(updateDataRecordByField.rejected, (state, { payload }) => {
      state.isSaving = false;
    });
    builder.addCase(updateDataRecord.pending, (state, { payload, meta }) => {
      state.isSaving = true;
    });
    builder.addCase(updateDataRecord.fulfilled, (state, { payload, meta }) => {
      state.records[meta.arg.contentTypeSlug] = { ...state.records[meta.arg.contentTypeSlug], [payload.id]: payload };
      state.primaryFieldValueCounts = _countBy(_values(state.records), 'primaryFieldValue');
      state.isSaving = false;
    });
    builder.addCase(updateDataRecord.rejected, (state, { payload }) => {
      state.isSaving = false;
    });
    builder.addCase(deleteDataRecord.pending, (state, { payload }) => {
      state.isSaving = true;
    });
    builder.addCase(deleteDataRecord.fulfilled, (state, { meta }) => {
      delete state.records[meta.arg.contentTypeSlug][meta.arg.recordId];
      state.primaryFieldValueCounts = _countBy(_values(state.records), 'primaryFieldValue');
      state.isSaving = false;
    });
    builder.addCase(deleteDataRecord.rejected, (state, { payload }) => {
      state.isSaving = false;
    });
    builder.addCase(realTimeRecordUpdated, (state, { payload }) => {
      const { record: updatedRecord } = payload;
      const { [updatedRecord.id]: currentRecord } = current(state).records[payload.record.contentType];
      if (
        currentRecord === undefined ||
        currentRecord.updatedDT === undefined ||
        currentRecord.updatedDT < updatedRecord.updatedDT
      ) {
        state.records[updatedRecord.contentType][updatedRecord.id] = updatedRecord;
      }
    });
    builder.addCase(realTimeRecordCreated, (state, { payload }) => {
      const { record } = payload;
      state.records[record.contentType][record.id] = record;
    });
    builder.addCase(realTimeRecordDeleted, (state, { payload }) => {
      const { record } = payload;
      delete state.records[record.contentType][record.id];
    });
  },
});

export default recordsSlice;
