import { faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import {
  Field,
  FilterCondition,
  FilterConditionGroup,
  FILTER_EXPRESSION,
  isFilterCondition,
  isFilterConditionGroup,
  OPERATOR_OPTIONS,
} from '../../../../data/types';
import ChangeFilterOperator from './ChangeFilterOperator';
import FilterFieldConditionGroupRow from './FilterFieldConditionGroupRow';
import FilterFieldConditionRow from './FilterFieldConditionRow';

interface FilterFieldConditionProps {
  condition: FilterCondition | FilterConditionGroup;
  index: number;

  updateFilteringField: (
    newField: string,
    newExpression: FILTER_EXPRESSION,
    newOption: string | undefined,
    newCompareTo: any,
    index: number,
  ) => void;
  removeFilteringField: (index: number) => void;
  updateOperator: (newOperator: OPERATOR_OPTIONS) => void;
  updateFilterCondition: (newFilters: FilterConditionGroup) => void;
  operator: OPERATOR_OPTIONS;
  fields: Field[];
  onChildPopover: Function;
}

export const FilterFieldCondition = ({
  condition,
  index,
  updateFilteringField,
  removeFilteringField,
  updateOperator,
  updateFilterCondition,
  operator,
  fields,
  onChildPopover,
}: FilterFieldConditionProps) => {
  return (
    <>
      <div
        className={classNames({
          'p-1 mb-1 d-flex flex-row': true,
          'align-items-center': !isFilterConditionGroup(condition),
        })}
      >
        <div style={{ minWidth: 80 }}>
          <div className="filter-field-row-lead me-2">
            {index === 0 ? (
              'Where'
            ) : (
              <ChangeFilterOperator operator={operator} updateOperator={updateOperator} isDisabled={index !== 1} />
            )}
          </div>
        </div>
        <div
            className='d-flex flex-row justify-space-between'
            style={isFilterConditionGroup(condition) ? {  backgroundColor: '#f3f4f6', border: '1px solid #e5e7eb', paddingRight: 7, paddingTop: 7 } : {width: 600, alignItems: 'center'}}
        >
          {isFilterConditionGroup(condition) ? (
            <FilterFieldConditionGroupRow
              condition={condition}
              index={index}
              updateFilterCondition={updateFilterCondition}
              removeFilter={() => removeFilteringField(index)}
              operator={condition.operator}
              fields={fields}
              onChildPopover={onChildPopover}
            />
          ) : (
            <FilterFieldConditionRow
              condition={condition}
              index={index}
              updateFilteringField={updateFilteringField}
              removeFilter={() => removeFilteringField(index)}
              operator={operator}
              fields={fields}
              onChildPopover={onChildPopover}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FilterFieldCondition;
