import { debounce, find as _find, get as _get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateDealRecord, updateDealRecordByField } from '../../../../data/deals/actions';
import { getRawSelectedDeal } from '../../../../data/deals/selectors';
import { useAppDispatch } from '../../../../data/store';
import { DealRecord } from '../../../../data/types';

interface TitleCompany {
  id: string;
  company: string;
  state: string;
  email: string;
  phone: string;
  contactName: string;
}
const formatTitleCompany = (
  id: string,
  company: string,
  state: string,
  email: string,
  phone: string,
  contactName: string,
): TitleCompany => ({ id, company, state, email, phone, contactName });

const titleCompanyInfo = [
  formatTitleCompany('1', 'BCHH, Inc.', 'Alabama', 'alabama@bchhinc.com', '(412) 249-8241', ''),
  formatTitleCompany('2', 'BCHH, Inc.', 'Arizona', 'arizon@bchhinc.com', '(412) 249-8241', ''),
  formatTitleCompany('3', 'BCHH, Inc.', 'Colorado', 'colorado@bchhinc.com', '(412) 249-8241', ''),
  formatTitleCompany('4', 'AMZ Title, LLC', 'Florida', 'neworders@amztitle.com', '(813) 200-6130', ''),
  formatTitleCompany('5', 'The McNamee Firm, PC', 'Georgia', 'closings2@themcnameefirm.com', '(901) 472-2640', ''),
  formatTitleCompany('6', 'BCHH, Inc.', 'Indiana', 'indiana@bchhinc.com', '(412) 249-8241', ''),
  formatTitleCompany('7', 'BCHH, Inc.', 'Kansas', 'kansas@bchhinc.com', '(316) 320-2202', ''),
  formatTitleCompany('8', 'BCHH, Inc.', 'Kentucky', 'kentucky@bchhinc.com', '(316) 320-2202', ''),
  formatTitleCompany('9', 'BCHH, Inc.', 'Missouri', 'missouri@bchhinc.com', '(316) 320-2202', ''),
  formatTitleCompany('10', 'BCHH, Inc.', 'Nevada', 'nevada@bchhinc.com', '(412) 249-8241', ''),
  formatTitleCompany('11', 'Costner Law', 'North Carolina', 'westcor@costnergroup.com', '(980) 219-7637', ''),
  formatTitleCompany('12', 'BCHH, Inc.', 'Ohio', 'ohio@bchhinc.com', '(216) 553-4300', ''),
  formatTitleCompany('13', 'BCHH, Inc.', 'Oklahoma', 'oklahoma@bchhinc.com', '(412) 249-8241', ''),
  formatTitleCompany(
    '14',
    'Summit Title & Escrow, LLC Tennessee.',
    'Tennessee',
    'orders@summittitleandescrow.com',
    '(901) 881-8600',
    '',
  ),
  formatTitleCompany('15', 'BCHH, Inc.', 'Texas', 'texas@bchhinc.com', '(469) 317-7866', ''),
];

const TITLE_COMPANY_NAME_FIELD_ID = 'f24a8b54-ee45-4aac-9f11-34b8d31f2560';
const TITLE_COMPANY_EMAIL_FIELD_ID = 'f5fda757-cf9e-4c60-9018-7a34efdb9a1b';
const TITLE_COMPANY_PHONE_FIELD_ID = 'd7903507-b04f-4c66-bfb6-20f0a2e63c4d';
const TITLE_COMPANY_CONTACT_FIELD_ID = '3a38bb1d-370d-43ba-ae05-492a3eb61029';

export const TitleCompanyInfoSection = ({ deal }: { deal: DealRecord }) => {
  const dispatch = useAppDispatch();
  const rawDeal = useSelector(getRawSelectedDeal);
  const debouncedUpdateDeal = useMemo(() => {
    return debounce((companyName, email, phone, contactName) => {
      if (rawDeal !== undefined) {
        dispatch(
          updateDealRecord({
            ...rawDeal,
            fields: {
              ...rawDeal.fields,
              [TITLE_COMPANY_NAME_FIELD_ID]: companyName,
              [TITLE_COMPANY_EMAIL_FIELD_ID]: email,
              [TITLE_COMPANY_PHONE_FIELD_ID]: phone,
              [TITLE_COMPANY_CONTACT_FIELD_ID]: contactName,
            },
          }),
        );
      }
    }, 1000);
  }, [rawDeal, dispatch]);

  const [titleCompanyId, setTitleCompany] = useState<number | string>('');
  const [titleCompanyName, setTitleCompanyName] = useState(_get(deal, `fields.${TITLE_COMPANY_NAME_FIELD_ID}`, ''));
  const [titleCompanyContactName, setTitleCompanyContactName] = useState(
    _get(deal, `fields.${TITLE_COMPANY_CONTACT_FIELD_ID}`, ''),
  );
  const [titleCompanyEmail, setTitleCompanyEmail] = useState(_get(deal, `fields.${TITLE_COMPANY_EMAIL_FIELD_ID}`, ''));
  const [titleCompanyPhone, setTitleCompanyPhone] = useState(_get(deal, `fields.${TITLE_COMPANY_PHONE_FIELD_ID}`, ''));

  const onChangeTitleCompanyName = useCallback(
    (e) => {
      setTitleCompanyName(e.target.value ?? '');
      debouncedUpdateDeal(e.target.value ?? '', titleCompanyEmail, titleCompanyPhone, titleCompanyContactName);
    },
    [debouncedUpdateDeal, titleCompanyContactName, titleCompanyEmail, titleCompanyPhone],
  );
  const onChangeTitleCompanyPhone = useCallback(
    (e) => {
      setTitleCompanyPhone(e.target.value ?? '');
      debouncedUpdateDeal(titleCompanyName, titleCompanyEmail, e.target.value ?? '', titleCompanyContactName);
    },
    [debouncedUpdateDeal, titleCompanyContactName, titleCompanyEmail, titleCompanyName],
  );
  const onChangeTitleCompanyEmail = useCallback(
    (e) => {
      setTitleCompanyEmail(e.target.value ?? '');
      debouncedUpdateDeal(titleCompanyName, e.target.value ?? '', titleCompanyPhone, titleCompanyContactName);
    },
    [debouncedUpdateDeal, titleCompanyContactName, titleCompanyName, titleCompanyPhone],
  );
  const onChangeTitleCompanyContactName = useCallback(
    (e) => {
      setTitleCompanyContactName(e.target.value ?? '');
      debouncedUpdateDeal(titleCompanyName, titleCompanyEmail, titleCompanyPhone, e.target.value ?? '');
    },
    [debouncedUpdateDeal, titleCompanyEmail, titleCompanyName, titleCompanyPhone],
  );

  const onChangeTitleCompanySelect = useCallback(
    (e) => {
      setTitleCompany(e.target.value ?? null);
      if (e.target.value !== '') {
        const titleCo = _find(titleCompanyInfo, { id: e.target.value });
        setTitleCompanyName(titleCo!.company);
        setTitleCompanyContactName(titleCo!.contactName);
        setTitleCompanyPhone(titleCo!.phone);
        setTitleCompanyEmail(titleCo!.email);
        debouncedUpdateDeal(titleCo!.company, titleCo!.email, titleCo!.phone, titleCo!.contactName);
      }
    },
    [debouncedUpdateDeal],
  );

  return (
    <div className="p-3">
      <div className="mb-3">
        <select
          onChange={onChangeTitleCompanySelect}
          value={titleCompanyId}
          className="form-select"
          aria-label="Default select example"
        >
          <option key="choose title co" value="">
            -- Choose Title Co --
          </option>
          {titleCompanyInfo.map((titleCo, idx) => (
            <option key={`title-${idx}`} value={titleCo.id}>
              {titleCo.company} {titleCo.state}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="title-co-name" className="form-label">
          Title Company Name
        </label>
        <input
          value={titleCompanyName}
          onChange={onChangeTitleCompanyName}
          type="text"
          className="form-control"
          id="title-co-name"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="title-co-contact" className="form-label">
          Title Company Contact First and Last Name
        </label>
        <input
          value={titleCompanyContactName}
          onChange={onChangeTitleCompanyContactName}
          type="text"
          className="form-control"
          id="title-co-contact"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="title-co-email" className="form-label">
          Title Company Email
        </label>
        <input
          value={titleCompanyEmail}
          onChange={onChangeTitleCompanyEmail}
          type="email"
          className="form-control"
          id="title-co-email"
        />
      </div>
      <div className="mb-3">
        <label htmlFor="title-co-phone" className="form-label">
          Title Company Phone
        </label>
        <input
          value={titleCompanyPhone}
          onChange={onChangeTitleCompanyPhone}
          type="phone"
          className="form-control"
          id="title-co-phone"
        />
      </div>
    </div>
  );
};

export default TitleCompanyInfoSection;
