import { ContentType, Field, FieldType, getFieldConfigDefault } from '@taida-corp/taidacorp-sdk';
import { find as _find } from 'lodash';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSelectedContentType } from '../../../../../../data/content-type/content-types/selectors';
import { updateField } from '../../../../../../data/content-type/fields/actions';
import { useAppDispatch } from '../../../../../../data/store';
import { EnabledFieldTypeSelectOptions, FieldConfigEditors } from '../../../../components/Fields';

interface FieldConfigModalProps {
  hide: () => void;
  field: Field;
}

export const FieldConfigModal = ({ hide, field }: FieldConfigModalProps) => {
  const dispatch = useAppDispatch();
  const contentType = useSelector(getSelectedContentType);
  const [internalFieldType, setInternalFieldType] = useState(field.type);
  const [internalConfig, setInternalConfig] = useState<any|undefined>(field.config);
  const onChangeConfig = (e: any) => setInternalConfig(e);
  const onChangeFieldType = useCallback((e: any) => {
    const newFieldType = e.target.value;
    const newConfigForField = getFieldConfigDefault(newFieldType);
    setInternalFieldType(e.target.value);
    setInternalConfig(newConfigForField);
  }, []);
 
  const helpText = useMemo(() => {
    return _find(EnabledFieldTypeSelectOptions, { value: internalFieldType })!.helpText;
  }, [internalFieldType]);
  const ConfigControl = useMemo(() => {
    return FieldConfigEditors[internalFieldType]
  }, [internalFieldType]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(
        updateField({
          field: {
            ...field,
            type: internalFieldType,
            config: internalConfig
          },
          contentTypeSlug: contentType!.slug,
        }),
      );
    },
    [contentType, dispatch, field, internalConfig, internalFieldType],
  );
  return (
    <Modal size="lg" show={true} onHide={hide} keyboard={false}>
      <Modal.Header className="d-lg-none" closeButton>
        <Modal.Title>Field Type</Modal.Title>
      </Modal.Header>
      <Modal.Body className="h-100" style={{zIndex: 1000, minHeight: 600, maxHeight: 600, overflowY: 'scroll'}}>
        <form onSubmit={onSubmit} id='field-config-form'>
        <div className="mb-2">
          <select className="form-select" value={internalFieldType} onChange={onChangeFieldType}>
            {EnabledFieldTypeSelectOptions.map((fieldTypeOption) => (
              <option key={fieldTypeOption.value} value={fieldTypeOption.value}>
                {fieldTypeOption.label}
              </option>
            ))}
          </select>
          <small className="text-muted">{helpText}</small>
        </div>
        <div className="mb-3">
          <ConfigControl onChangeConfig={onChangeConfig} field={field} internalConfig={internalConfig} />
        </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex flex-row justify-content-end'>
        <button className='btn btn-outline-secondary' onClick={hide}>Cancel</button>
        <button type='submit' form='field-config-form' className='btn btn-primary ms-3'>Save</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
