import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import MePage from '../../pages/account/MePage';
import SignatureWizard from '../../pages/account/signature-wizard/SignatureWizard';
import DealDetailPage from '../../pages/deal-detail/DealDetailPage';
import Deals from '../../pages/deals/Deals';
import NewDealFormPage from '../../pages/new-deal-form/NewDealFormPage';

export const AccountRoutes = () => {
  return (
    <div className="h-100">
      <Routes>
        <Route path="*" element={<MePage />}>
          <Route path="profile" element={<div />} />
          <Route path="signature" element={<SignatureWizard />} />
          <Route path="*" element={<Navigate to="/me/signature" replace />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AccountRoutes;
