import { ContentType, RecordView } from '@taida-corp/taidacorp-sdk';
import classNames from 'classnames';
import CSVImporterTool from './csv-importer/CSVImporterTool';
import TableAutomationsToggle from './table-automations/TableAutomationsToggle';
import TableExporterTool from './table-exporter/TableExporterTool';
import ViewFilteringTool from './view-filtering/ViewFilteringTool';
import ViewMetaForm from './view-meta-form/ViewMetaForm';
import ViewPicker from './view-picker/ViewPicker';
import ViewSortingTool from './view-sorting/ViewSortingTool';

import './ViewToolbar.scss';

interface ViewToolbarProps {
  currentView?: RecordView;
  views: RecordView[];
  contentType: ContentType;
}

export const ViewToolbar = ({ currentView, views, contentType }: ViewToolbarProps) => {
  return (
    <div
      className={classNames({
        'view-toolbar': true,
      })}
    >
      <ViewPicker views={views} currentView={currentView} contentTypeSlug={contentType.slug} />
      {currentView && <ViewMetaForm currentView={currentView} />}
      {currentView && currentView.type === 'table' && <ViewFilteringTool currentView={currentView} />}
      {currentView && currentView.type === 'table' && <ViewSortingTool currentView={currentView} />}
      {currentView && currentView.type === 'table' && <TableExporterTool currentView={currentView} />}
      {currentView && currentView.type === 'table' && <CSVImporterTool currentView={currentView} contentType={contentType} />}
      <div className="flex-grow-1" />
      {currentView && currentView.type === 'table' && <TableAutomationsToggle />}
    </div>
  );
};

export default ViewToolbar;
