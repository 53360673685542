import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { label } from 'aws-amplify';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Campaign,
  Field,
  FilterCondition,
  FilterConditionGroup,
  FILTER_EXPRESSION,
  OPERATOR_OPTIONS,
  SignUpCampaign,
  FieldSelect,
  FieldMultiSelect,
  FieldText,
  ContactFieldDefinitions,
} from '../../../../../data/types';
import { remove, replace } from '../../../../../utils/array';
import { getFieldFilterConditionDefault } from '../../../../../utils/fieldFilters';
import FilterFieldCondition from '../../../../deals/deals-toolbar/ViewFilter/FilterFieldCondition';

interface SignUpCampaignOptionsProps {
  form: SignUpCampaign;
  setForm: React.Dispatch<React.SetStateAction<Campaign>>;
}

export const SignUpCampaignOptions = ({ form, setForm }: SignUpCampaignOptionsProps) => {
  const onChange = useCallback(
    (e) => {
      setForm({
        ...form,
        options: {
          ...form.options,
        },
      } as any);
    },
    [form, setForm],
  );
  const allFields: Field[] = useMemo(
    () => ContactFieldDefinitions,
    [],
  );
  const addFilteringFieldGroup = useCallback(() => {
    const { operator, conditions } = form.options.filters || { operator: 'and', conditions: [] };
    const newCondition = getFieldFilterConditionDefault(allFields[0]);
    const newConditionGroup: FilterConditionGroup = { operator: 'and', conditions: [newCondition] };
    setForm(
      (curr) =>
        ({
          ...curr,
          options: {
            filters: { operator, conditions: [...conditions, newConditionGroup] },
          },
        } as SignUpCampaign),
    );
  }, [allFields, form.options.filters, setForm]);
  const addFilteringField = useCallback(() => {
    const { operator, conditions } = form.options.filters || { operator: 'and', conditions: [] };
    const newCondition = getFieldFilterConditionDefault(allFields[0]);
    setForm(
      (curr) =>
        ({
          ...curr,
          options: { filters: { operator, conditions: [...conditions, newCondition] } },
        } as SignUpCampaign),
    );
  }, [allFields, form.options.filters, setForm]);
  const removeFilteringField = useCallback(
    (index: number) => {
      const { operator, conditions } = form.options.filters || { operator: 'and', conditions: [] };
      const newConditions = remove(conditions, index);
      setForm(
        (curr) =>
          ({
            ...curr,
            options: { filters: { operator, conditions: newConditions } },
          } as SignUpCampaign),
      );
    },
    [form.options.filters, setForm],
  );
  const updateFilteringField = useCallback(
    (
      newField: string,
      newExpression: FILTER_EXPRESSION,
      newOption: string | undefined,
      newCompareTo: any,
      index: number,
    ) => {
      const { operator, conditions } = form.options.filters || { operator: 'and', conditions: [] };
      const newConditions: FilterCondition[] = replace(
        conditions,
        {
          fieldId: newField,
          expression: newExpression,
          option: newOption,
          compareTo: newCompareTo,
        } as FilterCondition,
        index,
      );
      setForm(
        (curr) =>
          ({
            ...curr,
            options: { filters: { operator, conditions: newConditions } },
          } as SignUpCampaign),
      );
    },
    [form.options.filters, setForm],
  );
  const updateOperator = useCallback(
    (newOperator: OPERATOR_OPTIONS) => {
      const { conditions } = form.options.filters || { operator: 'and', conditions: [] };
      setForm((curr) => ({
        ...curr,
        filters: { operator: newOperator, conditions },
      }));
    },
    [form.options.filters, setForm],
  );
  const updateFilterCondition = useCallback(
    (newFilters: FilterConditionGroup) => {
      setForm(
        (curr) =>
          ({
            ...curr,
            options: { filters: newFilters },
          } as SignUpCampaign),
      );
    },
    [setForm],
  );

  useEffect(() => {
    if (form.options.filters === undefined) {
      setForm(
        (curr) =>
          ({
            ...curr,
            options: { filters: { operator: 'and', conditions: [] } },
          } as SignUpCampaign),
      );
    }
  }, [form.options.filters, setForm]);

  if (form.options.filters === undefined) {
    return null;
  }

  return (
    <>
      <div className="mb-3">
        <label className="form-label">Filters</label>
        {form.options.filters.conditions.length === 0 && (
          <div>
            <small className="text-muted">No filters applied to this sign up email</small>
          </div>
        )}
        {form.options.filters.conditions.length > 0 && (
          <div className="view-filter__current-fields-list">
            <div className="text-secondary mb-1">Which contacts should get this sign up email:</div>

            {form.options.filters.conditions.map((condition, conditionIndex) => {
              return (
                <FilterFieldCondition
                  key={conditionIndex}
                  operator={form.options.filters.operator}
                  condition={condition}
                  fields={allFields}
                  index={conditionIndex}
                  updateFilteringField={updateFilteringField}
                  removeFilteringField={removeFilteringField}
                  updateOperator={updateOperator}
                  onChildPopover={() => {}}
                  updateFilterCondition={updateFilterCondition}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="mb-3">
        <button onClick={addFilteringField} className="btn btn-link text-black ps-0">
          <small className="text-muted">
            <FontAwesomeIcon className="ms-2" icon={faPlus} /> Add Condition
          </small>
        </button>
        <button onClick={addFilteringFieldGroup} className="btn btn-link text-black ps-0">
          <small className="text-muted">
            <FontAwesomeIcon className="ms-2" icon={faPlus} /> Add Condition Group
          </small>
        </button>
      </div>
    </>
  );
};
