import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { DealActivity } from '../types';
import { fetchDealActivities, createDealActivity, realTimeDealActivityCreated, realTimeDealActivityDeleted, realTimeDealActivityUpdated} from './actions';


interface DealActivitiesState {
  dealActivities: Record<string, DealActivity>;
  isLoading: boolean;
  isInitialized: boolean;
  isSending: boolean;
}

const initialState: DealActivitiesState = {
  dealActivities: {},
  isLoading: false,
  isInitialized: false,
  isSending:false,
};

export const dealActivitiesSlice = createSlice({
  name: 'dealActivities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    
    builder.addCase(fetchDealActivities.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDealActivities.fulfilled, (state, { payload }) => {
      state.dealActivities = keyBy(payload, 'dealActivityId');
      state.isLoading = false;
      state.isInitialized = true;
    });
    builder.addCase(fetchDealActivities.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isInitialized = true;
    });

    builder.addCase(createDealActivity.pending, (state, { payload }) => {
      state.isSending = true;
    });
    builder.addCase(createDealActivity.fulfilled, (state, { payload }) => {

      state.dealActivities =  {...state.dealActivities, [payload.dealActivityId]: payload};
      state.isSending = false;
    });
    builder.addCase(createDealActivity.rejected, (state, { payload }) => {
      state.isSending = false;
    });
    builder.addCase(realTimeDealActivityUpdated, (state, { payload }) => {
      const { dealActivity: message } = payload;
      state.dealActivities = {...state.dealActivities, [message.dealActivityId]: message};
    });
    builder.addCase(realTimeDealActivityCreated, (state, { payload }) => {
      const { dealActivity: message } = payload;
      state.dealActivities = {...state.dealActivities, [message.dealActivityId]: message};
    });
    builder.addCase(realTimeDealActivityDeleted, (state, { payload }) => {
      const { dealActivity: message } = payload;
      delete state.dealActivities[message.dealActivityId];
    });
  },
});

export default dealActivitiesSlice;
