import { find as _find, findIndex as _findIndex, isEqual as _isEqual } from 'lodash';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  APIView,
  APIViewField,
  Field,
  FieldType,
  FormulaDependency,
  Template,
  TemplateEditorOption,
  User,
  ValidationRule,
} from '../../../data/types';
import { API_VIEW_FIELD_HELPTEXT } from '../../Fields';
import TemplateEditor from '../../TemplateEditor/TemplateEditor';

interface APITemplateFieldProps {
  defaultTemplateOptions: TemplateEditorOption[];
  field: Field;
  users: User[];
  view: APIView;
  setInternalView: React.Dispatch<React.SetStateAction<APIView>>;
}

export const APITemplateField = ({
  view,
  field,
  defaultTemplateOptions,
  users,
  setInternalView,
}: APITemplateFieldProps) => {
  const viewField = useMemo(() => {
    let _viewField: APIViewField | undefined = _find(view.properties.fields, { fieldId: field.fieldId });
    if (_viewField === undefined) {
      _viewField = {
        fieldId: field.fieldId,
        isVisible: true,
        template: {
          templateSource: '',
          templateDependencies: [],
        },
        validationRules: [],
      };
    }
    if (_viewField.template === undefined) {
      _viewField = {
        ..._viewField,
        template: {
          templateSource: '',
          templateDependencies: [],
        },
      };
    }
    return _viewField;
  }, [field, view]);
  const [validationRules, setValidationRules] = useState<ValidationRule[]>(viewField.validationRules);
  const [template, setTemplate] = useState(viewField.template);
  const doUpsert = !!viewField.doUpsert;
  const isRequired = useMemo(() => {
    return validationRules.includes({ type: 'is required', message: 'This field is required' });
  }, [validationRules]);

  const updateViewField = useCallback(
    (updatedViewField: APIViewField) => {
      setInternalView((_view) => {
        let fields = [..._view.properties.fields];
        const viewFieldIndex = _findIndex(fields, { fieldId: field.fieldId });
        if (viewFieldIndex !== -1) {
          fields.splice(viewFieldIndex, 1, updatedViewField);
        } else {
          fields = [...fields, updatedViewField];
        }
        return {
          ..._view,
          properties: {
            ..._view.properties,
            fields,
          },
        };
      });
    },
    [setInternalView, field],
  );
  const updateTemplate = useCallback((newSource: string, newDeps: FormulaDependency[]) => {
    setTemplate({
      templateSource: newSource,
      templateDependencies: newDeps,
    });
  }, []);
  const updateDoUpsert = useCallback((e) => {
    const newDoUpsert = !!e.target.checked;
    updateViewField({
      ...viewField,
      doUpsert: newDoUpsert
    })
  }, [updateViewField, viewField]);
  const updateIsRequired = useCallback((e) => {
    const newIsRequired = !!e.target.checked;
    setValidationRules(newIsRequired ? [{type: 'is required', message: 'This field is required'}] : []);
  }, []);

  useEffect(() => {
    const newValidationRules: ValidationRule[] = isRequired
      ? [{ type: 'is required', message: 'This field is required' }]
      : [];
    if (!_isEqual(viewField.validationRules, newValidationRules) || !_isEqual(template, viewField.template)) {
      updateViewField({
        ...viewField,
        validationRules: newValidationRules,
        template,
      });
    }
  }, [template, isRequired, updateViewField, viewField]);

  const templateOptions: TemplateEditorOption[] = useMemo(() => {
    if (field.type === 'select' || field.type === 'multi-select') {
      const selectOptions: TemplateEditorOption = {
        label: 'Field Options',
        options: field.config.options.map((option):FormulaDependency => ({
          label: option.label,
          identifier: `fieldOptions.${field.fieldId}.values.${option.value}.value`,
        })),
      };
      return [...defaultTemplateOptions, selectOptions];
    }
    if (field.type === 'select-user' || field.type === 'multi-select-user') {
      const usersOptions: TemplateEditorOption = {
        label: 'Users',
        options: users.map((user):FormulaDependency => ({
          label: user.name,
          identifier: `users.${user.userId}.userId`,
        })),
      };
      return [...defaultTemplateOptions, usersOptions];
    }
    return defaultTemplateOptions;
  }, [field, defaultTemplateOptions, users]);
  if ((['formula'] as FieldType[]).indexOf(field.type) !== -1) {
    return (
      <div className="mb-3" key={field.fieldId}>
        <div>
          <label>{field.name}</label>
          <input type="text" disabled className="form-control" placeholder="This field is not writable" />
        </div>
      </div>
    );
  }

  return (
    <div className="mb-3" key={field.fieldId}>
      <div>
        <label>{field.name}</label>
        <TemplateEditor value={template} onChange={updateTemplate} templateOptions={templateOptions} />
        <small>{API_VIEW_FIELD_HELPTEXT[field.type] ?? ''}</small>
      </div>
      <div className="form-check">
        <input
          id={`do-${field.fieldId}-upsert`}
          className="form-check-input"
          type="checkbox"
          checked={doUpsert}
          onChange={updateDoUpsert}
        />
        <label htmlFor={`do-${field.fieldId}-upsert`} className="form-check-label">
          Upsert {field.name}
        </label>
      </div>
      <div className="form-check">
        <input
          id={`is-${field.fieldId}-required`}
          className="form-check-input"
          type="checkbox"
          checked={isRequired}
          onChange={updateIsRequired}
        />
        <label htmlFor={`is-${field.fieldId}-required`} className="form-check-label">
          {field.name} Is Required
        </label>
      </div>
    </div>
  );
};

export default APITemplateField;
