import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface NumberSortOptionProps {
    isSelected: boolean,
    setSelected: () => void;
}

export const NumberSortAscendingComponent = ({isSelected, setSelected}: NumberSortOptionProps) => {
    const btnClass = classNames('field-sort-button', {
        'field-sort-button--is-selected': isSelected,
    })
    return (
        <div className={btnClass} onClick={setSelected}>1 <FontAwesomeIcon icon={faArrowRight} /> 9</div>
    )
}

export const NumberSortDescendingComponent = ({isSelected, setSelected}: NumberSortOptionProps) => {
    const btnClass = classNames('field-sort-button', {
        'field-sort-button--is-selected': isSelected,
    })
    return (
        <div className={btnClass} onClick={setSelected}>9 <FontAwesomeIcon icon={faArrowRight} /> 1</div>
    )
}
