import React, { useEffect, useState } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsConfig from './taidacorp-aws-config';
import 'bootstrap/dist/css/bootstrap.min.css';
import useAuth from './hooks/auth/useAuth';
import FullPageLoader from './components/Loader/FullPageLoader';
import AnonymousRoutes from './navigation/routes/AnonymousRoutes';
import MainRoutes from './navigation/routes/MainRoutes';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faCoffee,
  faHome,
  faChartLine,
  faClipboardCheck,
  faPlusCircle,
  faMoneyCheck,
  faLink,
  faRocket,
  faCalendar,
  faTable,
  faGripHorizontal,
  faColumns,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons';
import {faTrello} from '@fortawesome/free-brands-svg-icons'

import './App.scss';

const icons = [
  faCheckSquare,
  faCoffee,
  faHome,
  faChartLine,
  faClipboardCheck,
  faPlusCircle,
  faMoneyCheck,
  faLink,
  faRocket,
  faCalendar,
  faTable,
  faGripHorizontal,
  faColumns,
  faChevronCircleDown,
  faTrello]
  
library.add(...icons as IconDefinition[]);

Amplify.configure(awsConfig);

const App = () => {
  const { user, isInitialized } = useAuth();

  if (!isInitialized) {
    return <FullPageLoader />;
  }

  if (user === undefined) {
    return <AnonymousRoutes />;
  }
  return <MainRoutes />;
};

export default App;
